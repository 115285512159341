import { call, put, takeLatest } from "@redux-saga/core/effects";

import { getErrorMessageFromAPI } from "../../../util/utilz";
import { message } from "antd";
import {
  addTeamMemberInError,
  addTeamMemberSuccess,
  deleteTeamMemberError,
  deleteTeamMemberSuccess,
  getTeamMemberListInError,
  getTeamMemberListInSuccess,
  updateTeamMemberInError,
  updateTeamMemberSuccess,
} from "./action/teamMemberAction";
import {
  addTeamMemberInstanceListEffect,
  deleteTeamMemberInstanceListEffect,
  getRolesInstanceListEffect,
  getTeamMemberInstanceListEffect,
  updateTeamMemberInstanceListEffect,
} from "./saga/effects/teamMemberEffects";
import Message from "../../../components/Message/Message";

import {
  ADD_TEAM_MEMBER_IN_PROGRESS,
  DELETE_TEAM_MEMBER_IN_PROGRESS,
  GET_ROLESlIST_IN_PROGRESS,
  GET_TEAM_MEMBER_IN_PROGRESS,
  UPDATE_TEAM_MEMBER_IN_PROGRESS,
} from "../../../constants/ActionTypes";

function* getTeamMemberList({ payload }) {
  try {
    const { data } = yield call(getTeamMemberInstanceListEffect, payload);

    yield put(
      getTeamMemberListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getTeamMemberListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addTeamMember({ payload }) {
  try {
    let { data } = yield call(
      addTeamMemberInstanceListEffect,
      payload.jsonData
    );

    yield put(
      addTeamMemberSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
  } catch (e) {
    yield put(
      addTeamMemberInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
  }
}

function* updateTeamMember({ payload }) {
  try {
    let { data } = yield call(
      updateTeamMemberInstanceListEffect,
      payload.jsonData
    );

    yield put(
      updateTeamMemberSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
  } catch (e) {
    yield put(
      updateTeamMemberInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
  }
}
function* deleteTeamMember({ payload }) {
  try {
    let { data } = yield call(deleteTeamMemberInstanceListEffect, payload);

    yield put(
      deleteTeamMemberSuccess({
        data: data,
        success: true,
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteTeamMemberError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
    }
  }
}

export default function* teamMemberSaga() {
  yield takeLatest(GET_TEAM_MEMBER_IN_PROGRESS, getTeamMemberList);
  yield takeLatest(ADD_TEAM_MEMBER_IN_PROGRESS, addTeamMember);

  yield takeLatest(UPDATE_TEAM_MEMBER_IN_PROGRESS, updateTeamMember);
  yield takeLatest(DELETE_TEAM_MEMBER_IN_PROGRESS, deleteTeamMember);
}
