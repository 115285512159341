import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addSpecializationInProgress } from "../../../appRedux/doctor/actions/Specialization";
import SpecializationForm from "./SpecializationForm";

const AddNewSpecialization = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const specializationData = useSelector((state) => state.specialization);

  const specializationStatus = specializationData?.addSpecialization;

  const onFinish = (datas) => {
    let formData = new URLSearchParams();
    formData.append("Name", datas.Name);

    dispatch(addSpecializationInProgress(formData));
  };
  useEffect(() => {
    if (specializationStatus?.success) {
      history.push("/specialization");
    }
  }, [specializationStatus?.success]);
  return (
    <SpecializationForm
      onFinish={onFinish}
      title={"Addnew.specialization.label.new"}
      buttonName={"submit"}
    />
  );
};

export default AddNewSpecialization;
