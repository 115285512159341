import React, { useState } from "react";
import {
  Row,
  Col,
  Spin,
  Modal,
  Typography,
  Form,
  Popover,
  Button,
  Tooltip,
} from "antd";
import {
  LoadingOutlined,
  PrinterFilled,
  DeleteFilled,
  EyeFilled,
  EditFilled,
  ExclamationCircleFilled,
  CloseCircleFilled,
  RedoOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import "../../../../styles/modules/pharmacy.less";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../../components/Button/ButtonComponent";

import TableComponent from "../../../../components/Table/TableComponent";

import IntlMessages from "../../../../util/IntlMessages";
import TextArea from "antd/lib/input/TextArea";
import RecordPayment from "./RecordPayment";
import {
  defaultStateAddUpdatePayment,
  deleteSalesInProgress,
  getListSalesInProgress,
  salesCancelUndoInProgress,
  slesPaymentInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import { useEffect } from "react";
import moment from "moment";
import { salesTypeList } from "../../../Doctor/data";
import { access } from "../../../../util/utilz";

const SalesTable = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ids, setIds] = useState("");
  const [idModel, setIdModel] = useState("");
  const [salesModalOpen, setSalesModalOpen] = useState(false);
  const [salesId, setSalesId] = useState("");
  const [salesIds, setSalesIds] = useState("");
  const [cancelledId, setCancelledId] = useState([{ soNo: "", reason: "" }]);
  const [Loading, setLoading] = useState(false);
  const [paymentDataModal, setpaymentDataModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { getSalesList, deleteSales, addSalesPayment, cancelUndo } =
    useSelector((state) => state?.pharmacy);
  const orgId = localStorage.getItem("org_id");

  const totalRecords = getSalesList.data?.totalRecords;
  useEffect(() => {
    const formData = { page: 1, orgId: orgId, pagination: true };
    dispatch(getListSalesInProgress(formData));
    dispatch(defaultStateAddUpdatePayment());
  }, []);

  useEffect(() => {
    if (getSalesList?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getSalesList]);
  useEffect(() => {
    if (
      deleteSales?.success ||
      cancelUndo?.success ||
      addSalesPayment?.success
    ) {
      const formData = { page: 1, orgId: orgId, pagination: true };
      dispatch(getListSalesInProgress(formData));
      setSalesModalOpen(false);
      form.resetFields();
      setpaymentDataModal(false);
    }
  }, [deleteSales?.success, cancelUndo?.success, addSalesPayment?.success]);

  const showModal = (id) => {
    setIds(id);
    setIsModalOpen(true);
  };
  const showSalesModal = (record) => {
    setSalesModalOpen(true);
    setSalesId(record?.sales_code);
    setSalesIds(record?.id);
  };

  const handleOk = () => {
    dispatch(deleteSalesInProgress(ids));
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const salesHandleOk = () => {
    setSalesModalOpen(false);
  };
  const handlePaymentModalOk = () => {
    setpaymentDataModal(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const formData = { page: page, orgId: orgId, pagination: true };
    dispatch(getListSalesInProgress(formData));
  };
  const onFinish = async (datas) => {
    const values = await form.validateFields();

    const cancelledSales = {
      sales_id: salesIds,
      reason: datas?.reason,
      type: 2,
    };
    dispatch(salesCancelUndoInProgress(cancelledSales));
  };
  const handleCancelUndo = (record) => {
    const cancelledSales = {
      sales_id: record?.id,
      reason: "",
      type: 1,
    };
    dispatch(salesCancelUndoInProgress(cancelledSales));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(PurchaseRequestList);
    } else {
      const newData = PurchaseRequestList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const columns = [
    {
      title: "SO.No",
      dataIndex: "sales_code",
      key: "soNo",
      width: 150,
    },
    {
      title: "Patient Type",
      dataIndex: "patient_type",
      key: "patient_type",
      width: 300,
      render: (total) => (
        <>
          {
            salesTypeList.find((data) => Number(data.value) === Number(total))
              ?.label
          }
        </>
      ),
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      key: "patient_name",
      width: 300,
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      key: "doctorName",
      width: 300,
    },
    {
      title: "Medicine",
      dataIndex: "sales_details",
      key: "medicine",
      width: 300,
      render: (sales_details) =>
        sales_details.map((Package, i) => {
          return <p key={i}>{`${i + 1}. ${Package?.product_name}`}</p>;
        }),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => <>{Number(total).toFixed(2)}</>,
    },
    {
      title: "Paid",
      dataIndex: "deposite",
      key: "paid",
      render: (total) => <>{Number(total).toFixed(2)}</>,
    },
    {
      title: "Due",
      dataIndex: "due",
      render: (_, record) => {
        const isCancelled = Number(record?.status) === 2;
        if (record?.due <= 0) {
          return (
            <Typography className="red-text">
              {Number(record.due).toFixed(2)}
            </Typography>
          );
        } else {
          return (
            <>
              <Typography className="red-text">
                {Number(record.due).toFixed(2)}
              </Typography>
              {!isCancelled ? (
                <ButtonComponent
                  rightIcon={<CreditCardOutlined />}
                  label="Collect"
                  className="secondary-color"
                  onClick={() => (
                    setpaymentDataModal(true),
                    setIdModel(record.id),
                    form1.resetFields()
                  )}
                />
              ) : null}
            </>
          );
        }
      },
      width: 180,
    },

    {
      title: "Action",
      dataIndex: "Action",
      width: 300,
      render: (_, record) => {
        const isCancelled = Number(record?.status) === 2;

        return (
          <>
            {isCancelled && (
              <div>
                <>
                  <Row>
                    <Col>
                      <Typography className="red-color">
                        This Sales was cancelled
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="table-button">
                    <Col className="button-edit">
                      <ButtonComponent
                        className={"green-button "}
                        icon={<RedoOutlined />}
                        label={"Undo"}
                        onClick={() => handleCancelUndo(record)}
                      ></ButtonComponent>
                    </Col>
                    <Col className="button-edit">
                      <Popover
                        content={() => {
                          return (
                            <div>
                              <p>
                                Reason:
                                {record.reason}
                              </p>
                            </div>
                          );
                        }}
                        title="Reason"
                        trigger="hover"
                      >
                        <Button
                          type={"primary"}
                          icon={<ExclamationCircleFilled />}
                        >
                          Reason
                        </Button>
                      </Popover>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="button-edit">
                      {" "}
                      <Tooltip placement="top" title={"Print"}>
                        {" "}
                        <Link
                          to={{
                            pathname: `/sales/view`,
                            state: { salesData: record, print: "print" },
                          }}
                        >
                          <ButtonComponent
                            icon={<PrinterFilled />}
                            size="default"
                            className="secondary-color"
                          ></ButtonComponent>
                        </Link>
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              </div>
            )}
            {!isCancelled && (
              <>
                <Row className="table-button">
                  {access("sales", "view") && (
                    <Col className="button-edit">
                      {" "}
                      <Tooltip placement="top" title={"View"}>
                        {" "}
                        <Link
                          to={{
                            pathname: `/sales/view`,
                            state: { salesData: record },
                          }}
                        >
                          <ButtonComponent
                            type="primary"
                            icon={<EyeFilled />}
                            size="default"
                          ></ButtonComponent>
                        </Link>
                      </Tooltip>
                    </Col>
                  )}
                  {access("sales", "edit") && (
                    <Col className="button-edit">
                      {" "}
                      <Tooltip placement="top" title={"Edit"}>
                        {" "}
                        <Link
                          to={{
                            pathname: `/sales/edit`,
                            state: { salesData: record, table: "table" },
                          }}
                        >
                          <ButtonComponent
                            className="secondary-color"
                            icon={<EditFilled />}
                            size="default"
                            onClick={() => {
                              dispatch(defaultStateAddUpdatePayment());
                            }}
                          ></ButtonComponent>
                        </Link>
                      </Tooltip>
                    </Col>
                  )}
                  {access("sales", "delete") && (
                    <Col className="button-edit">
                      {" "}
                      <Tooltip placement="top" title={"Delete"}>
                        <Typography>
                          {" "}
                          <ButtonComponent
                            type="danger"
                            icon={<DeleteFilled />}
                            size="default"
                            onClick={() => showModal(record.id)}
                          ></ButtonComponent>
                        </Typography>
                      </Tooltip>
                    </Col>
                  )}
                  <Col className="button-edit">
                    {" "}
                    <Tooltip placement="top" title={"Print"}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/sales/view`,
                          state: { salesData: record, print: "print" },
                        }}
                      >
                        <ButtonComponent
                          icon={<PrinterFilled />}
                          size="default"
                          className="secondary-color"
                        ></ButtonComponent>
                      </Link>
                    </Tooltip>
                  </Col>
                </Row>
                <Row className="table-button">
                  <Col className="button-edit">
                    <ButtonComponent
                      icon={<CloseCircleFilled />}
                      onClick={() => showSalesModal(record)}
                      label={"cancel"}
                      type="primary"
                    ></ButtonComponent>
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      },
    },
  ];
  const onFinishPaymentModal = (datas) => {
    datas.sales_id = idModel;
    datas.date = moment(datas.date).format("YYYY-MM-DD");
    dispatch(slesPaymentInProgress(datas));
  };
  const PurchaseRequestList = getSalesList?.data?.data;
  return (
    <Spin
      indicator={antIcon}
      spinning={Loading || deleteSales?.inProgress || cancelUndo?.inProgress}
    >
      <TableComponent
        columns={columns}
        dataSource={PurchaseRequestList || []}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        rowselector={handleSelectChange}
        itemList={PurchaseRequestList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"patient_name"}
        documentTitle={"Sales List"}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        // pagination={{
        //   total: totalRecords,
        //   onChange: (page) => handlePage(page),
        // }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
      <Modal
        title={
          <Typography className="cancel-sales">
            Cancel Sales: {salesId}
          </Typography>
        }
        open={salesModalOpen}
        // onOk={salesHandleOk}
        // onCancel={salesHandleCancel}
        closable={false}
        footer={[
          <Button key="back" type="danger" onClick={salesHandleOk}>
            Close
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={<IntlMessages id={"Addnew.pharmacy.label.reason"} />}
                name="reason"
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.pharmacy.validator.reason"} />
                    ),
                  },
                ]}
                style={{ width: "100%" }}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <ButtonComponent
                label="Submit"
                htmlType="submit"
                type="primary"
              />
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={<Typography className="cancel-sales">Record Payment</Typography>}
        open={paymentDataModal}
        width={800}
        // onOk={salesHandleOk}
        // onCancel={salesHandleCancel}
        closable={false}
        footer={[
          <Button
            key="back"
            className="secondary-color"
            onClick={handlePaymentModalOk}
          >
            Close
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinishPaymentModal}
          autoComplete="off"
          form={form1}
        >
          <RecordPayment />
        </Form>
      </Modal>
    </Spin>
  );
};

export default SalesTable;
