import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import { useDispatch, useSelector } from "react-redux";
import {
  defaultRoomBooking,
  getAvailableRoomsListInProgress,
} from "../../../appRedux/Ward/WardAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Meta from "antd/lib/card/Meta";

const AvailableRoomListTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const roomBookedData = useSelector((state) => state?.ward?.roomBook);
  const availableRoomListData = useSelector(
    (state) => state?.ward?.availableRoomsList
  );

  const availableRoomList = availableRoomListData?.data?.data;
  const totalRecords = availableRoomListData?.data?.data?.totalRecords;
  const bookedRoomData = useSelector((state) => state?.ward?.roomBook);
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Block Name",
      dataIndex: ["Block", "block_name"],
      key: "block_name",
    },
    {
      title: "Ward Name",
      dataIndex: ["Ward", "ward_name"],
      key: "ward_name",
    },

    {
      title: "Room Number",
      dataIndex: "room_no",
      key: "room_no",
    },
    {
      title: "Room Incharge Name",
      dataIndex: "room_incharge_name",
      key: "room_incharge_name",
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
      render: (text, record) => {
        return record.room_type === "1" ? "AC ROOM" : "NON AC ROOM";
      },
    },
    {
      title: "Room Status",
      dataIndex: "room_status",
      key: "room_status",
      render: (text, record) => {
        return record.room_status === "1" ? "Active" : "Inactive";
      },
    },
    {
      title: "Room Price",
      dataIndex: "room_type",
      key: "room_type",
      render: (text, record) => {
        return record.room_type === "1"
          ? record.ac_room_price
          : record.non_ac_room_price;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/book-room`,
                  state: { room: record },
                }}
              >
                <ButtonComponent
                  type="primary"
                  label={"Book Room"}
                  disabled={record.room_status !== "1"}
                />
              </Link>
            </Col>{" "}
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/bookroom`,
                  state: { room: record },
                }}
              >
                <ButtonComponent
                  type="primary"
                  label={"Book-----Room"}
                  disabled={record.room_status !== "1"}
                />
              </Link>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = { pagination: "true", pageNo: page, pageSize: "10" };
    dispatch(getAvailableRoomsListInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(availableRoomList);
    } else {
      const newData = availableRoomList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    if (availableRoomListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [availableRoomListData]);
  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getAvailableRoomsListInProgress(payload));
  }, []);
  useEffect(() => {
    if (roomBookedData?.success) {
      history.push("/ward-management");
    }
  }, [roomBookedData]);
  useEffect(() => {
    if (bookedRoomData?.success) {
      dispatch(defaultRoomBooking());
    }
  }, [bookedRoomData]);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.availableRooms"} />}
          </Typography>
        </Col>
        <Col>
          {/* <Link to={APP_ROUTES.ADD_BLOCK}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newBlock"} />}
            />
          </Link> */}
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <Row gutter={[16, 16]}>
          {availableRoomList?.map((mapAvailableRoom) => (
            <Col xl={6} lg={6} md={8} sm={12} xs={24}>
              {" "}
              <Card
                type="inner"
                title={
                  <Typography className="ward-heading">{`Room Number - ${mapAvailableRoom?.room_no}`}</Typography>
                }
                actions={[
                  <Link
                    to={{
                      pathname: `/bookroom`,
                      state: { room: mapAvailableRoom },
                    }}
                  >
                    <ButtonComponent
                      type="primary"
                      label={"Book Room"}
                      disabled={mapAvailableRoom.room_status !== "1"}
                    />
                  </Link>,
                ]}
              >
                <Row gutter={[16, 16]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {" "}
                    <Meta
                      title={`Block Name - ${mapAvailableRoom?.Block?.block_name}`}
                      description={`Room Incharge - ${mapAvailableRoom?.room_incharge_name}`}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Typography>{`${
                      mapAvailableRoom?.room_type === "1" ? "AC" : "NON-AC"
                    } (${
                      mapAvailableRoom?.room_type === "1"
                        ? mapAvailableRoom.ac_room_price
                        : mapAvailableRoom.non_ac_room_price
                    })`}</Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        {/* <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : availableRoomList}
          rowselector={handleSelectChange}
          itemList={availableRoomList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"room_no"}
          documentTitle={"Available Room List"}
        /> */}
      </Spin>
    </Card>
  );
};

export default AvailableRoomListTable;
