import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import {
  addWardInProgress,
  editWardInProgress,
} from "../../../appRedux/Ward/WardAction";

const AddWard = ({ editWard, editWardData, blockId }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const blockData = location.state?.blockId;
  const [packageValue, setPackageValue] = useState("");
  const [languageValue, setLanguageValue] = useState("");
  const addWardData = useSelector((state) => state?.ward?.addWard);
  const editWardResponse = useSelector((state) => state?.ward?.editWard);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (datas) => {
    if (editWard) {
      const editPayload = {
        block_id: editWardData?.block_id,
        ward_name: datas?.ward_name,
        ward_incharge_name: datas?.ward_incharge_name,
        ac_room_price: datas?.ac_room_price,
        non_ac_room_price: datas?.non_ac_room_price,
        ward_status: datas?.ward_status,
      };
      dispatch(editWardInProgress({ id: editWardData?.id, editPayload }));
    } else {
      const payload = {
        block_id: blockData?.id,
        ward_name: datas?.ward_name,
        ward_incharge_name: datas?.ward_incharge_name,
        ac_room_price: datas?.ac_room_price,
        non_ac_room_price: datas?.non_ac_room_price,
        rooms_information: datas?.items.map((item) => ({
          room_no: item.room_no,
          room_type: item.room_type,
          room_incharge_name: item.room_incharge_name,
        })),
      };
      dispatch(addWardInProgress(payload));
    }
    if (addWardData?.inProgress || editWardResponse?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const [Loading, setLoading] = useState(false);

  const changeLanguage = (value) => {
    setLanguageValue(value);
  };

  const languageList = [
    { label: "Active", value: "1" },
    { label: "InActive", value: "2" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (editWard) {
      form.setFieldsValue(editWardData);
    }
  }, [editWard]);

  const addDefaultRow = useRef(null);
  useEffect(() => {
    if (addDefaultRow.current) {
      addDefaultRow.current();
    }
  }, []);

  useEffect(() => {
    if (addWardData?.success || editWardResponse?.success) {
      // history.push("/ward");
      history.push({
        pathname: "/ward",
        state: { blockData: editWard ? blockId : blockData },
      });
    }
  }, [addWardData, editWardResponse]);
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {!editWard ? (
                    <IntlMessages id={"label.addWard"} />
                  ) : (
                    <IntlMessages id={"label.editWard"} />
                  )}
                </Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"ward_name"}
                      label={<IntlMessages id={"Addnew.ward.label.wardName"} />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.wardName"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"ward_incharge_name"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.wardInchargeName"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.wardInchargeName"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="number"
                      name={"ac_room_price"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.acRoomPrice"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.acRoomPrice"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="number"
                      name={"non_ac_room_price"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.nonAcRoomPrice"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.nonAcRoomPrice"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  {!editWard ? (
                    <Col
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="mx-10"
                    >
                      <Typography className="header-tag">Rooms</Typography>
                      <Form.List name="items">
                        {(fields, { add, remove }) => {
                          addDefaultRow.current = add;
                          return (
                            <>
                              {fields.map((field, index) => (
                                <Card
                                  className="mx-10"
                                  size="small"
                                  title={
                                    <Typography className="header-tag mx-10">
                                      {`${field.name + 1}.Room`}
                                    </Typography>
                                  }
                                  key={field.key}
                                  extra={
                                    index > 0 && (
                                      <ButtonComponent
                                        label="Remove"
                                        className="btn-cancel secondary-color"
                                        onClick={() => remove(field.name)}
                                      />
                                    )
                                  }
                                >
                                  <Row className="mx-20">
                                    <Col
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={24}
                                      xs={24}
                                    >
                                      <FormInput
                                        labelCol={"24"}
                                        wrapperCol={"24"}
                                        type="text"
                                        name={[field.name, "room_no"]}
                                        label={
                                          <IntlMessages
                                            id={"Addnew.ward.label.roomNumber"}
                                          />
                                        }
                                        rules={[
                                          {
                                            required: true,
                                            message: (
                                              <IntlMessages
                                                id={
                                                  "Addnew.ward.validator.roomNumber"
                                                }
                                              />
                                            ),
                                          },
                                        ]}
                                      />
                                    </Col>
                                    <Col
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={24}
                                      xs={24}
                                    >
                                      <FormInput
                                        labelCol={"24"}
                                        wrapperCol={"24"}
                                        type="text"
                                        name={[
                                          field.name,
                                          "room_incharge_name",
                                        ]}
                                        label={
                                          <IntlMessages
                                            id={
                                              "Addnew.ward.label.roomInchargeName"
                                            }
                                          />
                                        }
                                        rules={[
                                          {
                                            required: true,
                                            message: (
                                              <IntlMessages
                                                id={
                                                  "Addnew.ward.validator.roomInchargeName"
                                                }
                                              />
                                            ),
                                          },
                                        ]}
                                      />
                                    </Col>
                                    <Col
                                      xl={12}
                                      lg={12}
                                      md={24}
                                      sm={24}
                                      xs={24}
                                    >
                                      <Form.Item
                                        labelCol={{
                                          span: 24,
                                        }}
                                        wrapperCol={{
                                          span: 24,
                                        }}
                                        label={
                                          <IntlMessages
                                            id={"Addnew.ward.label.roomType"}
                                          />
                                        }
                                        name={[field.name, "room_type"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: (
                                              <IntlMessages
                                                id={
                                                  "Addnew.ward.validator.roomType"
                                                }
                                              />
                                            ),
                                          },
                                        ]}
                                      >
                                        <FormSelectBox
                                          placeholder="Select room Type"
                                          value={languageValue}
                                          options={languageList}
                                          onChange={changeLanguage}
                                          showSearch="true"
                                        />
                                      </Form.Item>
                                    </Col>
                                    {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    {index > 0 && (
                                      <ButtonComponent
                                        label="Remove"
                                        className="btn-cancel secondary-color"
                                        onClick={() => remove(field.name)}
                                      />
                                    )}
                                  </Col> */}
                                  </Row>
                                </Card>
                              ))}

                              <ButtonComponent
                                icon={<CreditCardOutlined />}
                                label={"ADD"}
                                onClick={() => add()}
                                type="primary"
                              />
                            </>
                          );
                        }}
                      </Form.List>
                    </Col>
                  ) : (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <Form.Item
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        label={
                          <IntlMessages id={"Addnew.ward.label.wardStatus"} />
                        }
                        name={"ward_status"}
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.admin.validator.wardStatus"}
                              />
                            ),
                          },
                        ]}
                      >
                        <FormSelectBox
                          placeholder="Select Ward Status"
                          value={languageValue}
                          options={languageList}
                          onChange={changeLanguage}
                          showSearch="true"
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link
                  to={{
                    pathname: `/ward`,

                    state: { blockData: editWard ? blockId : blockData },
                  }}
                >
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editWard ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddWard;
