import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { Col, Row, Table } from "antd";
import Typography from "antd/lib/typography/Typography";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../../../styles/modules/pharmacy.less";

import { useHistory } from "react-router";
import {
  getPharmacyListInProgress,
  getPharmacyProfileInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ViewHeader from "../view/ViewHeader";

const ViewItemTransfer = (viewData) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [printingDone, setPrintingDone] = useState(false);
  const [pharmacyName, setPharmacyName] = useState();
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const ViewItemTransferData = location.state?.itemData;

  const pharmacyProfile = useSelector(
    (state) => state?.pharmacy?.pharmacyProfile?.data?.data
  );
  const pharmacyListData = useSelector(
    (state) => state?.pharmacy?.pharmacyList
  );
  const pharmacyList = pharmacyListData?.data?.data || [];
  useEffect(() => {
    if (pharmacyList) {
      const pharmayName = pharmacyList?.filter(
        (data) => data?.id === ViewItemTransferData?.select_pharmacy
      );

      setPharmacyName(pharmayName[0]?.firstName);
    }
  }, [ViewItemTransferData, pharmacyList]);

  const history = useHistory();
  const goBackHandler = () => {
    history.goBack();
  };
  const ItemTransferList = ViewItemTransferData?.itemTransferInformations;
  const columns = [
    {
      title: " Name",
      dataIndex: ["Tablet", "medicine_name"],
      key: "personName",
    },

    {
      title: "Quantity",
      dataIndex: "quanity",
      key: "quanity",
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batchNo",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
  ];
  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
    const formData = {
      pagination: false,
      pageNo: 1,
      pageSize: 1,
      orgId: localStorage.getItem("org_id"),
    };

    dispatch(getPharmacyListInProgress(formData));
  }, []);

  useEffect(() => {
    if (pharmacyProfile && !printingDone) {
      if (location.state?.type === "print" && pharmacyName) {
        window.print();
        setPrintingDone(true);
      }
    }
  }, [pharmacyProfile, printingDone, pharmacyName]);
  return (
    <Row justify="center" align="middle" gutter={[0, 16]}>
      <Col xl={22} lg={23} md={23} xs={23} sm={23}>
        <Row justify={"space-between"}>
          <Col>
            <Typography className="header-tag hide-on-print">
              <IntlMessages id={"label.viewItemTransfer"} />
            </Typography>
          </Col>
          <Col>
            {/* <ButtonComponent
              icon={<PrinterFilled />}
              size="large"
              className="secondary-color"
              // onClick={()=>editPatient(record.id)}
            ></ButtonComponent>{" "} */}
            {location.state?.type === "view" && (
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            )}
          </Col>
        </Row>
      </Col>
      <Col xl={22} lg={22} md={22} xs={22} sm={22} className="my-10">
        <Row>
          <ViewHeader
            headerType={"salesReturn"}
            headerData={ViewItemTransferData}
            profileData={pharmacyProfile}
          />
        </Row>
      </Col>
      <Col xl={20} lg={23} md={23} xs={23} sm={24}>
        <Row justify={"space-between"}>
          <Col xl={12} lg={12} md={12} xs={24} sm={12} className="py-4">
            <Row gutter={[0, 16]}>
              <Col xl={10} lg={10} md={12} xs={12} sm={10}>
                <Typography className="view-list-details py-2">
                  Person Name
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.person_name}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={10}>
                <Typography className="view-list-details py-2">
                  Description
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.description}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={10}>
                <Typography className="view-list-details py-2">
                  Department Name
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={14}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.Department?.name}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xl={12} lg={12} md={12} xs={24} sm={12} className="py-4">
            <Row gutter={[0, 16]}>
              <Col xl={10} lg={10} md={12} xs={12} sm={10}>
                <Typography className="view-list-details py-2">
                  Contact No
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.person_contact}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={10}>
                <Typography className="view-list-details py-2">Date</Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography className="view-list-details py-2">
                  {moment(ViewItemTransferData?.date).format("DD-MM-YYYY")}
                </Typography>
              </Col>
              <Col xl={10} lg={10} md={12} xs={12} sm={10}>
                <Typography className="view-list-details py-2">
                  Item Transfer To
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography className="view-list-details py-2">
                  {ViewItemTransferData?.item_transfer_to === 1
                    ? "Hospital"
                    : "Pharmacy"}
                </Typography>
              </Col>{" "}
              {ViewItemTransferData?.item_transfer_to === 2 && (
                <>
                  {" "}
                  <Col xl={10} lg={10} md={12} xs={12} sm={10}>
                    <Typography className="view-list-details py-2">
                      Pharmacy Name
                    </Typography>
                  </Col>
                  <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                    <Typography className="view-list-details py-2">
                      {pharmacyName}
                    </Typography>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xl={22} lg={22} md={22} xs={24} sm={24}>
        <Table
          columns={columns}
          dataSource={ItemTransferList}
          scroll={{ x: true }}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default ViewItemTransfer;
