import { Table } from "antd";
import React from "react";
import IntlMessages from "../../../util/IntlMessages";

const PurchaseFooters = ({ subtotal, totalGST, discount, total, roundoff }) => {
  const columns = [
    {
      title: "name",
      dataIndex: "name",
    },
    {
      title: "value",
      dataIndex: "value",
    },
  ];
  const input = [
    subtotal
      ? {
          name: <IntlMessages id={"Addnew.purchaseRequest.subTotal"} />,
          value: subtotal,
        }
      : null,
    totalGST
      ? {
          name: "GST (+)",
          value: totalGST,
        }
      : null,
    discount
      ? {
          name: "Discount (-)",
          value: discount,
        }
      : null,
    total
      ? {
          name: <IntlMessages id={"Addnew.purchaseRequest.total"} />,
          value: total,
        }
      : null,
    roundoff
      ? {
          name: <IntlMessages id={"Addnew.purchase.roundoffTotal"} />,
          value: roundoff,
        }
      : null,
  ];

  return (
    <div>
      <Table
        showHeader={false}
        columns={columns}
        dataSource={input.filter((element) => element !== null)}
        pagination={false}
        scroll={{ x: true }}
      />

      {/* {subtotal && (
          <>
            <Col xs={15} sm={20} md={21} xl={22}>
              <Typography className="total-font" text-wrap="nowrap">
               
              </Typography>
            </Col>
            <Col xs={9} sm={4} md={3} xl={2} align="left">
              <Typography className="total-font">:&nbsp;{subtotal}</Typography>
            </Col>
          </>
        )}

        {totalGST && (
          <>
            <Col xs={15} sm={20} md={21} xl={22}>
              <Typography className="total-font">
               
                (+)
              </Typography>
            </Col>

            <Col xs={9} sm={4} md={3} xl={2} align="left">
              <Typography className="total-font">:&nbsp;{totalGST}</Typography>
            </Col>
          </>
        )}

        {discount && (
          <>
            <Col xs={15} sm={20} md={21} xl={22}>
              <Typography className="total-font">
                
              </Typography>
            </Col>
            <Col xs={9} sm={4} md={3} xl={2} align="left">
              <Typography className="total-font">:&nbsp;{discount}</Typography>
            </Col>
          </>
        )}

        {total && (
          <>
            <Col xs={15} sm={20} md={21} xl={22}>
              <Typography className="total-font">
                <IntlMessages id={"Addnew.purchaseRequest.total"} />
              </Typography>
            </Col>
            <Col xs={9} sm={4} md={3} xl={2} align="left">
              <Typography className="total-font">:&nbsp;{total}</Typography>
            </Col>
          </>
        )}

        {roundoff?.toString() && (
          <>
            <Col xs={15} sm={20} md={21} xl={22}>
              <Typography className="total-font">
                <IntlMessages id={"Addnew.purchase.roundoffTotal"} />
              </Typography>
            </Col>
            <Col xs={9} sm={4} md={3} xl={2} align="left">
              <Typography className="total-font">:&nbsp;{roundoff}</Typography>
            </Col>
          </>
        )} */}
      {/* </Col> */}
      {/* </Row> */}
      {/* </Col> */}
      {/* </Row> */}
    </div>
  );
};

export default PurchaseFooters;
