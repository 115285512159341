import React from "react";
import Widget from "../Widget";
import "./card.less";

const UserCard = (props) => {
  const { totalCount, heading, image, headingColor } = props;
  return (
    <Widget
      styleName="gx-card-full gx-dot-arrow-hover box-shadow"
    
    >
      <div className="gx-user-wid-row">
        <div className="gx-user-wid gx-mr-3">
          <img alt="..." src={image} className="gx-object-cover" />
        </div>
        <div className="gx-user-wid-body gx-py-3 gx-pr-3">
          <div className="ant-row-flex">
            <h1
              className={`h1 gx-mr-1 gx-mb-1  gx-font-weight-medium gx-fs-xxl ${headingColor}`}
            >
              {totalCount}
            </h1>
          </div>
          <p className="gx-mb-1 gx-text-grey gx-fs-xl">
            {heading}
            {/* <br /> @example */}
          </p>
          {/* <div className="gx-dot-arrow">
            <div className="gx-bg-primary gx-hover-arrow">
              <i className="icon icon-long-arrow-right gx-text-white" />
            </div>
            <div className="gx-dot">
              <i className="icon icon-ellipse-v gx-text-primary" />
            </div>
          </div> */}
        </div>
      </div>
    </Widget>
  );
};

export default UserCard;
