import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { getAvabileStockListInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";

const AvabileStockList = () => {
  const moment = require("moment");
  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);
  const [stock, setStock] = useState(100);
  const [stockDisplay, setStockDisplay] = useState(100);
  const [stockHandle, setStockHandle] = useState(true);
  const expiryStockData = useSelector(
    (state) => state?.pharmacy?.getAvailableStockList
  );
  const expiryStockList = expiryStockData?.data?.data;
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Medicine Name",
      dataIndex: "medicine_name",
    },
    {
      title: "company Name",
      dataIndex: "company",
    },

    {
      title: "Total Available Stock",
      dataIndex: "total_available_stock",
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (stockHandle) {
      dispatch(getAvabileStockListInProgress({ stock: stock }));
    }
    setStockHandle(false);
  }, [, stockHandle]);
  useEffect(() => {
    if (expiryStockData?.inProgress) {
      setLoading(true);
    } else {
      setStockDisplay(stock);
      setLoading(false);
    }
  }, [expiryStockData]);
  return (
    <Card>
      <Spin indicator={antIcon} spinning={Loading}>
        <Row className="p-10 Add-button">
          <Col xs={24} className="display-flex mb-10 align-items ">
            <Typography className="header-tag">
              {<IntlMessages id={"sidebar.AvabileStock"} />} - {stockDisplay}
            </Typography>
            <div className="display-flex1">
              <FormInput
                onChange={(e) => {
                  if (e.target?.value === "") {
                    setStock(0);
                  } else {
                    setStock(e.target?.value);
                  }
                  // setStock(e.target?.value);
                }}
                type={"number"}
                min={10}
              />
              <ButtonComponent
                label={"search"}
                className={"ml-10"}
                onClick={() => setStockHandle(true)}
              />
            </div>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={expiryStockList || []}
          pagination={false}
        />
      </Spin>
    </Card>
  );
};

export default AvabileStockList;
