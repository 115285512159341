import { Row, Col, Typography, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";

import "../../../../styles/modules/pharmacy.less";

import { useHistory } from "react-router-dom";

import ViewHeader from "../../view/ViewHeader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getPharmacyProfileInProgress } from "../../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";

import PurchaseFooters from "../PurchaseFooters";

const PrintPurchaseReturn = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const location = useLocation();
  const [printingDone, setPrintingDone] = useState(false);
  const { pharmacyProfile } = useSelector((state) => state?.pharmacy);
  const profileData = pharmacyProfile?.data?.data;
  const salesData = location.state?.purchaseReturnData;
  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "BatchNo",
      dataIndex: "batch_no",
      key: "batch_no",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Return Quantity",
      dataIndex: "return_quantity",
      key: "return_quantity",
    },

    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      key: "purchase_price",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "GST",
      dataIndex: "gst_percentage",
      key: "gst_percentage",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Return Amount",
      dataIndex: "return_amount",
      key: "return_amount",
    },
  ];
  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);

  useEffect(() => {
    if (profileData && !printingDone) {
      window.print();
      setPrintingDone(true);
    }
  }, [profileData, printingDone]);

  const columnss = [
    {
      title: "name",
      dataIndex: "name",
    },
    {
      title: "value",
      dataIndex: "value",
    },
  ];
  const input = [
    salesData?.company_name
      ? {
          name: "SupplierName",
          value: salesData?.company_name,
        }
      : null,
    salesData?.mobile
      ? {
          name: "Mobile",
          value: salesData?.mobile,
        }
      : null,
    salesData?.purchase_request_code
      ? {
          name: "purchase Return Code",
          value: salesData?.purchase_return_code,
        }
      : null,
    {
      name: " Address",
      value: `${salesData?.address1},
                           ${salesData?.city},
                            ${salesData?.state},
                            ${salesData?.zipcode}.`,
    },
  ];
  return (
    <Row justify="center" align="middle">
      <Col xl={23} lg={23} md={23} sm={24} xs={24}>
        <Row gutter={[0, 16]} className="margin-row">
          <ViewHeader
            headerType={"return"}
            headerData={salesData}
            profileData={profileData}
          />

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Table
              showHeader={false}
              columns={columnss}
              dataSource={input.filter((element) => element !== null)}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="margin-row">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Typography className="products">Products</Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Table
                  columns={columns}
                  dataSource={salesData?.purchase_return_details}
                  pagination={false}
                  className="responsive-table"
                  size="small"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} className="footer-total">
            <PurchaseFooters
              subtotal={salesData?.sub_total}
              totalGST={salesData?.gst_amount}
              total={salesData?.total}
              discount={salesData?.discount}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PrintPurchaseReturn;
