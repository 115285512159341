import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_DOCTOR_PROFILE_IN_PROGRESS,
  GET_USER_PROFILE_PROGRESS,
  UPDATE_DOCTOR_PROFILE_IN_PROGRESS,
  UPDATE_USER_PROFILE_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  getDoctorProfileError,
  getDoctorProfileInProgress,
  getDoctorProfileSuccess,
  getUserProfileInError,
  getUserProfileInSuccess,
  updateDoctorProfileError,
  updateDoctorProfileSuccess,
  updateUserProfileError,
  updateUserProfileSuccess,
} from "../actions/Profile";
import {
  getDoctorProfileEffect,
  getUserProfileEffect,
  updateDoctorProfileEffect,
  updateUserProfileEffect,
} from "./effects/Profile";
import Message from "../../../components/Message/Message";

function* GetUserProfileSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getUserProfileEffect, payload, token);

    yield put(
      getUserProfileInSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getUserProfileInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* UpdateUserProfileSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(updateUserProfileEffect, payload, token);

    yield put(
      updateUserProfileSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    if (payload?.handleSuccess) {
      payload?.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateUserProfileError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getDoctorProfileSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getDoctorProfileEffect, payload, token);

    yield put(
      getDoctorProfileSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDoctorProfileError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* updateDoctorProfileSaga({ payload }) {
  try {
    let { data } = yield call(
      updateDoctorProfileEffect,
      payload?.requestPayload
    );

    yield put(
      updateDoctorProfileSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateDoctorProfileError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
export function* ProfileSaga() {
  yield takeLatest(GET_USER_PROFILE_PROGRESS, GetUserProfileSaga);
  yield takeLatest(UPDATE_USER_PROFILE_PROGRESS, UpdateUserProfileSaga);
  yield takeLatest(GET_DOCTOR_PROFILE_IN_PROGRESS, getDoctorProfileSaga);
  yield takeLatest(UPDATE_DOCTOR_PROFILE_IN_PROGRESS, updateDoctorProfileSaga);
}
