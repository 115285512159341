import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  ADD_PACKAGE_IN_PROGRESS,
  ADD_SUBPACKAGE_IN_PROGRESS,
  DELETE_PACKAGE_IN_PROGRESS,
  DELETE_SUBPACKAGE_IN_PROGRESS,
  GET_MAIN_PACKAGE_LIST_IN_PROGRESS,
  GET_SUB_PACKAGE_IN_PROGRESS,
  UPDATE_PACKAGE_IN_PROGRESS,
  UPDATE_SUBPACKAGE_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import { message } from "antd";
import {
  getSubPackagesListInSuccess,
  getSubPackagesListInError,
  addPackagesInError,
  addPackagesInSuccess,
  getMainPackagesListInSuccess,
  getMainPackagesListInError,
  updatePackageInSuccess,
  updatePackageInError,
  deletePackageInSuccess,
  deletePackageInError,
  addSubpackagesInSuccess,
  addSubpackagesInError,
  updateSubpackagesInSuccess,
  updateSubpackagesInError,
  deleteSubpackagesInSuccess,
  deleteSubpackagesInError,
} from "../actions/billingPackage";
import {
  AddBillingPackagesEffect,
  AddSubpackagesEffect,
  deleteBillingPackagesEffect,
  deleteSubpackagesEffect,
  getMainPackagesListEffect,
  getSubPackagesListEffect,
  updateBillingPackagesEffect,
  updateSubpackagesEffect,
} from "./effects/BillingPackagesEffect";
import Message from "../../../components/Message/Message";

function* getBillingPackageList({ payload }) {
  try {
    const { data } = yield call(getSubPackagesListEffect, payload);
    yield put(
      getSubPackagesListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getSubPackagesListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* addPackages({ payload }) {
  try {
    let { data } = yield call(AddBillingPackagesEffect, payload);

    yield put(
      addPackagesInSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addPackagesInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* getMainBillingPackageList({ payload }) {
  try {
    const { data } = yield call(getMainPackagesListEffect, payload);
    yield put(
      getMainPackagesListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getMainPackagesListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* updatePackages({ payload }) {
  try {
    let { data } = yield call(updateBillingPackagesEffect, payload);

    yield put(
      updatePackageInSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updatePackageInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* deletePackages({ payload }) {
  try {
    let { data } = yield call(deleteBillingPackagesEffect, payload);

    yield put(
      deletePackageInSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deletePackageInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* addSubPackages({ payload }) {
  try {
    let { data } = yield call(AddSubpackagesEffect, payload);

    yield put(
      addSubpackagesInSuccess({
        data: data,
        success: true,
      })
    );

    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addSubpackagesInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* updateSubPackages({ payload }) {
  try {
    let { data } = yield call(updateSubpackagesEffect, payload);

    yield put(
      updateSubpackagesInSuccess({
        data: data,
        success: true,
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateSubpackagesInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* deleteSubPackages({ payload }) {
  try {
    let { data } = yield call(deleteSubpackagesEffect, payload);

    yield put(
      deleteSubpackagesInSuccess({
        data: data,
        success: true,
      })
    );

    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteSubpackagesInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

export function* BillingPackageSaga() {
  yield takeLatest(GET_SUB_PACKAGE_IN_PROGRESS, getBillingPackageList);
  yield takeLatest(ADD_PACKAGE_IN_PROGRESS, addPackages);
  yield takeLatest(
    GET_MAIN_PACKAGE_LIST_IN_PROGRESS,
    getMainBillingPackageList
  );
  yield takeLatest(UPDATE_PACKAGE_IN_PROGRESS, updatePackages);
  yield takeLatest(DELETE_PACKAGE_IN_PROGRESS, deletePackages);
  yield takeLatest(ADD_SUBPACKAGE_IN_PROGRESS, addSubPackages);
  yield takeLatest(UPDATE_SUBPACKAGE_IN_PROGRESS, updateSubPackages);
  yield takeLatest(DELETE_SUBPACKAGE_IN_PROGRESS, deleteSubPackages);
}
