import { Card, Col, Form, Row, Spin } from "antd";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { updateUserProfileProgress } from "../../../appRedux/doctor/actions/Profile";

import { getUserUpdateListProgress } from "../../../appRedux/doctor/actions/User";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";
import CitySelector from "../../Doctor/component/CitySelector";
import CountrySelector from "../../Doctor/component/CountrySelector";
import DoctorSelectBox from "../../Doctor/component/DoctorSelectBox";
import StateSelector from "../../Doctor/component/StateSelector";
import { LoadingOutlined } from "@ant-design/icons";

const UserUpdateForm = () => {
  const id = useParams();

  const history = useHistory();
  const [form] = Form.useForm();
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const { getuserupdatelist } = useSelector((state) => state.Users);
  const getUserData = getuserupdatelist?.data;
  const roleid = getuserupdatelist?.data?.role_id;

  const { updateprofile } = useSelector((state) => state.profile);
  const updateprofiledata = updateprofile?.data;

  const dispatch = useDispatch();

  const onFinish = (datas) => {
    let formData = new URLSearchParams();
    formData.append("firstName", datas.firstName);
    formData.append("lastName", datas.lastName);
    formData.append("email", datas.email);
    formData.append("contact", datas.contact);
    formData.append("address1", datas.address1);
    formData.append("address2", datas.address2);
    formData.append("city", datas.city);
    formData.append("state", datas.state);
    formData.append("country_code", datas.country_code);
    formData.append("role_id", roleid);
    formData.append("status", datas.status);
    dispatch(updateUserProfileProgress({ formData, id: id.id }));
  };
  const onChangeCountry = (value) => {
    setCountry(value);
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);

  useEffect(() => {
    dispatch(getUserUpdateListProgress(id.id));
  }, []);

  useEffect(() => {
    form.setFieldsValue(getUserData);
  }, [getUserData]);

  useEffect(() => {
    dispatch(getUserUpdateListProgress(id.id));
  }, [updateprofiledata]);
  useEffect(() => {
    if (getuserupdatelist?.progressing === true) {
      setLoading(true);
    } else if (getuserupdatelist?.success === true) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [
    getuserupdatelist?.progressing === true,
    getuserupdatelist?.success === true,
  ]);
  const Back = () => {
    history.push("/admin/getusers");
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <Auxiliary>
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            form={form}
            onFinish={onFinish}
          >
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages id={"Profile.myprofile.label.firstName"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Profile.myprofile.validator.firstName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages id={"Profile.myprofile.label.lastName"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Profile.myprofile.validator.lastName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Email"
                  name="email"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Profile.myprofile.label.email"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Profile.myprofile.validator.email"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Contact"
                  name="contact"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages id={"Profile.myprofile.label.contact"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Profile.myprofile.validator.contact"}
                        />
                      ),
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Address 1"
                  name="address1"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages id={"Profile.myprofile.label.address1"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Profile.myprofile.validator.address1"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Address 2"
                  name="address2"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages id={"Profile.myprofile.label.address2"} />
                  }
                  rules={[
                    {
                      required: false,
                      message: (
                        <IntlMessages
                          id={"Profile.myprofile.validator.address2"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CountrySelector
                  label={"Country Name"}
                  name={"country_code"}
                  onChange={onChangeCountry}
                  country={country}
                  rules={[
                    {
                      required: true,
                      message: "Please select your country!",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StateSelector
                  label={"State"}
                  name={"state"}
                  onChange={onChangeState}
                  country={state}
                  countryId={country?.id}
                  rules={[
                    {
                      required: true,
                      message: "Please select your state!",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CitySelector
                  label={"City"}
                  name={"city"}
                  onChange={onChangeCity}
                  country={city}
                  StateId={state?.id}
                  rules={[
                    {
                      required: true,
                      message: "Please select your state!",
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <DoctorSelectBox
                  name="status"
                  label={"Status"}
                  rules={[
                    {
                      required: true,
                      message: "Please select status!",
                    },
                  ]}
                  options={[
                    {
                      value: "1",
                      label: "Active",
                    },
                    {
                      value: "2",
                      label: "Pending",
                    },
                    {
                      value: "3",
                      label: "Disabled",
                    },
                    {
                      value: "4",
                      label: "Not-verified",
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row className="space">
              <Col className="btn-pos">
                <ButtonComponent type="danger" onClick={Back} label="Back" />
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  label="Update"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Auxiliary>
    </Spin>
  );
};

export default UserUpdateForm;
