import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Modal, Tooltip } from "antd";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultSubscriptionPayment,
  getOrganisationListInProgress,
  getPlanListInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";
import moment from "moment";
import PaymentForm from "./PaymentForm";
import { currentDate } from "../../../util/utilz";
import { APP_ROUTES } from "../../../constants/Routes";

const PaymentTable = () => {
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [pendingAmount, setPendingAmount] = useState(20);
  const [filteredUsers, setFilteredUsers] = useState("");

  const dispatch = useDispatch();
  const planListDetails = useSelector((state) => state?.superAdmin?.planList);

  const totalRecords = planListDetails?.data?.totalRecords;
  const addSubscriptionDetails = useSelector(
    (state) => state.superAdmin?.addSubscriptionDetails
  );
  const addPaymentData = useSelector(
    (state) => state.superAdmin?.addubscriptionPayment
  );
  const hospitalList = planListDetails?.data?.data;

  const showModal = (record) => {
    setIsModalOpen(true);

    setSelectedHospital(record); 
  };
  const onClickDueButton = (record) => {
    setPaymentModal(true);

    setSelectedHospital(record);
  };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const handleOkPayment = () => {
    setPaymentModal(false);
  };

  const handleCancelPayment = () => {
    setPaymentModal(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Hospital Name",
      dataIndex: ["Organization", "name"],
      key: "name",
    },
    {
      title: "Registered At",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) => moment(start_date).format("DD-MM-YYYY"),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (expiry_date) => moment(expiry_date).format("DD-MM-YYYY"),
    },
    {
      title: "Period",
      dataIndex: ["Subscription", "periods"],
      key: "periods",
    },
    // {
    //   title: "Renewal Date Starts From",
    //   dataIndex: "renewal_date",
    //   key: "renewal_date",
    //   render: (_, record) => {
    //     const renewalDate = calculateRenewalDate(record.expiry_date);
    //     return renewalDate === "Invalid Date" ? "Invalid Date" : renewalDate;
    //   },
    // },
    {
      title: "Due Amount",
      dataIndex: "pendingAmount",
      key: "pendingAmount",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        const isRenewalDisabled = moment().isBetween(
          moment(record.start_date),
          moment(record.expiry_date)
        );

        return (
          <Row className="table-button">
            {/* {record.paymentCompleted === "No" ? (
              <Col className="button-edit">
                <ButtonComponent
                  label={"Pay Due Amount"}
                  className="primary-color"
                  type="primary"
                  onClick={() => onClickDueButton(record)}
                />
              </Col>
            ) : (
              <Col className="button-edit">
                <ButtonComponent
                  label={"Renewal"}
                  className="primary-color"
                  type="primary"
                  disabled={isRenewalDisabled}
                  onClick={() => showModal(record)}
                />
              </Col>
            )} */}
            {record.Subscription?.name === "Free Trail " ? (
              <Col className="button-edit">
                <Link
                  to={{
                    pathname: `/payment/invoice`,
                    state: { paymentData: record, freeTrail: "true" },
                  }}
                >
                  <ButtonComponent
                    className="secondary-color"
                    label={"Invoice"}
                  />
                </Link>
              </Col>
            ) : (
              <Col className="button-edit">
                <Link
                  to={{
                    pathname: `/payment/history`,
                    state: { paymentData: record },
                  }}
                >
                  <ButtonComponent
                    className="secondary-color"
                    label={"View History"}
                    disabled={
                      record.subscriptionPayments.length > 0 ? false : true
                    }
                  />
                </Link>
              </Col>
            )}
            {/* {record?.paymentCompleted === "Yes" &&
              record?.expiry_date < currentDate && (
                <Col className="button-edit">
                  <Link
                    to={{
                      pathname: `/payment/invoice`,
                      state: { paymentData: record, freeTrail: "true" },
                    }}
                  >
                    <ButtonComponent
                      type="danger"
                      label={"Complete Previous payment"}
                    />
                  </Link>
                </Col>
              )} */}
          </Row>
        );
      },
    },
  ];

  const handlePage = (page) => {
    const payload = { pagination: true, pageSize: "10", pageNo: page };
    dispatch(getPlanListInProgress(payload));
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(hospitalList);
    } else {
      const newData = hospitalList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getPlanListInProgress(payload));
  }, []);
  useEffect(() => {
    if (planListDetails?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [planListDetails]);
  useEffect(() => {
    if (addSubscriptionDetails?.success) {
      setPaymentModal(true);
    }
  }, [addSubscriptionDetails]);
  useEffect(() => {
    if (addPaymentData?.success) {
      const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
      dispatch(getPlanListInProgress(payload));
      dispatch(defaultSubscriptionPayment());
    }
  }, [addPaymentData]);

  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{<IntlMessages id={"Addnew.payment"} />}</p>
        </Col>
        <Col>
          <Link to={APP_ROUTES.PAYMENT_ADD}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.payment"} />}
            />
          </Link>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : hospitalList}
          rowselector={handleSelectChange}
          itemList={hospitalList}
          filteredUsers={filteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          setFilteredUsers={setFilteredUsers}
          documentTitle={"Payment Details List"}
        />
      </Spin>

      <Modal
        title={
          selectedHospital?.paymentCompleted === "No"
            ? `Payment Details- Due Amount ${selectedHospital?.pendingAmount}`
            : "Payment Details"
        }
        open={paymentModal}
        onOk={handleOkPayment}
        onCancel={handleCancelPayment}
        width={600}
        footer={null}
        destroyOnClose={handleCancelPayment}
      >
        <PaymentForm
          selectedHospital={selectedHospital}
          pendingAmount={pendingAmount}
          setPaymentModal={setPaymentModal}
          paymentModal={paymentModal}
        />
      </Modal>
    </Card>
  );
};

export default PaymentTable;
