import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

const OtpSendEffect = (formData) => {
  return axiosInstance.request({
    url: "/auth/otpSend",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const VerifyOtpEffect = (formData) => {
  return axiosInstance.request({
    url: "/auth/otpVerification",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const UpdatePasswordEffect = (formData) => {
  return axiosInstance.request({
    url: "/auth/updatePassword",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export { OtpSendEffect, VerifyOtpEffect, UpdatePasswordEffect };
