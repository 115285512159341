import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  BLOOD_GROUP_IN_PROGRESS,
  GET_PATIENT_BILLING_REPORT_IN_PROGRESS,
  GET_PATIENT_LIST_BY_ID_IN_PROGRESS,
  PATIENT_ADD_LIST_IN_PROGRESS,
  PATIENT_CSV_UPLOAD_IN_PROGRESS,
  PATIENT_DELETE_LIST_IN_PROGRESS,
  PATIENT_LIST_IN_PROGRESS,
  PATIENT_UPDATE_LIST_IN_PROGRESS,
} from "../../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../../util/patient";
import {
  bloodGroupInFailure,
  bloodGroupInSuccess,
  getPatientListByIdInFailure,
  getPatientListByIdInSuccess,
  patientAddListInFailure,
  patientAddListInSuccess,
  patientBillingReportListInFailure,
  patientBillingReportListInSuccess,
  patientDeleteListInFailure,
  patientDeleteListInSuccess,
  PatientFileUploadInFailure,
  PatientFileUploadInSuccess,
  patientListInFailure,
  patientListInSuccess,
  patientUpdateListInFailure,
  patientUpdateListInSuccess,
} from "../action/action";
import {
  addPatientListEffect,
  deletePatientListEffect,
  getBloodGroupListEffect,
  getPatientBillingReportEffect,
  getpatientListByIdEffect,
  getpatientListEffect,
  patientFileUploadEffect,
  updatePatientListEffect,
} from "./patientEffect";
function* getPatientList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getpatientListEffect, payload, token);

    yield put(
      patientListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      patientListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* addPatientList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addPatientListEffect, payload.form, token);

    yield put(
      patientAddListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    message.success(data["message"]);
    message.info("Password is sent to your email!");
  } catch (e) {
    yield put(
      patientAddListInFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addPatientFileUpload({ payload }) {
  const token = localStorage.getItem("user_id");
  try {
    let { data } = yield call(patientFileUploadEffect, payload, token);

    yield put(
      PatientFileUploadInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      PatientFileUploadInFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* deletePatientList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(deletePatientListEffect, payload, token);

    yield put(
      patientDeleteListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      patientDeleteListInFailure({
        Error: true,
        message: e["message"],
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* updatePatientList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(updatePatientListEffect, payload, token);

    yield put(
      patientUpdateListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    message.success(data["message"]);
  } catch (e) {
    yield put(
      patientUpdateListInFailure({
        Error: true,
        message: e["message"],
      })
    );
    message.error(e["message"]);
  }
}
function* getPatientListById({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getpatientListByIdEffect, payload, token);

    yield put(
      getPatientListByIdInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getPatientListByIdInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* getPatientBloodGroup({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getBloodGroupListEffect, payload, token);

    yield put(
      bloodGroupInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      bloodGroupInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* getPatientBillingReportList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getPatientBillingReportEffect, payload, token);

    yield put(
      patientBillingReportListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      patientBillingReportListInFailure({
        Error: true,
        message: e["message"],
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
export default function* patientSaga() {
  yield takeLatest(PATIENT_LIST_IN_PROGRESS, getPatientList);
  yield takeLatest(PATIENT_ADD_LIST_IN_PROGRESS, addPatientList);
  yield takeLatest(PATIENT_DELETE_LIST_IN_PROGRESS, deletePatientList);
  yield takeLatest(PATIENT_UPDATE_LIST_IN_PROGRESS, updatePatientList);
  yield takeLatest(GET_PATIENT_LIST_BY_ID_IN_PROGRESS, getPatientListById);
  yield takeLatest(PATIENT_CSV_UPLOAD_IN_PROGRESS, addPatientFileUpload);
  yield takeLatest(BLOOD_GROUP_IN_PROGRESS, getPatientBloodGroup);
  yield takeLatest(
    GET_PATIENT_BILLING_REPORT_IN_PROGRESS,
    getPatientBillingReportList
  );
}
