import React, { useEffect, useRef, useState } from "react";

import ReactDOMServer from "react-dom/server";
import { Card, Col, DatePicker, Row, Table, Typography } from "antd";

import { currentDate } from "../../../util/utilz";

import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { PrinterFilled, ReconciliationTwoTone } from "@ant-design/icons";
import moment from "moment";
import { getPharmacryReportListInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";

import { paymentTypeList } from "../../Doctor/data";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";

const { RangePicker } = DatePicker;
function PharmacyReports() {
  const dispatch = useDispatch();
  const pdfContentRef = useRef(null);
  const { gstPharmacyReportList } = useSelector((state) => state.pharmacy);
  const [date, setDate] = useState([]);
  const [dates, setDates] = useState();
  const reports = gstPharmacyReportList?.data || [];
  const columns = [
    {
      title: "Sales Id",
      dataIndex: "sales_code",
    },
    {
      title: "Date",
      dataIndex: "sales_date",
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
    },
    // {
    //   title: "Patient Mobile",
    //   dataIndex: "patient_mobile",
    // },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "paid",
      dataIndex: "total_paid_amount",
      render: (record) => (
        <span className="display-inline">{record ? record : 0}</span>
      ),
    },
    {
      title: "Due",
      dataIndex: "due",
    },
    {
      title: "Payment Methods",
      dataIndex: "due",
      render: (_, record) => {
        return record?.PharmacyBillings.length !== 0
          ? record?.PharmacyBillings?.map((data, i) => (
              <span className="display-inline">
                {i + 1}.
                {
                  paymentTypeList.find(
                    (val) => Number(val.value) === Number(data.payment_type)
                  ).label
                }
                : {data?.Paid_amount},
              </span>
            ))
          : "-";
      },
    },
  ];

  const columns2 = [
    {
      title: "name",
      dataIndex: "name",
      render: (record) => <span className="display-inline">{record}</span>,
    },
    {
      title: "value",
      dataIndex: "value",
      render: (record) => <span className="display-inline">{record}</span>,
    },
  ];
  const input = [
    {
      name: "Total Sales",
      value: `${reports.totalBiiling ? reports.totalBiiling : 0} ₹`,
    },
    {
      name: "Total Collections",
      value: `${reports.totalsale ? reports.totalsale : 0} ₹`,
    },
    {
      name: "Total Due",
      value: `${
        reports.totalBiiling && reports.totalsale
          ? Number(reports.totalBiiling) - Number(reports.totalsale)
          : 0
      } ₹`,
    },
    {
      name: "Total Card Amount",
      value: `${reports.totalCardAmount ? reports.totalCardAmount : 0} ₹`,
    },
    {
      name: "Total Cash Amount",
      value: `${reports.totalCashAmount ? reports.totalCashAmount : 0} ₹`,
    },
    {
      name: "Total Online Amount",
      value: `${reports.totalOnlineAmount ? reports.totalOnlineAmount : 0} ₹`,
    },
    {
      name: "Total DD Amount",
      value: `${reports.totalDdAmount ? reports.totalDdAmount : 0} ₹`,
    },
    {
      name: "Total Cheque Amount",
      value: `${reports.totalChequeAmount ? reports.totalChequeAmount : 0} ₹`,
    },
    {
      name: "Total Wallet Amount",
      value: `${reports.totalWalletAmount ? reports.totalWalletAmount : 0} ₹`,
    },
    {
      name: "Total Other Amount",
      value: `${reports.totalOtherAmount ? reports.totalOtherAmount : 0} ₹`,
    },
  ];
  useEffect(() => {
    const fromData = {
      pagination: false,
      pageNo: 1,
      from_date: date[0] || currentDate,
      to_date: date[1] || currentDate,
    };
    dispatch(getPharmacryReportListInProgress(fromData));
    setDates(false);
  }, [dates]);
  const hadleDateScearch = () => {
    setDates(true);
  };
  const handleReport = (date, dates) => {
    setDates(false);
    setDate(dates);
    // if (dates[1] && dates[0]) {
    //   const fromData = {
    //     pagination: true,
    //     pageNo: 1,
    //     from_date: dates[0],
    //     to_date: dates[1],
    //   };
    //   dispatch(getPharmacryReportListInProgress(fromData));
    // }
  };
  const printHandler = () => {
    const newWindow = window.open("", "_blank");

    if (newWindow) {
      newWindow.document.write("<html><head><title>Print</title>");
      // newWindow.document.write(
      //   "<link rel='stylesheet' href='../purchase/purchase.less'>"
      // );
      newWindow.document.write(
        `<style>
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          .header-tag {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
          .ml-5 {
            margin-left: 5px;
          }
          .mr-7 {
            margin-right: 7px;
          }
        </style>`
      );
      newWindow.document.write("</head><body>");

      const printContent = document.createElement("div");
      printContent.id = "print-content";
      newWindow.document.body.appendChild(printContent);

      const headerTag = document.createElement("div");
      headerTag.className = "header-tag";
      headerTag.innerHTML = `
      <ReconciliationTwoTone class="mr-7" />
      <span>Billing Reports</span>
    `;
      printContent.appendChild(headerTag);

      const dateSpan = document.createElement("span");
      dateSpan.className = "ml-5";
      dateSpan.textContent = `${date[0] || currentDate} to ${
        date[1] || currentDate
      }`;
      headerTag.appendChild(dateSpan);

      // Assuming 'date' and 'currentDate' are defined elsewhere in your code

      const table1 = document.createElement("div");
      table1.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <Table
          columns={columns}
          dataSource={reports.data || []}
          pagination={false}
          scroll={{ x: true }}
        />
      );

      // Populate table1 with your first table data here

      printContent.appendChild(table1);

      const table2 = document.createElement("table");
      table2.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <Row className="display-class mb-20">
          <Col xl={16} lg={16} md={16} sm={12} xs={0}></Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <Table
              showHeader={false}
              columns={columns2}
              dataSource={input.filter((element) => element !== null)}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      );
      // Populate table2 with your second table data here

      printContent.appendChild(table2);

      newWindow.document.write("</body></html>");
      newWindow.document.close();

      newWindow.onload = () => {
        newWindow.print();
        newWindow.close(); // Close the new window after printing
      };
    } else {
      console.error("Failed to open new window for printing.");
    }
  };
  const handleDownloadPDF = async () => {
    const content = pdfContentRef.current;
    const options = {
      paperSize: "Letter",
      scale: 1,
      margin: { left: "1cm", right: "1cm" },
      landscape: true,
    };
    const group = await drawDOM(content, options);
    const data = await exportPDF(group);
    saveAs(
      data,
      `PharmacyBillingReports ${date[0] || currentDate} to ${
        date[1] || currentDate
      }.pdf`
    );
  };

  return (
    <div>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} className="display-flex mb-10 align-items ">
            <Typography className="header-tag">
              <ReconciliationTwoTone className="mr-7" />
              <IntlMessages id={"Billing Reports"} />
            </Typography>
            <div>
              <ButtonComponent
                type="success"
                icon={<PrinterFilled />}
                className={"print-button"}
                onClick={printHandler}
              ></ButtonComponent>
              <ButtonComponent
                type="success"
                label={"PDF"}
                className={"print-button"}
                onClick={handleDownloadPDF}
              />
            </div>
          </Col>
          <Col xs={24} className="display-flex mb-10 align-items ">
            <Typography className="header-tag">
              <span className="ml-5">
                {" "}
                {date[0] || currentDate} to {date[1] || currentDate}
              </span>
            </Typography>
            <div className="display-flex1">
              <RangePicker
                size={"large"}
                onChange={handleReport}
                defaultValue={[moment(currentDate), moment(currentDate)]}
              />
              <ButtonComponent
                label={"search"}
                className={"ml-10"}
                onClick={() => setDates(true)}
              />
            </div>
          </Col>
        </Row>
        <div ref={pdfContentRef}>
          <Table
            columns={columns}
            dataSource={reports.data || []}
            pagination={false}
            scroll={{ x: true }}
          />
          <Row className="display-class mb-20">
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Table
                showHeader={false}
                columns={columns2}
                dataSource={input.filter((element) => element !== null)}
                pagination={false}
                scroll={{ x: true }}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}

export default PharmacyReports;
