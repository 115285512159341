import React, { useEffect, useState } from "react";
import TableComponent from "../../../../components/Table/TableComponent";
import { Row, Col, message, Spin, Tooltip, Modal, Typography } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import { useDispatch } from "react-redux";
import {
  patientDeleteListInProgress,
  patientListInProgress,
} from "../../../../appRedux/admin/patient/action/action";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";
import { access } from "../../../../util/utilz";
// import { access } from "../../../../util/utilz";
const PatientTable = () => {
  const [deleteId, setId] = useState(null);
  const [origanizationId, setOrganizationId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = [
    {
      title: "Id",
      dataIndex: "patient_serial_no",
    },
    {
      title: "Name",
      dataIndex: "firstName",
      sortDirections: ["ascend", "descend"],
      key: "firstName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },

    {
      title: "DOB",
      dataIndex: "dob",
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Contact",
      dataIndex: "contact",
    },
    {
      title: "Address ",
      dataIndex: "address1",
      render: (_, record) =>
        `${record.address1},  ${record.address2 ? record.address2 + "," : ""} ${
          record.city
        },${record.state},${record.country_code}`,
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        const confirm = () => {
          handleDelete(record.id);
          message.success("deleted successfully");
        };

        const cancel = () => {
          message.error("cancelled");
        };
        return (
          <Row className="table-button">
            {access("patients", "edit") && (
              <Col className="button-edit">
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link to={`/patient/edit/${record.id}`}>
                    <ButtonComponent
                      type="primary"
                      icon={<EditOutlined />}
                      // onClick={()=>editPatient(record.id)}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"View"}>
                {" "}
                <Link
                  to={{
                    pathname: "/patient/view",
                    state: { patientData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<EyeOutlined />}
                    className="secondary-color"
                    // onClick={()=>editPatient(record.id)}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("patients", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
                <br />
                <br />
              </Col>
            )}

            {/* <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"View"}>
                {" "}
                <Link
                  to={{
                    pathname: "/patient/view",
                    state: { patientData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<EyeOutlined />}
                    // onClick={()=>editPatient(record.id)}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col> */}
          </Row>
        );
      },
    },
  ];
  const handleOk = () => {
    const payload = {
      deleteId: deleteId,
      organization_id: origanizationId,
    };
    dispatch(patientDeleteListInProgress(payload));
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [Loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const dispatch = useDispatch();
  const patientData = useSelector((state) => state.patient.getList);
  const patientList = patientData.data?.data;
  const deleteData = useSelector((state) => state.patient.deleteList);
  const totalPage = patientData.data?.totalPages;
  const patientPageSize = patientData.data?.pagesize;
  const currentpage = patientData.data?.currentPage;
  const totalRecords = patientData.data?.totalRecords;

  const handleDelete = (id) => {
    const i = patientList && patientList.filter((val) => val.id === id);

    // dispatch(patientDeleteListInProgress(i[0].id));
  };

  useEffect(() => {
    setLoading(true);
    // dispatch(patientListInProgress());
    handlePage(1);
    setLoading(false);
  }, [deleteData?.success]);
  useEffect(() => {
    if (patientData.inProgress) {
      setLoading(true);
    } else if (patientData.success) {
      setLoading(false);
    }
  }, [patientData.inProgress, patientData.success]);

  const handlePage = (page) => {
    dispatch(patientListInProgress(page));
  };
  const csvData = useSelector((state) => state.patient.csv);
  const csv = csvData?.data?.PatientsInfo;

  const [filteredUsers, setFilteredUsers] = useState("");
  // const [filteredDataa, setFilteredData] = useState(patientList);

  // const updateFilteredData = (filteredDataa) => {
  //   setFilteredData(filteredDataa);
  // };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(patientList);
    } else {
      const newData = patientList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const showModal = (id) => {
    setOrganizationId(id?.OrganizationPatientAccesses?.[0]?.Organization?.id);
    setIsModalOpen(true);
    setId(id.id);
  };
  // const access = (menu, type) => {
  //   const role_permision = JSON.parse(localStorage.getItem("role_permision"));

  //   if (role_permision === null) {
  //     const roleid = localStorage.getItem("role_id");
  //     if (Number(roleid) === 1) {
  //       return true;
  //     } else {
  //       return role_permision[menu]?.[type];
  //     }
  //   }
  //   return role_permision[menu]?.[type];
  // };

  return (
    <Spin indicator={antIcon} spinning={Loading}>
      {access("patients", "view") && (
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : patientList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          // patientapi={patientList}
          // patientFilter={updateFilteredData}
          rowselector={handleSelectChange}
          itemList={patientList}
          type={"patientlist"}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"firstName"}
          documentTitle={"Patient List"}
        />
      )}
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default PatientTable;
