import { axiosInstance } from "../../../../../util/utilz";

export const getTeamMemberInstanceListEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/teamMember/allList?pagination_required=true`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addTeamMemberInstanceListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/teamMember/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateTeamMemberInstanceListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/teamMember/update`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteTeamMemberInstanceListEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/teamMember/delete/${payload?.id}`,
    method: "DELETE",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
