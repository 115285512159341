import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

export const DoctorListEffect = (page) => {
  const organization_id = localStorage.getItem("org_id");
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/doctors/organization?organization_id=${organization_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: { pagesize: 10, pageNO: page, pagination_required: true },
  });
};

export const GetDoctorEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/doctors/details/${formData}",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
