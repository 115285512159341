import React, { useEffect, useState } from "react";

import { Row, Col, Spin } from "antd";
import {
  LoadingOutlined,
  FileAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TableComponent from "../../components/Table/TableComponent";
import { getDoctorAppointmentListInProgress } from "../../appRedux/doctor/actions/Appointment";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../constants/Routes";

const DoctorAppoinments = () => {
  const moment = require("moment");
  const current_date = moment().format("YYYY-MM-DD");
  const current_time = moment().format("hh mm");
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
    },
    {
      title: "Appointment Status Name",
      dataIndex: "appointment_status_name",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              {}
              <Link to={APP_ROUTES.VIDEOCALL}>
                <ButtonComponent
                  type="primary"
                  icon={<VideoCameraOutlined />}
                  disabled={
                    current_date === record?.date &&
                    current_time === record?.start_time
                      ? "true"
                      : "false"
                  }
                />
              </Link>
            </Col>
            <Col className="button-edit">
              <Link
                to={{
                  pathname: "/prescription/add",
                  state: record,
                }}
              >
                <ButtonComponent type="primary" icon={<FileAddOutlined />} />
              </Link>
            </Col>
          </Row>
        );
      },
    },
  ];
  const dispatch = useDispatch();
  const doctorData = useSelector(
    (state) => state?.appointment?.doctorappointmentlist
  );
  const [filteredUsers, setFilteredUsers] = useState("");
  const [Loading, setLoading] = useState(false);
  const DoctorList = doctorData?.data?.data;
  const totalRecords = doctorData?.data?.totalRecords;
  const doctorId = localStorage.getItem("doctor_id");

  const handlePage = (page) => {
    //dispatch(doctorListInProgress(page));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(DoctorList);
    } else {
      const newData = DoctorList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    const page = 1;
    dispatch(
      getDoctorAppointmentListInProgress({
        doctor_id: doctorId,
        isPrev: 1,
        page: page,
        pagination_required: true,
        currentTime: current_time,
        currentDate: current_date,
      })
    );
  }, []);

  useEffect(() => {
    if (doctorData?.inProgress) {
      setLoading(true);
    } else if (doctorData?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [doctorData?.inProgress]);

  return (
    <div>
      {" "}
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : DoctorList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          itemList={DoctorList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"firstName"}
          rowselector={handleSelectChange}
        />
      </Spin>
    </div>
  );
};

export default DoctorAppoinments;
