import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography, DatePicker } from "antd";
import "../../../styles/modules/pharmacy.less";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { gstList } from "../../Doctor/data";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getProductTypeInProgress,
  getProductUnitInProgress,
  getTabletCategoryListInProgress,
  newTabletInProgress,
  updateTabletsInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { APP_ROUTES } from "../../../constants/Routes";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddMedicine = ({ editMedicine, medicineListData }) => {
  const dispatch = useDispatch();
  const orgId = localStorage.getItem("org_id");
  const [categoryList, setCategoryList] = useState([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const [category, setCategory] = useState();
  const updateData = useSelector((state) => state.pharmacy?.updateTablet);
  const addMedicineData = useSelector((state) => state.pharmacy?.newTablet);
  const productTypeData = useSelector(
    (state) => state.pharmacy?.getProductType
  );
  const productUnitData = useSelector(
    (state) => state.pharmacy?.getProductUnit
  );
  const medicineCategoryList = useSelector(
    (state) => state?.pharmacy?.tabletCategoryList
  );

  const [gst, setGst] = useState();
  const [productUnit, setProductUnit] = useState();
  const [productType, setproductType] = useState();
  const [productUnitList, setProductUnitList] = useState([]);
  const [productTypeLst, setproductTypeList] = useState([]);

  const currentDate = moment().format("MM/DD/YYYY");

  const onFinish = (datas) => {
    const expiryDate = datas?.expiryDate.format("MM/DD/YYYY");
    const calculatedGstValue = (datas.purchasePrice * datas.gst) / 100;
    if (editMedicine) {
      const formData = {
        category: datas?.category,
        organization_id: orgId,
        medicine_name: datas?.name,
        price: datas?.purchasePrice,
        box: datas?.storeBox,
        s_price: datas?.salePrice,
        quantity: datas?.quantity,
        generic: datas?.genericName,
        company: datas?.company,
        effects: datas?.effects,
        e_date: expiryDate,
        add_date: currentDate,
        gst_value: datas?.gst,
        gst_percent: calculatedGstValue,
        medicine_type: datas?.productType,
        reorder_quantity: datas?.reorderQuantity,
        product_code: datas?.productCode,
        hsn_code: datas?.hsnCode,
        product_unit: productUnit,
        status: 1,
        minimum_order_quantity: datas?.minimumOrderQuantity,
        product_type: productType,
      };

      dispatch(updateTabletsInProgress({ formData, id: medicineListData?.id }));
    } else {
      const formData = {
        category: datas?.category,
        organization_id: orgId,
        medicine_name: datas?.name,
        price: datas?.purchasePrice,
        box: datas?.storeBox,
        s_price: datas?.salePrice,
        quantity: datas?.quantity,
        generic: datas?.genericName,
        company: datas?.company,
        effects: datas?.effects,
        e_date: expiryDate,
        add_date: currentDate,
        gst_value: datas?.gst,
        gst_percent: calculatedGstValue,
        medicine_type: datas?.productType,
        reorder_quantity: datas?.reorderQuantity,
        product_code: datas?.productCode,
        hsn_code: datas?.hsnCode,
        product_unit: productUnit,
        status: 1,
        minimum_order_quantity: datas?.minimumOrderQuantity,
        product_type: productType,
      };

      dispatch(newTabletInProgress(formData));
    }
  };

  const [Loading, setLoading] = useState(false);
  const onChangeDate = (date, dateString) => {
    const parsedDate = moment(date);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const changeCategory = (value) => {
    setCategory(value);
  };
  const changegst = (value) => {
    setGst(value);
  };
  const changeProductType = (value) => {
    setproductType(value);
  };
  const changeProductUnit = (value) => {
    setProductUnit(value);
  };
  useEffect(() => {
    if (editMedicine) {
      form.setFieldValue("expiryDate", moment(medicineListData?.e_date));
      form.setFieldValue("name", medicineListData?.medicine_name);
      form.setFieldValue("category", medicineListData?.category);
      form.setFieldValue("hsnCode", medicineListData?.hsn_code);
      form.setFieldValue("gst", medicineListData?.gst_percent);
      form.setFieldValue("productCode", medicineListData?.product_code);
      form.setFieldValue("purchasePrice", medicineListData?.price);
      form.setFieldValue("salePrice", medicineListData?.s_price);
      form.setFieldValue("storeBox", medicineListData?.box);
      form.setFieldValue("genericName", medicineListData?.generic);
      form.setFieldValue("company", medicineListData?.company);
      form.setFieldValue("effects", medicineListData?.effects);
      form.setFieldValue("productType", medicineListData?.product_type);
      form.setFieldValue("productUnit", medicineListData?.product_unit);
      form.setFieldValue("quantity", medicineListData?.quantity);
      form.setFieldValue(
        "minimumOrderQuantity",
        medicineListData?.minimum_order_quantity
      );
      form.setFieldValue("reorderQuantity", medicineListData?.reorder_quantity);

      setCategory(medicineListData?.category);
      setProductUnit(medicineListData?.product_unit);
    }
  }, [medicineListData]);

  useEffect(() => {
    if (updateData?.success || addMedicineData?.success) {
      history.push("/medicine");
    }
  }, [updateData, addMedicineData]);

  useEffect(() => {
    if (medicineCategoryList?.success) {
      const categoryList =
        medicineCategoryList &&
        medicineCategoryList?.data?.data.map((item) => {
          return { label: item.category, value: item.category };
        });
      setCategoryList(categoryList);
    }
    if (productTypeData?.success) {
      const productTypeList =
        productTypeData &&
        productTypeData?.data?.data.map((item) => {
          return { label: item.name, value: item.name };
        });
      setproductTypeList(productTypeList);
    }
    if (productUnitData?.success) {
      const productUnitList =
        productUnitData &&
        productUnitData?.data?.data.map((item) => {
          return { label: item.name, value: item.id };
        });
      setProductUnitList(productUnitList);
    }
  }, [medicineCategoryList, productTypeData, productUnitData]);
  useEffect(() => {
    const formData = {
      pageNo: 0,
      orgId: orgId,
      pagination: false,
      pageSize: 0,
    };
    dispatch(getTabletCategoryListInProgress(formData));
    dispatch(getProductTypeInProgress());
    dispatch(getProductUnitInProgress());
  }, []);
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {editMedicine ? (
                    <IntlMessages id={"label.editProduct"} />
                  ) : (
                    <IntlMessages id={"label.addProduct"} />
                  )}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-15">
                <Form.Item
                  label={<IntlMessages id={"Addnew.pharmacy.label.category"} />}
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.category"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select Category"
                    editMode="true"
                    forFilter="true"
                    showSearch="true"
                    value={category}
                    options={categoryList}
                    onChange={changeCategory}
                    className="responsive-select"
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"name"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.name"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.pharmacy.validator.name"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"hsnCode"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.hsnCode"} />}
                />
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  label={<IntlMessages id={"Addnew.pharmacy.label.gst"} />}
                  name="gst"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.pharmacy.validator.gst"} />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select GST"
                    editMode="true"
                    forFilter="true"
                    showSearch="true"
                    value={gst}
                    options={gstList}
                    onChange={changegst}
                    className="responsive-select"
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"productCode"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.productCode"} />
                  }
                />
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"purchasePrice"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.purchasePrice"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.purchasePrice"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"salePrice"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.salePrice"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.salePrice"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>{" "}
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"quantity"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.quantity"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.quantity"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>{" "}
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"storeBox"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.storeBox"} />}
                />
              </Col>{" "}
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"genericName"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.genericName"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.genericName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>{" "}
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"company"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.company"} />}
                />
              </Col>{" "}
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"effects"}
                  label={<IntlMessages id={"Addnew.pharmacy.label.effects"} />}
                />
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name={"expiryDate"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.expiryDate"} />
                  }
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message: (
                        <IntlMessages id={"Medicine.validator.expiryDate"} />
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    className="calender-responsive"
                    format={"D/MM/YYYY"}
                    onChange={onChangeDate}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.productType"} />
                  }
                  name="productType"
                  rules={[
                    {
                      required: false,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.productType"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select Product Type"
                    editMode="true"
                    forFilter="true"
                    showSearch="true"
                    value={productType}
                    options={productTypeLst}
                    onChange={changeProductType}
                    className="responsive-select"
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.productUnit"} />
                  }
                  name="productUnit"
                  rules={[
                    {
                      required: false,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.productUnit"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select Product Unit"
                    editMode="true"
                    forFilter="true"
                    showSearch="true"
                    value={productUnit}
                    options={productUnitList}
                    onChange={changeProductUnit}
                    className="responsive-select"
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"minimumOrderQuantity"}
                  label={
                    <IntlMessages
                      id={"Addnew.pharmacy.label.minimumOrderQuantity"}
                    />
                  }
                />
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"reorderQuantity"}
                  label={
                    <IntlMessages
                      id={"Addnew.pharmacy.label.reOrderQuantity"}
                    />
                  }
                />
              </Col>
            </Row>
            <Row className="mt-10">
              <Col
                xs={24}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.MEDICINELIST}>
                  <ButtonComponent
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editMedicine ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddMedicine;
