import { Row, Col, Typography, Table, Form, Card } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "../../../styles/modules/superAdmin.less";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../../assets/images/DocMotez/logo.png";

import moment from "moment";

import { EnvironmentFilled, PhoneFilled } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { getGstListInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";

const FreeInvoice = ({ selectedHospital }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [gst, setGst] = useState();
  const hospitalSubscriptionDetails = location.state?.paymentData?.Subscription;
  const hospitalPersonalDetails = location.state?.paymentData?.Organization;

  const printRef = useRef();

  const currentDate = moment().format("DD/MM/YYYY");
  const currentTime = moment().format("hh:mm A");

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Periods",
      dataIndex: "periods",
      key: "periods",
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
    },
  ];
  //   const hospitalList = hospitalDetails?.subscriptionPaymentsDetails;

  const gstData = useSelector((state) => state.pharmacy?.gstList);

  const subscriptionListData = [
    {
      start_date: location.state?.paymentData?.start_date,
      periods: location.state?.paymentData?.periods,
      pricing: location.state?.paymentData?.pricing,
    },
  ];

  useEffect(() => {
    dispatch(getGstListInProgress());
  }, []);
  useEffect(() => {
    const gst = gstData?.data?.data.find(
      (item) => item.id === hospitalPersonalDetails?.Subscription?.gst
    )?.gst;
    const gstAmount =
      (hospitalPersonalDetails?.Subscription?.pricing * gst?.replace("%", "")) /
      100;
    setGst(gstAmount);
  }, [gstData?.success]);
  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Row ref={printRef} className="mt-20">
          {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}> */}
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="invoice-logo">
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <img src={logo} width={150} alt="image" />
              </Col>
              <Col xl={7} lg={6} md={10} sm={10} xs={24}>
                <Row>
                  <Col
                    xl={16}
                    lg={20}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Typography className="invoice-header my-10">
                      INVOICE
                    </Typography>
                  </Col>
                </Row>
                <Row className="my-20">
                  <Col xl={6} lg={6} md={8} sm={10} xs={10}>
                    <Typography className="list-header1">DATE</Typography>
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col xl={15} lg={13} md={10} sm={10} xs={10}>
                    <Typography className="list-item">{currentDate}</Typography>
                  </Col>
                </Row>{" "}
                <Row className="my-20">
                  <Col xl={6} lg={6} md={8} sm={10} xs={10}>
                    <Typography className="list-header1">TIME</Typography>
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col xl={15} lg={13} md={10} sm={10} xs={10}>
                    <Typography className="list-item">{currentTime}</Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            xl={20}
            lg={24}
            md={24}
            xs={24}
            sm={24}
            align="top"
            justify="center"
          >
            <Row
              style={{
                marginLeft: 0,
                marginRight: 0,
              }}
              justify="center"
              gutter={[0, 10]}
            >
              <Col xl={20} lg={20} md={20} xs={24} sm={24} align="center">
                <Typography align="center" className="view-name">
                  {hospitalPersonalDetails?.name}
                </Typography>
              </Col>
              <Col xl={20} lg={20} md={20} xs={24} sm={24}>
                <Typography align="center" justify="center">
                  <EnvironmentFilled />
                  {hospitalPersonalDetails?.street},
                  {hospitalPersonalDetails?.address},
                  {hospitalPersonalDetails?.city},
                  {hospitalPersonalDetails?.city},
                  {hospitalPersonalDetails?.state},
                  {hospitalPersonalDetails?.country}
                </Typography>
              </Col>
              <Col xl={20} lg={20} md={20} xs={24} sm={24}>
                <Typography align="center">
                  <PhoneFilled />
                  {hospitalPersonalDetails?.contact}
                </Typography>
              </Col>
            </Row>
          </Col>
          {/* </Col> */}
          <Col
            xl={22}
            lg={22}
            md={24}
            sm={24}
            xs={24}
            // display="flex"
            // justify="center"
            className="justify-center"
          >
            <Row className="mt-20 justify-center">
              <Col xl={12} lg={12} md={24} sm={24} xs={24} className="ml-5">
                <Row>
                  <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                    <Typography className="list-header1">
                      Hospital Name
                    </Typography>
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={0.5} xs={0.5} className="my-10">
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="my-10"
                  >
                    <Typography className="list-item">
                      {hospitalPersonalDetails?.name}
                    </Typography>
                  </Col>
                  <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                    <Typography className="list-header1">Email</Typography>
                  </Col>{" "}
                  <Col xl={1} lg={1} md={1} sm={0.5} xs={0.5} className="my-10">
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="my-10"
                  >
                    <Typography className="list-item">
                      {hospitalPersonalDetails?.email}
                    </Typography>
                  </Col>
                  <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                    <Typography className="list-header1">
                      Alternative Mobile{" "}
                    </Typography>
                  </Col>{" "}
                  <Col xl={1} lg={1} md={1} sm={0.5} xs={0.5} className="my-10">
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="my-10"
                  >
                    <Typography className="list-item">
                      {hospitalPersonalDetails?.alternate_contact}
                    </Typography>
                  </Col>{" "}
                  <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                    <Typography className="list-header1">
                      License Number
                    </Typography>
                  </Col>{" "}
                  <Col xl={1} lg={1} md={1} sm={0.5} xs={0.5} className="my-10">
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="my-10"
                  >
                    <Typography className="list-item">
                      {hospitalPersonalDetails?.license_no}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={10} lg={12} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                    <Typography className="list-header1">
                      Currency Code
                    </Typography>
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={0.5} xs={0.5} className="my-10">
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="my-10"
                  >
                    <Typography className="list-item">
                      {" "}
                      {hospitalPersonalDetails?.currency_code}
                    </Typography>
                  </Col>
                  <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                    <Typography className="list-header1">Plan Name</Typography>
                  </Col>{" "}
                  <Col xl={1} lg={1} md={1} sm={0.5} xs={0.5} className="my-10">
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="my-10"
                  >
                    <Typography className="list-item">
                      {hospitalSubscriptionDetails?.name}
                    </Typography>
                  </Col>{" "}
                  <Col xl={9} lg={9} md={9} sm={10} xs={10} className="my-10">
                    <Typography className="list-header1">Period</Typography>
                  </Col>{" "}
                  <Col xl={1} lg={1} md={1} sm={0.5} xs={0.5} className="my-10">
                    <Typography className="list-header1">:</Typography>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="my-10"
                  >
                    <Typography className="list-item">
                      {hospitalSubscriptionDetails?.periods}
                    </Typography>
                  </Col>{" "}
                </Row>
              </Col>
            </Row>
          </Col>{" "}
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mt-30 mb-10">
            <Typography className="list-header1">Details:</Typography>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Table
              columns={columns}
              dataSource={subscriptionListData}
              pagination={false}
              className="responsive-table"
            />
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className="mt-20"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography className="list-header1">
              If you have any doubt in this Invoice , please contact
            </Typography>{" "}
          </Col>{" "}
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className="mt-20"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography>Name</Typography>{" "}
          </Col>{" "}
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className="mt-20"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography className="list-header1">THANK YOU !</Typography>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FreeInvoice;
