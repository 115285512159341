import React from "react";
import Auxiliary from "../../util/Auxiliary";
import { Col, Divider, Row } from "antd";
import IntlMessages from "../../util/IntlMessages";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../constants/Routes";
import { access } from "../../util/utilz";

function AppointmentQuestionsHeader() {
  return (
    <Auxiliary>
      <Row className="bot">
        <Col span={12}>
          <h1 className="text">{<IntlMessages id={"Addnew.questions"} />}</h1>
        </Col>
        {access("appoinmentquestions", "add") && (
          <Col span={12} className="btn-main">
            <Link to={APP_ROUTES.APPOINMENTQUESTIONSCREEN}>
              <ButtonComponent
                className="buttons"
                icon={<ArrowLeftOutlined />}
                type="primary"
                label={<IntlMessages id={"Addnew.questions.goback"} />}
              />
            </Link>
          </Col>
        )}
      </Row>
      <Divider />
    </Auxiliary>
  );
}

export default AppointmentQuestionsHeader;
