import React from "react";

import { Card, Form, Spin } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Auxiliary from "../../../util/Auxiliary";

import { newTabletInProgress } from "../../../appRedux/doctor/actions/Tablets";
import {  CloseOutlined } from "@ant-design/icons";
import TabletsForm from "./TabletsForm";
import moment from "moment";
import { getDoctorInProgress } from "../../../appRedux/doctor/actions/doctor";
import { useHistory } from "react-router-dom";

const AddTablets = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [navigate, setNavigate] = useState(false);
  const history = useHistory();
  const orgId =
    useSelector(
      (state) => state?.doctorList?.getDoctor?.data?.data?.[0]?.organization_id
    ) || localStorage.getItem("org_id");

  const addData = useSelector((state) => state?.tablets?.newTablet);

  const onFinish = (datas) => {
    var e_date = moment(datas.e_date.toString()).format("D/MM/YYYY");
    var add_date = moment(datas.add_date.toString()).format("D/MM/YYYY");
    dispatch(
      newTabletInProgress({
        medicine_name: datas.medicine_name,
        category: datas.category,
        price: datas.price,
        box: datas.box,
        s_price: datas.s_price,
        quantity: datas.quantity,
        generic: datas.generic,
        company: datas.company,
        effects: datas.effects,
        e_date: e_date,
        add_date: add_date,
        gst_value: datas.gst_value,
        gst_percent: datas.gst_percent,
        medicine_type: datas.medicine_type,
        s_price: datas.s_price,
        reorder_quantity: datas.reorder_quantity,
        product_code: datas.product_code,
        hsn_code: datas.hsn_code,
        product_unit: datas.product_unit,
        minimum_order_quantity: datas.minimum_order_quantity,
        product_type: datas.product_type,
        organization_id: orgId,
        status: 1,
      })
    );
    setNavigate(true);
  };
  useEffect(() => {
    const doctorId = localStorage.getItem("doctor_id");
    dispatch(getDoctorInProgress(doctorId));
  }, []);
  useEffect(() => {
    if (addData?.success && navigate)
      history.push({
        pathname: "/tabletlist",
        state: { tableUpdate: addData?.success },
      });
    setNavigate(false);
  }, [addData?.success && navigate]);

  return (
    <Auxiliary>
      {" "}
      <Spin
        size="large"
        spinning={addData?.inProgress}
        tip="Please wait a moment"
        style={{ margin: "20px" }}
      >
        <Card>
          <TabletsForm
            onFinish={onFinish}
            Button={"ADD Medicine"}
            form={form}
            title={"Add MEDICINE"}
            HeaderButton={"CANCEL"}
            type={"danger"}
            icon={<CloseOutlined />}
          />
        </Card>
      </Spin>
    </Auxiliary>
  );
};

export default AddTablets;
