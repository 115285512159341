import React, { useState } from "react";
import { Row, Col, Spin, Card } from "antd";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const PaymentHistory = () => {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");

  const location = useLocation();
  const hospitalDetails = location.state?.paymentData;
  const goBackHandler = () => {
    history.goBack();
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Paid Amount",
      dataIndex: "paid",
      key: "paid",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },

    {
      title: "Options",
      dataIndex: "option",
      render: (_, record, index) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/payment/invoice`,
                  state: { paymentData: record, hospitalData: hospitalDetails },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  label={"INVOICE"}
                />
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Download Full Invoice",
      dataIndex: "option",
      render: (_, record, index) => {
        return (
          <Row className="table-button">
            {index === 0 && (
              <Col className="button-edit">
                <Link
                  to={{
                    pathname: `/payment/invoice`,
                    state: {
                      paymentData: record,
                      hospitalData: hospitalDetails,
                      completeInvoice: "true",
                    },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    label={"FULL-INVOICE"}
                    className={"ml-20"}
                  />
                </Link>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(hospitalList);
    } else {
      const newData = hospitalList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  const hospitalList = hospitalDetails?.subscriptionPayments;

  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            <IntlMessages id={"Addnew.payment_history"} /> -{" "}
            {hospitalDetails?.Organization?.name}
          </p>
        </Col>
        <Col>
          <ButtonComponent
            type="danger"
            icon={<ArrowLeftOutlined />}
            onClick={goBackHandler}
          ></ButtonComponent>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : hospitalList}
          rowselector={handleSelectChange}
          itemList={hospitalList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"hospital_name"}
          disableTableHeaderButtons="false"
        />
      </Spin>
    </Card>
  );
};

export default PaymentHistory;
