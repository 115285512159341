import {
  APPOINMENT_RESCHEDULE_ERROR,
  APPOINMENT_RESCHEDULE_PROGRESS,
  APPOINMENT_RESCHEDULE_SUCCESS,
  GET_APPOINTMENTS_LIST_IN_ERROR,
  GET_APPOINTMENTS_LIST_IN_PROGRESS,
  GET_APPOINTMENTS_LIST_IN_SUCCESS,
  GET_APPOINTMENTS_UPCOMMING_LIST_IN_ERROR,
  GET_APPOINTMENTS_UPCOMMING_LIST_IN_PROGRESS,
  GET_APPOINTMENTS_UPCOMMING_LIST_IN_SUCCESS,
  GET_DOCTOR_APPOINTMENT_LIST_IN_ERROR,
  GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS,
  GET_DOCTOR_APPOINTMENT_LIST_IN_SUCCESS,
  GET_DOCTOR_APPOINTMENT_UPCOMING_IN_ERROR,
  GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS,
  GET_DOCTOR_APPOINTMENT_UPCOMING_IN_SUCCESS,
  GET_UPCOMING_APPOINTMENT_LIST_ERROR,
  GET_UPCOMING_APPOINTMENT_LIST_INPROGRESS,
  GET_UPCOMING_APPOINTMENT_LIST_SUCCESS,
} from "../../../constants/ActionTypes";
// Get All Appointments
export const getAppointmentsListInProgress = (payload) => ({
  type: GET_APPOINTMENTS_LIST_IN_PROGRESS,
  payload: payload,
});

export const getAppointmentsListInSuccess = (payload) => ({
  type: GET_APPOINTMENTS_LIST_IN_SUCCESS,
  payload: payload,
});

export const getAppointmentsListInError = (payload) => ({
  type: GET_APPOINTMENTS_LIST_IN_ERROR,
  payload: payload,
});
export const getUpcomingAppointmentInProgress = (payload) => ({
  type: GET_UPCOMING_APPOINTMENT_LIST_INPROGRESS,
  payload: payload,
});

export const getUpcomingAppointmentSuccess = (payload) => ({
  type: GET_UPCOMING_APPOINTMENT_LIST_SUCCESS,
  payload: payload,
});

export const getUpcomingAppointmentError = (payload) => ({
  type: GET_UPCOMING_APPOINTMENT_LIST_ERROR,
  payload: payload,
});
// Get Doctor Appointment
export const getDoctorAppointmentListInProgress = (payload) => ({
  type: GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS,
  payload: payload,
});

export const getDoctorAppointmentListInSuccess = (payload) => ({
  type: GET_DOCTOR_APPOINTMENT_LIST_IN_SUCCESS,
  payload: payload,
});

export const getDoctorAppointmentListInError = (payload) => ({
  type: GET_DOCTOR_APPOINTMENT_LIST_IN_ERROR,
  payload: payload,
});

// Get Doctor Upcoming Appointment
export const getDoctorUpcomingAppointmentListInProgress = (payload) => ({
  type: GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS,
  payload: payload,
});

export const getDoctorUpcomingAppointmentListInSuccess = (payload) => ({
  type: GET_DOCTOR_APPOINTMENT_UPCOMING_IN_SUCCESS,
  payload: payload,
});

export const getDoctorUpcomingAppointmentListInError = (payload) => ({
  type: GET_DOCTOR_APPOINTMENT_UPCOMING_IN_ERROR,
  payload: payload,
});

// appointment reschedule

export const reScheduleAppointmentByIdInProgress = (payload) => {
  return {
    type: APPOINMENT_RESCHEDULE_PROGRESS,
    payload: payload,
  };
};
export const reScheduleAppointmentByIdInSuccess = (payload) => {
  return {
    type: APPOINMENT_RESCHEDULE_SUCCESS,
    payload: payload,
  };
};
export const reScheduleAppointmentByIdInFailure = (payload) => {
  return {
    type: APPOINMENT_RESCHEDULE_ERROR,
    payload: payload,
  };
};
