import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddSales from "./AddSales";
import { useSelector } from "react-redux";
import { getSalesInProgress } from "../../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch } from "react-redux";

const EditSales = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  // const salesData = location.state?.salesData;
  const { getSales, addSalesPayment, updateSalesPayment } = useSelector(
    (state) => state?.pharmacy
  );
  const salesData = getSales?.data?.data || "";

  useEffect(() => {
    dispatch(getSalesInProgress(location.state?.salesData?.id));
  }, [
    dispatch,
    location.state?.salesData?.id,
    addSalesPayment?.success,
    updateSalesPayment?.success,
  ]);

  return <AddSales salesData={salesData} editSales="true" />;
};

export default EditSales;
