import React from "react";
import { Card, Row, Col, Form } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import { APP_ROUTES } from "../../../constants/Routes";
import { Link } from "react-router-dom";

const SpecializationForm = ({ title, onFinish, buttonName, form }) => {
  return (
    <Card>
      <h1>{<IntlMessages id={title} />}</h1>
      <Form
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        colon={"true"}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row>
          <Col xs={24} sm={24} md={12} lg={24} xl={12}>
            {" "}
            <FormInput
              labelCol={"4"}
              wrapperCol={"24"}
              type="text"
              placeholder="Name"
              name={"Name"}
              label={<IntlMessages id={"Addnew.org.label.name"} />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"Addnew.org.validator.name"} />,
                },
              ]}
            />
          </Col>
        </Row>

        <Row className="button-row">
          <Col className="register-button">
            {" "}
            <Link to={APP_ROUTES.SPECIALIZATION}>
              <ButtonComponent
                type="danger"
                label="Cancel"
                style={{ marginRight: "10px" }}
              />
            </Link>
            <ButtonComponent
              type="primary"
              label={buttonName}
              htmlType="submit"
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SpecializationForm;
