import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from "../components/CircularProgress";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import DocmozIcon from "../assets/icons/final-logo1 1.png";
import DocmozCar from "../common/images/doctor-heartbeat.jpg";
import DocmozCar2 from "../common/images/loginpage3.png";
import HeartIcon from "../common/images/blood-pressure-3312513_1280.png";
import { APP_ROUTES } from "../constants/Routes";
import {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userTwitterSignIn,
  setOtp,
  hideAuthLoader,
  setVerificationOtp,
  setRegisterSuccess,
} from "../appRedux/doctor/actions/Auth";
import IntlMessages from "../util/IntlMessages";
import { UserLoginInProgress } from "../appRedux/doctor/actions/AuthAction";
import Otpsend from "./OtpSend";

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const [form] = Form.useForm();
  const emaildata = form.getFieldValue("email");
  const [otp, setOtp] = useState(false);
  const [loginOtp, setLoginOtp] = useState(false);
  const history = useHistory();
  const authUser1 = useSelector((state) => state?.auth?.userLogin);
  const AuthData = useSelector((state) => state?.authData?.authData);
  const OTPERROR = AuthData?.data;
  const getToken = localStorage.getItem("user_id");

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  });
  useEffect(() => {
    if (AuthData?.success == true && getToken != null) {
      setTimeout(() => {
        history.push("/");
      }, 500);
    }
  }, [AuthData?.success, getToken]);

  const onFinishFailed = (errorInfo) => {};
  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(UserLoginInProgress(values));
  };
  useEffect(() => {
    if (AuthData?.error) dispatch(hideAuthLoader());
  }, [AuthData?.error]);
  useEffect(() => {
    if (
      OTPERROR?.message ===
      "Please verify your account with the recent OTP received"
    )
      history.push(`/otpVerification/${emaildata}`);
  }, [OTPERROR]);
  return (
    <div className=" dashbord-logo-content">
      {/* {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : ( */}
      <>
        <div
          className=" left-side "
          // style={{ background: "#F6F5FB", color: "black" }}
        >
          <div className="carousel-content-main ">
            <img alt="example" src={DocmozCar2} className="carousel-content" />
          </div>
        </div>
        <div className=" right-side">
          <Card className="card-width card-effect">
            <div className="gx-app-logo login-logo">
              <img alt="example" src={DocmozIcon} />
            </div>

            {otp ? (
              <Otpsend setOtp={setOtp} loginOtp={loginOtp} />
            ) : (
              <Form
                initialValues={{ remember: true }}
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  // initialValue="jeevajj1720@gmail.com"
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                  name="email"
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  // initialValue="Jeeva@17"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>

                <Link
                  className="gx-login-form-forgot form-forgot"
                  onClick={() => {
                    setOtp(true);
                    setLoginOtp(false);
                  }}
                >
                  Forgot password
                </Link>

                <div className="form-buttons ">
                  <Button
                    type="primary"
                    className="gx-mb-0 form-button"
                    htmlType="submit"
                    onClick={() => {
                      setOtp(true);
                      setLoginOtp(true);
                    }}
                  >
                    <IntlMessages id="Sign In with OTP" />
                  </Button>
                  <Button
                    type="primary"
                    className="gx-mb-0 form-button"
                    htmlType="submit"
                    loading={AuthData?.inProgress}
                  >
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </div>
              </Form>
            )}
          </Card>
        </div>
      </>
      {/* )} */}
      {showMessage ? message.error(alertMessage.toString()) : null}
    </div>
  );
};

export default SignIn;
