import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal, Typography, Tooltip } from "antd";
import {
  LoadingOutlined,
  PrinterFilled,
  EyeFilled,
  EditFilled,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";
import "../../../../styles/modules/pharmacy.less";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../../components/Button/ButtonComponent";

import TableComponent from "../../../../components/Table/TableComponent";

import moment from "moment";
import {
  defaultStateAddUpdatePayment,
  getSalesReturnListInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import { access } from "../../../../util/utilz";

const SalesReturnTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { getSalesReturn } = useSelector((state) => state?.pharmacy);
  const PurchaseRequestList = getSalesReturn?.data?.data || [];
  const totalRecords = getSalesReturn.data?.totalRecords;

  useEffect(() => {
    const formData = { pageNo: 1, pagination: true };
    dispatch(getSalesReturnListInProgress(formData));
    dispatch(defaultStateAddUpdatePayment());
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const formData = { pageNo: page, pagination: true };
    dispatch(getSalesReturnListInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(PurchaseRequestList);
    } else {
      const newData = PurchaseRequestList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const columns = [
    {
      title: "SR.No",
      dataIndex: "sales_return_code",
      key: "sales_return_code",
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
    },
    {
      title: "SR Date",
      dataIndex: "return_date",
      key: "return_date",
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Sales Id",
      dataIndex: "salesId",
      sortDirections: ["ascend", "descend"],
      key: "salesId",
      sorter: (a, b) => {
        if (a && b && a.salesId && b.salesId) {
          return a.salesId.localeCompare(b.salesId);
        }
        return 0;
      },
      render: (_, record) => {
        return record?.Sale?.sales_code;
      },
    },
    {
      title: "Return Medicines",
      dataIndex: "returnMedicines",
      key: "returnMedicines",
      render: (_, record) => {
        return record?.sales_return_details?.length;
      },
    },
    {
      title: "Need to pay",
      dataIndex: "need_to_pay",
      sortDirections: ["ascend", "descend"],
      key: "need_to_pay",
      sorter: (a, b) => {
        if (a && b && a.needToPay && b.needToPay) {
          return a.needToPay.localeCompare(b.needToPay);
        }
        return 0;
      },
      render: (data) => {
        return `${Number(data).toFixed(2)}`;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("salesReturn", "view") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"View"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/sales/return/view`,
                      state: { salesReturnData: record },
                    }}
                  >
                    <ButtonComponent
                      type="primary"
                      icon={<EyeFilled />}
                      size="default"
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("salesReturn", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/sales/return/edit`,
                      state: { salesData: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EditFilled />}
                      size="default"
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}

            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Print"}>
                {" "}
                <Link
                  to={{
                    pathname: `/sales/return/view`,
                    state: { salesReturnData: record, print: "print" },
                  }}
                >
                  <ButtonComponent
                    icon={<PrinterFilled />}
                    size="default"
                    type="primary"
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  useEffect(() => {
    if (getSalesReturn?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getSalesReturn]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : PurchaseRequestList}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        rowselector={handleSelectChange}
        itemList={PurchaseRequestList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"patient_name"}
        documentTitle={"Sales  Return List"}
      />

      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <Typography>Are you sure, you want to delete the Data</Typography>
      </Modal>
    </Spin>
  );
};

export default SalesReturnTable;
