import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddItemTransfer from "./AddItemTransfer";

const EditItemTransfer = () => {
  const location = useLocation();
  const supplierData = location.state?.itemTransfer;

  return (
    <AddItemTransfer itemTransferData={supplierData} editItemTransfer="true" />
  );
};

export default EditItemTransfer;
