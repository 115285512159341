import {
  DeleteOutlined,
  EyeOutlined,
  FileAddOutlined,
  HistoryOutlined,
  LoadingOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  ScheduleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import React, { Fragment, useState } from "react";
import ButtonComponent from "../../components/Button/ButtonComponent";
import {
  Row,
  Col,
  Button,
  Spin,
  message,
  Popconfirm,
  Tooltip,
  Typography,
  Modal,
} from "antd";
import PageHeader from "../../components/PageHeader/PageHeader";
import { APP_ROUTES } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";
import TableComponent from "../../components/Table/TableComponent";
import { Tabs } from "antd";
import { useEffect } from "react";
import {
  getAppointmentsListInProgress,
  getAppointmentsUpcommingListInProgress,
  getDoctorAppointmentListInProgress,
  getDoctorUpcomingAppointmentListInProgress,
  getUpcomingAppointmentInProgress,
  reScheduleAppointmentByIdInProgress,
} from "../../appRedux/doctor/actions/Appointment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addPrescription,
  submitPrescription,
} from "../../appRedux/doctor/actions/Prescription";
import moment from "moment";
import {
  getPatientAppoinmentListInProgress,
  getUpcomingPatientAppoinmentListInProgress,
  patientDeleteAppointmentListInProgress,
} from "../../appRedux/Patient/Action/Action";
import { useClient } from "./components/VideoAppoinment/Settings";
import { access, formatDate } from "../../util/utilz";
import ChatBots from "../ChatBot/ChatBots";

const { TabPane } = Tabs;

const AllAppoinments = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [reScheduleModel, setRescheduleModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const dispatch = useDispatch();
  const [tabStatus, setTabStatus] = useState(2);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));
  const patient = localStorage.getItem("patient_id") != "null" ? true : false;

  const doctor_id = localStorage.getItem("doctor_id") != "null" ? true : false;

  const admin = localStorage.getItem("role_id") === "1" ? true : false;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("hh:mm A"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(async () => {
    client.leave();
    client.removeAllListeners();
  }, []);
  const appointmentlists = useSelector((state) => state.patientAppointment);
  // const appointmentLists = appointmentlist?.appointmentlist?.data?.data;

  const upcomingAppointment = useSelector((state) => state.appointment);

  const {
    upcomingAppointmentlist,
    appointmentlist,
    doctorupcomingappointmentlist,
    doctorappointmentlist,
    reSchedule,
    appointmentUpcomminglist,
  } = useSelector((state) => state.appointment);
  const { getAppointmentList } = useSelector(
    (state) => state.patientAppointment
  );
  const { getUpcomingAppointmentList, deleteAppointmentList } = useSelector(
    (state) => state.patientAppointment
  );
  const upcomingPatientList = getUpcomingAppointmentList?.data;

  const appointmentLists = doctor_id
    ? doctorappointmentlist?.data?.data
    : patient
    ? getAppointmentList?.data?.data
    : appointmentlist?.data?.data;
  const totalRecords = doctor_id
    ? doctorappointmentlist?.data?.totalRecords
    : patient
    ? getAppointmentList?.data?.totalRecords
    : appointmentlist?.data?.totalRecords;

  const upcomingAppointmentLists = patient
    ? upcomingPatientList?.data
    : doctor_id
    ? doctorupcomingappointmentlist?.data?.data
    : upcomingAppointmentlist?.data?.data;
  const totalRecords2 = patient
    ? upcomingPatientList?.totalRecords
    : doctor_id
    ? doctorupcomingappointmentlist?.data?.totalRecords
    : upcomingAppointmentlist?.data?.totalRecords;

  // const moment = appointmentslist?.appointmentlist?.data?.data?.start_time

  const handleDelete = (id) => {
    dispatch(patientDeleteAppointmentListInProgress(id));
  };
  const handleReSchedule = (id) => {
    dispatch(reScheduleAppointmentByIdInProgress({ id: id.id }));
    setOpenModel(true);
  };
  useEffect(() => {
    if (reSchedule?.data?.appointmentInfo && openModel) {
      setRescheduleModel(true);
    }
  }, [reSchedule?.data?.appointmentInfo]);

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sortDirections: ["ascend", "descend"],
      key: "patient_name",
      sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
      sortDirections: ["ascend", "descend"],
      key: "doctor_name",
      sorter: (a, b) => a.doctor_name.localeCompare(b.doctor_name),
    },
    {
      title: "Day",
      dataIndex: "week_day",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      render: (start_time) => moment(start_time, ["h:mm A"]).format("hh:mm A"),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (end_time) => moment(end_time, ["h:mm A"]).format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => formatDate(date),
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Symptom",
      dataIndex: "symptom",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        const confirm = () => {
          handleDelete(record.id);
          message.success("Deleted successfully");
        };
        const cancel = () => {
          message.success("cancelled");
        };

        const currentDate = moment();
        const startTime = moment(record.date + "T" + record.start_time);
        const endTime = moment(record.date + "T" + record.end_time);
        const isCurrentDate = currentDate.isSame(moment(record.date), "day");
        const isWithinTimeRange = currentDate.isBetween(startTime, endTime);
        return (
          <>
            <Row className="table-button">
              {access("appointments", "edit") && (
                <>
                  {isCurrentDate &&
                  isWithinTimeRange &&
                  Number(record.consultation_type) === 2 ? (
                    <Col className="button-edit mx-10">
                      <Tooltip placement="top" title={"Video Call"}>
                        <Link
                          to={{
                            pathname: APP_ROUTES.VIDEOCALL,
                            state: record,
                            type: "edit",
                          }}
                        >
                          <ButtonComponent
                            type="primary"
                            icon={<VideoCameraOutlined />}
                          />
                        </Link>
                      </Tooltip>
                    </Col>
                  ) : null}
                </>
              )}
              {Number(tabStatus) === 2 && patient && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={8}
                  className="button-edit mx-10"
                >
                  {" "}
                  <Popconfirm
                    title="Cancel the Appointment"
                    description="Are you sure to cancel this Appointment?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip placement="top" title={"Cancel Appointment"}>
                      {access("appointments", "delete") && (
                        <Button
                          type="danger"
                          icon={<DeleteOutlined />}
                        ></Button>
                      )}
                    </Tooltip>
                  </Popconfirm>
                </Col>
              )}

              {record?.isPrescriptionAdded === "Yes" ? (
                <>
                  <Col className="button-edit mx-10">
                    <Tooltip placement="top" title={"View-Prescription"}>
                      <Link
                        to={{
                          pathname: "/prescription/data",
                          state: { record: record },
                        }}
                      >
                        <ButtonComponent
                          type="primary"
                          icon={<EyeOutlined />}
                        />
                      </Link>
                    </Tooltip>
                  </Col>
                </>
              ) : (
                <>
                  {doctor_id && (
                    <>
                      {(record?.appointment_status === 4 &&
                        record?.isPrescriptionAdded === "No") ||
                      Number(tabStatus) === 3 ? (
                        <>
                          <Col className="button-edit mx-10">
                            <Tooltip placement="top" title={"Add-Prescription"}>
                              <Link
                                to={{
                                  pathname: `${APP_ROUTES.ADDPRESCRIPTION}`,
                                  state: record,
                                }}
                              >
                                <ButtonComponent
                                  type="primary"
                                  icon={<FileAddOutlined />}
                                />
                              </Link>
                            </Tooltip>
                          </Col>
                        </>
                      ) : (
                        <>
                          {Number(record.isPrev) != 1 && (
                            <>
                              {access("appointments", "edit") && (
                                <>
                                  {isCurrentDate && isWithinTimeRange ? (
                                    <Col className="button-edit mx-10">
                                      <Tooltip
                                        placement="top"
                                        title={"Re-Schedule"}
                                      >
                                        <Button
                                          icon={<ScheduleOutlined />}
                                          size="large"
                                          type="primary"
                                          disabled={true}
                                        ></Button>
                                      </Tooltip>
                                    </Col>
                                  ) : (
                                    <Col className="button-edit mx-10">
                                      <Tooltip
                                        placement="top"
                                        title={"Re-Scheduled"}
                                      >
                                        <Popconfirm
                                          title="Re-Scheduled the Appointment"
                                          description="Are you sure to Re-Scheduled this Appointment?"
                                          onConfirm={() => {
                                            handleReSchedule(record);
                                          }}
                                          onCancel={cancel}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <Button
                                            icon={<ScheduleOutlined />}
                                            size="large"
                                            type="primary"
                                            // onClick={}
                                          ></Button>
                                        </Popconfirm>
                                        {/* <ButtonComponent
                                  icon={<ScheduleOutlined />}
                                  type="primary"
                                  onClick={() => {
                                    handleReSchedule(record);
                                  }}
                                /> */}
                                      </Tooltip>
                                    </Col>
                                  )}
                                </>
                              )}
                            </>
                          )}{" "}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    // handlePage(1);
    handleUpcomingPage(1);
    previousHandlePage(1);
  }, [deleteAppointmentList?.success, reSchedule?.success]);

  useEffect(() => {
    dispatch(submitPrescription());
    dispatch(addPrescription());
  }, [deleteAppointmentList?.success, reSchedule?.success]);

  const handlePage = (page) => {
    dispatch(getAppointmentsListInProgress(page));
  };
  const previousHandlePage = (page) => {
    if (patient) {
      dispatch(
        getPatientAppoinmentListInProgress({
          page,
          currentDate,
          currentTime,
        })
      );
    } else if (doctor_id) {
      dispatch(
        getDoctorAppointmentListInProgress({ page, currentDate, currentTime })
      );
    } else {
      dispatch(getAppointmentsListInProgress({ page, isPrev: 1 }));
    }
  };
  const handleUpcomingPage = (page) => {
    if (patient) {
      dispatch(
        getUpcomingPatientAppoinmentListInProgress({
          page,
          currentDate,
          currentTime,
        })
      );
    } else if (doctor_id) {
      dispatch(
        getDoctorUpcomingAppointmentListInProgress({
          page,
          currentDate,
          currentTime,
        })
      );
    } else {
      dispatch(getUpcomingAppointmentInProgress({ page, isPrev: 0 }));
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <Fragment>
      <Spin
        indicator={antIcon}
        spinning={
          deleteAppointmentList.inProgress ||
          reSchedule.progressing ||
          appointmentlist?.progressing ||
          doctorappointmentlist?.progressing ||
          upcomingAppointmentlist?.progressing
        }
      >
        <PageHeader
          header={<IntlMessages id={"Addnew.appoinment"} />}
          link={APP_ROUTES.APPOINMENTANSWER}
          buttonLabel={<IntlMessages id={"Addnew.patient.addappoinment"} />}
          icon={<PlusCircleOutlined />}
          module={"appointments"}
          accessData="true"
        />

        <Tabs
          tabPosition={"top"}
          onChange={(e) => {
            setTabStatus(e);
          }}
        >
          {/* <TabPane tab="All" key="1">
          <Spin
            size="large"
            spinning={loading}
            tip="Please wait a moment"
            style={{ margin: "20px" }}
          >
            <TableComponent
              columns={columns}
              dataSource={filteredUsers ? filteredUsers : appointmentLists}
              scroll={true}
              pagination={{
                total: totalRecords,
                onChange: (page) => handlePage(page),
              }}
              // Allappointments={appointmentLists}
              // AllappointmentsFilter={updateFilteredData}
              rowselector={handleSelectChange}
              itemList={appointmentLists}
              filteredUsers={filteredUsers}
              setFilteredUsers={setFilteredUsers}
              type="Appointment"
              field={"doctor_name"}
            />
          </Spin>
        </TabPane> */}
          <TabPane tab="Upcoming" key="2">
            <TableComponent
              columns={columns}
              dataSource={upcomingAppointmentLists}
              pagination={{
                total: totalRecords2,
                onChange: (page) => handleUpcomingPage(page),
              }}
              itemList={upcomingAppointmentLists}
              type="Appointment"
              documentTitle={"Appointment"}
            />
          </TabPane>
          <TabPane tab="Previous" key="3">
            <TableComponent
              columns={columns}
              dataSource={appointmentLists}
              pagination={{
                total: totalRecords,
                onChange: (page) => previousHandlePage(page),
              }}
              itemList={appointmentLists}
              type="Appointment"
              documentTitle={"Appointment"}
            />
          </TabPane>
        </Tabs>
        <ChatBots />
      </Spin>
    </Fragment>
  );
};

export default AllAppoinments;
