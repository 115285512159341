import React from "react";

import { Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { PlusCircleOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import "../../../styles/modules/billingPackages.less";
import { APP_ROUTES } from "../../../constants/Routes";
import Auxiliary from "../../../util/Auxiliary";
import { access } from "../../../util/utilz";

const BillingHeader = () => {
  return (
    <Auxiliary>
      <Row className="bot">
        <Col span={12}>
          <p className="header-tag">{<IntlMessages id={"Addnew.payment"} />}</p>
        </Col>
        {access("billingPayment", "add") && (
          <Col span={12} className="btn-main">
            <Link to={APP_ROUTES.ADDBILLINGS}>
              <ButtonComponent
                className="buttons"
                icon={<PlusCircleOutlined />}
                type="primary"
                label={<IntlMessages id={"Addnew.payment.add"} />}
              />
            </Link>
          </Col>
        )}
      </Row>
      <Divider />
    </Auxiliary>
  );
};

export default BillingHeader;
