import {
  GET_PHARMACY_DASHBOARD_ERROR,
  GET_PHARMACY_DASHBOARD_IN_PROGRESS,
  GET_PHARMACY_DASHBOARD_SUCCESS,
  GET_TABLETS_BY_ORG_ERROR,
  GET_TABLETS_BY_ORG_IN_PROGRESS,
  GET_TABLETS_BY_ORG_SUCCESS,
  DELETE_TABLET_ERROR,
  DELETE_TABLET_IN_PROGRESS,
  DELETE_TABLET_SUCCESS,
  GET_TABLET_ERROR,
  GET_TABLET_IN_PROGRESS,
  GET_TABLET_LIST_ERROR,
  GET_TABLET_LIST_IN_PROGRESS,
  GET_TABLET_LIST_SUCCESS,
  GET_TABLET_SUCCESS,
  NEW_TABLET_ERROR,
  NEW_TABLET_IN_PROGRESS,
  NEW_TABLET_SUCCESS,
  UPDATE_TABLET_ERROR,
  UPDATE_TABLET_IN_PROGRESS,
  UPDATE_TABLET_SUCCESS,
  GET_TABLET_CATEGORY_LIST_IN_PROGRESS,
  GET_TABLET_CATEGORY_LIST_SUCCESS,
  GET_TABLET_CATEGORY_LIST_ERROR,
  ADD_TABLET_CATEGORY_IN_PROGRESS,
  ADD_TABLET_CATEGORY_SUCCESS,
  ADD_TABLET_CATEGORY_ERROR,
  EDIT_TABLET_CATEGORY_IN_PROGRESS,
  EDIT_TABLET_CATEGORY_SUCCESS,
  EDIT_TABLET_CATEGORY_ERROR,
  DELETE_TABLET_CATEGORY_IN_PROGRESS,
  DELETE_TABLET_CATEGORY_SUCCESS,
  DELETE_TABLET_CATEGORY_ERROR,
  DEFAULT_TABLET_CATEGORY,
  DEFAULT_TABLET_LIST,
  GET_PRODUCT_TYPE_IN_PROGRESS,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_ERROR,
  GET_PRODUCT_UNIT_IN_PROGRESS,
  GET_PRODUCT_UNIT_SUCCESS,
  GET_PRODUCT_UNIT_ERROR,
  GET_SUPPLIER_LIST_IN_PROGRESS,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_LIST_ERROR,
  ADD_SUPPLIER_IN_PROGRESS,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_ERROR,
  EDIT_SUPPLIER_IN_PROGRESS,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_ERROR,
  DELETE_SUPPLIER_IN_PROGRESS,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_ERROR,
  DEFAULT_SUPPLIER,
  GET_PHARMACY_PROFILE_IN_PROGRESS,
  GET_PHARMACY_PROFILE_SUCCESS,
  GET_PHARMACY_PROFILE_ERROR,
  EDIT_PHARMACY_PROFILE_IN_PROGRESS,
  EDIT_PHARMACY_PROFILE_SUCCESS,
  EDIT_PHARMACY_PROFILE_ERROR,
  CHANGE_PASSWORD_IN_PROGRESS,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  EXPIRY_STOCK_IN_PROGRESS,
  EXPIRY_STOCK_SUCCESS,
  EXPIRY_STOCK_ERROR,
  STOCK_ADJUSTMENT_LIST_IN_PROGRESS,
  STOCK_ADJUSTMENT_LIST_SUCCESS,
  STOCK_ADJUSTMENT_LIST_ERROR,
  ADD_STOCK_ADJUSTMENT_IN_PROGRESS,
  ADD_STOCK_ADJUSTMENT_SUCCESS,
  ADD_STOCK_ADJUSTMENT_ERROR,
  EDIT_STOCK_ADJUSTMENT_IN_PROGRESS,
  EDIT_STOCK_ADJUSTMENT_SUCCESS,
  EDIT_STOCK_ADJUSTMENT_ERROR,
  DELETE_STOCK_ADJUSTMENT_IN_PROGRESS,
  DELETE_STOCK_ADJUSTMENT_ERROR,
  DELETE_STOCK_ADJUSTMENT_SUCCESS,
  GET_PURCHASE_LIST_IN_PROGRESS,
  GET_PURCHASE_LIST_SUCCESS,
  GET_PURCHASE_LIST_ERROR,
  ADD_PURCHASE_IN_PROGRESS,
  ADD_PURCHASE_SUCCESS,
  ADD_PURCHASE_ERROR,
  EDIT_PURCHASE_IN_PROGRESS,
  EDIT_PURCHASE_SUCCESS,
  EDIT_PURCHASE_ERROR,
  DELETE_PURCHASE_IN_PROGRESS,
  DELETE_PURCHASE_SUCCESS,
  DELETE_PURCHASE_ERROR,
  GET_PURCHASE_REQUEST_LIST_IN_PROGRESS,
  GET_PURCHASE_REQUEST_LIST_SUCCESS,
  GET_PURCHASE_REQUEST_LIST_ERROR,
  ADD_PURCHASE_REQUEST_SUCCESS,
  ADD_PURCHASE_REQUEST_ERROR,
  EDIT_PURCHASE_REQUEST_IN_PROGRESS,
  EDIT_PURCHASE_REQUEST_SUCCESS,
  EDIT_PURCHASE_REQUEST_ERROR,
  DELETE_PURCHASE_REQUEST_ERROR,
  DELETE_PURCHASE_REQUEST_SUCCESS,
  DELETE_PURCHASE_REQUEST_IN_PROGRESS,
  GET_PURCHASE_RETURN_LIST_IN_PROGRESS,
  GET_PURCHASE_RETURN_LIST_SUCCESS,
  GET_PURCHASE_RETURN_LIST_ERROR,
  ADD_PURCHASE_RETURN_IN_PROGRESS,
  ADD_PURCHASE_RETURN_SUCCESS,
  ADD_PURCHASE_RETURN_ERROR,
  EDIT_PURCHASE_RETURN_IN_PROGRESS,
  EDIT_PURCHASE_RETURN_SUCCESS,
  EDIT_PURCHASE_RETURN_ERROR,
  DELETE_PURCHASE_RETURN_IN_PROGRESS,
  DELETE_PURCHASE_RETURN_SUCCESS,
  DELETE_PURCHASE_RETURN_ERROR,
  ADD_PURCHASE_REQUEST_IN_PROGRESS,
  PURCHASE_REQUEST_DEFAULT_DATA,
  GET_TABLETS_ID_IN_PROGRESS,
  GET_TABLETS_ID_SUCCESS,
  GET_TABLETS_ID_ERROR,
  ADD_SALES_IN_PROGRESS,
  ADD_SALES_SUCCESS,
  ADD_SALES_ERROR,
  GET_LIST_SALES_IN_PROGRESS,
  GET_LIST_SALES_SUCCESS,
  GET_LIST_SALES_ERROR,
  GET_SALES_IN_PROGRESS,
  GET_SALES_SUCCESS,
  GET_SALES_ERROR,
  EDIT_SALES_IN_PROGRESS,
  EDIT_SALES_SUCCESS,
  EDIT_SALES_ERROR,
  GET_SALES_LIST_IN_PROGRESS,
  GET_SALES_LIST_SUCCESS,
  GET_SALES_LIST_ERROR,
  STOCK_ADJUSTMENT_DEFAULT_DATA,
  ADD_BULK_MEDICINE_IN_PROGRESS,
  ADD_BULK_MEDICINE_SUCCESS,
  ADD_BULK_MEDICINE_ERROR,
  DEFAULT_BULK_MEDICINE,
  DEFAULT_PURCHASE_RETURN,
  DELETE_SALES_IN_PROGRESS,
  DELETE_SALES_SUCCESS,
  DELETE_SALES_ERROR,
  SALES_PAYMENT_IN_PROGRESS,
  SALES_PAYMENT_SUCCESS,
  SALES_PAYMENT_ERROR,
  UPDATE_SALES_PAYMENT_IN_PROGRESS,
  UPDATE_SALES_PAYMENT_SUCCESS,
  UPDATE_SALES_PAYMENT_ERROR,
  SALES_PAYMENT_DUE_IN_PROGRESS,
  SALES_PAYMENT_DUE_SUCCESS,
  SALES_PAYMENT_DUE_ERROR,
  GET_DEPARTMENT_LIST_IN_PROGRESS,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_DEPARTMENT_LIST_ERROR,
  ADD_DEPARTMENT_IN_PROGRESS,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_ERROR,
  EDIT_DEPARTMENT_IN_PROGRESS,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_IN_PROGRESS,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  GET_ITEM_TRANSFER__LIST_IN_PROGRESS,
  GET_ITEM_TRANSFER_LIST_SUCCESS,
  GET_ITEM_TRANSFER_LIST_ERROR,
  ADD_ITEM_TRANSFER_IN_PROGRESS,
  ADD_ITEM_TRANSFER_SUCCESS,
  ADD_ITEM_TRANSFER_ERROR,
  EDIT_ITEM_TRANSFER_IN_PROGRESS,
  EDIT_ITEM_TRANSFER_ERROR,
  EDIT_ITEM_TRANSFER_SUCCESS,
  DELETE_ITEM_TRANSFER_IN_PROGRESS,
  DELETE_ITEM_TRANSFER_SUCCESS,
  DELETE_ITEM_TRANSFER_ERROR,
  DEFAULT_ITEM_TRANSFER,
  GET_PHARMACY_LIST_IN_PROGRESS,
  GET_PHARMACY_LIST_SUCCESS,
  GET_PHARMACY_LIST_ERROR,
  SALES_CANCEL_UNDO_IN_PROGRESS,
  SALES_CANCEL_UNDO_SUCCESS,
  SALES_CANCEL_UNDO_ERROR,
  DEFAULT_UPDATE_ADD_PAYMENT,
  PATIENT_SALES_DUE_IN_PROGRESS,
  PATIENT_SALES_DUE_SUCCESS,
  PATIENT_SALES_DUE_ERROR,
  GET_DUE_PATIENT_IN_PROGRESS,
  GET_DUE_PATIENT_SUCCESS,
  GET_DUE_PATIENT_ERROR,
  ADD_SALES_RETURN_IN_PROGRESS,
  ADD_SALES_RETURN_SUCCESS,
  ADD_SALES_RETURN_ERROR,
  GET_SALES_RETURN_LIST_IN_PROGRESS,
  GET_SALES_RETURN_LIST_SUCCESS,
  GET_SALES_RETURN_LIST_ERROR,
  UPDATE_SALES_RETURN_IN_PROGRESS,
  UPDATE_SALES_RETURN_SUCCESS,
  UPDATE_SALES_RETURN_ERROR,
  SALES_LIST_BY_PATIENT_PROGRESS,
  SALES_LIST_BY_PATIENT_SUCCESS,
  SALES_LIST_BY_PATIENT_ERROR,
  ADD_BULK_PURCHASE_IN_PROGRESS,
  ADD_BULK_PURCHASE_SUCCESS,
  ADD_BULK_PURCHASE_ERROR,
  BULK_MEDICINE_CATEGORY_INPROGRESS,
  BULK_MEDICINE_CATEGORY_SUCCESS,
  BULK_MEDICINE_CATEGORY_ERROR,
  GET_GST_LIST_INPROGRESS,
  GET_GST_LIST_SUCCESS,
  GET_GST_LIST_ERROR,
  DEFAULT_BULK_MEDICINE_CATEGORY,
  GET_PHARMACYREPORT_IN_PROGRESS,
  GET_PHARMACYREPORT_IN_SUCCESS,
  GET_PHARMACYREPORT_IN_ERROR,
  GET_AVABILE_STOCK_IN_SUCCESS,
  GET_AVABILE_STOCK_IN_ERROR,
  GET_AVABILE_STOCK_IN_PROGRESS,
} from "../../constants/ActionTypes";
import { departmentList } from "../../screens/Doctor/data";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  pharmacyDashboard: { ...defaultStatus },
  getTablets: { ...defaultStatus },
  tabletList: { ...defaultStatus },
  getTablet: { ...defaultStatus },
  newTablet: { ...defaultStatus },
  updateTablet: { ...defaultStatus },
  deleteTablet: { ...defaultStatus },
  TabletOrg: { ...defaultStatus },
  tabletCategoryList: { ...defaultStatus },
  addTabletCategory: { ...defaultStatus },
  editTabletCategory: { ...defaultStatus },
  deleteTabletCategory: { ...defaultStatus },
  getProductType: { ...defaultStatus },
  getProductUnit: { ...defaultStatus },
  getSupplierList: { ...defaultStatus },
  addSupplier: { ...defaultStatus },
  editSupplier: { ...defaultStatus },
  deleteSupplier: { ...defaultStatus },
  expiryStock: { ...defaultStatus },
  stockAdjustmentList: { ...defaultStatus },
  addStockAdjustment: { ...defaultStatus },
  editStockAdjustment: { ...defaultStatus },
  deleteStockAdjustment: { ...defaultStatus },
  purchaseList: { ...defaultStatus },
  addPurchase: { ...defaultStatus },
  editPurchase: { ...defaultStatus },
  deletePurchase: { ...defaultStatus },
  purchaseRequestList: { ...defaultStatus },
  addPurchaseRequest: { ...defaultStatus },
  editPurchaseRequest: { ...defaultStatus },
  deletePurchaseRequest: { ...defaultStatus },
  purchaseReturnList: { ...defaultStatus },
  addPurchaseReturn: { ...defaultStatus },
  editPurchaseReturn: { ...defaultStatus },
  deletePurchaseReturn: { ...defaultStatus },
  getTabletsId: { ...defaultStatus },
  addSales: { ...defaultStatus },
  getSalesList: { ...defaultStatus },
  getSales: { ...defaultStatus },
  editSaless: { ...defaultStatus },
  deleteSales: { ...defaultStatus },
  addBulkMedicine: { ...defaultStatus },
  addSalesPayment: { ...defaultStatus },
  updateSalesPayment: { ...defaultStatus },
  salesPaymentDue: { ...defaultStatus },
  departmentList: { ...defaultStatus },
  addDepartment: { ...defaultStatus },
  editDepartment: { ...defaultStatus },
  deleteDepartment: { ...defaultStatus },
  itemTransferList: { ...defaultStatus },
  addItemTransfer: { ...defaultStatus },
  editItemTransfer: { ...defaultStatus },
  deleteItemTransfer: { ...defaultStatus },
  pharmacyList: { ...defaultStatus },
  cancelUndo: { ...defaultStatus },
  patientSalesDue: { ...defaultStatus },
  dueByPatient: { ...defaultStatus },
  addSalesReturn: { ...defaultStatus },
  getSalesReturn: { ...defaultStatus },
  updateSalesReturn: { ...defaultStatus },
  salesByPatientList: { ...defaultStatus },
  bulkPurchase: { ...defaultStatus },
  bulkMedicineCategory: { ...defaultStatus },
  gstList: { ...defaultStatus },
  gstPharmacyReportList: { ...defaultStatus },
  getAvailableStockList: { ...defaultStatus },
};
const PharmacyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PHARMACY_DASHBOARD_IN_PROGRESS:
      return {
        ...state,
        pharmacyDashboard: { ...defaultStatus, inProgress: true },
      };
    case GET_PHARMACY_DASHBOARD_SUCCESS:
      return {
        ...state,
        pharmacyDashboard: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PHARMACY_DASHBOARD_ERROR:
      return {
        ...state,
        pharmacyDashboard: { ...defaultStatus, ...payload, error: true },
      };
    case GET_TABLETS_BY_ORG_IN_PROGRESS:
      return {
        ...state,
        tabletOrg: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_TABLETS_BY_ORG_SUCCESS:
      return {
        ...state,
        tabletOrg: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_TABLETS_BY_ORG_ERROR:
      return {
        ...state,
        tabletOrg: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_TABLET_LIST_IN_PROGRESS:
      return {
        ...state,
        tabletList: { ...defaultStatus, inProgress: true, ...payload },
      };
    case GET_TABLET_LIST_SUCCESS:
      return {
        ...state,
        tabletList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_TABLET_LIST_ERROR:
      return {
        ...state,
        tabletList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case NEW_TABLET_IN_PROGRESS:
      return {
        ...state,
        newTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case NEW_TABLET_SUCCESS:
      return {
        ...state,
        newTablet: { ...defaultStatus, ...payload, success: true },
      };
    case NEW_TABLET_ERROR:
      return {
        ...state,
        newTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_TABLET_IN_PROGRESS:
      return {
        ...state,
        updateTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_TABLET_SUCCESS:
      return {
        ...state,
        updateTablet: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_TABLET_ERROR:
      return {
        ...state,
        updateTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_TABLET_IN_PROGRESS:
      return {
        ...state,
        getTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_TABLET_SUCCESS:
      return {
        ...state,
        getTablet: { ...defaultStatus, ...payload, success: true },
      };
    case GET_TABLET_ERROR:
      return {
        ...state,
        getTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DELETE_TABLET_IN_PROGRESS:
      return {
        ...state,
        deleteTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_TABLET_SUCCESS:
      return {
        ...state,
        deleteTablet: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_TABLET_ERROR:
      return {
        ...state,
        deleteTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_TABLET_CATEGORY_LIST_IN_PROGRESS:
      return {
        ...state,
        tabletCategoryList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_TABLET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        tabletCategoryList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_TABLET_CATEGORY_LIST_ERROR:
      return {
        ...state,
        tabletCategoryList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_TABLET_CATEGORY_IN_PROGRESS:
      return {
        ...state,
        addTabletCategory: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_TABLET_CATEGORY_SUCCESS:
      return {
        ...state,
        addTabletCategory: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_TABLET_CATEGORY_ERROR:
      return {
        ...state,
        addTabletCategory: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_TABLET_CATEGORY_IN_PROGRESS:
      return {
        ...state,
        editTabletCategory: { ...defaultStatus, ...payload, inProgress: true },
      };
    case EDIT_TABLET_CATEGORY_SUCCESS:
      return {
        ...state,
        editTabletCategory: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_TABLET_CATEGORY_ERROR:
      return {
        ...state,
        editTabletCategory: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_TABLET_CATEGORY_IN_PROGRESS:
      return {
        ...state,
        deleteTabletCategory: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_TABLET_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteTabletCategory: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_TABLET_CATEGORY_ERROR:
      return {
        ...state,
        deleteTabletCategory: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_TABLET_CATEGORY:
      return {
        ...state,
        addTabletCategory: {
          ...defaultStatus,
        },
        editTabletCategory: {
          ...defaultStatus,
        },
      };
    case DEFAULT_TABLET_LIST:
      return {
        ...state,
        newTablet: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
        updateTablet: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PRODUCT_TYPE_IN_PROGRESS:
      return {
        ...state,
        getProductType: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        getProductType: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PRODUCT_TYPE_ERROR:
      return {
        ...state,
        getProductType: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_PRODUCT_UNIT_IN_PROGRESS:
      return {
        ...state,
        getProductUnit: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PRODUCT_UNIT_SUCCESS:
      return {
        ...state,
        getProductUnit: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PRODUCT_UNIT_ERROR:
      return {
        ...state,
        getProductUnit: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_SUPPLIER_LIST_IN_PROGRESS:
      return {
        ...state,
        getSupplierList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        getSupplierList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SUPPLIER_LIST_ERROR:
      return {
        ...state,
        getSupplierList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_SUPPLIER_IN_PROGRESS:
      return {
        ...state,
        addSupplier: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_SUPPLIER_SUCCESS:
      return {
        ...state,
        addSupplier: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SUPPLIER_ERROR:
      return {
        ...state,
        addSupplier: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_SUPPLIER_IN_PROGRESS:
      return {
        ...state,
        editSupplier: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_SUPPLIER_SUCCESS:
      return {
        ...state,
        editSupplier: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_SUPPLIER_ERROR:
      return {
        ...state,
        editSupplier: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_SUPPLIER_IN_PROGRESS:
      return {
        ...state,
        deleteSupplier: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        deleteSupplier: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_SUPPLIER_ERROR:
      return {
        ...state,
        deleteSupplier: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_SUPPLIER:
      return {
        ...state,
        addSupplier: {
          ...defaultStatus,
        },
        editSupplier: { ...defaultStatus },
      };
    case GET_PHARMACY_PROFILE_IN_PROGRESS:
      return {
        ...state,
        pharmacyProfile: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PHARMACY_PROFILE_SUCCESS:
      return {
        ...state,
        pharmacyProfile: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PHARMACY_PROFILE_ERROR:
      return {
        ...state,
        pharmacyProfile: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_PHARMACY_PROFILE_IN_PROGRESS:
      return {
        ...state,
        editPharmacyProfile: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_PHARMACY_PROFILE_SUCCESS:
      return {
        ...state,
        editPharmacyProfile: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_PHARMACY_PROFILE_ERROR:
      return {
        ...state,
        editPharmacyProfile: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case CHANGE_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        changePassword: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: { ...defaultStatus, ...payload, success: true },
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EXPIRY_STOCK_IN_PROGRESS:
      return {
        ...state,
        expiryStock: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EXPIRY_STOCK_SUCCESS:
      return {
        ...state,
        expiryStock: { ...defaultStatus, ...payload, success: true },
      };
    case EXPIRY_STOCK_ERROR:
      return {
        ...state,
        expiryStock: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case STOCK_ADJUSTMENT_LIST_IN_PROGRESS:
      return {
        ...state,
        stockAdjustmentList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
        addStockAdjustment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case STOCK_ADJUSTMENT_LIST_SUCCESS:
      return {
        ...state,
        stockAdjustmentList: { ...defaultStatus, ...payload, success: true },
      };
    case STOCK_ADJUSTMENT_LIST_ERROR:
      return {
        ...state,
        stockAdjustmentList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_STOCK_ADJUSTMENT_IN_PROGRESS:
      return {
        ...state,
        addStockAdjustment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        addStockAdjustment: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_STOCK_ADJUSTMENT_ERROR:
      return {
        ...state,
        addStockAdjustment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_STOCK_ADJUSTMENT_IN_PROGRESS:
      return {
        ...state,
        editStockAdjustment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        editStockAdjustment: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_STOCK_ADJUSTMENT_ERROR:
      return {
        ...state,
        editStockAdjustment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_STOCK_ADJUSTMENT_IN_PROGRESS:
      return {
        ...state,
        deleteStockAdjustment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        deleteStockAdjustment: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_STOCK_ADJUSTMENT_ERROR:
      return {
        ...state,
        deleteStockAdjustment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_PURCHASE_LIST_IN_PROGRESS:
      return {
        ...state,
        purchaseList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PURCHASE_LIST_SUCCESS:
      return {
        ...state,
        purchaseList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_PURCHASE_LIST_ERROR:
      return {
        ...state,
        purchaseList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_PURCHASE_IN_PROGRESS:
      return {
        ...state,
        addPurchase: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_PURCHASE_SUCCESS:
      return {
        ...state,
        addPurchase: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_PURCHASE_ERROR:
      return {
        ...state,
        addPurchase: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_PURCHASE_IN_PROGRESS:
      return {
        ...state,
        editPurchase: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_PURCHASE_SUCCESS:
      return {
        ...state,
        editPurchase: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case EDIT_PURCHASE_ERROR:
      return {
        ...state,
        editPurchase: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_PURCHASE_IN_PROGRESS:
      return {
        ...state,
        deletePurchase: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_PURCHASE_SUCCESS:
      return {
        ...state,
        deletePurchase: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_PURCHASE_ERROR:
      return {
        ...state,
        deletePurchase: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_PURCHASE_REQUEST_LIST_IN_PROGRESS:
      return {
        ...state,
        purchaseRequestList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PURCHASE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        purchaseRequestList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_PURCHASE_REQUEST_LIST_ERROR:
      return {
        ...state,
        purchaseRequestList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_PURCHASE_REQUEST_IN_PROGRESS:
      return {
        ...state,
        addPurchaseRequest: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        addPurchaseRequest: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_PURCHASE_REQUEST_ERROR:
      return {
        ...state,
        addPurchaseRequest: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_PURCHASE_REQUEST_IN_PROGRESS:
      return {
        ...state,
        editPurchaseRequest: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        editPurchaseRequest: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case EDIT_PURCHASE_REQUEST_ERROR:
      return {
        ...state,
        editPurchaseRequest: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_PURCHASE_REQUEST_IN_PROGRESS:
      return {
        ...state,
        deletePurchaseRequest: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        deletePurchaseRequest: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_PURCHASE_REQUEST_ERROR:
      return {
        ...state,
        deletePurchaseRequest: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case PURCHASE_REQUEST_DEFAULT_DATA:
      return {
        ...state,
        addPurchaseRequest: {
          ...defaultStatus,
        },
        editPurchaseRequest: {
          ...defaultStatus,
        },
      };
    case GET_PURCHASE_RETURN_LIST_IN_PROGRESS:
      return {
        ...state,
        purchaseReturnList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PURCHASE_RETURN_LIST_SUCCESS:
      return {
        ...state,
        purchaseReturnList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_PURCHASE_RETURN_LIST_ERROR:
      return {
        ...state,
        purchaseReturnList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_PURCHASE_RETURN_IN_PROGRESS:
      return {
        ...state,
        addPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_PURCHASE_RETURN_SUCCESS:
      return {
        ...state,
        addPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_PURCHASE_RETURN_ERROR:
      return {
        ...state,
        addPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_PURCHASE_RETURN_IN_PROGRESS:
      return {
        ...state,
        editPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_PURCHASE_RETURN_SUCCESS:
      return {
        ...state,
        editPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case EDIT_PURCHASE_RETURN_ERROR:
      return {
        ...state,
        editPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_PURCHASE_RETURN_IN_PROGRESS:
      return {
        ...state,
        deletePurchaseReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_PURCHASE_RETURN_SUCCESS:
      return {
        ...state,
        deletePurchaseReturn: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_PURCHASE_RETURN_ERROR:
      return {
        ...state,
        deletePurchaseReturn: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_TABLETS_ID_IN_PROGRESS:
      return {
        ...state,
        getTabletsId: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_TABLETS_ID_SUCCESS:
      return {
        ...state,
        getTabletsId: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_TABLETS_ID_ERROR:
      return {
        ...state,
        getTabletsId: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_SALES_IN_PROGRESS:
      return {
        ...state,
        addSales: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_SALES_SUCCESS:
      return {
        ...state,
        addSales: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_SALES_ERROR:
      return {
        ...state,
        addSales: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_LIST_SALES_IN_PROGRESS:
      return {
        ...state,
        getSalesList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_LIST_SALES_SUCCESS:
      return {
        ...state,
        getSalesList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_LIST_SALES_ERROR:
      return {
        ...state,
        getSalesList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_SALES_IN_PROGRESS:
      return {
        ...state,
        getSales: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_SALES_SUCCESS:
      return {
        ...state,
        getSales: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_SALES_ERROR:
      return {
        ...state,
        getSales: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case EDIT_SALES_IN_PROGRESS:
      return {
        ...state,
        editSaless: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_SALES_SUCCESS:
      return {
        ...state,
        editSaless: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case EDIT_SALES_ERROR:
      return {
        ...state,
        editSaless: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_BULK_MEDICINE_IN_PROGRESS:
      return {
        ...state,
        addBulkMedicine: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_BULK_MEDICINE_SUCCESS:
      return {
        ...state,
        addBulkMedicine: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_BULK_MEDICINE_ERROR:
      return {
        ...state,
        addBulkMedicine: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_BULK_MEDICINE:
      return {
        ...state,
        addBulkMedicine: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DEFAULT_PURCHASE_RETURN:
      return {
        ...state,
        addPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
        editPurchaseReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };

    case DELETE_SALES_IN_PROGRESS:
      return {
        ...state,
        deleteSales: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_SALES_SUCCESS:
      return {
        ...state,
        deleteSales: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_SALES_ERROR:
      return {
        ...state,
        deleteSales: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case SALES_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        addSalesPayment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case SALES_PAYMENT_SUCCESS:
      return {
        ...state,
        addSalesPayment: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case SALES_PAYMENT_ERROR:
      return {
        ...state,
        addSalesPayment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_UPDATE_ADD_PAYMENT:
      return {
        ...state,
        addSalesPayment: {
          ...defaultStatus,
        },
        updateSalesPayment: {
          ...defaultStatus,
        },
        editSaless: {
          ...defaultStatus,
        },
        addSales: {
          ...defaultStatus,
        },
        addSalesReturn: {
          ...defaultStatus,
        },
        updateSalesReturn: {
          ...defaultStatus,
        },
      };

    case UPDATE_SALES_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        updateSalesPayment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case UPDATE_SALES_PAYMENT_SUCCESS:
      return {
        ...state,
        updateSalesPayment: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_SALES_PAYMENT_ERROR:
      return {
        ...state,
        updateSalesPayment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case SALES_PAYMENT_DUE_IN_PROGRESS:
      return {
        ...state,
        salesPaymentDue: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case SALES_PAYMENT_DUE_SUCCESS:
      return {
        ...state,
        salesPaymentDue: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case SALES_PAYMENT_DUE_ERROR:
      return {
        ...state,
        salesPaymentDue: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_DEPARTMENT_LIST_IN_PROGRESS:
      return {
        ...state,
        departmentList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        departmentList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DEPARTMENT_LIST_ERROR:
      return {
        ...state,
        departmentList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_DEPARTMENT_IN_PROGRESS:
      return {
        ...state,
        addDepartment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        addDepartment: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_DEPARTMENT_ERROR:
      return {
        ...state,
        addDepartment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_DEPARTMENT_IN_PROGRESS:
      return {
        ...state,
        editDepartment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        editDepartment: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case EDIT_DEPARTMENT_ERROR:
      return {
        ...state,
        editDepartment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_DEPARTMENT_IN_PROGRESS:
      return {
        ...state,
        deleteDepartment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        deleteDepartment: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_DEPARTMENT_ERROR:
      return {
        ...state,
        deleteDepartment: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case SALES_CANCEL_UNDO_IN_PROGRESS:
      return {
        ...state,
        cancelUndo: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case SALES_CANCEL_UNDO_SUCCESS:
      return {
        ...state,
        cancelUndo: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case SALES_CANCEL_UNDO_ERROR:
      return {
        ...state,
        cancelUndo: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case PATIENT_SALES_DUE_IN_PROGRESS:
      return {
        ...state,
        patientSalesDue: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case PATIENT_SALES_DUE_SUCCESS:
      return {
        ...state,
        patientSalesDue: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case PATIENT_SALES_DUE_ERROR:
      return {
        ...state,
        patientSalesDue: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_DUE_PATIENT_IN_PROGRESS:
      return {
        ...state,
        dueByPatient: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_DUE_PATIENT_SUCCESS:
      return {
        ...state,
        dueByPatient: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DUE_PATIENT_ERROR:
      return {
        ...state,
        dueByPatient: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case ADD_SALES_RETURN_IN_PROGRESS:
      return {
        ...state,
        addSalesReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_SALES_RETURN_SUCCESS:
      return {
        ...state,
        addSalesReturn: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_SALES_RETURN_ERROR:
      return {
        ...state,
        addSalesReturn: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_SALES_RETURN_LIST_IN_PROGRESS:
      return {
        ...state,
        getSalesReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_SALES_RETURN_LIST_SUCCESS:
      return {
        ...state,
        getSalesReturn: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_SALES_RETURN_LIST_ERROR:
      return {
        ...state,
        getSalesReturn: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_SALES_RETURN_IN_PROGRESS:
      return {
        ...state,
        updateSalesReturn: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case UPDATE_SALES_RETURN_SUCCESS:
      return {
        ...state,
        updateSalesReturn: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_SALES_RETURN_ERROR:
      return {
        ...state,
        updateSalesReturn: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case SALES_LIST_BY_PATIENT_PROGRESS:
      return {
        ...state,
        salesByPatientList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case SALES_LIST_BY_PATIENT_SUCCESS:
      return {
        ...state,
        salesByPatientList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case SALES_LIST_BY_PATIENT_ERROR:
      return {
        ...state,
        salesByPatientList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_ITEM_TRANSFER__LIST_IN_PROGRESS:
      return {
        ...state,
        itemTransferList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_ITEM_TRANSFER_LIST_SUCCESS:
      return {
        ...state,
        itemTransferList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_ITEM_TRANSFER_LIST_ERROR:
      return {
        ...state,
        itemTransferList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_ITEM_TRANSFER_IN_PROGRESS:
      return {
        ...state,
        addItemTransfer: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_ITEM_TRANSFER_SUCCESS:
      return {
        ...state,
        addItemTransfer: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_ITEM_TRANSFER_ERROR:
      return {
        ...state,
        addItemTransfer: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case EDIT_ITEM_TRANSFER_IN_PROGRESS:
      return {
        ...state,
        editItemTransfer: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_ITEM_TRANSFER_SUCCESS:
      return {
        ...state,
        editItemTransfer: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case EDIT_ITEM_TRANSFER_ERROR:
      return {
        ...state,
        editItemTransfer: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_ITEM_TRANSFER_IN_PROGRESS:
      return {
        ...state,
        deleteItemTransfer: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_ITEM_TRANSFER_SUCCESS:
      return {
        ...state,
        deleteItemTransfer: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_ITEM_TRANSFER_ERROR:
      return {
        ...state,
        deleteItemTransfer: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_ITEM_TRANSFER:
      return {
        ...state,
        addItemTransfer: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
        editItemTransfer: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PHARMACY_LIST_IN_PROGRESS:
      return {
        ...state,
        pharmacyList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PHARMACY_LIST_SUCCESS:
      return {
        ...state,
        pharmacyList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_PHARMACY_LIST_ERROR:
      return {
        ...state,
        pharmacyList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_BULK_PURCHASE_IN_PROGRESS:
      return {
        ...state,
        bulkPurchase: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_BULK_PURCHASE_SUCCESS:
      return {
        ...state,
        bulkPurchase: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_BULK_PURCHASE_ERROR:
      return {
        ...state,
        bulkPurchase: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case BULK_MEDICINE_CATEGORY_INPROGRESS:
      return {
        ...state,
        bulkMedicineCategory: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DEFAULT_BULK_MEDICINE_CATEGORY:
      return {
        ...state,
        bulkMedicineCategory: {
          ...defaultStatus,
        },
      };
    case BULK_MEDICINE_CATEGORY_SUCCESS:
      return {
        ...state,
        bulkMedicineCategory: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case BULK_MEDICINE_CATEGORY_ERROR:
      return {
        ...state,
        bulkMedicineCategory: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_GST_LIST_INPROGRESS:
      return {
        ...state,
        gstList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_GST_LIST_SUCCESS:
      return {
        ...state,
        gstList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_GST_LIST_ERROR:
      return {
        ...state,
        gstList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_AVABILE_STOCK_IN_PROGRESS:
      return {
        ...state,
        getAvailableStockList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_AVABILE_STOCK_IN_SUCCESS:
      return {
        ...state,
        getAvailableStockList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_AVABILE_STOCK_IN_ERROR:
      return {
        ...state,
        getAvailableStockList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    default:
      return state;
  }
};
export default PharmacyReducer;
