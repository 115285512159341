import axios from "axios";
import { axiosInstance } from "../../../../../util/utilz";

export const getDoctorsListEffect = (page, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/doctors/organization?organization_id=${organization_id}&pageNo=${page}&pagesize=10&pagination_required=true`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
export const doctorFileUploadEffect = (formData, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/doctors/bulkInsertDoctors",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type":
        "multipart/form-data;boundary=<calculated when request is sent>",
    },
  });
};

export const addDoctorsListEffect = (formData, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/admin/addDoctor",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${tokens}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const updateDoctorsListEffect = ({ id, formData }) => {
  const token = localStorage.getItem("user_id");

  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/doctors/update?doctor_id=${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const deleteDoctorsListEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/doctors/delete/${data?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDoctorlistUpdateEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/doctors/details?doctor_id=${data}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};

// export const getAllQualificationsEffect = () => {
//   return axiosInstance.request({
//     url: "/qualification/list",
//     method: "GET",
//   });
// };

export const getAllCountriesEffect = () => {
  return axiosInstance.request({
    url: "/common/getCountry",
    method: "GET",
  });
};

export const getAllCitiesEffect = (formData) => {
  return axiosInstance.request({
    url: `common/getCities/${formData}`,
    method: "GET",
  });
};

export const getAllStatesEffect = (formData) => {
  return axiosInstance.request({
    url: `/common/getState/${formData}`,
    method: "GET",
  });
};
