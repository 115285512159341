import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";

import { useDispatch, useSelector } from "react-redux";
import { getTotalRoomsListInProgress } from "../../../appRedux/Ward/WardAction";

import Meta from "antd/lib/card/Meta";

const TotalRoomsList = ({ selectedBlockId, onBlockSelect }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");

  const bookedRoomData = useSelector((state) => state.ward.totalRoomsList);
  const bookedRoomList = bookedRoomData?.data?.data;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (bookedRoomData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookedRoomData]);

  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getTotalRoomsListInProgress(payload));
  }, [dispatch]);

  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.totalRoomList"} />}
          </Typography>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <Row>
          {" "}
          {bookedRoomList?.map((mapAvailableRoom) => (
            <Col xl={6} lg={6} md={8} sm={12} xs={24}>
              {" "}
              <Card
                type="inner"
                title={
                  <Typography className="ward-heading">{`Room Number - ${mapAvailableRoom?.room_number}`}</Typography>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {" "}
                    <Meta
                      title={`Block Name - ${mapAvailableRoom?.Block?.block_name}`}
                      description={`Ward Name - ${mapAvailableRoom?.Ward?.ward_name}`}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Typography>{`Room Type - ${
                      mapAvailableRoom?.room_type === "1" ? "AC" : "NON AC"
                    } `}</Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
    </Card>
  );
};

export default TotalRoomsList;
