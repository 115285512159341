import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import PageHeader from "../../components/PageHeader/PageHeader";
import IntlMessages from "../../util/IntlMessages";
import { Card, Col, Modal, Row } from "antd";
import { events } from "./data";
import ButtonComponent from "../../components/Button/ButtonComponent";
import CalendarHistory from "./components/calendar/CalendarHistory";
import Description from "../../components/Description/Description";
const localizer = momentLocalizer(moment);

const AppoinmentCalendar = () => {
  const [culture, setCulture] = useState("fr");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");

  let rtl = culture === "ar-AE";
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (name, email, id, mobile, gender, dob, address) => {
    setIsModalVisible(true);
    setName(name);
    setEmail(email);
    setId(id);
    setMobile(mobile);
    setGender(gender);
    setDob(dob);
    setAddress(address);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time Sot",
      dataIndex: "time",
    },
    {
      title: "Doctor",
      dataIndex: "doctorname",
    },

    {
      title: "",
      dataIndex: "",
      render: () => (
        <ButtonComponent type="primary" label="Edit"></ButtonComponent>
      ),
    },
  ];
  return (
    <div className="gx-main-content">
      <div className="gx-rbc-calendar">
        <PageHeader
          header={<IntlMessages id={"Addnew.appoinmentcalender"} />}
          accessData="false"
        />
        <Calendar
          localizer={localizer}
          rtl={rtl}
          events={events}
          defaultDate={new Date(moment().format("L"))}
          onSelectEvent={(event) =>
            showModal(
              event.patientname,
              event.email,
              event.Patientid,
              event.number,
              event.gender,
              event.dob,
              event.address
            )
          }
        />
      </div>
      <Modal
        title="Patient History"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
      >
        <Card>
          <Row>
            <Col span={6}>
              <h2 className="patient-history-header">Patient Info</h2>
              <p className="patient-email">{email}</p>

              <Description label="Patient Name" data={name} />

              <Description label="Patient Id" data={id} />

              <Description label="Mobile" data={mobile} />

              <Description label="Date of Birth" data={dob} />
              <Description label="Gender" data={gender} />
              <Description label="Address" data={address} />
            </Col>

            <Col span={18}>
              <CalendarHistory patientname={name} />
            </Col>
          </Row>
        </Card>
      </Modal>
    </div>
  );
};

export default AppoinmentCalendar;
