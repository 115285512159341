import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

export const getUserProfileEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const userid = localStorage.getItem("id");
  const orgId = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/user/details/${userid}?organization_id=${orgId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateUserProfileEffect = ({ formData }) => {
  const token = localStorage.getItem("user_id");
  const userid = localStorage.getItem("id");
  return axiosInstance.request({
    url: `/user/update/${userid}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
export const getDoctorProfileEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const userid = localStorage.getItem("id");
  const orgId = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/doctors/details`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateDoctorProfileEffect = (formData) => {
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: "/doctors/update",
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
