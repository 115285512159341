import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Typography } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getAppointmentByDoctorPatientInProgress } from "../../../appRedux/Patient/Action/Action";
import moment from "moment";
import { formatDate } from "../../../util/utilz";

const ViewAppoinment = () => {
  const location = useLocation();
  const patientId = location.state;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (date) => formatDate(date),
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
    },

    {
      title: "End Time",
      dataIndex: "end_time",
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
    },

    { title: "Consultaion Fee", dataIndex: "consultation_fee" },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: "/patient1/view/prescription",
                  state: { patientData: record },
                }}
              >
                <ButtonComponent
                  type="primary"
                  label={"View Prescription"}
                ></ButtonComponent>
              </Link>
            </Col>
          </Row>
        );
      },
    },
  ];
  const dispatch = useDispatch();
  const singlePatientData = useSelector(
    (state) => state.patient.getSingleHealthRecord
  );
  const doctorData = useSelector(
    (state) => state?.patientAppointment?.appoitmnetsByDoctorPatient
  );
  const history = useHistory();
  const doctorList = doctorData?.data?.data;

  const [Loading, setLoading] = useState(false);

  const totalRecords = singlePatientData?.data?.totalRecords;
  const handlePage = (page) => {
    dispatch(
      getAppointmentByDoctorPatientInProgress({
        patient_id: patientId,
        doctor_id: localStorage.getItem("doctor_id"),
        current_time: CurrentTime,
        current_date: CurrentDate,
        isPrev: 1,
        pagination: true,
        pageNo: page,
      })
    );
  };

  const CurrentDate = moment().format("YYYY-MM-DD");
  const CurrentTime = moment().format("hh:mmA");
  const day = moment().format("A");
  console.log("CurrentTime", CurrentTime);
  const goBackHandler = () => {
    history.goBack();
  };
  useEffect(() => {
    dispatch(
      getAppointmentByDoctorPatientInProgress({
        patient_id: patientId,
        doctor_id: localStorage.getItem("doctor_id"),
        current_time: CurrentTime,
        current_date: CurrentDate,
        isPrev: 1,
        pagination: true,
        pageNo: 1,
      })
    );
  }, [patientId]);

  useEffect(() => {
    if (doctorData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [doctorData]);
  console.log("doctorData", doctorData);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <Row>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <Row justify={"space-between"} className="margin-row">
            <Col>
              <Typography className="view-header">
                Patient Appointment History
              </Typography>
            </Col>
            <Col>
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
                size="small"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <TableComponent
            columns={columns}
            dataSource={doctorList || []}
            pagination={{
              total: totalRecords,
              onChange: (page) => handlePage(page),
            }}
            documentTitle={"Patient Appointment History"}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default ViewAppoinment;
