import React, { useEffect, useState } from "react";
import { Button, Form, Input, Col, Row } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  hideAuthLoader,
  OtpSendInProgress,
  showAuthLoader,
} from "../appRedux/doctor/actions/Auth";

const FormItem = Form.Item;

const Otpsend = ({ setOtp, loginOtp }) => {
  const [email, setEmail] = useState("");
  const [navigate, setNavigate] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser, registerdata } =
    useSelector(({ auth }) => auth);

  const sendOtpData = useSelector((state) => state.register);
  const OtpSuccess = sendOtpData?.otpsend?.success;

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    setEmail(values.email);
    let formData = new URLSearchParams();
    formData.append("email", values.email);

    formData.append("subject_type", loginOtp ? 3 : 2);
    dispatch(OtpSendInProgress(formData));
    setNavigate(true);
  };

  useEffect(() => {
    if (OtpSuccess === true && navigate) {
      history.push({
        pathname: `/otpVerification/${email}`,
        state: { loginOtp: loginOtp },
      });
      setOtp(false);
      setNavigate(false);
    }
  }, [OtpSuccess]);
  // useEffect(() => {
  //   dispatch(hideAuthLoader());
  // }, []);
  return (
    <Form
      initialValues={{ remember: true }}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="gx-signin-form gx-form-row0"
    >
      {" "}
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "The input is not valid E-mail!",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>{" "}
      <Link
        className="gx-login-form-forgot form-forgot"
        onClick={() => {
          console.log("OtpSuccess");
          setOtp(false);
        }}
      >
        SignIn
      </Link>
      <div className="form-buttons ">
        {" "}
        <Button
          type="primary"
          className="gx-mb-4"
          htmlType="submit"
          loading={sendOtpData?.otpsend?.inProgress}
        >
          SEND OTP
        </Button>
      </div>
    </Form>
  );
};

export default Otpsend;
