import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_BLOCK_IN_PROGRESS,
  ADD_ROOM_IN_PROGRESS,
  ADD_WARD_IN_PROGRESS,
  ALL_DOCTOR_LIST_IN_PROGRESS,
  DELETE_BLOCK_IN_PROGRESS,
  DELETE_ROOM_IN_PROGRESS,
  DELETE_WARD_IN_PROGRESS,
  EDIT_BLOCK_IN_PROGRESS,
  EDIT_ROOM_IN_PROGRESS,
  EDIT_WARD_IN_PROGRESS,
  GET_AVAILABLE_ROOM_LIST_IN_PROGRESS,
  GET_BLOCK_LIST_IN_PROGRESS,
  GET_BOOKED_ROOM_LIST_IN_PROGRESS,
  GET_ROOM_LIST_IN_PROGRESS,
  GET_TOTAL_ROOM_LIST_IN_PROGRESS,
  GET_WARD_LIST_BY_BLOCK_IN_PROGRESS,
  GET_WARD_LIST_IN_PROGRESS,
  ROOM_BOOKING_IN_PROGRESS,
  ROOM_DISCHARGE_IN_PROGRESS,
  ROOM_SHIFT_IN_PROGRESS,
  WARD_DASHBOARD_COUNT_IN_PROGRESS,
} from "../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../util/utilz";

import Message from "../../components/Message/Message";
import {
  RoomBookingEffect,
  addBlockEffect,
  addRoomEffect,
  addWardEffect,
  allDoctorListEffect,
  availableRoomListEffect,
  blockListEffect,
  bookedRoomsListEffect,
  deleteBlockEffect,
  deleteRoomEffect,
  deleteWardEffect,
  dischargeRoomEffect,
  editBlockEffect,
  editRoomEffect,
  editWardEffect,
  roomListEffect,
  shiftRoomEffect,
  totalRoomsListEffect,
  wardCountEffect,
  wardListEffect,
  wardListbyBlockEffect,
} from "./WardEffect";
import {
  addBlockError,
  addBlockSuccess,
  addRoomError,
  addRoomSuccess,
  addWardError,
  addWardSuccess,
  allDoctorListError,
  allDoctorListSuccess,
  deleteBlockError,
  deleteBlockSuccess,
  deleteRoomError,
  deleteRoomSuccess,
  deleteWardError,
  deleteWardSuccess,
  editBlockError,
  editBlockSuccess,
  editRoomError,
  editRoomSuccess,
  editWardError,
  editWardSuccess,
  getAvailableRoomsListInFailure,
  getAvailableRoomsListInSuccess,
  getBlockListError,
  getBlockListSuccess,
  getBookedRoomsListInFailure,
  getBookedRoomsListInSuccess,
  getTotalRoomsListInFailure,
  getTotalRoomsListInSuccess,
  getWardListByBlockError,
  getWardListByBlockSuccess,
  getWardListError,
  getWardListSuccess,
  roomBookInFailure,
  roomBookInSuccess,
  roomDischargeInFailure,
  roomDischargeInSuccess,
  roomListSuccess,
  roomListsError,
  roomShiftInFailure,
  roomShiftInSuccess,
  wardDashboardCountListInFailure,
  wardDashboardCountListInSuccess,
} from "./WardAction";

function* getBlockListWatcher({ payload }) {
  console.log("saga");
  try {
    let { data } = yield call(blockListEffect, payload);

    yield put(
      getBlockListSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getBlockListError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* addBlockWatcher({ payload }) {
  console.log("saga-", payload);
  try {
    let { data } = yield call(addBlockEffect, payload);

    yield put(
      addBlockSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addBlockError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* editBlockWatcher({ payload }) {
  try {
    let { data } = yield call(editBlockEffect, payload);

    yield put(
      editBlockSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editBlockError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteBlockWatcher({ payload }) {
  try {
    let { data } = yield call(deleteBlockEffect, payload);
    console.log("dataaa", data);
    yield put(
      deleteBlockSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteBlockError({
        Error: true,
        message: e["message"],
      })
    );
  }
}

function* getWardListWatcher({ payload }) {
  console.log("saga");
  try {
    let { data } = yield call(wardListEffect, payload);

    yield put(
      getWardListSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getWardListError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* addWardWatcher({ payload }) {
  console.log("saga-", payload);
  try {
    let { data } = yield call(addWardEffect, payload);

    yield put(
      addWardSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addWardError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* editWardWatcher({ payload }) {
  try {
    let { data } = yield call(editWardEffect, payload);

    yield put(
      editWardSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editWardError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteWardWatcher({ payload }) {
  try {
    let { data } = yield call(deleteWardEffect, payload);
    console.log("dataaa", data);
    yield put(
      deleteWardSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteWardError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* wardListByBlockWatcher({ payload }) {
  try {
    let { data } = yield call(wardListbyBlockEffect, payload);

    yield put(
      getWardListByBlockSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getWardListByBlockError({
        Error: true,
        message: e["message"],
      })
    );
  }
}

function* getRoomListWatcher({ payload }) {
  console.log("saga");
  try {
    let { data } = yield call(roomListEffect, payload);

    yield put(
      roomListSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      roomListsError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* addRoomWatcher({ payload }) {
  console.log("saga-", payload);
  try {
    let { data } = yield call(addRoomEffect, payload);

    yield put(
      addRoomSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addRoomError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* editRoomWatcher({ payload }) {
  try {
    let { data } = yield call(editRoomEffect, payload);

    yield put(
      editRoomSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editRoomError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteRoomWatcher({ payload }) {
  try {
    let { data } = yield call(deleteRoomEffect, payload);
    console.log("dataaa", data);
    yield put(
      deleteRoomSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteRoomError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* allDoctorListWatcher({ payload }) {
  try {
    let { data } = yield call(allDoctorListEffect, payload);
    console.log("dataaa", data);
    yield put(
      allDoctorListSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      allDoctorListError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* availableRoomListWatcher({ payload }) {
  try {
    let { data } = yield call(availableRoomListEffect, payload);
    console.log("dataaa", data);
    yield put(
      getAvailableRoomsListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getAvailableRoomsListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* roomBookWatcher({ payload }) {
  try {
    let { data } = yield call(RoomBookingEffect, payload);
    console.log("dataaa", data);
    yield put(
      roomBookInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      roomBookInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* wardCountWatcher({ payload }) {
  try {
    let { data } = yield call(wardCountEffect, payload);
    console.log("dataaa", data);
    yield put(
      wardDashboardCountListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      wardDashboardCountListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* bookedRoomsListWatcher({ payload }) {
  try {
    let { data } = yield call(bookedRoomsListEffect, payload);
    console.log("dataaa", data);
    yield put(
      getBookedRoomsListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getBookedRoomsListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* totalRoomsListWatcher({ payload }) {
  try {
    let { data } = yield call(totalRoomsListEffect, payload);
    console.log("dataaa", data);
    yield put(
      getTotalRoomsListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getTotalRoomsListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* shiftRoomWatcher({ payload }) {
  try {
    let { data } = yield call(shiftRoomEffect, payload);
    console.log("dataaa", data);
    yield put(
      roomShiftInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      roomShiftInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* dischargeRoomWatcher({ payload }) {
  try {
    let { data } = yield call(dischargeRoomEffect, payload);
    console.log("dataaa", data);
    yield put(
      roomDischargeInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      roomDischargeInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
export default function* WardSaga() {
  yield takeLatest(GET_BLOCK_LIST_IN_PROGRESS, getBlockListWatcher);
  yield takeLatest(ADD_BLOCK_IN_PROGRESS, addBlockWatcher);
  yield takeLatest(EDIT_BLOCK_IN_PROGRESS, editBlockWatcher);
  yield takeLatest(DELETE_BLOCK_IN_PROGRESS, deleteBlockWatcher);
  yield takeLatest(GET_WARD_LIST_IN_PROGRESS, getWardListWatcher);
  yield takeLatest(ADD_WARD_IN_PROGRESS, addWardWatcher);
  yield takeLatest(EDIT_WARD_IN_PROGRESS, editWardWatcher);
  yield takeLatest(DELETE_WARD_IN_PROGRESS, deleteWardWatcher);
  yield takeLatest(GET_WARD_LIST_BY_BLOCK_IN_PROGRESS, wardListByBlockWatcher);
  yield takeLatest(GET_ROOM_LIST_IN_PROGRESS, getRoomListWatcher);
  yield takeLatest(ADD_ROOM_IN_PROGRESS, addRoomWatcher);
  yield takeLatest(EDIT_ROOM_IN_PROGRESS, editRoomWatcher);
  yield takeLatest(DELETE_ROOM_IN_PROGRESS, deleteRoomWatcher);
  yield takeLatest(ALL_DOCTOR_LIST_IN_PROGRESS, allDoctorListWatcher);
  yield takeLatest(
    GET_AVAILABLE_ROOM_LIST_IN_PROGRESS,
    availableRoomListWatcher
  );
  yield takeLatest(ROOM_BOOKING_IN_PROGRESS, roomBookWatcher);
  yield takeLatest(WARD_DASHBOARD_COUNT_IN_PROGRESS, wardCountWatcher);
  yield takeLatest(GET_BOOKED_ROOM_LIST_IN_PROGRESS, bookedRoomsListWatcher);
  yield takeLatest(GET_TOTAL_ROOM_LIST_IN_PROGRESS, totalRoomsListWatcher);
  yield takeLatest(ROOM_SHIFT_IN_PROGRESS, shiftRoomWatcher);
  yield takeLatest(ROOM_DISCHARGE_IN_PROGRESS, dischargeRoomWatcher);
}
