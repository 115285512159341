import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import AddPurchaseReturn from "./AddPurchaseReturn";

const ViewPurchaseReturn = () => {
  const location = useLocation();
  const purchaseData = location.state?.purchaseReturnData;

  return <AddPurchaseReturn viewPurchaseData={purchaseData} viewData="true" />;
};

export default ViewPurchaseReturn;
