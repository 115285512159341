import { axiosInstance } from "../../../../util/utilz";

const ScheduleEffect = (page) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/schedule/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      pagination_required: true,
      pageNo: page,
      pagesize: 10,
    },
  });
};
const AddScheduleEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/schedule/add",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const DeleteScheduleEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/schedule/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const UpdateScheduleEffect = ({ id, formData }) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/schedule/update/${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const GetDataScheduleEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/schedule/${formData}`,
    method: "GET",

    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const WeekdayScheduleEffect = () => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/weekday/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export {
  ScheduleEffect,
  AddScheduleEffect,
  DeleteScheduleEffect,
  UpdateScheduleEffect,
  GetDataScheduleEffect,
  WeekdayScheduleEffect,
};
