import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Modal, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import IntlMessages from "../../../util/IntlMessages";
import "../../../styles/modules/doctor.less";

import Auxiliary from "../../../util/Auxiliary";

const TeamMemberHeader = () => {
  const [open, setOpen] = useState(false);

  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Auxiliary>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"Team.teamMember"} />}
          </p>
        </Col>
        <Col>
          <Link to={APP_ROUTES.TEAMMEMBERSFORM}>
            <ButtonComponent
              icon={<PlusCircleOutlined />}
              type="primary"
              label="Add New"
            />
          </Link>

          <Modal
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              [
                // <Button key="back" onClick={handleCancel}>
                //   Cancel
                // </Button>,
                // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                //   Submit
                // </Button>
              ]
            }
          ></Modal>
        </Col>
      </Row>
      <Divider />
    </Auxiliary>
  );
};

export default TeamMemberHeader;
