import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddBlock from "./AddBlock";

const EditBlock = () => {
  const location = useLocation();
  const blockData = location.state?.blockData;

  return <AddBlock editBlockData={blockData} editBlock={"true"} />;
};

export default EditBlock;
