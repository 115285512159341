import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Spin, Tooltip, Typography } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Auxiliary from "../../../util/Auxiliary";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  deleteScheduleInProgress,
  requestScheduleInProgress,
} from "../../../appRedux/doctor/actions/Schedule";
import { access } from "../../../util/utilz";

const ScheduleTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setId] = useState("");
  const [filteredUsers, setFilteredUsers] = useState("");
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteScheduleInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Day",
      dataIndex: "week_day",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
    },

    {
      title: "EndTime",
      dataIndex: "end_time",
    },
    {
      title: "Interval",
      dataIndex: "interval",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("schedule", "edit") && (
              <Col className="button-edit">
                <Tooltip placement="top" title={"Edit"}>
                  <Link
                    to={{
                      pathname: "/schedule/edit",
                      state: record,
                    }}
                  >
                    <ButtonComponent type="primary" icon={<EditOutlined />} />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("schedule", "view") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"View"}>
                  {" "}
                  <Link
                    to={{
                      pathname: "/schedule/view",
                      state: record,
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EyeOutlined />}
                    />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("schedule", "delete") && (
              <Col className="button-edit">
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record.id)}
                    />
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const scheduleData = useSelector((state) => state?.schedule);
  const deleteSuccess = scheduleData?.deleteSchedule?.success;

  const scheduleList = scheduleData?.schedule?.data?.data;
  const totalRecords = scheduleData?.schedule?.data?.totalRecords;
  const scheduleItem = scheduleData?.schedule;
  const deleteSchedule = scheduleData?.deleteSchedule;

  const handlePage = (page) => {
    dispatch(requestScheduleInProgress(page));
  };
  useEffect(() => {
    const page = 1;
    dispatch(requestScheduleInProgress(page));
  }, []);
  useEffect(() => {
    const page = 1;
    if (deleteSuccess) {
      dispatch(requestScheduleInProgress(page));
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (scheduleItem?.inProgress || deleteSchedule?.inProgress) {
      setLoading(true);
    } else if (scheduleItem?.success || deleteSchedule?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [
    scheduleItem?.inProgress,
    scheduleItem?.success,
    deleteSchedule?.success,
    deleteSchedule?.inProgress,
  ]);
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(scheduleList);
    } else {
      const newData = scheduleList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : scheduleList}
          tableLayout={"horizontal"}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          itemList={scheduleList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"week_day"}
          documentTitle="Schedule List"
          rowselector={handleSelectChange}
        />
        <Modal
          title="Delete Data"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ className: "secondary-color" }}
        >
          <p>Are you sure, you want to delete the Data ?</p>
        </Modal>
      </Spin>
    </Auxiliary>
  );
};

export default ScheduleTable;
