import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Modal, Tooltip } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultWard,
  deleteWardInProgress,
  getWardListByBlockInProgress,
} from "../../../appRedux/Ward/WardAction";

import Meta from "antd/lib/card/Meta";

const WardTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const blockData = location.state?.blockData;
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const wardListByBlockData = useSelector(
    (state) => state?.ward?.wardListByBlock
  );
  const addWardData = useSelector((state) => state?.ward?.addWard);
  const editWardResponse = useSelector((state) => state?.ward?.editWard);
  const deleteWard = useSelector((state) => state?.ward?.deleteWard);
  const wardList = wardListByBlockData?.data?.data;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteWardInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (wardListByBlockData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [wardListByBlockData]);
  useEffect(() => {
    const payload = {
      pagination: "true",
      pageNo: "1",
      pageSize: "10",
      block_id: blockData?.id,
    };
    console.log("payload", payload);
    dispatch(getWardListByBlockInProgress(payload));
  }, []);

  useEffect(() => {
    if (addWardData?.success || editWardResponse?.success) {
      dispatch(defaultWard());
    }
  }, [addWardData, editWardResponse]);
  useEffect(() => {
    if (deleteWard?.success) {
      const payload = {
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        block_id: blockData?.id,
      };
      dispatch(getWardListByBlockInProgress(payload));
    }
  }, [deleteWard]);
  return (
    <Card>
      <Row style={{ display: "flex", justifyContent: "end" }} className="mx-20">
        <Link
          to={{
            pathname: `block`,
          }}
        >
          <ButtonComponent type="danger" icon={<ArrowLeftOutlined />} />
        </Link>
      </Row>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.wards"} />} Block - {""}
            {blockData?.block_name}
          </Typography>
        </Col>
        <Col>
          <Link
            to={{
              pathname: `/ward/add`,
              state: { blockId: blockData },
            }}
          >
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newWard"} />}
            />
          </Link>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        {" "}
        <Row gutter={[16, 16]}>
          {wardList?.map((mapWard) => (
            <Col xl={6} lg={6} md={8} sm={12} xs={24}>
              {" "}
              <Card
                type="inner"
                title={
                  <Typography className="ward-heading">{`Block Name - ${mapWard?.ward_name}`}</Typography>
                }
                actions={[
                  <Tooltip placement="top" title={"Edit"}>
                    {" "}
                    <Link
                      to={{
                        pathname: `/ward/edit`,
                        state: { wardData: mapWard, blockData: blockData },
                      }}
                    >
                      <ButtonComponent
                        className="secondary-color"
                        icon={<EditFilled />}
                        size={"small"}
                      ></ButtonComponent>
                    </Link>
                  </Tooltip>,
                  <Tooltip placement="top" title={"Delete"}>
                    <Typography>
                      <ButtonComponent
                        type="danger"
                        icon={<DeleteFilled />}
                        size={"small"}
                        onClick={() => showModal(mapWard.id)}
                      ></ButtonComponent>
                    </Typography>
                  </Tooltip>,
                ]}
              >
                <Row gutter={[16, 16]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {" "}
                    <Meta
                      description={`Ward Status - ${
                        mapWard?.ward_status === "1" ? "Active" : "Inactive"
                      }`}
                      title={`Ward Incharge - ${mapWard?.ward_incharge_name}`}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Typography>{`${mapWard?.total_rooms} (${mapWard?.ac_room_count}-AC/${mapWard?.non_ac_room_count}-Non-AC)`}</Typography>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Link
                      to={{
                        pathname: `/rooms`,
                        state: { wardData: mapWard },
                      }}
                    >
                      <ButtonComponent
                        type="primary"
                        label={"Room List"}
                      ></ButtonComponent>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default WardTable;
