import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PRESCRIPTION_IN_PROGRESS,
  CANCEL_PRESCRIPTION_IN_PROGRESS,
  PRESCRIPTION_BY_PATIENT_IN_PROGRESS,
  PRESCRIPTION_DATA_IN_PROGRESS,
  PRESCRIPTION_LIST_IN_PROGRESS,
  SAVE_PRESCRIPTION_IN_PROGRESS,
  SEEN_PRESCRIPTION_IN_PROGRESS,
  SUBMIT_PRESCRIPTION_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  addPrescriptionInError,
  addPrescriptionInSuccess,
  cancelPrescriptionInError,
  cancelPrescriptionInSuccess,
  prescriptionByPatientInError,
  prescriptionByPatientInSuccess,
  prescriptionDataInError,
  prescriptionDataInProgress,
  prescriptionListInError,
  prescriptionListInSuccess,
  savePrescriptionInError,
  savePrescriptionInSuccess,
  seenPrescriptionInError,
  seenPrescriptionInSuccess,
  submitPrescriptionInError,
  submitPrescriptionInSuccess,
} from "../actions/Prescription";
import {
  addPrescriptionEffect,
  CancelPrescriptionEffect,
  prescriptionByPatientEffect,
  prescriptionDataEffect,
  prescriptionListEffect,
  SavePrescriptionEffect,
  seenPrescriptionEffect,
  SubmitPrescriptionEffect,
} from "./effects/PrescriptionEffect";

function* AddPrescriptionWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addPrescriptionEffect, payload, token);
    yield put(
      addPrescriptionInSuccess({
        data: data?.PrescriptionInfo,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addPrescriptionInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* PrescriptionListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(prescriptionListEffect, payload, token);

    yield put(
      prescriptionListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      prescriptionListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* PrescriptionDataWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(prescriptionDataEffect, payload, token);
    yield put(
      prescriptionDataInProgress({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      prescriptionDataInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* PrescriptionByPatientWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(prescriptionByPatientEffect, payload, token);
    yield put(
      prescriptionByPatientInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      prescriptionByPatientInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* SavePrescriptionWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(SavePrescriptionEffect, payload);
    yield put(
      savePrescriptionInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      savePrescriptionInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* CancelPrescriptionWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(CancelPrescriptionEffect, payload, token);
    yield put(
      cancelPrescriptionInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      cancelPrescriptionInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* SubmitPrescriptionWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(SubmitPrescriptionEffect, payload, token);
    yield put(
      submitPrescriptionInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      submitPrescriptionInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* seenPrescriptionWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(seenPrescriptionEffect, payload);
    yield put(
      seenPrescriptionInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      seenPrescriptionInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

export function* prescriptionSaga() {
  yield takeLatest(ADD_PRESCRIPTION_IN_PROGRESS, AddPrescriptionWatcher);
  yield takeLatest(PRESCRIPTION_LIST_IN_PROGRESS, PrescriptionListWatcher);
  yield takeLatest(PRESCRIPTION_DATA_IN_PROGRESS, PrescriptionDataWatcher);
  yield takeLatest(
    PRESCRIPTION_BY_PATIENT_IN_PROGRESS,
    PrescriptionByPatientWatcher
  );
  yield takeLatest(SAVE_PRESCRIPTION_IN_PROGRESS, SavePrescriptionWatcher);
  yield takeLatest(CANCEL_PRESCRIPTION_IN_PROGRESS, CancelPrescriptionWatcher);
  yield takeLatest(SUBMIT_PRESCRIPTION_IN_PROGRESS, SubmitPrescriptionWatcher);

  yield takeLatest(SEEN_PRESCRIPTION_IN_PROGRESS, seenPrescriptionWatcher);
}
