import {
  GET_DOCTOR_PROFILE_ERROR,
  GET_DOCTOR_PROFILE_IN_PROGRESS,
  GET_DOCTOR_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_PROGRESS,
  GET_USER_PROFILE_SUCCESS,
  UPDATE_DOCTOR_PROFILE_ERROR,
  UPDATE_DOCTOR_PROFILE_IN_PROGRESS,
  UPDATE_DOCTOR_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_PROGRESS,
  UPDATE_USER_PROFILE_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  getprofile: { ...defaultStatus },
  updateprofile: { ...defaultStatus },
  getDoctorProfile: { ...defaultStatus },
  updateDoctorProfile: { ...defaultStatus },
};
const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_PROFILE_PROGRESS:
      return {
        ...state,
        getprofile: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        getprofile: {
          ...defaultStatus,
          ...payload.data,
          success: true,
        },
      };
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        getprofile: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_USER_PROFILE_PROGRESS:
      return {
        ...state,
        updateprofile: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateprofile: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updateprofile: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_DOCTOR_PROFILE_IN_PROGRESS:
      return {
        ...state,
        getDoctorProfile: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_DOCTOR_PROFILE_SUCCESS:
      return {
        ...state,
        getDoctorProfile: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DOCTOR_PROFILE_ERROR:
      return {
        ...state,
        getDoctorProfile: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_DOCTOR_PROFILE_IN_PROGRESS:
      return {
        ...state,
        updateDoctorProfile: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_DOCTOR_PROFILE_SUCCESS:
      return {
        ...state,
        updateDoctorProfile: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_DOCTOR_PROFILE_ERROR:
      return {
        ...state,
        updateDoctorProfile: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default profileReducer;
