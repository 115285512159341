import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import AddPurchaseRequest from "./AddPurchaseRequest";

const EditPurchaseRequest = () => {
  const location = useLocation();
  const purchaseRequestData = location.state?.supplierData;

  return (
    <AddPurchaseRequest
      purchaseRequestData={purchaseRequestData}
      editPurchase="true"
    />
  );
};

export default EditPurchaseRequest;
