import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Typography, Tooltip, Card } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PayCircleOutlined,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import PatientDueHeader from "./PatientDueHeader";
import { APP_ROUTES } from "../../../constants/Routes";
import { patientSalesDueInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";
import { access } from "../../../util/utilz";

const PatientDue = () => {
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { patientSalesDue } = useSelector((state) => state?.pharmacy);

  useEffect(() => {
    const formData = {
      pagination: true,
      pageNo: 1,
      pageSize: 10,
    };

    dispatch(patientSalesDueInProgress(formData));
  }, []);

  const totalRecords = patientSalesDue.data?.totalRecords;

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Mobile No",
      dataIndex: "contact",
    },

    {
      title: "Address",
      dataIndex: "Address",
      render: (_, record) => {
        return (
          <>
            {record?.address1 ? record?.address1 : null}
            {record?.address2 ? "," + record?.address2 : null}
            {record?.city ? "," + record?.city : null}
            {record?.state ? "," + record?.state : null}
            {record?.country_code ? "," + record?.country_code : null}.
          </>
        );
      },
    },

    {
      title: "Due",
      dataIndex: "duePending",
      render: (_, record) => {
        return (
          <Typography className="red-text">
            {Number(record.duePending).toFixed(2)}
          </Typography>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("patientDue", "view") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"View"}>
                  {" "}
                  <Link
                    to={{
                      pathname: APP_ROUTES.VIEWDUEDETAILS,
                      state: { patient: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<PayCircleOutlined />}
                      label={"Pay Now"}
                    >
                      {" "}
                    </ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const formData = {
      pagination: true,
      pageNo: page,
      pageSize: 10,
    };
    dispatch(patientSalesDueInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(patientSalesDue?.data?.data);
    } else {
      const newData = patientSalesDue?.data?.data.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  return (
    <Spin indicator={antIcon} spinning={patientSalesDue?.inProgress}>
      <Card>
        <PatientDueHeader Due={patientSalesDue?.data?.totalDue} />
        <TableComponent
          columns={columns}
          dataSource={patientSalesDue?.data?.data || []}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          rowselector={handleSelectChange}
          itemList={patientSalesDue?.data?.data}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"firstName"}
          documentTitle={"Patient Due List"}
        />
      </Card>
    </Spin>
  );
};

export default PatientDue;
