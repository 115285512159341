import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Table,
  Typography,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import TableComponent from "../../../components/Table/TableComponent";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";

import { APP_ROUTES } from "../../../constants/Routes";
import { useDispatch } from "react-redux";
import {
  addRolesInProgress,
  updateRolesInProgress,
} from "../../../appRedux/admin/roles/action/rolesAction";
import SelectorsAppointment from "../../Appoinment/Selectors";
import SelectBoxAppointment from "../../Appoinment/SelectBox";
// import { addRolesInProgress } from '../../../../appRedux/admin/roles/action/rolesAction';

const AddRoles = () => {
  const [form] = Form.useForm();

  const history = useHistory();
  const location = useLocation();
  const id = location?.state?.id;
  const [errorOpen, setErrorOpen] = useState(false);
  const [formData, setFormData] = useState([
    {
      key: "1",
      module: "patients",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "2",
      module: "doctors",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "3",
      module: "appointments",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "4",
      module: "billingPayment",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "5",
      module: "schedule",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "6",
      module: "billingPackage",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "7",
      module: "billingSubPackage",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "8",
      module: "purchase",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "9",
      module: "purchaseReturn",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "10",
      module: "purchaseRequest",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "11",
      module: "sales",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "12",
      module: "salesReturn",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "13",
      module: "itemTransfer",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "14",
      module: "stockAdjustment",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "15",
      module: "medicineList",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "16",
      module: "medicineCategory",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "17",
      module: "department",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "17",
      module: "suppliers",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "18",
      module: "patientDue",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "19",
      module: "expiryStock",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "20",
      module: "holidays",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "21",
      module: "consultation",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },

    {
      key: "22",
      module: "appoinmentquestions",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "23",
      module: "wardManagement",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "23",
      module: "healthManagement",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "24",
      module: "prescription",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
    {
      key: "24",
      module: "pharmacyReports",
      add: false,
      view: false,
      edit: false,
      delete: false,
      allaccess: false,
      noaccess: true,
    },
  ]);
  // const [formData, setFormData] = useState([]);
  const dispatch = useDispatch();

  const defaultValues = location?.state;

  useEffect(() => {
    if (defaultValues && defaultValues?.permissions) {
      const parsedPermissions = JSON.parse(defaultValues.permissions);
      const initialFormData = formData.map((item) => {
        const moduleName = item?.module;
        const modulePermissions = parsedPermissions[moduleName];
        return {
          ...item,
          add: modulePermissions?.add,
          view: modulePermissions?.view,
          edit: modulePermissions?.edit,
          delete: modulePermissions?.delete,
          allaccess: modulePermissions?.allaccess,
          noaccess: modulePermissions?.noaccess,
        };
      });
      setFormData(initialFormData);
    }
  }, [defaultValues]);

  const handleCheckboxChange = (module, type, checked) => {
    let updatedFormData = formData.map((item) => {
      if (item.module === module) {
        let newAllAccess = false;
        let newNoAccess = false;

        let addChecked = item.add;
        let viewChecked = item.view;
        let editChecked = item.edit;
        let deleteChecked = item.delete;

        if (type === "allaccess" && checked) {
          addChecked = true;
          viewChecked = true;
          editChecked = true;
          deleteChecked = true;
          newAllAccess = true;
        } else if (type === "noaccess" && checked) {
          addChecked = false;
          viewChecked = false;
          editChecked = false;
          deleteChecked = false;
          newAllAccess = false;
          newNoAccess = true;
        } else {
          addChecked = type === "add" ? checked : addChecked;
          viewChecked = type === "view" ? checked : viewChecked;
          editChecked = type === "edit" ? checked : editChecked;
          deleteChecked = type === "delete" ? checked : deleteChecked;

          if (addChecked && viewChecked && editChecked && deleteChecked) {
            newAllAccess = true;
          }
          if (!addChecked && !viewChecked && !editChecked && !deleteChecked) {
            newNoAccess = true;
          }
        }

        return {
          ...item,
          add: addChecked,
          view: viewChecked,
          edit: editChecked,
          delete: deleteChecked,
          allaccess: newAllAccess,
          noaccess: newNoAccess,
        };
      }
      return item;
    });

    setFormData(updatedFormData);
  };

  useEffect(() => {
    form.setFieldsValue({ permissions: formData });
  }, [form, formData]);

  const columns = [
    {
      dataIndex: "module",
      title: "Module Access",
      width: 150,
    },
    {
      dataIndex: "add",
      title: "Add",
      align: "center",
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.add}
          onChange={(e) =>
            handleCheckboxChange(record.module, "add", e.target.checked)
          }
        />
      ),
    },
    {
      dataIndex: "view",
      title: "View",
      align: "center",
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.view}
          onChange={(e) =>
            handleCheckboxChange(record.module, "view", e.target.checked)
          }
        />
      ),
    },
    {
      dataIndex: "edit",
      title: "Edit",
      align: "center",
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.edit}
          onChange={(e) =>
            handleCheckboxChange(record.module, "edit", e.target.checked)
          }
        />
      ),
    },
    {
      dataIndex: "delete",
      title: "Delete",
      align: "center",
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.delete}
          onChange={(e) =>
            handleCheckboxChange(record.module, "delete", e.target.checked)
          }
        />
      ),
    },
    {
      dataIndex: "allaccess",
      title: "All Access",
      align: "center",
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.allaccess}
          onChange={(e) =>
            handleCheckboxChange(record.module, "allaccess", e.target.checked)
          }
        />
      ),
    },
    {
      dataIndex: "noaccess",
      title: "No Access",
      align: "center",
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.noaccess}
          onChange={(e) =>
            handleCheckboxChange(record.module, "noaccess", e.target.checked)
          }
        />
      ),
    },
  ];

  const data = formData;
  const getModulePermissions = (moduleName) => {
    const module = formData.find((item) => item.module === moduleName);
    return {
      add: module.add,
      view: module.view,
      edit: module.edit,
      delete: module.delete,
      allaccess: module.allaccess,
      noaccess: module.noaccess,
    };
  };

  const onFinish = async (values) => {
    console.log("submit", values);
    const handleSuccess = () => {
      history.push(APP_ROUTES.ROLES);
    };
    if (location?.type === "edit") {
      const rolesPayload = [
        {
          ...values,
          id: id,
          name: values.role,
          role_id: values?.role_id,
          permissions: formData.reduce((acc, item) => {
            acc[item.module] = { ...getModulePermissions(item.module) };
            return acc;
          }, {}),
        },
      ];

      dispatch(updateRolesInProgress({ rolesPayload, handleSuccess }));
    } else {
      const rolesPayload = [
        {
          ...values,
          name: values.role,
          role_id: values?.role_id,
          permissions: formData.reduce((acc, item) => {
            acc[item.module] = { ...getModulePermissions(item.module) };
            return acc;
          }, {}),
        },
      ];

      dispatch(addRolesInProgress({ rolesPayload, handleSuccess }));
    }
  };
  const handleChange = (e) => {
    if (Number(e.value) === 5) {
      form.setFieldsValue({ role: null });
    } else {
      form.setFieldsValue({ role: e.label });
    }
  };

  React.useEffect(() => {
    console.log("sdf", Number(location?.state?.role_id));
    form.setFieldsValue({
      role: location?.type === "edit" ? location?.state?.name : "",
      role_id:
        location?.type === "edit"
          ? Number(location?.state?.role_id) === 0
            ? 5
            : Number(location?.state?.role_id)
          : "",
    });
  }, [location?.state?.name]);

  return (
    <div>
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col xs={22}>
          <Typography style={{ fontSize: "18px", marginLeft: "4px" }}>
            {location?.type === "edit" ? "Edit Roles" : "Add Roles"}
          </Typography>
        </Col>
        <Col xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link to={APP_ROUTES.ROLES}>
            <Button type="primary">Back</Button>
          </Link>
        </Col>
      </Row>
      <Form form={form} onFinish={onFinish}>
        <Row
          style={{ display: "flex", alignItems: "center", marginLeft: "3px" }}
        >
          <Col xs={10}>
            <SelectBoxAppointment
              margin="mb-10"
              label={"Role Type"}
              name={"role_id"}
              placeholder={"Select your role type"}
              // editMode={location?.type === "edit" ? false : true}
              rules={[
                {
                  required: true,
                  message: "Please select role type ",
                },
              ]}
              options={[
                {
                  value: 2,
                  label: "Doctor",
                },
                {
                  value: 4,
                  label: "pharmacy",
                },
                {
                  value: 5,
                  label: "Others",
                },
              ]}
              onChange={handleChange}
            />
          </Col>
          <Col xs={4}></Col>
          <Col xs={10}>
            <Form.Item
              label="Role Name"
              name="role"
              rules={[{ required: true, message: "Please enter a Role Name" }]}
            >
              <Input
                // readOnly={location?.type === "edit" ? true : false}
                placeholder="Enter Role Name"
                defaultValue={location?.state?.name || ""}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="permissions">
          {(fields, { add, remove }) => (
            <div>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
              />
            </div>
          )}
        </Form.List>

        <div style={{ marginTop: "20px" }}>
          <Button type="primary" htmlType="submit">
            Save Role
          </Button>
        </div>
      </Form>

      {/* Error Modal */}
      {errorOpen && <div>Error Modal Placeholder</div>}
    </div>
  );
};

export default AddRoles;
