import { axiosInstance } from "../../../../util/utilz";

const SpecializationEffect = (page) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/specialization/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      pageNo: page,
      pageSize: 10,
      pagination_required: true,
    },
  });
};

const AddSpecializationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/admin/addSpecialization",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const DeleteSpecializationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/admin/deleteSpecialization/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const UpdateSpecializationEffect = ({ id, formData }) => {
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: `/admin/updateSpecialization/${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

const GetDataSpecializationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/specialization/details/${formData}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export {
  SpecializationEffect,
  AddSpecializationEffect,
  DeleteSpecializationEffect,
  UpdateSpecializationEffect,
  GetDataSpecializationEffect,
};
