import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import IntlMessages from "../../util/IntlMessages";
import ButtonComponent from "../../components/Button/ButtonComponent";
import FormInput from "../../components/Input/FormInput";
import FormSelectBox from "../../components/SelectBox/SelectBox";
import {
  getBlockListInProgress,
  getWardListByBlockInProgress,
  roomBookInProgress,
  roomListsInProgress,
} from "../../appRedux/Ward/WardAction";
import { useDispatch, useSelector } from "react-redux";
import { doctorListInProgress } from "../../appRedux/doctor/actions/doctor";
import { patientListInProgress } from "../../appRedux/doctor/actions/patient";
import TextArea from "antd/lib/input/TextArea";
import { currentDate } from "../../util/utilz";

const BookroomForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedRoomData = location.state?.room;

  const [doctorValue, setDoctorValue] = useState("");
  const [patientValue, setPatientValue] = useState("");
  const [blockValue, setBlockValue] = useState("");
  const [wardValue, setWardValue] = useState("");
  const [roomValue, setRoomValue] = useState("");
  const addWardData = useSelector((state) => state?.ward?.addWard);
  const editWardResponse = useSelector((state) => state?.ward?.editWard);
  const doctorListData = useSelector((state) => state?.doctorList?.doctorList);
  const doctorList = doctorListData?.data?.data;
  const patientData = useSelector((state) => state.patient.getList);
  const patientList = patientData?.data?.data;
  const blockDataList = useSelector((state) => state.ward.blockList);
  const blockList = blockDataList?.data?.data;
  const wardData = useSelector((state) => state.ward.wardListByBlock);
  const wardList = wardData?.data?.data;
  const roomData = useSelector((state) => state.ward.roomList?.data?.data);

  const roomBookedData = useSelector((state) => state?.ward?.roomBook);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (datas) => {
    const formData = {
      patient_id: patientValue,
      doctor_id: doctorValue,
      block_id: blockValue,
      ward_id: wardValue,
      room_id: roomValue,
      room_type: datas?.room_type === "AC" ? "1" : "2",
      check_in_date: datas?.check_in_date,
      check_in_incharge: datas?.check_in_incharge || "",
      check_in_reason: datas?.check_in_reason,
      follow_procedure: datas?.follow_procedure || "",
      checkin_by: datas?.checkin_by || "",
      price_per_day: datas?.price_per_day || "0",
      ...(datas?.room_type === "AC"
        ? { ac_room_price: datas?.ac_room_price }
        : { non_ac_room_price: datas?.non_ac_room_price }),
    };

    dispatch(roomBookInProgress(formData));
    if (addWardData?.inProgress || editWardResponse?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const [Loading, setLoading] = useState(false);
  const changeDoctor = (value) => {
    setDoctorValue(value);
  };
  const changePatient = (value) => {
    setPatientValue(value);
  };
  const changeBlock = (value) => {
    setBlockValue(value);
    setWardValue("");
    setRoomValue("");
    dispatch(
      getWardListByBlockInProgress({
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        block_id: value,
      })
    );
  };
  const changeWard = (value) => {
    setWardValue(value);
    setRoomValue("");
    dispatch(
      roomListsInProgress({
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        ward_id: value,
      })
    );
  };
  const changeRoom = (value) => {
    setRoomValue(value);
    const romDetails = availableRoomList.filter(
      (room) => room?.id === roomValue
    );

    form.setFieldValue(
      "room_type",
      selectedRoomData?.room_type === "1" ? "AC" : "NON-AC"
    );
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const addDefaultRow = useRef(null);
  useEffect(() => {
    if (addDefaultRow.current) {
      addDefaultRow.current();
    }
  }, []);
  useEffect(() => {
    setBlockValue(selectedRoomData?.Block?.id);
    setWardValue(selectedRoomData?.Ward?.id);
    setRoomValue(selectedRoomData?.id);
    form.setFieldValue("block", selectedRoomData?.Block?.block_name);
    form.setFieldValue("ward", selectedRoomData?.Ward?.ward_name);
    form.setFieldValue("room", selectedRoomData?.room_no);
    form.setFieldValue(
      "room_type",
      selectedRoomData?.room_type === "1" ? "AC" : "NON-AC"
    );
    const roomType = selectedRoomData?.room_type;

    roomType === "1"
      ? form.setFieldValue("ac_room_price", selectedRoomData?.ac_room_price)
      : form.setFieldValue(
          "non_ac_room_price",
          selectedRoomData?.non_ac_room_price
        );
  }, [selectedRoomData]);
  //   useEffect(() => {
  //     if (addWardData?.success || editWardResponse?.success) {
  //       // history.push("/ward");
  //       history.push({
  //         pathname: "/ward",
  //       });
  //     }
  //   }, [addWardData, editWardResponse]);

  useEffect(() => {
    const payload = {
      pageNo: "1",
    };
    dispatch(doctorListInProgress(payload));
    dispatch(patientListInProgress(1));

    dispatch(
      getBlockListInProgress({
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getWardListByBlockInProgress({
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        block_id: selectedRoomData?.Block?.id,
      })
    );

    dispatch(
      roomListsInProgress({
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        ward_id: selectedRoomData?.Ward?.id,
      })
    );
  }, [selectedRoomData]);
  useEffect(() => {
    form.setFieldValue("check_in_date", currentDate);
  }, []);

  useEffect(() => {
    if (roomBookedData?.success) {
      history.push("/ward-management");
    }
  }, [roomBookedData]);
  const availableRoomList =
    roomData &&
    roomData?.filter(
      (room) => room?.room_status === "1" && room?.booked_status === "1"
    );

  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="py-10">
                <Typography className="header-tag">
                  Room Booking Form
                </Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={<IntlMessages id={"Addnew.ward.label.Doctor"} />}
                      name={"doctor"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.admin.validator.doctor"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select Doctor"
                        value={doctorValue}
                        options={
                          doctorList?.map((pack, i) => ({
                            label: pack.firstName,
                            value: pack.id,
                            key: pack.id,
                          })) || []
                        }
                        onChange={changeDoctor}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={<IntlMessages id={"Addnew.ward.label.Patient"} />}
                      name={"patient"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.admin.validator.patient"}
                            />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select Patient"
                        value={patientValue}
                        options={
                          patientList?.map((pack, i) => ({
                            label: pack.firstName,
                            value: pack.id,
                            key: pack.id,
                          })) || []
                        }
                        onChange={changePatient}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={<IntlMessages id={"Addnew.ward.label.block"} />}
                      name={"block"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages id={"Addnew.admin.validator.block"} />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select Block"
                        value={blockValue}
                        options={
                          blockList?.map((pack, i) => ({
                            label: pack.block_name,
                            value: pack.id,
                            key: pack.id,
                          })) || []
                        }
                        onChange={changeBlock}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={<IntlMessages id={"Addnew.ward.label.ward"} />}
                      name={"ward"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages id={"Addnew.admin.validator.ward"} />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select Ward "
                        value={wardValue}
                        options={
                          wardList?.map((pack, i) => ({
                            label: pack.ward_name,
                            value: pack.id,
                            key: pack.id,
                          })) || []
                        }
                        onChange={changeWard}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      label={<IntlMessages id={"Addnew.ward.label.room"} />}
                      name={"room"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages id={"Addnew.admin.validator.room"} />
                          ),
                        },
                      ]}
                    >
                      <FormSelectBox
                        placeholder="Select Room"
                        value={roomValue}
                        options={
                          (availableRoomList &&
                            availableRoomList?.map((pack, i) => ({
                              label: pack.room_no,
                              value: pack.id,
                              key: pack.id,
                            }))) ||
                          []
                        }
                        onChange={changeRoom}
                        showSearch="true"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"room_type"}
                      disabled={true}
                      label={<IntlMessages id={"Addnew.ward.label.roomType"} />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.roomType"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  {form.getFieldValue("room_type") === "AC" ? (
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"ac_room_price"}
                        readOnly={true}
                        label={
                          <IntlMessages id={"Addnew.ward.label.acRoomPrice"} />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.ward.validator.acRoomPrice"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                  ) : (
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={24}
                      xs={24}
                      style={{ display: "none" }}
                    >
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        readOnly={true}
                        name={"non_ac_room_price"}
                        label={
                          <IntlMessages
                            id={"Addnew.ward.label.nonAcRoomPrice"}
                          />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.ward.validator.nonAcRoomPrice"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                  )}
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"check_in_date"}
                      disabled={true}
                      label={
                        <IntlMessages id={"Addnew.ward.label.checkinDate"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkinDate"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"check_in_incharge"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.check_in_incharge"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.check_in_incharge"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"checkin_by"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.checkin_by"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkin_by"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="number"
                      name={"price_per_day"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.price_per_day"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.price_per_day"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name={"check_in_reason"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.check_in_reason"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.check_in_reason"}
                            />
                          ),
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name={"follow_procedure"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.follow_procedure"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.follow_procedure"}
                            />
                          ),
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link
                  to={{
                    pathname: `/ward-management`,
                  }}
                >
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={"Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default BookroomForm;
