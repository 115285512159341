import {
  ADD_DISEASES_PROGRESS,
  ADD_QUESTIONS_ANSWER_PROGRESS,
  DELETE_DISEASES_PROGRESS,
  DELETE_QUESTIONS_ANSWER_PROGRESS,
  GET_DISEASES_LIST_PROGRESS,
  GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS,
  GET_QUESTIONS_ANSWER_LIST_PROGRESS,
  UPDATE_DISEASES_PROGRESS,
  UPDATE_QUESTIONS_ANSWER_PROGRESS,
} from "../../../constants/ActionTypes";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  addDiseasesInError,
  addDiseasesInSuccess,
  addQuestionAnswerInError,
  addQuestionAnswerInSuccess,
  deleteDiseasesInError,
  deleteDiseasesInSuccess,
  deleteQuestionAnswerInError,
  deleteQuestionAnswerInSuccess,
  getDiseaseListInError,
  getDiseaseListInSuccess,
  getQuestionAnswerByDiseasesListInError,
  getQuestionAnswerByDiseasesListInSuccess,
  getQuestionAnswerListInError,
  getQuestionAnswerListInSuccess,
  updateDiseasesInError,
  updateDiseasesInSuccess,
  updateQuestionAnswerInError,
  updateQuestionAnswerInSuccess,
} from "../actions/appointmentQuestions";
import {
  addDiseasesEffect,
  addQuestionAnswerEffect,
  deleteDiseasesEffect,
  deleteQuestionAnswerEffect,
  getDiseasesEffect,
  getQuestionAnswerByDiseasesEffect,
  getQuestionAnswerEffect,
  updateDiseasesEffect,
  updateQuestionAnswerEffect,
} from "./effects/AppointmentQuestions";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import { message } from "antd";
import Message from "../../../components/Message/Message";

function* addDiseases({ payload }) {
  try {
    const { data } = yield call(addDiseasesEffect, payload);

    yield put(
      addDiseasesInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addDiseasesInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* getDiseases({ payload }) {
  try {
    const { data } = yield call(getDiseasesEffect, payload);

    yield put(
      getDiseaseListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      getDiseaseListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* updateDiseases({ payload }) {
  try {
    const { data } = yield call(updateDiseasesEffect, payload);
    yield put(
      updateDiseasesInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateDiseasesInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* deleteDiseases({ payload }) {
  try {
    const { data } = yield call(deleteDiseasesEffect, payload);
    yield put(
      deleteDiseasesInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteDiseasesInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* addQuestionAnswer({ payload }) {
  try {
    const { data } = yield call(addQuestionAnswerEffect, payload);

    yield put(
      addQuestionAnswerInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );

    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addQuestionAnswerInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* getListQuestionAnswer({ payload }) {
  try {
    const { data } = yield call(getQuestionAnswerEffect, payload);

    yield put(
      getQuestionAnswerListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      getQuestionAnswerListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* updateQuestionAnswer({ payload }) {
  try {
    const { data } = yield call(updateQuestionAnswerEffect, payload);
    yield put(
      updateQuestionAnswerInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      updateQuestionAnswerInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
  }
}

function* deleteQuestionAnswer({ payload }) {
  try {
    const { data } = yield call(deleteQuestionAnswerEffect, payload);
    yield put(
      deleteQuestionAnswerInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteQuestionAnswerInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* getListQuestionAnswerByDiseases({ payload }) {
  try {
    const { data } = yield call(getQuestionAnswerByDiseasesEffect, payload);

    yield put(
      getQuestionAnswerByDiseasesListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      getQuestionAnswerByDiseasesListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
export function* AppointmentQuestionsSaga() {
  yield takeLatest(ADD_DISEASES_PROGRESS, addDiseases);
  yield takeLatest(GET_DISEASES_LIST_PROGRESS, getDiseases);
  yield takeLatest(UPDATE_DISEASES_PROGRESS, updateDiseases);
  yield takeLatest(DELETE_DISEASES_PROGRESS, deleteDiseases);
  yield takeLatest(ADD_QUESTIONS_ANSWER_PROGRESS, addQuestionAnswer);
  yield takeLatest(GET_QUESTIONS_ANSWER_LIST_PROGRESS, getListQuestionAnswer);
  yield takeLatest(UPDATE_QUESTIONS_ANSWER_PROGRESS, updateQuestionAnswer);
  yield takeLatest(DELETE_QUESTIONS_ANSWER_PROGRESS, deleteQuestionAnswer);
  yield takeLatest(
    GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS,
    getListQuestionAnswerByDiseases
  );
}
