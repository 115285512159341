import { call, put, takeEvery } from "redux-saga/effects";
import Message from "../../../components/Message/Message";

import {
  FORGET_PASSWORD_IN_PROGRESS,
  OTP_SEND_IN_PROGRESS,
  VERIFY_OTP_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  ForgetPasswordError,
  ForgetPasswordSuccess,
  hideAuthLoader,
  OtpSendError,
  OtpSendSuccess,
  VerifyOtpError,
  VerifyOtpSuccess,
} from "../actions/Auth";

import {
  OtpSendEffect,
  UpdatePasswordEffect,
  VerifyOtpEffect,
} from "./effects/RegisterEffect";
import { UserLoginSuccess } from "../actions/AuthAction";

function* OtpSendWatcher({ payload }) {
  try {
    let { data } = yield call(OtpSendEffect, payload);

    yield put(
      OtpSendSuccess({
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      OtpSendError({
        error: true,
        message: e["message"],
      })
    );

    yield call(Message, { type: "error", content: "Incorrect Email ID" });
    yield put(hideAuthLoader());
  }
}

function* VerifyOtpWatcher({ payload }) {
  try {
    let { data } = yield call(VerifyOtpEffect, payload);

    if (data?.user) {
      yield call(Message, {
        type: "success",
        content: "Login Successfully",
      });

      localStorage.setItem("user_id", data?.token);
      localStorage.setItem("doctor_id", data?.user?.doctor_id);
      localStorage.setItem("patient_id", data?.user?.patient_id);
      localStorage.setItem("id", data?.user?.user_id);
      localStorage.setItem("pass", data?.user?.password);
      localStorage.setItem("role_id", data?.user?.role_id);
      localStorage.setItem("email", data?.user?.email);
      localStorage.setItem("org_id", data?.user?.organization_id);
      localStorage.setItem("pharmacy_id", data?.user?.pharmacies_id);
      localStorage.setItem("role_permision", data?.user?.access);
      yield put(
        UserLoginSuccess({
          data: data?.user,
          success: true,
        })
      );
    } else {
      yield put(
        VerifyOtpSuccess({
          message: data["message"],
          success: true,
        })
      );
      yield call(Message, { type: "success", content: data.message });
    }
  } catch (e) {
    yield put(
      VerifyOtpError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, { type: "error", content: "Enter Correct OTP" });

    yield put(hideAuthLoader());
  }
}

function* UpdatePasswordWatcher({ payload }) {
  try {
    let { data } = yield call(UpdatePasswordEffect, payload);

    yield put(
      ForgetPasswordSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      ForgetPasswordError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(hideAuthLoader());
  }
}

export default function* RegisterSaga() {
  yield takeEvery(OTP_SEND_IN_PROGRESS, OtpSendWatcher);
  yield takeEvery(VERIFY_OTP_IN_PROGRESS, VerifyOtpWatcher);
  yield takeEvery(FORGET_PASSWORD_IN_PROGRESS, UpdatePasswordWatcher);
}
