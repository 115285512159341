import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_HOSPITAL_IN_PROGRESS,
  ADD_ORG_TIME_PROGRESS,
  ADD_PACKAGES_IN_PROGRESS,
  ADD_PLAN_IN_PROGRESS,
  ADD_SA_ORG_IN_PROGRESS,
  ADD_SUBSCRIPTION_DETAILS_INPROGRESS,
  ADD_SUBSCRIPTION_IN_PROGRESS,
  ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS,
  DELETE_HOSPITAL_IN_PROGRESS,
  DELETE_PACKAGE_SA_IN_PROGRESS,
  DELETE_PLAN_IN_PROGRESS,
  DELETE_SA_ORG_IN_PROGRESS,
  DELETE_SUBSCRIPTION_IN_PROGRESS,
  EDIT_HOSPITAL_IN_PROGRESS,
  EDIT_PACKAGE_IN_PROGRESS,
  EDIT_PLAN_IN_PROGRESS,
  EDIT_SA_ORG_IN_PROGRESS,
  EDIT_SUBSCRIPTION_IN_PROGRESS,
  GET_HOSPITAL_LIST_IN_PROGRESS,
  GET_MODULE_PERMISSION_IN_PROGRESS,
  GET_ORG_DETAILS_BY_ID_IN_PROGRESS,
  GET_PACKAGES_IN_PROGRESS,
  GET_PLAN_LIST_IN_PROGRESS,
  GET_SA_ORG__LIST_IN_PROGRESS,
  GET_SUBSCRIPTION_LIST_IN_PROGRESS,
  GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS,
} from "../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../util/utilz";

import Message from "../../components/Message/Message";

import {
  addHospitalError,
  addHospitalSuccess,
  addOrgTimeError,
  addOrgTimeSuccess,
  addOrganisationSuccess,
  addOrganisationrror,
  addPackageError,
  addPackageSuccess,
  addPlanError,
  addPlanSuccess,
  addSubscriptionDetailsError,
  addSubscriptionDetailsSuccess,
  addSubscriptionError,
  addSubscriptionPaymentError,
  addSubscriptionPaymentSuccess,
  addSubscriptionSuccess,
  deleteHospitalError,
  deleteHospitalSuccess,
  deleteOrganisationSuccess,
  deletePackageError,
  deletePackageSuccess,
  deletePlanError,
  deletePlanSuccess,
  deleteSubscriptionError,
  deleteSubscriptionSuccess,
  editHospitalError,
  editHospitalSuccess,
  editOrganisationError,
  editOrganisationSuccess,
  editOrganisationrror,
  editPackageError,
  editPackageSuccess,
  editPlanError,
  editPlanSuccess,
  editSubscriptionError,
  editSubscriptionSuccess,
  getHospitalListError,
  getHospitalListSuccess,
  getModulePermissionError,
  getModulePermissionSuccess,
  getOrgDetailsByIdError,
  getOrgDetailsByIdSuccess,
  getOrganisationListError,
  getOrganisationListSuccess,
  getPackageListError,
  getPackageListSuccess,
  getPlanListError,
  getPlanListSuccess,
  getSuperAdminDashboardError,
  getSuperAdminDashboardSuccess,
  getsubscriptionListError,
  getsubscriptionListSuccess,
} from "./SuperAdminAction";
import {
  HospitalListEffect,
  addHospitalEffect,
  addOrgEffect,
  addOrgTimeEffect,
  addPackageEffect,
  addPlanEffect,
  addSubscriptionDetailsEffect,
  addSubscriptionEffect,
  addSubscriptionPaymentEffect,
  deleteHospitalEffect,
  deleteOrgEffect,
  deletePackageEffect,
  deletePlanEffect,
  deleteSubscriptionEffect,
  editHospitalEffect,
  editOrgEffect,
  editPackageEffect,
  editPlanEffect,
  editSubscriptionEffect,
  getModulePermissionEffect,
  getOrgDetailsByIdEffect,
  getSuperAdminDashboardEffect,
  orgListEffect,
  planListEffect,
  subscriptionListEffect,
} from "./SuperAdminEffect";
import { deleteOrgError } from "../doctor/actions/organisation";

function* getSuperAdminDashboardWatcher() {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getSuperAdminDashboardEffect, token);

    yield put(
      getSuperAdminDashboardSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getSuperAdminDashboardError({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* getHospitalListWatcher({ payload }) {
  try {
    let { data } = yield call(HospitalListEffect, payload);

    yield put(
      getHospitalListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getHospitalListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addHospitalWatcher({ payload }) {
  try {
    let { data } = yield call(addHospitalEffect, payload);

    yield put(
      addHospitalSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addHospitalError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* editHospitalWatcher({ payload }) {
  try {
    let { data } = yield call(editHospitalEffect, payload);

    yield put(
      editHospitalSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editHospitalError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteHospitalWatcher({ payload }) {
  try {
    let { data } = yield call(deleteHospitalEffect, payload);

    yield put(
      deleteHospitalSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteHospitalError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* getPackageListWatcher({ payload }) {
  try {
    let { data } = yield call(getPackageListWatcher, payload);

    yield put(
      getPackageListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getPackageListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addPackageWatcher({ payload }) {
  try {
    let { data } = yield call(addPackageEffect, payload);

    yield put(
      addPackageSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addPackageError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* editPackageWatcher({ payload }) {
  try {
    let { data } = yield call(editPackageEffect, payload);

    yield put(
      editPackageSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editPackageError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* deletePackageWatcher({ payload }) {
  try {
    let { data } = yield call(deletePackageEffect, payload);

    yield put(
      deletePackageSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deletePackageError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* getSubscriptionListWatcher({ payload }) {
  try {
    let { data } = yield call(subscriptionListEffect, payload);

    yield put(
      getsubscriptionListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getsubscriptionListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addSubscriptionWatcher({ payload }) {
  try {
    let { data } = yield call(addSubscriptionEffect, payload);

    yield put(
      addSubscriptionSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addSubscriptionError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* editSubscriptionWatcher({ payload }) {
  try {
    let { data } = yield call(editSubscriptionEffect, payload);

    yield put(
      editSubscriptionSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editSubscriptionError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteSubscriptionWatcher({ payload }) {
  try {
    let { data } = yield call(deleteSubscriptionEffect, payload);

    yield put(
      deleteSubscriptionSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteSubscriptionError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* getModulePermissionWatcher({ payload }) {
  try {
    let { data } = yield call(getModulePermissionEffect, payload);

    yield put(
      getModulePermissionSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getModulePermissionError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* getPlanListWatcher({ payload }) {
  try {
    let { data } = yield call(planListEffect, payload);

    yield put(
      getPlanListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getPlanListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addPlanWatcher({ payload }) {
  try {
    let { data } = yield call(addPlanEffect, payload);

    yield put(
      addPlanSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addPlanError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* editPlanWatcher({ payload }) {
  try {
    let { data } = yield call(editPlanEffect, payload);

    yield put(
      editPlanSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editPlanError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* deletePlanWatcher({ payload }) {
  try {
    let { data } = yield call(deletePlanEffect, payload);

    yield put(
      deletePlanSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deletePlanError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* getOrgListWatcher({ payload }) {
  try {
    let { data } = yield call(orgListEffect, payload);

    yield put(
      getOrganisationListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getOrganisationListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addOrgWatcher({ payload }) {
  try {
    let { data } = yield call(addOrgEffect, payload);

    yield put(
      addOrganisationSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addOrganisationrror({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* editOrgWatcher({ payload }) {
  try {
    let { data } = yield call(editOrgEffect, payload);

    yield put(
      editOrganisationSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      editOrganisationError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteOrgWatcher({ payload }) {
  try {
    let { data } = yield call(deleteOrgEffect, payload);

    yield put(
      deleteOrganisationSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      deleteOrgError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addSubscriptionPaymentWatcher({ payload }) {
  try {
    let { data } = yield call(addSubscriptionPaymentEffect, payload);

    yield put(
      addSubscriptionPaymentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addSubscriptionPaymentError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addSubscriptionDetailsWatcher({ payload }) {
  try {
    let { data } = yield call(addSubscriptionDetailsEffect, payload);

    yield put(
      addSubscriptionDetailsSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addSubscriptionDetailsError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* addOrgTimeWatcher({ payload }) {
  try {
    let { data } = yield call(addOrgTimeEffect, payload);

    yield put(
      addOrgTimeSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      addOrgTimeError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* getOrgDetailsByIdWatcher({ payload }) {
  try {
    let { data } = yield call(getOrgDetailsByIdEffect, payload);

    yield put(
      getOrgDetailsByIdSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    yield put(
      getOrgDetailsByIdError({
        error: true,
        message: e["message"],
      })
    );
  }
}
export default function* SuperAdminSaga() {
  yield takeLatest(
    GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS,
    getSuperAdminDashboardWatcher
  );
  yield takeLatest(GET_HOSPITAL_LIST_IN_PROGRESS, getHospitalListWatcher);
  yield takeLatest(ADD_HOSPITAL_IN_PROGRESS, addHospitalWatcher);
  yield takeLatest(EDIT_HOSPITAL_IN_PROGRESS, editHospitalWatcher);
  yield takeLatest(DELETE_HOSPITAL_IN_PROGRESS, deleteHospitalWatcher);
  yield takeLatest(GET_PACKAGES_IN_PROGRESS, getPackageListWatcher);
  yield takeLatest(ADD_PACKAGES_IN_PROGRESS, addPackageWatcher);
  yield takeLatest(EDIT_PACKAGE_IN_PROGRESS, editPackageWatcher);
  yield takeLatest(DELETE_PACKAGE_SA_IN_PROGRESS, deletePackageWatcher);
  yield takeLatest(
    GET_SUBSCRIPTION_LIST_IN_PROGRESS,
    getSubscriptionListWatcher
  );
  yield takeLatest(ADD_SUBSCRIPTION_IN_PROGRESS, addSubscriptionWatcher);
  yield takeLatest(EDIT_SUBSCRIPTION_IN_PROGRESS, editSubscriptionWatcher);
  yield takeLatest(DELETE_SUBSCRIPTION_IN_PROGRESS, deleteSubscriptionWatcher);
  yield takeLatest(
    GET_MODULE_PERMISSION_IN_PROGRESS,
    getModulePermissionWatcher
  );
  yield takeLatest(GET_PLAN_LIST_IN_PROGRESS, getPlanListWatcher);
  yield takeLatest(ADD_PLAN_IN_PROGRESS, addPlanWatcher);
  yield takeLatest(EDIT_PLAN_IN_PROGRESS, editPlanWatcher);
  yield takeLatest(DELETE_PLAN_IN_PROGRESS, deletePlanWatcher);
  yield takeLatest(GET_SA_ORG__LIST_IN_PROGRESS, getOrgListWatcher);
  yield takeLatest(ADD_SA_ORG_IN_PROGRESS, addOrgWatcher);
  yield takeLatest(DELETE_SA_ORG_IN_PROGRESS, deleteOrgWatcher);
  yield takeLatest(EDIT_SA_ORG_IN_PROGRESS, editOrgWatcher);
  yield takeLatest(
    ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS,
    addSubscriptionPaymentWatcher
  );
  yield takeLatest(
    ADD_SUBSCRIPTION_DETAILS_INPROGRESS,
    addSubscriptionDetailsWatcher
  );
  yield takeLatest(ADD_ORG_TIME_PROGRESS, addOrgTimeWatcher);
  yield takeLatest(GET_ORG_DETAILS_BY_ID_IN_PROGRESS, getOrgDetailsByIdWatcher);
}
