import {
  GET_DOCTOR_PROFILE_ERROR,
  GET_DOCTOR_PROFILE_IN_PROGRESS,
  GET_DOCTOR_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_PROGRESS,
  GET_USER_PROFILE_SUCCESS,
  UPDATE_DOCTOR_PROFILE_ERROR,
  UPDATE_DOCTOR_PROFILE_IN_PROGRESS,
  UPDATE_DOCTOR_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_PROGRESS,
  UPDATE_USER_PROFILE_SUCCESS,
} from "../../../constants/ActionTypes";

export const getUserProfileInProgress = (payload) => ({
  type: GET_USER_PROFILE_PROGRESS,
  payload: payload,
});

export const getUserProfileInSuccess = (payload) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: payload,
});

export const getUserProfileInError = () => ({
  type: GET_USER_PROFILE_ERROR,
});

export const updateUserProfileProgress = (payload) => ({
  type: UPDATE_USER_PROFILE_PROGRESS,
  payload: payload,
});

export const updateUserProfileSuccess = (payload) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: payload,
});

export const updateUserProfileError = () => ({
  type: UPDATE_USER_PROFILE_ERROR,
});
export const getDoctorProfileInProgress = (payload) => ({
  type: GET_DOCTOR_PROFILE_IN_PROGRESS,
  payload: payload,
});

export const getDoctorProfileSuccess = (payload) => ({
  type: GET_DOCTOR_PROFILE_SUCCESS,
  payload: payload,
});

export const getDoctorProfileError = () => ({
  type: GET_DOCTOR_PROFILE_ERROR,
});
export const updateDoctorProfileInProgress = (payload) => ({
  type: UPDATE_DOCTOR_PROFILE_IN_PROGRESS,
  payload: payload,
});

export const updateDoctorProfileSuccess = (payload) => ({
  type: UPDATE_DOCTOR_PROFILE_SUCCESS,
  payload: payload,
});

export const updateDoctorProfileError = () => ({
  type: UPDATE_DOCTOR_PROFILE_ERROR,
});
