import axios from "axios";
import {
  axiosInstance,
  getTokenfromLocalStorage,
} from "../../../../util/utilz";

export const AddConsultationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/consultantFee/add",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const UpdateConsultationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/consultantFee/update/${formData?.consultationId}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const GetConsultationEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/consultantFee/details?doctor_id=${formData}`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
