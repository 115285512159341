import React, { useEffect, useState } from "react";
import { Col, Form, Input, Radio, Row, Tag } from "antd";
import moment from "moment";
import TimeSlotDates from "./TimeSlotDates";
import Title from "antd/lib/skeleton/Title";
import Paragraph from "antd/lib/skeleton/Paragraph";

const TimeSlot = ({ form }) => {
  // const [form] = Form.useForm();

  const [dates, setDates] = useState([]);

  useEffect(() => {
    let data = [];
    const currentDate = moment();
    for (let i = 0; i <= 5; i++) {
      data[i] = moment(currentDate).add(i, "days");
    }
    setDates(data);
  }, []);

  const handleSlot = (val, v) => {
    form.setFieldsValue({ slot_id: val });
  };
  // const handleSlott = (val) => {
  //   form.setFieldsValue({ end_time: val });
  // }

  // const slotValue = Form.useWatch("start_time", form);
  const slValue = Form.useWatch("slot_id");

  return (
    <Radio.Group style={{ display: "grid" }}>
      <Row className="slot-row">
        {/* {dates.map((date, index) => ( */}
        <Col>
          {/* <Tag  color="#3F51B5" > */}
          {/* <span>Schedule Slot</span> */}
          {/* {moment(date).format("ddd,MMM-DD")} */}
          {/* </Tag> */}
          <TimeSlotDates
            // date={moment(date).format("DD-MM-YYYY")}
            handleSlot={handleSlot}
            // index={index}
            // key={index}
            // selectedValue={{ slotValue, slValue }}
            form={form}
          />
        </Col>
      </Row>
      <Form.Item
        name={"start_time"}
        className={"d-none"}
        rules={[{ required: true, message: "Please input an start time" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"end_time"}
        className={"d-none"}
        rules={[{ required: true, message: "Please input an end time" }]}
      >
        <Input />
      </Form.Item>
    </Radio.Group>
  );
};

export default TimeSlot;
