import { axiosInstance } from "../../util/utilz";

export const getSuperAdminDashboardEffect = () => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/user/superadmindashboard",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const HospitalListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: "/departments/getlist/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addHospitalEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: "/departments/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addOrgTimeEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/admin/update/${formData?.orgId}`,
    data: formData?.payload,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editHospitalEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: `/departments/update/${formData?.id}`,
    data: formData?.datas,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteHospitalEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: `/departments/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const PackageListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: "/departments/getlist/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPackageEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: "/departments/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPackageEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: `/departments/update/${formData?.id}`,
    data: formData?.datas,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePackageEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    // url: `/departments/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const subscriptionListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subscription/getsubscriptions?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addSubscriptionEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/subscription/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editSubscriptionEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subscription/updatesubscription/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteSubscriptionEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subscription/deletesubscription/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getModulePermissionEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/mastermenus/menus",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const planListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subscriptiondetails/getsubscriptions?pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}&pagination_required=${formData?.pagination}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPlanEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/subscription/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPlanEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subscription/updatesubscription/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePlanEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subscription/deletesubscription/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const orgListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/organizations/list?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addOrgEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/admin/addOrganizations",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editOrgEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/admin/updateOrganizations/${formData?.id}`,
    data: formData?.payload,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteOrgEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/admin/deleteOrganizations/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addSubscriptionPaymentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/subscriptionpayments/insertsubscriptionpayment",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addSubscriptionDetailsEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/subscriptiondetails/insertsubscriptiondetails",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getOrgDetailsByIdEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/organizations/details/${formData}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
