import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultShiftRoom,
  getBlockListInProgress,
  getBookedRoomsListInProgress,
} from "../../../appRedux/Ward/WardAction";
import { Link } from "react-router-dom/cjs/react-router-dom";

import Meta from "antd/lib/card/Meta";

const BookedRoomsList = ({ selectedBlockId, onBlockSelect }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const bookedRoomData = useSelector((state) => state.ward.bookedRoomsList);
  const shiftRoom = useSelector((state) => state?.ward?.roomShift);
  const bookedRoomList = bookedRoomData?.data?.data;
  const handlePage = (page) => {
    const payload = { pagination: "true", pageNo: page, pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(bookedRoomList);
    } else {
      const newData = bookedRoomList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const handleBlockSelect = (record) => {
    if (onBlockSelect) {
      onBlockSelect(record.id);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Check-in By",
      dataIndex: "checkin_by",
      key: "checkin_by",
    },
    {
      title: "Block Name",
      dataIndex: "block_name",
      key: "block_name",
    },
    {
      title: "Ward Name",
      dataIndex: "ward_name",
      key: "ward_name",
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "doctor Name",
      dataIndex: "doctor_name",
      key: "doctor_name",
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        console.log("record", record);
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/shift-room`,
                  state: { roomData: record },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  label="Shift Room"
                />
              </Link>
            </Col>
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/discharge-room`,
                  state: { roomData: record },
                }}
              >
                <ButtonComponent type="primary" label="Discharge Room" />
              </Link>
            </Col>
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    if (shiftRoom?.success) {
      dispatch(defaultShiftRoom());
    }
  }, [shiftRoom]);
  useEffect(() => {
    if (bookedRoomData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookedRoomData]);

  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getBookedRoomsListInProgress(payload));
  }, [dispatch]);
  console.log("bookedRoomList", bookedRoomList);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.bookedRoomList"} />}
          </Typography>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        {" "}
        <Row gutter={[16, 16]}>
          {bookedRoomList?.map((mapAvailableRoom) => (
            <Col xl={6} lg={8} md={8} sm={12} xs={24}>
              <Card
                type="inner"
                title={
                  <Typography className="ward-heading">{`Room Number - ${mapAvailableRoom?.room_number}`}</Typography>
                }
                actions={[
                  <Link
                    to={{
                      pathname: `/shift-room`,
                      state: { roomData: mapAvailableRoom },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      label="Shift"
                    />
                  </Link>,
                  <Link
                    to={{
                      pathname: `/discharge-room`,
                      state: { roomData: mapAvailableRoom },
                    }}
                  >
                    <ButtonComponent type="primary" label="Discharge" />
                  </Link>,
                ]}
              >
                <Row gutter={[16, 16]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Meta
                      title={`Check-in By - ${mapAvailableRoom?.checkin_by}`}
                      description={`Room Type - ${
                        mapAvailableRoom?.room_type === 1 ? "AC" : "NON-AC"
                      }`}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Typography>{`Doctor Name - ${mapAvailableRoom?.doctor_name} `}</Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
    </Card>
  );
};

export default BookedRoomsList;
