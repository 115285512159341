import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row, Card } from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { message } from "antd/lib/index";

import {
  hideAuthLoader,
  OtpSendInProgress,
  setForgetPassword,
  showAuthLoader,
  VerifyOtpInProgress,
} from "../appRedux/doctor/actions/Auth";
import CircularProgress from "../components/CircularProgress";
import OtpInput from "react-otp-input";
import DocmozIcon from "../assets/icons/final-logo1 1.png";
import DocmozCar2 from "../common/images/loginpage3.png";
import { Link } from "react-router-dom";
import { withConfirm } from "antd/lib/modal/confirm";

// import OtpInput from "react-otp-input";

const FormItem = Form.Item;

const OtpVerification = (props) => {
  const [code, setCode] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const handleChange = (code) => setCode(code);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const loginOtp = location.state?.loginOtp;

  let slug = useParams();
  const [navigate, setNavigate] = useState(false);
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const getToken = localStorage.getItem("user_id");
  const sendOtpData = useSelector((state) => state.register);
  const OtpSuccess = sendOtpData?.otpsend?.success;

  const verifyOtpData = useSelector((state) => state.register);
  const VerifyOtpSuccess = verifyOtpData?.verifyotp?.success;
  const AuthData = useSelector((state) => state?.authData?.authData);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());

    let formData = new URLSearchParams();

    formData.append("email", slug.email);
    formData.append("verification_code", values.verification_code);
    formData.append("type", loginOtp ? 2 : 1);

    dispatch(VerifyOtpInProgress(formData));
    setNavigate(true);
  };
  useEffect(() => {
    dispatch(hideAuthLoader());
  }, []);
  useEffect(() => {
    if (AuthData?.success == true && getToken != null) {
      setTimeout(() => {
        history.push("/");
      }, 500);
    }
  }, [AuthData?.success, getToken]);
  useEffect(() => {
    if (VerifyOtpSuccess === true && navigate) {
      console.log("verifyOtpData", verifyOtpData);

      history.push(`/forgetpassword/${slug.email}`);
      setNavigate(false);
    }
  }, [VerifyOtpSuccess]);
  useEffect(() => {
    dispatch(setForgetPassword());
  }, []);

  const resend = () => {
    setCode("");
    let formData = new URLSearchParams();
    formData.append("email", slug.email);
    formData.append("subject_type", 2);
    dispatch(OtpSendInProgress(formData));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        setCode("");
        if (minutes === 0) {
          setCode("");
          clearInterval(interval);
        } else {
          setSeconds(45);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  useEffect(() => {
    if (OtpSuccess === true) {
      setMinutes(0);
      setSeconds(45);
    }
  }, [OtpSuccess]);
  return (
    <div className=" dashbord-logo-content">
      <>
        <div className=" left-side ">
          <div className="carousel-content-main ">
            <img alt="example" src={DocmozCar2} className="carousel-content" />
          </div>
        </div>
        <div className=" right-side">
          <Card className="card-width card-effect">
            <div className="gx-app-logo login-logo">
              <img alt="example" src={DocmozIcon} />
            </div>

            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="opt-form"
            >
              <Row>
                <Col span={24}>
                  {" "}
                  <FormItem
                    name="verification_code"
                    rules={[
                      {
                        required: true,
                        type: "text",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <OtpInput
                      value={code}
                      onChange={handleChange}
                      numInputs={4}
                      separator={<span style={{ width: "8px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        width: "50px",
                        height: "50px",
                        fontSize: "20px",
                        color: "#000",
                        fontWeight: "700",
                        caretColor: "blue",
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none",
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="text-wap">
                  <Button
                    type={"link"}
                    loading={sendOtpData?.otpsend?.inProgress}
                    onClick={() => {
                      resend();
                    }}
                    disabled={seconds != 0}
                    className="disable-color"
                  >
                    {" "}
                    Resent Otp {seconds}
                  </Button>
                </Col>
              </Row>
              <Row className="mb-10 ">
                <Col span={24}>
                  <Link to="/" className="gx-login-form-forgot form-forgot ">
                    SignIn
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  {" "}
                  <FormItem>
                    {" "}
                    <Button
                      type="primary"
                      className="gx-mb-4"
                      htmlType="submit"
                      disabled={seconds === 0}
                    >
                      verify otp
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </>
      {/* )} */}
      {showMessage ? message.error(alertMessage.toString()) : null}
    </div>
  );
};

export default OtpVerification;
