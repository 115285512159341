import { axiosInstance } from "../../../../../util/utilz";

export const getDashboardListEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  const organization_id = localStorage.getItem("org_id");

  return axiosInstance.request({
    url: `/user/admindashboard`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      organizationId: organization_id,
    },
  });
};
