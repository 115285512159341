import { Card, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoctorAppointmentListInProgress,
  getDoctorUpcomingAppointmentListInProgress,
} from "../../appRedux/doctor/actions/Appointment";
import TableComponent from "../../components/Table/TableComponent";
import DoctorAppointmentHeader from "./DoctorAppointmentHeader";
export const columns = [
  {
    title: "Patient Name",
    dataIndex: "patient_name",
    sortDirections: ["ascend", "descend"],
    key: "patient_name",
    sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
  },
  {
    title: "Doctor Name",
    dataIndex: "doctor_name",
    sortDirections: ["ascend", "descend"],
    key: "doctor_name",
    sorter: (a, b) => a.doctor_name.localeCompare(b.doctor_name),
  },
  {
    title: "Week Day",
    dataIndex: "week_day",
  },
  {
    title: "Start Time",
    dataIndex: "start_time",
    render: (start_time) => moment(start_time, ["h:mm A"]).format("hh:mm A"),
  },
  {
    title: "End Time",
    dataIndex: "end_time",
    render: (end_time) => moment(end_time, ["h:mm A"]).format("hh:mm A"),
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Reason",
    dataIndex: "reason",
  },
  {
    title: "Symptom",
    dataIndex: "symptom",
  },
];

const DoctorAppoinment = () => {
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("hh:mm A"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const dispatch = useDispatch();
  // const isPrev = "0";
  useEffect(() => {
    // handlePage(1)
    const page = 1;
    dispatch(
      getDoctorAppointmentListInProgress({ page, currentDate, currentTime })
    );
  }, []);
  useEffect(() => {
    // handleUpcomingPage(1)
    const page = 1;
    dispatch(
      getDoctorUpcomingAppointmentListInProgress({
        page,
        currentDate,
        currentTime,
      })
    );
  }, []);
  const appointmentlist = useSelector((state) => state.appointment);
  const appointmentLists = appointmentlist.doctorappointmentlist?.data?.data;
  const totalRecords =
    appointmentlist.doctorappointmentlist?.data?.totalRecords;

  const upcomingAppointment = useSelector((state) => state.appointment);
  const upcomingAppointmentLists =
    upcomingAppointment.doctorupcomingappointmentlist?.data?.data;
  const totalRecords2 =
    upcomingAppointment.doctorupcomingappointmentlist?.data?.totalRecords;

  const handlePage = (page) => {
    dispatch(
      getDoctorAppointmentListInProgress({ page, currentDate, currentTime })
    );
  };
  const handleUpcomingPage = (page) => {
    dispatch(
      getDoctorUpcomingAppointmentListInProgress({
        page,
        currentDate,
        currentTime,
      })
    );
  };
  return (
    <Card>
      <DoctorAppointmentHeader />
      <Tabs tabPosition={"top"}>
        <TabPane tab="Previous" key="1">
          <TableComponent
            columns={columns}
            dataSource={appointmentLists}
            pagination={{
              total: totalRecords,
              onChange: (page) => handlePage(page),
            }}
            itemList={appointmentLists}
            documentTitle={"Appointment"}
          />
        </TabPane>
        <TabPane tab="Upcoming" key="2">
          <TableComponent
            columns={columns}
            dataSource={upcomingAppointmentLists}
            pagination={{
              total: totalRecords2,
              onChange: (page) => handleUpcomingPage(page),
            }}
            itemList={upcomingAppointmentLists}
            documentTitle={"Appointment"}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default DoctorAppoinment;
