import { Card, Col, Form, Modal, Row, Spin, Typography } from "antd";

import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  addHolidaysListInProgress,
  updateHolidaysListInProgress,
} from "../../../appRedux/doctor/actions/Holiday";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import FormInput from "../../../components/Input/FormInput";

import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";

import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const HolidayAddForm = ({ editHoliday, editHolidayData }) => {
  const [loading, setLoading] = useState(false);
  const [holidayData, setHolidayData] = useState({});
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const doctorid = localStorage.getItem("doctor_id");
  const history = useHistory();
  const addHolidayData = useSelector((state) => state?.holiday?.addholidaylist);
  const editHolidayStatus = useSelector(
    (state) => state.holiday?.updateholidaylist
  );

  useEffect(() => {
    if (addHolidayData?.success === true) {
      form.resetFields();
    }
  }, [addHolidayData]);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const tomorrowFormatted = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const onFinish = (datas) => {
    const payload = {
      date: datas?.date,
      note: datas?.note,
      doctor_id: doctorid,
      appointment_status: 1,
    };
    setHolidayData(payload);

    if (editHoliday) {
      dispatch(
        updateHolidaysListInProgress({ payload, id: editHolidayData.id })
      );
    } else {
      dispatch(addHolidaysListInProgress(payload));
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    if (!editHoliday) form.setFieldValue("date", tomorrowFormatted);
  }, []);
  useEffect(() => {
    if (addHolidayData?.success || editHolidayStatus?.success) {
      history.push("/holidays");
    } else if (addHolidayData?.error || editHolidayStatus?.error) {
      setLoading(false);
    }
  }, [addHolidayData, editHolidayStatus]);
  useEffect(() => {
    if (editHoliday) {
      form.setFieldValue("date", editHolidayData?.date);
      form.setFieldValue("note", editHolidayData?.note);
    }
  }, [editHoliday]);
  const handleOk = () => {
    if (addHolidayData?.data === "1") {
      setIsModalOpen(false);
    } else if (addHolidayData?.error) {
      const payload = {
        date: holidayData?.date,
        note: holidayData?.note,
        doctor_id: doctorid,
        appointment_status: 2,
      };
      if (editHoliday) {
        dispatch(
          updateHolidaysListInProgress({ payload, id: editHolidayData.id })
        );
      } else {
        dispatch(addHolidaysListInProgress(payload));
      }
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (addHolidayData?.data === "1") {
      setIsModalOpen(false);
    } else if (addHolidayData?.error) {
      setIsModalOpen(true);
    }
  }, [addHolidayData]);
  useEffect(() => {
    if (addHolidayData?.progressing || editHolidayStatus?.progressing) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addHolidayData, editHolidayStatus]);
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <Auxiliary>
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            form={form}
            onFinish={onFinish}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {editHoliday ? (
                    <IntlMessages id={"label.editHoliday"} />
                  ) : (
                    <IntlMessages id={"label.addHoliday"} />
                  )}
                </Typography>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="date"
                  min={disablePastDate()}
                  // defaultValue={tomorrowFormatted}
                  placeholder="Date"
                  name="date"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.holiday.label.date"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.holiday.validator.date"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Note"
                  name="note"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.holiday.label.note"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.holiday.validator.note"} />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row className="space">
              <Col className="btn-pos">
                <Link to={"/holidays"}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="secondary-color mr-10"
                  />
                </Link>
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  label={editHoliday ? "Update" : "Submit"}
                />
              </Col>
            </Row>
          </Form>
        </Card>{" "}
        <Modal
          // title="Delete Data"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
        >
          {" "}
          <Spin indicator={antIcon} spinning={loading}>
            <p>{addHolidayData?.message}</p>
          </Spin>
        </Modal>
      </Auxiliary>
    </Spin>
  );
};

export default HolidayAddForm;
