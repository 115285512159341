import { DeleteFilled, EditFilled, LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Spin, Tooltip, Typography } from "antd";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import { Link } from "react-router-dom";
import TableComponent from "../../../components/Table/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePackageInProgress,
  getMainPackagesListInProgress,
} from "../../../appRedux/doctor/actions/billingPackage";
import { access, formatPrice } from "../../../util/utilz";

const BillingpackageTable = () => {
  const [deleteId, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const organization_id = localStorage.getItem("org_id");
  const [navigate, setNavigate] = useState(false);
  const { billingPackages, deletePackage } = useSelector((state) => state);
  const billingPackageListStatus = billingPackages?.mainPackagesList;
  const totalRecords = billingPackages?.mainPackagesList?.data?.totalRecords;
  const deletePackageData = billingPackages?.deletePackage;

  const handleOk = () => {
    setIsModalOpen(false);
    const paylod = {
      id: deleteId,
    };
    dispatch(deletePackageInProgress(paylod));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  useEffect(() => {
    const paylod = {
      organization_id: organization_id,
      pageNo: 1,
    };
    dispatch(getMainPackagesListInProgress(paylod));
  }, []);

  useEffect(() => {
    if (billingPackages?.deletePackage?.success && navigate) {
      window.location.reload();
      setNavigate(false);
    }
  }, [billingPackages?.deletePackage?.success && navigate]);
  const handlePage = (page) => {
    const paylod = {
      organization_id: organization_id,
      pageNo: page,
    };
    dispatch(getMainPackagesListInProgress(paylod));
  };

  const columns = [
    {
      title: "Package Name",
      dataIndex: "package_name",
    },
    {
      title: "Packages",
      dataIndex: "subPackages",
      render: (subPackages) =>
        subPackages.map((Package, i) => {
          return <p key={i}>{`${i + 1}. ${Package?.package_name}`}</p>;
        }),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      render: (data) => formatPrice(data),
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="btn-edit">
            {access("billingPackage", "edit") && (
              <Col>
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  <Link
                    to={{
                      pathname: APP_ROUTES.EDITBILLINGPACKAGES,
                      state: record,
                    }}
                  >
                    <ButtonComponent icon={<EditFilled />} type="primary" />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("billingPackage", "delete") && (
              <Col span={2}>
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    <ButtonComponent
                      icon={<DeleteFilled />}
                      type="danger"
                      onClick={() => showModal(record.id)}
                    />
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];
  useEffect(() => {
    if (deletePackageData?.success) {
      const paylod = {
        organization_id: organization_id,
        pageNo: 1,
      };
      dispatch(getMainPackagesListInProgress(paylod));
    }
  }, [deletePackageData]);
  useEffect(() => {
    if (billingPackageListStatus?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [billingPackageListStatus]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <TableComponent
        columns={columns}
        type={"billingPackages"}
        dataSource={billingPackages?.mainPackagesList?.data?.data}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        documentTitle={"Billing Packages List"}
      />{" "}
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default BillingpackageTable;
