import React, { useState } from "react";
import HolidayAddForm from "./HolidaysAddForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const HolidaysEditForm = () => {
  const location = useLocation();
  const editHolidayData = location.state?.holidayData;

  return (
    <HolidayAddForm editHoliday="true" editHolidayData={editHolidayData} />
  );
};

export default HolidaysEditForm;
