import { LoadingOutlined } from "@ant-design/icons";
import {  Col, Form, Input, Row, Spin } from "antd";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileInProgress,
  updateUserProfileProgress,
} from "../../../appRedux/doctor/actions/Profile";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";
import CitySelector from "../../Doctor/component/CitySelector";
import CountrySelector from "../../Doctor/component/CountrySelector";
import DoctorSelectBox from "../../Doctor/component/DoctorSelectBox";
import StateSelector from "../../Doctor/component/StateSelector";
import { APP_ROUTES } from "../../../constants/Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormItem from "antd/es/form/FormItem";
import {
  editOrganisationInProgress,
  getOrgDetailsByIdInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";

const ProfileForm = () => {
  const [double, setDouble] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [country, setCountry] = useState("");
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const loginid = localStorage.getItem("id");
  const [photo123, setphoto123] = useState(null);
  const org_id = localStorage.getItem("org_id");
  const roleId = localStorage.getItem("role_id");
  const [image, setImage] = useState(null);
  const userid = localStorage.getItem("user_id");
  const password = localStorage.getItem("pass");
  const { getprofile } = useSelector((state) => state.profile);
  const getprofiledata = getprofile?.data;
  const role_id = getprofile?.data?.role_id;
  const role_name = getprofile?.data?.role_name;
  const { updateprofile } = useSelector((state) => state.profile);
  const orgDetails = useSelector(
    (state) => state?.superAdmin?.getOrgDetailsById?.data?.data
  );
  const updateprofiledata = updateprofile?.data;
  const [number, setNumber] = useState();
  const changeItem = (value) => {
    setNumber(value);
  };

  const profileData = useSelector((state) => state.profile);
  const profileSuccess = profileData?.updateprofile;

  const dispatch = useDispatch();

  const onFinish = (datas) => {
    const handleSuccess = () => {
      history.push(APP_ROUTES.DASHBOARD);
    };
    let formData = new URLSearchParams();
    formData.append("firstName", datas.firstName);
    formData.append("lastName", datas.lastName);
    formData.append("email", datas.email);
    formData.append("contact", datas.contact);
    formData.append("address1", datas.address1);
    formData.append("address2", datas.address2);
    formData.append("city", datas.city);
    formData.append("state", datas.state);
    formData.append("country_code", datas.country_code);
    formData.append("password", "test@12");
    formData.append("role_id", role_id);
    formData.append("status", datas.status);
    formData.append("profile_image", image);

    if (roleId === 1) {
      const payload = {
        firstName: datas.firstName, //noo
        lastName: datas.name, //noo
        name: datas.name,
        email: datas.email,
        contact: datas.contact,
        alternate_contact: orgDetails.alternate_contact,
        address: datas.address,
        street: datas.street,
        city: datas.city,
        state: datas.state,
        country: datas.country,
        country_code: orgDetails.country_code,
        currency_code: orgDetails.currency_code,
        license_no: orgDetails.license_no,
        status: orgDetails?.status,
        password: datas.password, //noo
        subscription_id: datas?.Subscription?.subscription,
        start_date: orgDetails?.currentDate, /// doubt
        pricing: orgDetails?.Subscription?.pricing,
        periods: orgDetails?.Subscription?.periods,
        payment_status: "1", ///doubt
        role_id: 1,
      };
      dispatch(editOrganisationInProgress({ id: orgDetails?.id, payload }));
    } else {
      dispatch(
        updateUserProfileProgress({ formData, id: loginid, handleSuccess })
      );
    }
  };
  useEffect(() => {
    if (getprofile?.progressing) {
      setLoading(true);
    } else if (getprofile?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [getprofile?.progressing, getprofile?.success]);

  useEffect(() => {
    if (profileSuccess?.progressing) {
      setDouble(true);
    } else if (profileSuccess?.success) {
      setDouble(false);
    } else {
      setDouble(false);
    }
  }, [profileSuccess?.progressing === true, profileSuccess?.success === true]);

  const onChangeCountry = (value) => {
    setCountry(value);
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);


  useEffect(() => {
    if (getprofiledata) {
      form.setFieldsValue({
        firstName: getprofiledata?.firstName,
        lastName: getprofiledata?.lastName,
        email: getprofiledata?.email,
        contact: getprofiledata?.contact,
        address1: getprofiledata?.address1,
        address2: getprofiledata?.address2,
        org_name: getprofile?.data?.organization_name,
        status: getprofiledata?.status,
        role_name: getprofile?.data?.role_name,
        country_code: getprofiledata?.country_code,
        city: getprofiledata?.city,
        state: getprofiledata?.state,
      });
      form.setFieldValue("profile_url", getprofiledata?.profile_image);
      setphoto123(getprofiledata?.profile_image);
      setCountry(getprofiledata?.country_code);
      setCity(getprofiledata?.city);
      setState(getprofiledata?.state);
    }
  }, [form, getprofiledata]);

  useEffect(() => {

    dispatch(getUserProfileInProgress());
    if (roleId === 1) {
      dispatch(getOrgDetailsByIdInProgress(org_id));
    }
  }, [updateprofiledata]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }
  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
          onFinish={onFinish}
        >
          <Row>
            {roleId === 1 ? (
              <>
                {" "}
                <Col
                  xl={23}
                  lg={23}
                  md={23}
                  sm={23}
                  xs={23}
                  className="justify-center"
                >
                  <Col className="img">
                    <img className="img-btn" src={photo123} />
                  </Col>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormItem
                    label="Profile"
                    rules={[
                      {
                        required: true,
                        message: "Please upload your image!",
                      },
                    ]}
                    name="profile_url"
                    valuePropName="fileList"
                  >
                    <Input
                      type={"file"}
                      accept="image/*"
                      name="profile_url"
                      onChange={(e) => encodeImageFileAsURL(e)}
                    />
                  </FormItem>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="First Name"
                name="firstName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={
                  <IntlMessages id={"Profile.myprofile.label.firstName"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.firstName"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Last Name"
                name="lastName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.lastName"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.lastName"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Email"
                name="email"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.email"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Profile.myprofile.validator.email"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Contact"
                name="contact"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.contact"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.contact"}
                      />
                    ),
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Address 1"
                name="address1"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.address1"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.address1"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Address 2"
                name="address2"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.address2"} />}
                rules={[
                  {
                    required: false,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.address2"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CountrySelector
                label={"Country Name"}
                name={"country_code"}
                onChange={onChangeCountry}
                country={country}
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <StateSelector
                label={"State"}
                name={"state"}
                onChange={onChangeState}
                country={state}
                countryId={country?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your state!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CitySelector
                label={"City"}
                name={"city"}
                onChange={onChangeCity}
                country={city}
                StateId={state?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your state!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Role Name"
                name="role_name"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.roleName"} />}
                readOnly={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DoctorSelectBox
                name="status"
                label={"Status"}
                rules={[
                  {
                    required: true,
                    message: "Please select status!",
                  },
                ]}
                options={[
                  {
                    value: "1",
                    label: "Active",
                  },
                  {
                    value: "2",
                    label: "Pending",
                  },
                  {
                    value: "3",
                    label: "Disabled",
                  },
                  {
                    value: "4",
                    label: "Not-verified",
                  },
                ]}
                disabled
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Organization Name"
                name="org_name"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.orgName"} />}
                readOnly={true}
              />
            </Col>
          </Row>
          <Row className="space">
            <Col className="btn-pos">
              {/* <ButtonComponent type="danger" htmlType="reset" label="Reset" /> */}
              <ButtonComponent
                type="primary"
                htmlType="submit"
                label="Update Profile"
                loading={double}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Auxiliary>
  );
};

export default ProfileForm;
