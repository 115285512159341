import React from "react";

import { Button, Card, Col, Divider, Form, Input, Row, Spin } from "antd";
import IntlMessages from "../../util/IntlMessages";
import Auxiliary from "../../util/Auxiliary";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ForgetPasswordInProgress,
  showAuthLoader,
} from "../../appRedux/doctor/actions/Auth";
import FormItem from "antd/es/form/FormItem";
import { APP_ROUTES } from "../../constants/Routes";
import { LoadingOutlined } from "@ant-design/icons";

const ConfirmPasswordScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getprofile } = useSelector((state) => state.profile);
  const ReportList = useSelector((state) => state);

  const passwordloading=ReportList?.register?.forgetpassword?.inProgress


  const email = localStorage.getItem("email");
  const onFinishFailed = (errorInfo) => {};
  const onFinish = (values) => {
    dispatch(showAuthLoader());
    const handleSuccess = () => {
      history.push(APP_ROUTES.DASHBOARD);
    };
    let formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", values.password);
const payload={
  formData,
  handleSuccess
}
    dispatch(ForgetPasswordInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={passwordloading}>
    <Card>
      <div>
        <Row className="p-10 Add-button">
          <Col>
            <p className="header-tag">
              {<IntlMessages id={"Addnew.changepassword"} />}
            </p>
          </Col>
        </Row>

        <Divider />
      </div>
      <Row>
        <div className="gx-app-login-content">
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="gx-signin-form gx-form-row0"
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Re-Type Password" />
            </Form.Item>
            <Row>
              <Col span={24}>
                <FormItem>
                  <Button type="primary" className="gx-mb-4" htmlType="submit">
                    change password
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
    </Card>
    </Spin>
    </Auxiliary>
  );
};

export default ConfirmPasswordScreen;
