/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */

import axios from "axios";
import { getAccessToken } from "../appRedux/doctor/sagas/effects/AuthEffect";

// import store from "../../src/store/store";

// import { setAlertInfo } from "./Common.slice";

export const HandleAxiosRequest = async (config) => {
  try {
    const token = localStorage.getItem("user_id");
    const organization_id = localStorage.getItem("org_id");
    // config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["organizationId"] = organization_id;

    // config.data = config.data;
    console.log("config", config);
    return config;
  } catch (error) {
    console.log("e", error);
  }
};
export const HandleAxiosResponse = async (response) => {
  try {
    const requestedData = response.config.data;
    response.data = response?.data;
    console.log("response", response?.data);
    if (response?.data?.generateRefreshToken) {
      localStorage.setItem("rt", response?.data?.generateRefreshToken);
    }
    const url = response.config.url;
    return response;
  } catch (error) {
    console.log("e", error);
  }
};

let tokenRefreshPromise = null;

async function handleTokenRefresh() {
  if (!tokenRefreshPromise) {
    const token = { token: localStorage.getItem("rt") };
    tokenRefreshPromise = getAccessToken(token)
      .then((response) => {
        const { data } = response;
        localStorage.setItem("user_id", data.accessToken);
        return data.accessToken;
      })
      .finally(() => {
        tokenRefreshPromise = null;
      });
  }
  return tokenRefreshPromise;
}
export const HandleAxiosResponseWithError = async (error) => {
  try {
    console.log("e", error);
    if (error?.response?.status === 403 && !error.config._retry) {
      const originalRequest = error.config;
      originalRequest._retry = true;
      try {
        const newAccessToken = await handleTokenRefresh();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        const response = await axios.request(originalRequest);
        HandleAxiosResponse(response);
        return response;
      } catch (tokenRefreshError) {
        // Handle token refresh error, e.g., redirect to login
        return Promise.reject(tokenRefreshError);
      }
    }

    if (error?.response?.status === 400) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  } catch (e) {
    console.log("e", e);
    return Promise.reject(error);
  }
};
