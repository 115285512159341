import { Row, Col, Typography, Table } from "antd";
import React, { useEffect, useState } from "react";

import "../../../../styles/modules/pharmacy.less";

import { useHistory } from "react-router-dom";

import ViewHeader from "../../view/ViewHeader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { salesTypeList } from "../../../Doctor/data";
import SalesFooter from "../SalesFooter";

import { useDispatch, useSelector } from "react-redux";
import { getPharmacyProfileInProgress } from "../../../../appRedux/Pharmacy/PharmacyAction";

const ViewSalesReturn = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const salesReturnData = location.state?.salesReturnData;
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const { pharmacyProfile } = useSelector((state) => state?.pharmacy);
  const [printingDone, setPrintingDone] = useState(false);
  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);
  useEffect(() => {
    if (
      location.state?.print &&
      !printingDone &&
      salesReturnData &&
      pharmacyProfile?.data
    ) {
      window.print();
      setPrintingDone(true);
    }
  }, [
    location.state?.print,
    printingDone,
    salesReturnData,
    pharmacyProfile?.data,
  ]);
  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      render: (_, __, index) => index + 1,
    },
    {
      title: "ProductName",
      dataIndex: "product_name",
      key: "productName",
    },
    {
      title: "BatchNo",
      dataIndex: "batch_no",
      key: "batchNo",
    },
    {
      title: "Price",
      dataIndex: "unit_price",
      key: "expiryDate",
    },

    {
      title: "GST",
      dataIndex: "gst",
      key: "GST",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Retutn Qunatity",
      dataIndex: "return_quantity",
      key: "totalAmount",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      render: (_, record) => {
        return Number(record?.return_quantity) * Number(record?.unit_price);
      },
    },
  ];

  return (
    <Row justify="center" align="middle">
      <Col xl={23} lg={23} md={23} sm={24} xs={24}>
        <Row className="margin-row" gutter={[0, 16]}>
          <ViewHeader
            headerType={"salesReturn"}
            headerData={salesReturnData}
            profileData={pharmacyProfile?.data?.data}
          />
          <Col xl={23} lg={23} md={23} sm={24} xs={24} className="ml-15">
            <Row className="margin-row ml-10 justify-space">
              <Col>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                    <Typography className="view-list-details py-5 text-warp">
                      Patient : {salesReturnData?.patient_name}
                    </Typography>
                    <Typography className="view-list-details py-5 text-warp">
                      Mobile : {salesReturnData?.Sale?.patient_mobile}
                    </Typography>
                    <Typography className="view-list-details py-5 text-warp">
                      Type &nbsp; &nbsp;:&nbsp;
                      {
                        salesTypeList.find(
                          (data) =>
                            Number(data.value) ===
                            Number(salesReturnData?.Sale?.patient_type)
                        )?.label
                      }
                    </Typography>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                    <Typography className="view-list-details py-5 text-warp">
                      Doctor : {salesReturnData?.doctor_name}
                    </Typography>
                    <Typography className="view-list-details py-5 text-warp">
                      Sales Return Id : {salesReturnData?.sales_return_code}
                    </Typography>
                    <Typography className="view-list-details py-5 text-warp">
                      Date :{" "}
                      {moment(salesReturnData?.return_date).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="margin-row">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Typography className="products">Products</Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Table
                  columns={columns}
                  dataSource={salesReturnData?.sales_return_details}
                  pagination={false}
                  className="responsive-table"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} className="footer-total">
            <SalesFooter
              subtotal={salesReturnData?.sub_total}
              discount={salesReturnData?.discount}
              receivedAmount={salesReturnData?.deposits}
              NeedToPay={salesReturnData?.need_to_pay}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewSalesReturn;
