import { axiosInstance } from "../../../../util/utilz";

export const getUsersListEffect = (token) => {
  return axiosInstance.request({
    url: "/admin/userList?pageNo=1&pagesize=20&pagination_required=false",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteUsersListEffect = (data, token) => {
  return axiosInstance.request({
    url: `/admin/userDelete/${data}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getUserUpdateListEffect = (data, token) => {
  const orgId = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/user/details/${data}?organization_id=${orgId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
