import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

export const getHolidayListEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: "/holiday/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
    params: {
      pageNo: data,
      pageSize: 10,
      isPrev: 0,
      pagination_required: true,
    },
  });
};

export const deleteHolidayListEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/holiday/delete/${data}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};

export const addHolidayListEffect = (formData, token) => {
  const tokens = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/holiday/add/",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateHolidayListEffect = ({ payload, id }) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/holiday/update/${id}`,
    method: "PUT",
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getHolidayUpdateListEffect = (data, token) => {
  const tokens = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/holiday/details/${data}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
