import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  DELETE_USERS_LIST_PROGRESS,
  GET_USERS_LIST_PROGRESS,
  GET_USER_UPDATE_LIST_PROGRESS,
} from "../../../constants/ActionTypes";

import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  deleteUsersListInError,
  deleteUsersListInSuccess,
  getUserListInError,
  getUserListInSuccess,
  getUserUpdateListError,
  getUserUpdateListSuccess,
} from "../actions/User";
import {
  deleteUsersListEffect,
  getUsersListEffect,
  getUserUpdateListEffect,
} from "./effects/User";

function* getUsersListSaga() {
  try {
    const token = localStorage.getItem("user_id");
    const { data, status } = yield call(getUsersListEffect, token);

    if (status == "500") {
      message.warning("Please try again later!");
    }
    yield put(
      getUserListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getUserListInError({ message: getErrorMessageFromAPI(e), error: true })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* deleteUsersList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(deleteUsersListEffect, payload, token);

    yield put(
      deleteUsersListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deleteUsersListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* GetUserUpdateListSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getUserUpdateListEffect, payload, token);

    yield put(
      getUserUpdateListSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getUserUpdateListError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

export function* UsersSaga() {
  yield takeLatest(GET_USERS_LIST_PROGRESS, getUsersListSaga);
  yield takeLatest(DELETE_USERS_LIST_PROGRESS, deleteUsersList);
  yield takeLatest(GET_USER_UPDATE_LIST_PROGRESS, GetUserUpdateListSaga);
}
