import React, { useEffect, useState } from "react";

import { getpatientListAppointmentEffect } from "../../appRedux/Patient/Sagas/Effect/patientAppointmentEffect";
import SelectBoxAppointment from "./SelectBox";
const SelectorsAppointment = ({
  placeholder = "Select patient",
  label = "patient",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
  onChange,
  getApiData,
  className,
  disabled,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getStatusList();
  }, []);
  const getStatusList = async () => {
    try {
      setLoading(true);
      const { data } = await getpatientListAppointmentEffect();
      const patientList = data?.data;

      // const vardata = [data];
      let options = patientList.map((row) => ({
        label: `${row.firstName}  ${row.contact ? -row.contact : ""}`,
        value: row.id,
      }));
      setOptions(options);

      // getApiData(data);
      setLoading(false);
    } catch (error) {
      setOptions([]);
      // getApiData();
      setLoading(false);
    }
  };
  return (
    <SelectBoxAppointment
      loading={loading}
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
      onChange={onChange}
      className={className}
      disabled={disabled}
    />
  );
};
export default SelectorsAppointment;
