import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

export const getFamilyMemberListEffect = (patient_id) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/familyMember/allListByPatientId?pagination_required=false&pageNo=1&pagesize=10&patient_id=${patient_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
