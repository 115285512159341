import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddRoom from "./AddRoom";

const EditRoom = () => {
  const location = useLocation();
  const roomData = location.state?.roomData;

  return <AddRoom editRoomData={roomData} editRoom={"true"} />;
};

export default EditRoom;
