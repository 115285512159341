import { LoadingOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Spin } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "antd";

import {
  getDataUpdateInProgress,
  updateDoctorListInProgress,
} from "../../../appRedux/admin/Doctor/actions/Doctors";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";

import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";

import CitySelector from "./CitySelector";
import CountrySelector from "./CountrySelector";
import DesignationSelector from "./DesignationSelector";
import OrganizationSelector from "./OrganizationSelector";
import QualificationSelector from "./QualificationSelector";
import SpecializationSelector from "./SpecializationSelector";
import StateSelector from "./StateSelector";
import { APP_ROUTES } from "../../../constants/Routes";

const DoctorEditForm = () => {
  const [double, setDouble] = useState(false);
  const { Option } = Select;
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [photo123, setphoto123] = useState(null);
  const [country, setCountry] = useState("");
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const [form] = Form.useForm();
  const history = useHistory();
  const { getupdatelist } = useSelector((state) => state.doctor);

  const getupdatelists = getupdatelist?.data;
  const getdoctorprofile = getupdatelist?.data?.profile_image;

  const { updatelist } = useSelector((state) => state.doctor);
  const updatelists = updatelist.data.DoctorsInfo;
  const getUpdateDoctorProfile = updatelists?.profile_url;

  const userid = getupdatelist?.data?.user_id;
  const roleid = getupdatelist?.data?.role_id;

  const loginid = localStorage.getItem("id");

  const [number, setNumber] = useState();
  const changeItem = (value) => {
    setNumber(value);
  };
  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }
  const onChangeCountry = (value) => {
    setCountry(value);
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);

  const doctorData = useSelector((state) => state.doctor);
  const doctorSuccess = doctorData?.updatelist;

  const dispatch = useDispatch();
  const id = useParams();

  const onFinish = (datas) => {
    const handleSuccess = () => {
      history.push(APP_ROUTES.DOCTOR);
    };
    let formData = new URLSearchParams();
    formData.append("firstName", datas.firstName);
    formData.append("lastName", datas.lastName);
    formData.append("dob", datas.dob);
    formData.append("email", datas.email);
    formData.append("contact", datas.contact);
    formData.append("address1", datas.address1);
    formData.append("address2", datas.address2);
    formData.append("city", datas.city);
    formData.append("state", datas.state);
    formData.append("country_code", datas.country_code);
    formData.append("qualification", datas.qualification);
    formData.append("license_no", datas.license_no);
    formData.append("user_id", userid);
    formData.append("role_id", roleid);
    formData.append("organization_id", datas.organization_id);
    formData.append("specialization_id", datas.specialization_id);
    formData.append("designation_id", datas.designation_id);
    formData.append("profile_image", image);
    formData.append("gender_type", datas.gender_type);

    dispatch(
      updateDoctorListInProgress({ formData, id: id.id, handleSuccess })
    );
  };
  useEffect(() => {
    dispatch(getDataUpdateInProgress(id.id));
  }, []);
  useEffect(() => {
    // Check if getupdatelist and its data exist
    if (getupdatelist && getupdatelist.data) {
      const initialValues = {
        id: getupdatelists.id,
        firstName: getupdatelists.firstName,
        lastName: getupdatelists.lastName,
        dob: getupdatelists.dob,
        email: getupdatelists.email,
        contact: getupdatelists.contact,
        address1: getupdatelists.address1 || "",
        address2: getupdatelists.address2 || "",
        city: getupdatelists.city,
        state: getupdatelists.state,
        country_code: getupdatelists.country_code,
        qualification: getupdatelists.qualification,
        license_no: getupdatelists.license_no,
        organization_id: getupdatelists.organization_id,
        specialization_id: getupdatelists.specialization_id,
        designation_id: getupdatelists.designation_id,
        gender_type: getupdatelists.gender_type,
      };

      form.setFieldsValue(initialValues);

      // Set the profile image if it exists
      if (getupdatelists.profile_image) {
        setphoto123(getupdatelists.profile_image);
      }
    }
  }, [getupdatelist, form]);

  useEffect(() => {
    if (getupdatelist?.progressing) {
      setLoading(true);
    } else if (getupdatelist?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [getupdatelist?.progressing, getupdatelist?.success]);

  useEffect(() => {
    if (updatelist?.progressing) {
      setDouble(true);
    } else if (updatelist?.success) {
      setDouble(false);
    } else {
      setDouble(false);
    }
  }, [updatelist?.progressing, updatelist?.success]);

  const Back = () => {
    history.push("/doctor");
  };
  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <Auxiliary>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col className="img">
              <img
                className="img-btn"
                name="profile_image"
                src={photo123}
              ></img>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Profile"
                rules={[
                  {
                    required: false,
                    message: "Please upload your image!",
                  },
                ]}
                name="image"
                valuePropName="fileList"
              >
                <Input
                  type={"file"}
                  name="image"
                  accept="image/*"
                  onChange={(e) => encodeImageFileAsURL(e)}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="FirstName"
                name="firstName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.firstname"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.firstname"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="LastName"
                name="lastName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.lastname"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.lastname"} />
                    ),
                  },
                ]}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="date"
                placeholder="DOB"
                name="dob"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.dob"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.dob"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Email"
                name="email"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.email"} />}
                rules={[
                  {
                    type: "email",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.emailtype"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.email"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Contact"
                name="contact"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.contact"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.contact"} />
                    ),
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Address 1"
                name="address1"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.address1"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address1"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address1"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Address 2"
                name="address2"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.address2"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address2"} />
                    ),
                  },
                  {
                    required: false,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address2"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CountrySelector
                label={"Country Name"}
                name={"country_code"}
                onChange={onChangeCountry}
                country={country}
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <StateSelector
                label={"State"}
                name={"state"}
                onChange={onChangeState}
                country={state}
                countryId={country?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your state!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CitySelector
                label={"City"}
                name={"city"}
                onChange={onChangeCity}
                country={city}
                StateId={state?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your city!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <QualificationSelector
                margin="mb-30"
                label={"Qualification"}
                name={"qualification"}
                placeholder={"Select Your Qualification"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your qualification",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="License No"
                name="license_no"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.license_no"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.license_no"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.license_no"} />
                    ),
                  },
                ]}
              />
            </Col>

            <Col xs={0}>
              <OrganizationSelector
                margin="mb-30"
                label={"Organization"}
                name={"organization_id"}
                placeholder={"Select Your Organization"}
                editMode={false}
                rules={[
                  {
                    required: true,
                    message: "Please select your organization",
                  },
                ]}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <SpecializationSelector
                margin="mb-30"
                label={"Specialization"}
                name={"specialization_id"}
                placeholder={"Select Your Specialization"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your specialization",
                  },
                ]}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DesignationSelector
                margin="mb-30"
                label={"Designation"}
                name={"designation_id"}
                placeholder={"Select Your Designation"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your designation",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Gender"
                name="gender_type"
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Select placeholder="Select gender">
                  {genderOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <FormInput name="id" type="hidden" />
          {/* <FormInput name="user_id" type="hidden" /> */}
          {/* <Row className="space">
            <Col className="btn-pos">
              <ButtonComponent type="danger" onClick={Back} label="Back" />
              <ButtonComponent
                type="primary"
                htmlType="submit"
                label="Update"
                loading={double}
              />
            </Col>
          </Row>{" "} */}
          <Row className="button-row">
            <Col className="register-button">
              {" "}
              <ButtonComponent
                label="Cancel"
                className="btn-cancel secondary-color"
                onClick={Back}
              />
              <ButtonComponent
                type="primary"
                label="Submit"
                htmlType="submit"
              />
            </Col>
          </Row>
        </Form>
      </Auxiliary>
    </Spin>
  );
};

export default DoctorEditForm;
