import React, { useState } from "react";
import { Col, Row, Divider, Button, Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../constants/Routes";
import "../../../../styles/modules/patient.less";
import IntlMessages from "../../../../util/IntlMessages";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import PatientCSVfile from "../../patientCSVfile";
import { access } from "../../../../util/utilz";

const PatientHeader = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{<IntlMessages id={"Patients"} />}</p>
        </Col>
        {access("patients", "add") && (
          <Col>
            <Link to={APP_ROUTES.ADDNEWPATIENT}>
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.patient.addnew"} />}
                icon={<PlusCircleOutlined />}
              />
            </Link>
            <Button
              className="secondary-color"
              onClick={showModal}
              style={{ marginLeft: "10px" }}
            >
              Import CSV
            </Button>
            <Modal
              open={open}
              title="Add New"
              onOk={handleOk}
              onCancel={handleCancel}
              footer={
                [
                  // <Button key="back" onClick={handleCancel}>
                  //   Cancel
                  // </Button>,
                  // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                  //   Submit
                  // </Button>
                ]
              }
            >
              <PatientCSVfile handleOk={handleOk} />
            </Modal>
          </Col>
        )}
      </Row>

      <Divider />
    </div>
  );
};

export default PatientHeader;
