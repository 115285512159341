import { Row, Col, Typography, Avatar } from "antd";
import React from "react";
import "../../../styles/modules/pharmacy.less";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import {
  ArrowLeftOutlined,
  EnvironmentFilled,
  PhoneFilled,
  PrinterFilled,
  UserOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

const ViewHeader = ({
  headerType,
  headerData,
  profileData,
  printingDone,
  setPrintingDone,
}) => {
  const history = useHistory();
  const goBackHandler = () => {
    history.goBack();
  };
  const printHandler = () => {
    setPrintingDone(false);
  };

  return (
    <>
      <Col xl={18} lg={18} md={14} sm={14} xs={24}>
        {/* <Typography className="view-heading view-grid ">
          {headerType === "request"
            ? "Purchase Request"
            : headerType === "return"
            ? "Purchase Return"
            : headerType === "purchase"
            ? "Purchase"
            : headerType === "Supplier"
            ? "Supplier"
            : headerType === "sales"
            ? "Sales"
            : "Sales Return"}
        </Typography> */}
      </Col>
      <Col xl={6} lg={6} md={10} sm={10} xs={24}>
        {(headerType != "request" || headerType != "return") && (
          <>
            {printingDone && (
              <Row
                // style={{ marginLeft: 0, marginRight: 0 }}
                justify="end"
                className="view-grid"
              >
                {/* <ButtonComponent
                  type="primary"
                  icon={<DownloadOutlined />}
                ></ButtonComponent> */}
                <ButtonComponent
                  type="success"
                  icon={<PrinterFilled />}
                  className={"print-button"}
                  onClick={printHandler}
                ></ButtonComponent>

                <ButtonComponent
                  type="danger"
                  icon={<ArrowLeftOutlined />}
                  onClick={goBackHandler}
                ></ButtonComponent>
              </Row>
            )}
          </>
        )}
      </Col>
      <Col xl={4} lg={4} md={4} xs={4} sm={4} align="top">
        {/* <img src={profileData?.profile_image} width={150} /> */}
        <Avatar
          size={80}
          icon={<UserOutlined />}
          src={profileData?.profile_image}
          shape="circle"
        />
      </Col>
      <Col xl={12} lg={12} md={12} xs={24} sm={24}>
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
          }}
          justify="center"
          gutter={[0, 10]}
        >
          <Col xl={20} lg={20} md={20} xs={24} sm={24} align="center">
            <Typography align="center" className="view-name">
              {profileData?.organization_name}
            </Typography>
          </Col>
          <Col xl={20} lg={20} md={20} xs={24} sm={24}>
            <Typography align="center" justify="center">
              <EnvironmentFilled />
              {profileData?.address}, {profileData?.city},{profileData?.state}
            </Typography>
          </Col>
          <Col xl={20} lg={20} md={20} xs={24} sm={24}>
            <Typography align="center">
              <PhoneFilled />
              {profileData?.contact}
            </Typography>
          </Col>
        </Row>
      </Col>
      {headerType != "salesReturn" && (
        <Col xl={8} lg={8} md={8} xs={24} sm={10}>
          <Row gutter={[8, 8]} className="ml-10">
            {(headerType === "salesReturn" || headerType != "return") && (
              <>
                {headerData?.sales_code && (
                  <>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className="view-list-details">
                        Bill No
                      </Typography>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className="view-list-details">
                        : {headerData?.sales_code}
                      </Typography>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="view-list-details">Date</Typography>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="view-list-details">
                :
                {moment(
                  headerData?.sales_date ||
                    headerData?.return_date ||
                    headerData?.date
                ).format("DD-MM-YYYY")}
              </Typography>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="view-list-details">Timing</Typography>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="view-list-details">
                {/* : {moment(profileData?.createdAt).format("HH:MM:SS")}{" "} */}
                : {moment(profileData?.createdAt).format("hh:mm a")}
              </Typography>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="view-list-details">DL Number</Typography>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="view-list-details">
                : {profileData?.DL_license_no}
              </Typography>
            </Col>
            {headerData?.gstNumber && (
              <>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className="view-list-details">
                    GST Number
                  </Typography>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className="view-list-details">
                    : {headerData?.gstNumber}
                  </Typography>
                </Col>
              </>
            )}
          </Row>
        </Col>
      )}
    </>
  );
};

export default ViewHeader;
