import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, TimePicker, Radio } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import moment from "moment";
import { weekDayScheduleInProgress } from "../../../appRedux/doctor/actions/Schedule";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Interval } from "../../../constants/Schedule";

const ScheduleForm = ({
  title,
  onFinish,
  buttonName,
  form,

  startTime,
  endTime,
}) => {
  const dispatch = useDispatch();
  const [weekday, setWeekDay] = useState("");
  const [interval, setInterval] = useState("");
  const dayList = useSelector((state) => state?.schedule);
  const weekDay_list = dayList?.weekDaySchedule?.data?.data;

  const data_weeks =
    weekDay_list &&
    weekDay_list.map((mapdata) => ({
      // id: mapdata.id,
      label: mapdata.day,
      value: mapdata.id,
    }));

  const changeWeekday = (value) => {
    setWeekDay(value);
  };
  const changeInterval = (value) => {
    setInterval(value);
  };
  const onChangestartTime = (time, timeString) => {
    var start_time = moment(time.toString()).format("HH:mm:ss");
  };

  const onChangeEndTime = (time, timeString) => {
    var end_time = moment(time.toString()).format("HH:mm:ss");
  };
  useEffect(() => {
    dispatch(weekDayScheduleInProgress());
  }, []);

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  // const default_start_time = false;
  // const default_end_time = false;
  // useEffect(() => {
  //   console.log("editData[0]", editData[0]);
  //   const data = editData[0];
  //   form.setFieldsValue({ data });
  //   form.setFieldsValue({
  //     week_day_id: "Test", //working
  //   });
  // }, [editData]);
  const worker = {
    start_time: moment("2020-06-09T12:40:14+0000"),
  };
  return (
    <Card>
      <h1>{<IntlMessages id={title} />}</h1>
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          worker,
        }}
        colon={"true"}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              label={<IntlMessages id={"Addnew.schedule.label.day"} />}
              name={"week_day_id"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.schedule.validator.day"} />
                  ),
                },
              ]}
            >
              <FormSelectBox
                placeholder="Select Week"
                value={weekday}
                options={data_weeks ? data_weeks : []}
                onChange={changeWeekday}
                className="responsive-select"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              label={<IntlMessages id={"Addnew.schedule.label.startTime"} />}
              name={"start_time"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.schedule.validator.startTime"} />
                  ),
                },
              ]}
            >
              <TimePicker
                onChange={onChangestartTime}
                // defaultValue={
                //   default_start_time
                //     ? moment(default_start_time, "HH:mm:ss")
                //     : ""
                // }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              label={<IntlMessages id={"Addnew.schedule.label.endTime"} />}
              name={"end_time"}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.schedule.validator.endTime"} />
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("start_time") < value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Expired Date should be greater than add date!")
                    );
                  },
                }),
              ]}
              // rules={[
              //   {
              //     required: true,
              //     message: (
              //       <IntlMessages id={"Addnew.schedule.validator.endTime"} />
              //     ),
              //   },
              // ]}
            >
              <TimePicker
                onChange={onChangeEndTime}
                // defaultValue={
                //   default_end_time ? moment(default_end_time, "HH:mm:ss") : ""
                // }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              label={<IntlMessages id={"Addnew.schedule.label.interval"} />}
              name={"interval"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.schedule.validator.interval"} />
                  ),
                },
              ]}
            >
              <FormSelectBox
                placeholder="Select Interval"
                value={interval}
                options={Interval}
                onChange={changeInterval}
                className="responsive-select"
              />
            </Form.Item>
          </Col>
        </Row>{" "}
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              label={<IntlMessages id={"Addnew.schedule.label.status"} />}
              name={"status"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.schedule.validator.status"} />
                  ),
                },
              ]}
            >
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={0}>Active</Radio>
                <Radio value={1}>Inactive</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="button-row">
          <Col className="register-button">
            <Link to={"/schedule"}>
              <ButtonComponent
                type="danger"
                label="Cancel"
                className="secondary-color"
              />
            </Link>

            <ButtonComponent
              type="primary"
              label={buttonName}
              htmlType="submit"
              style={{ marginLeft: "10px" }}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ScheduleForm;
