import React, { useEffect, useState } from "react";
import "../../styles/modules/admin.less";
import { Card, Col, Row, Spin, Table, Tooltip, Typography } from "antd";

import allpatients from "../../assets/images/DocMotez/patient_logo.png";
import todayprescription from "../../assets/images/DocMotez/todayprescription.png";
import allPrescription from "../../assets/images/DocMotez/allprescription.png";

import { LoadingOutlined, EyeOutlined } from "@ant-design/icons";

import UserCard from "../../components/card/UserCard";

import { useDispatch, useSelector } from "react-redux";
import {
  expiryStockInProgress,
  getListSalesInProgress,
  getPharmacyDashboardInProgress,
} from "../../appRedux/Pharmacy/PharmacyAction";
import moment from "moment";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../constants/Routes";
import { useHistory } from "react-router-dom";
import { seenPrescriptionInProgress } from "../../appRedux/doctor/actions/Prescription";

const PharmacyDashboardScreen = () => {
  const role_id = localStorage.getItem("role_id");
  const currentDate = moment();
  const formattedDate = currentDate.format("YYYY-MM-DD");
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const [Loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const orgId = localStorage.getItem("org_id");

  const pharmacyData = useSelector(
    (state) => state.pharmacy?.pharmacyDashboard
  );
  const salesData = useSelector((state) => state.pharmacy?.getSalesList);
  const expiryStockData = useSelector((state) => state.pharmacy?.expiryStock);
  const salesList = salesData?.data?.data;
  const expiryStockList = expiryStockData?.data?.data;
  const pharmacyCount = pharmacyData?.data?.count;

  const handleSeen = (record) => {
    if (Number(role_id) === 4 && Number(record?.pharmacy_status) === 2) {
      dispatch(seenPrescriptionInProgress({ prescriptionId: record?.id }));
    }
    history.push({
      pathname: `${APP_ROUTES.PRESCRIPTIONDATA}`,
      state: { record, prescriptions: "prescriptions" },
    });

    // ;
  };
  const salesColumn = [
    {
      title: "S.No",
      dataIndex: "sales_code",
      key: "soNo",
      width: 150,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      key: "patient_name",
      width: 300,
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      key: "doctorName",
      width: 300,
    },
    {
      title: "Medicine",
      dataIndex: "sales_details",
      key: "medicine",
      width: 300,
      render: (sales_details) =>
        sales_details.map((Package, i) => {
          return <p key={i}>{`${i + 1}. ${Package?.product_name}`}</p>;
        }),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => <>{Number(total).toFixed(2)}</>,
    },
    {
      title: "Paid",
      dataIndex: "deposite",
      key: "paid",
      render: (total) => <>{Number(total).toFixed(2)}</>,
    },
    {
      title: "Due",
      dataIndex: "due",

      width: 180,
    },
  ];
  const expiryStockColumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Product Name",
      dataIndex: "product_name",

      key: "product_name",
    },
    {
      title: "Batch No.",
      dataIndex: "batch_no",

      key: "batchNo",
    },

    {
      title: "Purchase Date",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),

      key: "createdAt",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",

      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
      key: "expiry_date",
    },
    {
      title: "Stock",
      dataIndex: "available_stock",

      key: "stock",
    },
  ];
  const prescriptionColumn = [
    {
      title: "patient Name",
      dataIndex: "patient_name",
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
    },

    {
      title: "pharmacy status",
      dataIndex: "pharmacy_status",
      render: (ps) => {
        return (
          <>
            {Number(ps) === 1
              ? "not sent"
              : Number(ps) === 2
              ? "doctor to Pharmacy"
              : Number(ps) === 3
              ? "Pharmacy seen"
              : "Tablets get by patients"}
          </>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {" "}
            <Col className="button-edit">
              {/* <Link
                to={{
                  pathname: `${APP_ROUTES.PRESCRIPTIONDATA}`,
                  state: { record },
                }}
              > */}
              <Tooltip placement="top" title={"View"}>
                <Typography>
                  <ButtonComponent
                    type="primary"
                    icon={<EyeOutlined />}
                    onClick={() => handleSeen(record)}
                  />
                </Typography>
              </Tooltip>

              {/* </Link> */}
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    const formData = { orgId: orgId, pagination: true, pageNo: 1 };
    dispatch(getPharmacyDashboardInProgress());
    // dispatch(getSalesListInProgress(formData));
  }, []);
  useEffect(() => {
    if (pharmacyData?.inProgress) {
      setLoading(true);
    } else if (pharmacyData?.success || pharmacyData?.error) {
      setLoading(false);
    }
  }, [pharmacyData]);
  useEffect(() => {
    const formData = { page: 1, orgId: orgId, pagination: true };
    dispatch(getListSalesInProgress(formData));

    dispatch(expiryStockInProgress({ formattedDate }));
  }, []);
  return (
    <Card>
      <Spin indicator={antIcon} spinning={Loading}>
        <Row gutter={[16, 16]}>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <UserCard
              heading="Total Sales"
              totalCount={pharmacyCount?.totalSales}
              image={allPrescription}
              headingColor={"headingColorGreen"}
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <UserCard
              heading="This Month Sales"
              totalCount={pharmacyCount?.thisMonthSales}
              image={allpatients}
              headingColor={"headingColorBlue"}
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <UserCard
              heading="Today Sales"
              totalCount={pharmacyCount?.todaySales}
              image={todayprescription}
              headingColor={"headingColorOrange"}
            />
          </Col>
        </Row>
        <Row className="px-0" gutter={[16, 16]}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="h4 gx-text-capitalize">Recent Prescription</h2>

            <Table
              showHeader={true}
              columns={prescriptionColumn}
              dataSource={pharmacyData?.data?.Prescription}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="h4 gx-text-capitalize">Recent Sales</h2>

            <Table
              showHeader={true}
              columns={salesColumn}
              dataSource={salesList}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="h4 gx-text-capitalize">Expiry Stock</h2>
            <Table
              showHeader={true}
              columns={expiryStockColumn}
              dataSource={expiryStockList}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default PharmacyDashboardScreen;
