import axios from "axios";
import moment from "moment";
import { axiosInstance, currentDate } from "../../util/utilz";
const token = localStorage.getItem("user_id");
const patient_id = localStorage.getItem("patient_id");
const organization_id = localStorage.getItem("org_id");
// const id = localStorage.getItem("id");

export const getPharmacyDashboardEffect = () => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/user/pharmacydashboard",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getPatientHealthRecordsEffect = () => {
  return axiosInstance.request({
    url: "/healthRecord/allList?pagination_required=true&pageNo=1&pagesize=10",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const GetTabletsEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/tablet/organization`,
    params: {
      organization_id: formData?.OrgId,
      pageNo: formData,
      pagesize: 100,
    },
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const TabletListEffect = (formData) => {
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: `/tablet/organization`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      organization_id: formData?.organization_id,
      pagesize: 10,
      pageNo: formData?.page,
      pagination_required: formData?.pagination_required
        ? formData?.pagination_required
        : true,
    },
  });
};

export const NewTabletEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/tablet/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const GetTabletEffect = (page) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/tablet/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      pagesize: 10,
      pageNO: page,
      pagination_required: true,
    },
  });
};
export const UpdateTabletEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/tablet/update/${formData?.id}`,
    method: "PUT",
    data: formData?.formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const DeleteTabletEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/tablet/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const TabletByOrgEffect = (formData) => {
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: `/tablet/organization?organization_id=${formData?.orgId}&pageNo=${formData?.pageNo}&pageSize=${formData?.pageSize}&pagination_required=${formData?.pagination_required}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const TabletCategoryListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/medicinecategorie/getAllCategorybyorginations?organization_id=${formData?.orgId}&pagination_required=${formData?.pagination}&pageSize=${formData?.pageSize}&pageNo=${formData?.pageNo}`,

    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const AddTabletCategoryEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/medicinecategorie/insertCategories",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const EditTabletCategoryEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `medicinecategorie/updateCategory/${formData?.id}`,
    data: formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const DeleteTabletCategoryEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/medicinecategorie/deleteCategory/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const ProductTypeEffect = () => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "producttype/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const ProductUnitEffect = () => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/productunit/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const supplierListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/supplier/supplierbyorganization?pagination_required=${
      formData?.pagination ? formData?.pagination : false
    }&pageSize=${formData?.pageSize}&pageNo=${formData?.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addSupplierEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/supplier/add",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editSupplierEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/supplier/update/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteSupplierEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/supplier/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const PharmacyProfileEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/pharmacy/details?pharmacy_id=${formData}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const EditPharmacyProfileEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/pharmacy/update/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const ChangePasswordEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/user/changepassword",
    data: formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const expiryStockEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/store/getExpiryDate",
    method: "GET",
    params: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "text/plain",
    },
  });
};

export const stockAdjustmentListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/store/storeListOrganization?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addStockAdjustmentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/store/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editStockAdjustmentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/store/update/${formData?.id}`,
    data: formData?.StoresInfo?.insert_details[0],
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteStockAdjustmentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/store/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const purchaseListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchase/getAllPurchase?organization_id=${formData.orgId}&pagination_required=${formData.pagination}&pageSize=10&pageNo=${formData.page}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPurchaseEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/purchase/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPurchaseEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchase/update/${formData?.id}`,
    data: formData?.data,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePurchaseEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchase/deletePurchase/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const purchaseRequestListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchaseRequest/organization?organization_id=${formData?.organization_id}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}&pagination_required=${formData?.pagination_required}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPurchaseRequestEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/purchaseRequest/add",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPurchaseRequestEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchaseRequest/update/${formData?.id}`,
    data: formData?.data,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePurchaseRequestEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchaseRequest/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const purchaseReturnListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchasereturn/organization?pagination_required=${formData?.pagination_required}&organization_id=${formData?.organization_id}&pageNo=${formData?.pageNo}&pagesize=${formData?.pagesize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPurchaseReturnEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/purchasereturn/add",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPurchaseReturnEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchasereturn/update/${formData?.id}`,
    data: formData?.editFormData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePurchaseReturnEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/purchasereturn/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getTabletIdEffect = (formData) => {
  const CurrentDate = moment().format("YYYY-MM-DD");
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/store/getTabletById?pageNo=${1}&pagesize=${5}&pagination_required=${
      formData?.pagination_required
    }&current_date=${CurrentDate}&name=${
      formData?.name ? formData?.name : null
    }`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addSalesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/sales/add",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSalesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/saleslist?pageNo=${formData?.page}&pagesize=10&pagination_required=${formData?.pagination}&organization_id=${formData?.orgId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSalesDetailsEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/getsales?sales_id=${formData}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const editSalesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/update/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const departmentListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/departments/getlist/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addDepartmentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/departments/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editDepartmentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/departments/update/${formData?.id}`,
    data: formData?.datas,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteDepartmentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/departments/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const pharmacyListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/pharmacy/list/organization?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}&organization_id=${formData?.orgId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const itemTransferListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `itemtransfer/getbyorganization?pagination_required=${formData?.pagination}&pagesize=${formData?.pageSize}&pageNo=${formData?.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addItemTransferEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/itemtransfer/insert",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editItemTransferEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/itemtransfer/update/${formData?.id}`,
    data: formData?.formData,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteItemTransferEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `itemtransfer/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addBulkMedicineEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/tablet/bulkInsertTablets",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteSalesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/delete/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addSalesPaymentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/pharmacybilling/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateSalesPaymentEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/pharmacybilling/update/${formData?.id}`,
    data: formData?.datas,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSalesDueEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/pharmacybilling/getdue?patient_id=${formData.patient_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      organizationId: organization_id,
    },
  });
};

export const salesCancelUndoEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/cancel?sales_id=${formData.sales_id}&type=${formData.type}&reason=${formData.reason}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const patientSalesDueEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/patientdues?pagination_required=${formData?.pagination}&pageNo=${formData?.pageNo}&pagesize=${formData?.pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      organizationId: organization_id,
    },
  });
};

export const dueByPatientEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/getDues?patient_id=${formData?.patient_id}&pagination_required=${formData?.pagination}&pagesize=${formData?.pageSize}&pageNo=${formData?.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addSalesReturnEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/salesreturn/insert`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSalesReturnListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/salesreturn/salesListorganization?pagination_required=${
      formData?.pagination
    }&pageNo=${formData?.pageNo}&pagesize=${
      formData?.pageSize ? formData?.pageSize : 10
    }`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateSalesReturnEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/salesreturn/update/${formData.id}`,
    method: "PUT",
    data: formData?.data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSalesListByPatientEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/sales/getpatientsales?patient_id=${
      formData?.patient_id
    }&pagination_required=${formData?.pagination}&pageNo=${
      formData?.pageNo
    }&pagesize=${formData?.pageSize ? formData?.pageSize : 10}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addBulkPurchaseEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/purchase/bulkinsertpurchase",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const bulkMedicineCategoryEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/medicinecategorie/bulkInsertcategories",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getGstListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/gst/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getPharmacyReportListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/pharmacybilling/getReports?organization_id=${organization_id}&report_type=daily&to_date=${
      formData.to_date
    }&from_date=${formData.from_date}&pagination_required=${
      formData?.pagination
    }&pageNo=${formData?.pageNo}&pagesize=${
      formData?.pageSize ? formData?.pageSize : 10
    }`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getAvabileStockListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/store/availablestock?organization_id=${organization_id}&stock=${formData.stock}&current_date=${currentDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
