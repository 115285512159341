import { Button, Col, Modal, Row, Spin, Table, Typography } from "antd";
import React, { useState } from "react";
import "../billing.less";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import { getBillingByPatientListInProgress } from "../../../appRedux/doctor/actions/addPayment";
import { useDispatch, useSelector } from "react-redux";
const BillingFromHeader = ({ title = "New Payment", patientId }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { billingPaymentreducer } = useSelector((state) => state);
  const showModal = () => {
    setIsModalOpen(true);
    dispatch(getBillingByPatientListInProgress(patientId));
  };
  const handlePaymentModalOk = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.NO",
      dataIndex: "sNo",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      key: "doctor",
    },
    {
      title: "Payment Date",
      dataIndex: "DATE",
      key: "paymentDate",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => (
        <>
          {record?.PatientBillingSubPackages?.map((val) => (
            <Typography>
              {val?.package_name} ({val?.quantity}) -{" "}
              {val?.quantity * val?.price}
            </Typography>
          ))}
        </>
      ),
    },
    {
      title: "Discount Amount",
      dataIndex: "discount",
      key: "discountAmount",
    },
    {
      title: "Recieved Amount",
      dataIndex: "paid_amount",
      key: "recievedAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "grand_total",
      key: "totalAmount",
    },
    {
      title: "Due Amount",
      dataIndex: "due",
      key: "dueAmount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          {Number(record?.due) === 0 ? (
            <Typography color="#008000">Paid</Typography>
          ) : (
            <Typography>UnPaid</Typography>
          )}
        </>
      ),
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <div>
      <Row>
        <Col>
          <Typography className="billing-from-title">{title}</Typography>
        </Col>
        <Col>
          {patientId && (
            <ButtonComponent
              icon={<EyeOutlined />}
              label={"View Payment History"}
              onClick={showModal}
              type="primary"
            />
          )}
        </Col>
      </Row>
      <Spin spinning={billingPaymentreducer?.patientList?.inProgress === true}>
        <Modal
          title={
            <Typography className="cancel-sales">Payment History</Typography>
          }
          open={isModalOpen}
          width={1000}
          closable={false}
          footer={[
            <Button key="back" type="danger" onClick={handlePaymentModalOk}>
              Close
            </Button>,
          ]}
        >
          {" "}
          <Spin
            indicator={antIcon}
            spinning={billingPaymentreducer?.patientList?.inProgress}
          >
            <Table
              columns={columns}
              dataSource={billingPaymentreducer?.patientList?.data?.data}
              pagination={false}
            />
          </Spin>
        </Modal>
      </Spin>
    </div>
  );
};
export default BillingFromHeader;
