import { Fragment, useState } from "react";
import VideoCall from "./components/VideoAppoinment/VideoCall";
import { useLocation } from "react-router-dom";

function JoinCall() {
  const location = useLocation();

  const [inCall, setInCall] = useState(false);

  return (
    <Fragment>
      <VideoCall
        setInCall={setInCall}
        appointmentId={location.state.id}
        channelName={location.state.id}
      />
    </Fragment>
  );
}

export default JoinCall;
