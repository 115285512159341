// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

//Videocall
export const REQUEST_VIDEO_CALL_IN_PROGRESS = "REQUEST_VIDEO_CALL_IN_PROGRESS";
export const REQUEST_VIDEO_CALL_SUCCESS = "REQUEST_VIDEO_CALL_SUCCESS";
export const REQUEST_VIDEO_CALL_FAILURE = "REQUEST_VIDEO_CALL_FAILURE";
//org
export const REQUEST_ORGANISATION_IN_PROGRESS =
  "REQUEST_ORGANISATION_IN_PROGRESS";
export const REQUEST_ORGANISATION_SUCCESS = "REQUEST_ORGANISATION_SUCCESS";
export const REQUEST_ORGANISATION_FAILURE = "REQUEST_ORGANISATION_FAILURE";
export const ADD_ORGANISATION_IN_PROGRESS = "ADD_ORGANISATION_IN_PROGRESS";
export const ADD_ORGANISATION_SUCCESS = "ADD_ORGANISATION_SUCCESS";
export const ADD_ORGANISATION_FAILURE = "ADD_ORGANISATION_FAILURE";

export const DELETE_ORGANISATION_IN_PROGRESS =
  "DELETE_ORGANISATION_IN_PROGRESS";
export const DELETE_ORGANISATION_SUCCESS = "DELETE_ORGANISATION_SUCCESS";
export const DELETE_ORGANISATION_FAILURE = "DELETE_ORGANISATION_FAILURE";

export const UPDATE_ORGANISATION_IN_PROGRESS =
  "UPDATE_ORGANISATION_IN_PROGRESS";
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_FAILURE = "UPDATE_ORGANISATION_FAILURE";
export const GET_ORGANISATION_NAME_IN_PROGRESS =
  "GET_ORGANISATION_NAME_IN_PROGRESS";
export const GET_ORGANISATION_NAME__SUCCESS = "GET_ORGANISATION_NAME__SUCCESS";
export const GET_ORGANISATION_NAME__FAILURE = "GET_ORGANISATION_NAME__FAILURE";
//designation
export const GET_DESIGNATION_IN_PROGRESS = "GET_DESIGNATION_IN_PROGRESS";
export const GET_DESIGNATION_SUCCESS = "GET_DESIGNATION_SUCCESS";
export const GET_DESIGNATION_FAILURE = "GET_DESIGNATION_FAILURE";
export const ADD_DESIGNATION_IN_PROGRESS = "ADD_DESIGNATION_IN_PROGRESS";
export const ADD_DESIGNATION_SUCCESS = "ADD_DESIGNATION_SUCCESS";
export const ADD_DESIGNATION_FAILURE = "ADD_DESIGNATION_FAILURE";
export const DELETE_DESIGNATION_IN_PROGRESS = "DELETE_DESIGNATION_IN_PROGRESS";
export const DELETE_DESIGNATION_SUCCESS = "DELETE_DESIGNATION_SUCCESS";
export const DELETE_DESIGNATION_FAILURE = "DELETE_DESIGNATION_FAILURE";
export const GETDATA_DESIGNATION_IN_PROGRESS =
  "GETDATA_SPECIALIZATION_IN_PROGRESS";
export const GETDATA_DESIGNATION_SUCCESS = "GETDATA_DESIGNATION_SUCCESS";
export const GETDATA_DESIGNATION_FAILURE = "GETDATA_DESIGNATION_FAILURE";

export const UPDATE_DESIGNATION_IN_PROGRESS = "UPDATE_DESIGNATION_IN_PROGRESS";
export const UPDATE_DESIGNATION_SUCCESS = "UPDATE_DESIGNATION_SUCCESS";
export const UPDATE_DESIGNATION_FAILURE = "UPDATE_DESIGNATION_FAILURE";
//specialization

export const GET_SPECIALIZATION_IN_PROGRESS = "GET_SPECIALIZATION_IN_PROGRESS";
export const GET_SPECIALIZATION_SUCCESS = "GET_SPECIALIZATION_SUCCESS";
export const GET_SPECIALIZATION_FAILURE = "GET_SPECIALIZATION_FAILURE";
export const ADD_SPECIALIZATION_IN_PROGRESS = "ADD_SPECIALIZATION_IN_PROGRESS";
export const ADD_SPECIALIZATION_SUCCESS = "ADD_SPECIALIZATION_SUCCESS";
export const ADD_SPECIALIZATION_FAILURE = "ADD_SPECIALIZATION_FAILURE";
export const DELETE_SPECIALIZATION_IN_PROGRESS =
  "DELETE_SPECIALIZATION_IN_PROGRESS";
export const DELETE_SPECIALIZATION_SUCCESS = "DELETE_SPECIALIZATION_SUCCESS";
export const DELETE_SPECIALIZATION_FAILURE = "DELETE_DESIGNATION_FAILURE";
export const GETDATA_SPECIALIZATION_IN_PROGRESS =
  "GETDATA_SPECIALIZATION_IN_PROGRESS";
export const GETDATA_SPECIALIZATION_SUCCESS = "GETDATA_SPECIALIZATION_SUCCESS";
export const GETDATA_SPECIALIZATION_FAILURE = "GETDATA_SPECIALIZATION_FAILURE";

export const UPDATE_SPECIALIZATION_IN_PROGRESS =
  "UPDATE_SPECIALIZATION_IN_PROGRESS";
export const UPDATE_SPECIALIZATION_SUCCESS = "UPDATE_SPECIALIZATION_SUCCESS";
export const UPDATE_SPECIALIZATION_FAILURE = "UPDATE_SPECIALIZATION_FAILURE";
// GET patient
export const PATIENT_LIST_IN_PROGRESS = "PATIENT_LIST_IN_PROGRESS";
export const PATIENT_LIST_IN_SUCCESS = "PATIENT_LIST_IN_SUCCESS";
export const PATIENT_LIST_IN_FAILURE = "PATIENT_LIST_IN_FAILURE";
// ADD patient
export const PATIENT_ADD_LIST_IN_PROGRESS = "PATIENT_ADD_LIST_IN_PROGRESS";
export const PATIENT_ADD_LIST_IN_SUCCESS = "PATIENT_ADD_LIST_IN_SUCCESS";
export const PATIENT_ADD_LIST_IN_FAILURE = "PATIENT_ADD_LIST_IN_FAILURE";

export const PATIENT_LIST_PROGRESS = "PATIENT_LIST_PROGRESS";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAILURE = "PATIENT_LIST_FAILURE";
// DELETE patient
export const PATIENT_DELETE_LIST_IN_PROGRESS =
  "PATIENT_DELETE_LIST_IN_PROGRESS";
export const PATIENT_DELETE_LIST_IN_SUCCESS = "PATIENT_DELETE_LIST_IN_SUCCESS";
export const PATIENT_DELETE_LIST_IN_FAILURE = "PATIENT_DELETE_LIST_IN_FAILURE";
// UPDATE patient
export const PATIENT_UPDATE_LIST_IN_PROGRESS =
  "PATIENT_UPDATE_LIST_IN_PROGRESS";
export const PATIENT_UPDATE_LIST_IN_SUCCESS = "PATIENT_UPDATE_LIST_IN_SUCCESS";
export const PATIENT_UPDATE_LIST_IN_FAILURE = "PATIENT_UPDATE_LIST_IN_FAILURE";
// GET patient BY ID
export const GET_PATIENT_LIST_BY_ID_IN_PROGRESS =
  "GET_PATIENT_LIST_BY_ID_IN_PROGRESS";
export const GET_PATIENT_LIST_BY_ID_IN_SUCCESS =
  "GET_PATIENT_LIST_BY_ID_IN_SUCCESS";
export const GET_PATIENT_LIST_BY_ID_IN_FAILURE =
  "GET_PATIENT_LIST_BY_ID_IN_FAILURE";
// GET appoinment by patients
export const GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS =
  "GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS";
export const GET_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS =
  "GET_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS";
export const GET_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE =
  "GET_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE";
// GET upcoming appoinment by patients
export const GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS =
  "GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS";
export const GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS =
  "GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS";
export const GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE =
  "GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE";
// DELETE patient appointment list
export const PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS =
  "PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS";
export const PATIENT_DELETE_APPOINTMENT_LIST_IN_SUCCESS =
  "PATIENT_DELETE_APPOINTMENT_LIST_IN_SUCCESS";
export const PATIENT_DELETE_APPOINTMENT_LIST_IN_FAILURE =
  "PATIENT_DELETE_APPOINTMENT_LIST_IN_FAILURE";
// DELETE upcoming patient appointment list
export const PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS =
  "PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS";
export const PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_SUCCESS =
  "PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_SUCCESS";
export const PATIENT_UPCOMING__DELETE_APPOINTMENT_LIST_IN_FAILURE =
  "PATIENT_UPCOMING__DELETE_APPOINTMENT_LIST_IN_FAILURE";

// ADD patient appointment list
export const PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS =
  "PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS";
export const PATIENT_ADD_APPOINTMENT_LIST_IN_SUCCESS =
  "PATIENT_ADD_APPOINTMENT_LIST_IN_SUCCESS";
export const PATIENT_ADD_APPOINTMENT_LIST_IN_FAILURE =
  "PATIENT_ADD_APPOINTMENT_LIST_IN_FAILURE";
// UPDATE patient appointment list
export const PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS =
  "PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS";
export const PATIENT_UPDATE_APPOINTMENT_LIST_IN_SUCCESS =
  "PATIENT_UPDATE_APPOINTMENT_LIST_IN_SUCCESS";
export const PATIENT_UPDATE_APPOINTMENT_LIST_IN_FAILURE =
  "PATIENT_UPDATE_APPOINTMENT_LIST_IN_FAILURE";
// GET patient appointment BY ID
export const GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS =
  "GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS";
export const GET_PATIENT_APPOINTMENT_BY_ID_IN_SUCCESS =
  "GET_PATIENT_APPOINTMENT_BY_ID_IN_SUCCESS";
export const GET_PATIENT_APPOINTMENT_BY_ID_IN_FAILURE =
  "GET_PATIENT_APPOINTMENT_BY_ID_IN_FAILURE";
// Patient CSV file upload
export const PATIENT_CSV_UPLOAD_IN_PROGRESS = "PATIENT_CSV_UPLOAD_IN_PROGRESS";
export const PATIENT_CSV_UPLOAD_IN_SUCCESS = "PATIENT_CSV_UPLOAD_IN_SUCCESS";
export const PATIENT_CSV_UPLOAD_IN_FAILURE = "PATIENT_CSV_UPLOAD_IN_FAILURE";
// blood group
export const BLOOD_GROUP_IN_PROGRESS = "BLOOD_GROUP_IN_PROGRESS";
export const BLOOD_GROUP_IN_SUCCESS = "BLOOD_GROUP_IN_SUCCESS";
export const BLOOD_GROUP_IN_FAILURE = "BLOOD_GROUP_IN_FAILURE";
// GET patient appointment BY ID

export const GET_SCHEDULE_IN_PROGRESS = "GET_SCHEDULE_IN_PROGRESS";
export const GET_SCHEDULE_IN_SUCCESS = "GET_SCHEDULE_IN_SUCCESS";
export const GET_SCHEDULE_IN_FAILURE = "GET_SCHEDULE_IN_FAILURE";
// Doctor consultation fee
export const DOCTOR_CONSULTATION_IN_PROGRESS =
  "DOCTOR_CONSULTATION_IN_PROGRESS";
export const DOCTOR_CONSULTATION_IN_SUCCESS = "DOCTOR_CONSULTATION_IN_SUCCESS";
export const DOCTOR_CONSULTATION_IN_FAILURE = "DOCTOR_CONSULTATION_IN_FAILURE";
// Doctorlist
export const GET_DOCTOR_LIST_PROGRESS = "GET_DOCTOR_LIST_PROGRESS";
export const GET_DOCTOR_LIST_SUCCESS = "GET_DOCTOR_LIST_SUCCESS";
export const GET_DOCTOR_LIST_ERROR = "GET_DOCTOR_LIST_ERROR";

export const ADD_DOCTOR_LIST_PROGRESS = "ADD_DOCTOR_LIST_PROGRESS";
export const ADD_DOCTOR_LIST_SUCCESS = "ADD_DOCTOR_LIST_SUCCESS";
export const ADD_DOCTOR_LIST_ERROR = "ADD_DOCTOR_LIST_ERROR";

export const UPDATE_DOCTOR_LIST_PROGRESS = "UPDATE_DOCTOR_LIST_PROGRESS";
export const UPDATE_DOCTOR_LIST_SUCCESS = "UPDATE_DOCTOR_LIST_SUCCESS";
export const UPDATE_DOCTOR_LIST_ERROR = "UPDATE_DOCTOR_LIST_ERROR";

export const DELETE_DOCTOR_LIST_PROGRESS = "DELETE_DOCTOR_LIST_PROGRESS";
export const DELETE_DOCTOR_LIST_SUCCESS = "DELETE_DOCTOR_LIST_SUCCESS";
export const DELETE_DOCTOR_LIST_ERROR = "DELETE_DOCTOR_LIST_ERROR";

export const DOCTOR_CSV_UPLOAD_IN_PROGRESS = "DOCTOR_CSV_UPLOAD_IN_PROGRESS";
export const DOCTOR_CSV_UPLOAD_IN_SUCCESS = "DOCTOR_CSV_UPLOAD_IN_SUCCESS";
export const DOCTOR_CSV_UPLOAD_IN_FAILURE = "DOCTOR_CSV_UPLOAD_IN_FAILURE ";

export const GET_DOCTOR_UPDATE_LIST_PROGRESS =
  "GET_DOCTOR_UPDATE_LIST_PROGRESS";
export const GET_DOCTOR_UPDATE_LIST_SUCCESS = "GET_DOCTOR_UPDATE_LIST_SUCCESS";
export const GET_DOCTOR_UPDATE_LIST_ERROR = "GET_DOCTOR_UPDATE_LIST_ERROR";

// AppointmentLists

export const GET_APPOINTMENTS_LIST_IN_PROGRESS =
  "GET_APPOINTMENTS_LIST_IN_PROGRESS";

export const GET_APPOINTMENTS_LIST_IN_SUCCESS =
  "GET_APPOINTMENTS_LIST_IN_SUCCESS";
export const GET_APPOINTMENTS_LIST_IN_ERROR = "GET_APPOINTMENTS_LIST_IN_ERROR";

export const GET_APPOINTMENTS_UPCOMMING_LIST_IN_PROGRESS =
  "GET_APPOINTMENTS_UPCOMMING_LIST_IN_PROGRESS";
export const GET_APPOINTMENTS_UPCOMMING_LIST_IN_SUCCESS =
  "GET_APPOINTMENTS_UPCOMMING_LIST_IN_SUCCESS";
export const GET_APPOINTMENTS_UPCOMMING_LIST_IN_ERROR =
  "GET_APPOINTMENTS_UPCOMMING_LIST_IN_ERROR";

// DoctorAppointmentList

export const GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS =
  "GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS";
export const GET_DOCTOR_APPOINTMENT_LIST_IN_SUCCESS =
  "GET_DOCTOR_APPOINTMENT_LIST_IN_SUCCESS";
export const GET_DOCTOR_APPOINTMENT_LIST_IN_ERROR =
  "GET_DOCTOR_APPOINTMENT_LIST_IN_ERROR";

// DoctorAppointmentList

export const GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS =
  "GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS";
export const GET_DOCTOR_APPOINTMENT_UPCOMING_IN_SUCCESS =
  "GET_DOCTOR_APPOINTMENT_UPCOMING_IN_SUCCESS";
export const GET_DOCTOR_APPOINTMENT_UPCOMING_IN_ERROR =
  "GET_DOCTOR_APPOINTMENT_UPCOMING_IN_ERROR";

// DoctorQualification

export const GET_QUALIFICATION_LIST_IN_PROGRESS =
  "GET_QUALIFICATION_LIST_IN_PROGRESS";
export const GET_QUALIFICATION_LIST_IN_SUCCESS =
  "GET_QUALIFICATION_LIST_IN_SUCCESS";
export const GET_QUALIFICATION_LIST_IN_ERROR =
  "GET_QUALIFICATION_LIST_IN_ERROR";

// DoctorQualificationDelete

export const DELETE_QUALIFICATION_LIST_IN_PROGRESS =
  "DELETE_QUALIFICATION_LIST_IN_PROGRESS";
export const DELETE_QUALIFICATION_LIST_IN_SUCCESS =
  "DELETE_QUALIFICATION_LIST_IN_SUCCESS";
export const DELETE_QUALIFICATION_LIST_IN_ERROR =
  "DELETE_QUALIFICATION_LIST_IN_ERROR";

// DoctorQualificationAdd

export const ADD_QUALIFICATION_LIST_IN_PROGRESS =
  "ADD_QUALIFICATION_LIST_IN_PROGRESS";
export const ADD_QUALIFICATION_LIST_IN_SUCCESS =
  "ADD_QUALIFICATION_LIST_IN_SUCCESS";
export const ADD_QUALIFICATION_LIST_IN_ERROR =
  "ADD_QUALIFICATION_LIST_IN_ERROR";

// DoctorQualificationAdd

export const UPDATE_QUALIFICATION_LIST_IN_PROGRESS =
  "UPDATE_QUALIFICATION_LIST_IN_PROGRESS";
export const UPDATE_QUALIFICATION_LIST_IN_SUCCESS =
  "UPDATE_QUALIFICATION_LIST_IN_SUCCESS";
export const UPDATE_QUALIFICATION_LIST_IN_ERROR =
  "UPDATE_QUALIFICATION_LIST_IN_ERROR";

// DoctorQualificationUpdateList

export const GET_QUALIFICATION_UPDATE_LIST_PROGRESS =
  "GET_QUALIFICATION_UPDATE_LIST_PROGRESS";
export const GET_QUALIFICATION_UPDATE_LIST_SUCCESS =
  "GET_QUALIFICATION_UPDATE_LIST_SUCCESS";
export const GET_QUALIFICATION_UPDATE_LIST_ERROR =
  "GET_QUALIFICATION_UPDATE_LIST_ERROR";

// GETUSERPROFILE

export const GET_USER_PROFILE_PROGRESS = " GET_USER_PROFILE_PROGRESS";
export const GET_USER_PROFILE_SUCCESS = " GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_ERROR = " GET_USER_PROFILE_ERROR";

// UPDATEUSERPROFILE

export const UPDATE_USER_PROFILE_PROGRESS = " UPDATE_USER_PROFILE_PROGRESS";
export const UPDATE_USER_PROFILE_SUCCESS = " UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = " UPDATE_USER_PROFILE_ERROR";

// GETHOLIDAYS

export const GET_HOLIDAYS_LIST_PROGRESS = " GET_HOLIDAYS_LIST_PROGRESS";
export const GET_HOLIDAYS_LIST_SUCCESS = " GET_HOLIDAYS_LIST_SUCCESS";
export const GET_HOLIDAYS_LIST_ERROR = " GET_HOLIDAYS_LIST_ERROR";

// ADDHOLIDAYS

export const ADD_HOLIDAYS_LIST_PROGRESS = " ADD_HOLIDAYS_LIST_PROGRESS";
export const ADD_HOLIDAYS_LIST_SUCCESS = " ADD_HOLIDAYS_LIST_SUCCESS";
export const ADD_HOLIDAYS_LIST_ERROR = " ADD_HOLIDAYS_LIST_ERROR";

// DELETEHOLIDAYS

export const DELETE_HOLIDAYS_LIST_PROGRESS = " DELETE_HOLIDAYS_LIST_PROGRESS";
export const DELETE_HOLIDAYS_LIST_SUCCESS = " DELETE_HOLIDAYS_LIST_SUCCESS";
export const DELETE_HOLIDAYS_LIST_ERROR = " DELETE_HOLIDAYS_LIST_ERROR";

// UPDATEHOLIDAYS

export const UPDATE_HOLIDAYS_LIST_PROGRESS = " UPDATE_HOLIDAYS_LIST_PROGRESS";
export const UPDATE_HOLIDAYS_LIST_SUCCESS = " UPDATE_HOLIDAYS_LIST_SUCCESS";
export const UPDATE_HOLIDAYS_LIST_ERROR = " UPDATE_HOLIDAYS_LIST_ERROR";

//doctor
export const DOCTOR_LIST_IN_PROGRESS = "DOCTOR_LIST_IN_PROGRESS";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAILURE = "DOCTOR_LIST_FAILURE";
//schedule

export const REQUEST_SCHEDULE_IN_PROGRESS = "REQUEST_SCHEDULE_IN_PROGRESS";
export const REQUEST_SCHEDULE_SUCCESS = "REQUEST_SCHEDULE_SUCCESS";
export const REQUEST_SCHEDULE_FAILURE = "REQUEST_SCHEDULE_FAILURE";
export const ADD_SCHEDULE_IN_PROGRESS = "ADD_SCHEDULE_IN_PROGRESS";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_FAILURE = "ADD_SCHEDULE_FAILURE";

export const DELETE_SCHEDULE_IN_PROGRESS = "DELETE_SCHEDULE_IN_PROGRESS";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAILURE = "DELETE_SCHEDULE_FAILURE";

export const UPDATE_SCHEDULE_IN_PROGRESS = "UPDATE_SCHEDULE_IN_PROGRESS";
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_FAILURE = "UPDATE_SCHEDULE_FAILURE";
export const GETDATA_SCHEDULE_IN_PROGRESS = "GETDATA_SCHEDULE_IN_PROGRESS";
export const GETDATA_SCHEDULE_SUCCESS = "GETDATA_SCHEDULE_SUCCESS";
export const GETDATA_SCHEDULE_FAILURE = "GETDATA_SCHEDULE_FAILURE";

// DoctorHolidayUpdateList

export const GET_HOLIDAY_UPDATE_LIST_PROGRESS =
  "GET_HOLIDAY_UPDATE_LIST_PROGRESS";
export const GET_HOLIDAY_UPDATE_LIST_SUCCESS =
  "GET_HOLIDAY_UPDATE_LIST_SUCCESS";
export const GET_HOLIDAY_UPDATE_LIST_ERROR = "GET_HOLIDAY_UPDATE_LIST_ERROR";

// HolidaySuccess
export const SET_HOLIDAY_LIST_FAILURE = "SET_HOLIDAY_LIST_FAILURE";

// GetUsers

export const GET_USERS_LIST_PROGRESS = "GET_USERS_LIST_PROGRESS";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";

// DeleteUsers

export const DELETE_USERS_LIST_PROGRESS = "DELETE_USERS_LIST_PROGRESS";
export const DELETE_USERS_LIST_SUCCESS = "DELETE_USERS_LIST_SUCCESS";
export const DELETE_USERS_LIST_ERROR = "DELETE_USERS_LIST_ERROR";
//weekday
export const WEEKDAY_SCHEDULE_IN_PROGRESS = "WEEKDAY_SCHEDULE_IN_PROGRESS";
export const WEEKDAY_SCHEDULE_SUCCESS = "WEEKDAY_SCHEDULE_SUCCESS";
export const WEEKDAY_SCHEDULE_FAILURE = "WEEKDAY_SCHEDULE_FAILURE";
//otpsend

export const OTP_SEND_IN_PROGRESS = "OTP_SEND_IN_PROGRESS";
export const OTP_SEND_SUCCESS = "OTP_SEND_SUCCESS";
export const OTP_SEND_FAILURE = "OTP_SEND_FAILURE";

//verify opt

export const VERIFY_OTP_IN_PROGRESS = "VERIFY_OTP_IN_PROGRESS";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

//forget password

export const FORGET_PASSWORD_IN_PROGRESS = "FORGET_PASSWORD_IN_PROGRESS";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
//set data

export const SET_OTP = "SET_OTP";
export const SET_VERIFICATION_OTP = "SET_VERIFICATION_OTP";
export const SET_REGISTER_SUCCESS = "SET_REGISTER_SUCCESS";
export const SET_FORGET_SUCCESS = "SET_FORGET_SUCCESS";

//org-list
export const GET_ORG_LIST_IN_PROGRESS = "GET_ORG_LIST_IN_PROGRESS";
export const GET_ORG_LIST_SUCCESS = "GET_ORG_LIST_SUCCESS";
export const GET_ORG_LIST_FAILURE = "GET_ORG_LIST_FAILURE";

//designation-list
export const GET_DESIGNATION_LIST_IN_PROGRESS =
  "GET_DESIGNATION_LIST_IN_PROGRESS";
export const GET_DESIGNATION_LIST_SUCCESS = "GET_DESIGNATION_LIST_SUCCESS";
export const GET_DESIGNATION_LIST_FAILURE = "GET_DESIGNATION_LIST_FAILURE";
//specialisation-list
export const GET_SPECIALIZATION_LIST_IN_PROGRESS =
  "GET_SPECIALIZATION_LIST_IN_PROGRESS";
export const GET_SPECIALIZATION_LIST_SUCCESS =
  "GET_SPECIALIZATION_LIST_SUCCESS";
export const GET_SPECIALIZATION_LIST_FAILURE =
  "GET_SPECIALIZATION_LIST_FAILURE";

// ADDPRESCRIPTION
export const ADD_PRESCRIPTION_IN_PROGRESS = "ADD_PRESCRIPTION_IN_PROGRESS";
export const ADD_PRESCRIPTION_IN_SUCCESS = "ADD_PRESCRIPTION_IN_SUCCESS";
export const ADD_PRESCRIPTION_IN_ERROR = "ADD_PRESCRIPTION_IN_ERROR";
//getpresciption list
export const PRESCRIPTION_LIST_IN_PROGRESS = "PRESCRIPTION_LIST_IN_PROGRESS";
export const PRESCRIPTION_LIST_IN_SUCCESS = "PRESCRIPTION_LIST_IN_SUCCESS";
export const PRESCRIPTION_LIST_IN_ERROR = "PRESCRIPTION_LIST_IN_ERROR";
//getprescription
export const PRESCRIPTION_DATA_IN_PROGRESS = "PRESCRIPTION_DATA_IN_PROGRESS";
export const PRESCRIPTION_DATA_IN_SUCCESS = "PRESCRIPTION_DATA_IN_SUCCESS";
export const PRESCRIPTION_DATA_IN_ERROR = "PRESCRIPTION_DATA_IN_ERROR";
//getprescription bby patient
export const PRESCRIPTION_BY_PATIENT_IN_PROGRESS =
  "PRESCRIPTION_BY_PATIENT_IN_PROGRESS";
export const PRESCRIPTION_BY_PATIENT_IN_SUCCESS =
  "PRESCRIPTION_BY_PATIENT_IN_SUCCESS";
export const PRESCRIPTION_BY_PATIENT_IN_ERROR =
  "PRESCRIPTION_BY_PATIENT_IN_ERROR";
//save prescription
export const SAVE_PRESCRIPTION_IN_PROGRESS = "SAVE_PRESCRIPTION_IN_PROGRESS";
export const SAVE_PRESCRIPTION_IN_SUCCESS = "SAVE_PRESCRIPTION_IN_SUCCESS";
export const SAVE_PRESCRIPTION_IN_ERROR = "SAVE_PRESCRIPTION_IN_ERROR";
//cancel prescription
export const CANCEL_PRESCRIPTION_IN_PROGRESS =
  "CANCEL_PRESCRIPTION_IN_PROGRESS";
export const CANCEL_PRESCRIPTION_IN_SUCCESS = "CANCEL_PRESCRIPTION_IN_SUCCESS";
export const CANCEL_PRESCRIPTION_IN_ERROR = "CANCEL_PRESCRIPTION_IN_ERROR";
//submit prescription
export const SUBMIT_PRESCRIPTION_IN_PROGRESS =
  "SUBMIT_PRESCRIPTION_IN_PROGRESS";
export const SUBMIT_PRESCRIPTION_IN_SUCCESS = "SUBMIT_PRESCRIPTION_IN_SUCCESS";
export const SUBMIT_PRESCRIPTION_IN_ERROR = "SUBMIT_PRESCRIPTION_IN_ERROR";

//setdata
export const SET_PRESCRIPTION_SUBMIT = "SET_PRESCRIPTION_SUBMIT";
export const SET_PRESCRIPTION_ADD = "SET_PRESCRIPTION_ADD";

//get tablets
export const GET_TABLETS_BY_ORG_IN_PROGRESS = "GET_TABLETS_BY_ORG_IN_PROGRESS";
export const GET_TABLETS_BY_ORG_SUCCESS = "GET_TABLETS_BY_ORG_SUCCESS";
export const GET_TABLETS_BY_ORG_ERROR = "GET_TABLETS_BY_ORG_ERROR";

// GETUSERUPDATELISTS

export const GET_USER_UPDATE_LIST_PROGRESS = "GET_USER_UPDATE_LIST_PROGRESS";
export const GET_USER_UPDATE_LIST_SUCCESS = "GET_USER_UPDATE_LIST_SUCCESS";
export const GET_USER_UPDATE_LIST_ERROR = "GET_USER_UPDATE_LIST_ERROR";

//login
export const LOGIN_USER_IN_PROGRESS = "LOGIN_USER_IN_PROGRESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
//REgister
export const REGISTER_USER_IN_PROGRESS = "REGISTER_USER_IN_PROGRESS";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
//logout

export const LOGOUT_USER_IN_PROGRESS = "LOGOUT_USER_IN_PROGRESS";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
//consultation

export const ADD_CONSULTATION_IN_PROGRESS = "ADD_CONSULTATION_IN_PROGRESS";
export const ADD_CONSULTATION_SUCCESS = "ADD_CONSULTATION_SUCCESS";
export const ADD_CONSULTATION_ERROR = "ADD_CONSULTATION_ERROR";
export const UPDATE_CONSULTATION_IN_PROGRESS =
  " UPDATE_CONSULTATION_IN_PROGRESS";
export const UPDATE_CONSULTATION_SUCCESS = " UPDATE_CONSULTATION_SUCCESS";
export const UPDATE_CONSULTATION_ERROR = " UPDATE_CONSULTATION_ERROR";
export const GET_CONSULTATION_IN_PROGRESS = "GET_CONSULTATION_IN_PROGRESS";
export const GET_CONSULTATION_SUCCESS = "GET_CONSULTATION_SUCCESS";
export const GET_CONSULTATION_ERROR = "GET_CONSULTATION_ERROR";

//getdoctot
export const GET_DOCTOR_IN_PROGRESS = "GET_DOCTOR_IN_PROGRESS";
export const GET_DOCTOR_SUCCESS = "GET_DOCTOR_SUCCESS";
export const GET_DOCTOR_ERROR = "GET_DOCTOR_ERROR";

//tablets

export const GET_TABLET_LIST_IN_PROGRESS = "GET_TABLET_LIST_IN_PROGRESS";
export const GET_TABLET_LIST_SUCCESS = "GET_TABLET_LIST_SUCCESS";
export const GET_TABLET_LIST_ERROR = "GET_TABLET_LIST_ERROR";

export const UPDATE_TABLET_IN_PROGRESS = "UPDATE_TABLET_IN_PROGRESS";
export const UPDATE_TABLET_SUCCESS = "UPDATE_TABLET_SUCCESS";
export const UPDATE_TABLET_ERROR = "UPDATE_TABLET_ERROR";

export const GET_TABLET_IN_PROGRESS = "GET_TABLET_IN_PROGRESS";
export const GET_TABLET_SUCCESS = "GET_TABLET_SUCCESS";
export const GET_TABLET_ERROR = "GET_TABLET_ERROR";

export const DELETE_TABLET_IN_PROGRESS = "DELETE_TABLET_IN_PROGRESS";
export const DELETE_TABLET_SUCCESS = "DELETE_TABLET_SUCCESS";
export const DELETE_TABLET_ERROR = "DELETE_TABLET_ERROR";

export const NEW_TABLET_IN_PROGRESS = "NEW_TABLET_IN_PROGRESS";
export const NEW_TABLET_SUCCESS = "NEW_TABLET_SUCCESS";
export const NEW_TABLET_ERROR = "NEW_TABLET_ERROR";

export const TABLET_BY_ORG_IN_PROGRESS = "TABLET_BY_ORG_IN_PROGRESS";
export const TABLET_BY_ORG_SUCCESS = "TABLET_BY_ORG_SUCCESS";
export const TABLET_BY_ORG_ERROR = "TABLET_BY_ORG_ERROR";
//appoinment of particular doctor
export const DOCTOR_APPOINMENT_LIST_IN_PROGRESS =
  "DOCTOR_APPOINMENT_LIST_IN_PROGRESS";
export const DOCTOR_APPOINMENT_LIST_SUCCESS = "DOCTOR_APPOINMENT_LIST_SUCCESS";
export const DOCTOR_APPOINMENT_LIST_ERROR = "DOCTOR_APPOINMENT_LIST_ERROR";

//family Member actions
export const FAMILY_MEMBER_LIST_IN_PROGRESS = "FAMILY_MEMBER_LIST_IN_PROGRESS";
export const FAMILY_MEMBER_LIST_SUCCESS = "FAMILY_MEMBER_LIST_SUCCESS";
export const FAMILY_MEMBER_LIST_ERROR = "FAMILY_MEMBER_LIST_ERROR";

//billing packages actions
export const ADD_PACKAGE_IN_PROGRESS = "ADD_PACKAGE_IN_PROGRESS";
export const ADD_PACKAGE_IN_SUCCESS = "ADD_PACKAGE_IN_SUCCESS";
export const ADD_PACKAGE_IN_ERROR = "ADD_PACKAGE_IN_ERROR";

export const GET_SUB_PACKAGE_IN_PROGRESS = "GET_SUB_PACKAGE_LIST_IN_PROGRESS";
export const GET_SUB_PACKAGE_LIST_SUCCESS = "GET_SUB_PACKAGE_LIST_SUCCESS";
export const GET_SUB_PACKAGE_LIST_ERROR = "GET_SUB_PACKAGE_LIST_ERROR";

export const GET_MAIN_PACKAGE_LIST_IN_PROGRESS =
  "GET_MAIN_PACKAG_LIST_IN_PROGRESS";
export const GET_MAIN_PACKAG_LIST_SUCCESS = "GET_MAIN_PACKAG_LIST_SUCCESS";
export const GET_MAIN_PACKAG_LIST_ERROR = "GET_MAIN_PACKAG_LIST_ERROR";

export const UPDATE_PACKAGE_IN_PROGRESS = "UPDATE_PACKAGE_IN_PROGRESS";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_ERROR = "UPDATE_PACKAGE_ERROR";

export const DELETE_PACKAGE_IN_PROGRESS = "DELETE_PACKAGE_IN_PROGRESS";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_ERROR = "DELETE_PACKAGE_ERROR";
export const ADD_SUBPACKAGE_IN_PROGRESS = "ADD_SUBPACKAGE_IN_PROGRESS";
export const ADD_SUBPACKAGE_IN_SUCCESS = "ADD_SUBPACKAGE_IN_SUCCESS";
export const ADD_SUBPACKAGE_IN_ERROR = "ADD_SUBPACKAGE_IN_ERROR";
export const UPDATE_SUBPACKAGE_IN_PROGRESS = "UPDATE_SUBPACKAGE_IN_PROGRESS";
export const UPDATE_SUBPACKAGE_SUCCESS = "UPDATE_SUBPACKAGE_SUCCESS";
export const UPDATE_SUBPACKAGE_ERROR = "UPDATE_SUBPACKAGE_ERROR";
export const DELETE_SUBPACKAGE_IN_PROGRESS = "DELETE_SUBPACKAGE_IN_PROGRESS";
export const DELETE_SUBPACKAGE_SUCCESS = "DELETE_SUBPACKAGE_SUCCESS";
export const DELETE_SUBPACKAGE_ERROR = "DELETE_SUBPACKAGE_ERROR";
//add payment actions
export const ADD_PAYMENT_IN_PROGRESS = "ADD_PAYMENT_IN_PROGRESS";
export const ADD_PAYMENT_IN_SUCCESS = "ADD_PAYMENT_IN_SUCCESS";
export const ADD_PAYMENT_IN_ERROR = "ADD_PAYMENT_IN_ERROR";

export const GET_BILLING_LIST_IN_PROGRESS = "GET_BILLING_LIST_IN_PROGRESS";
export const GET_BILLING_LIST_SUCCESS = "GET_BILLING_LIST_SUCCESS";
export const GET_BILLING_LIST_ERROR = "GET_BILLING_LIST_ERROR";
//health record
export const GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS =
  "GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS";
export const GET_PATIENT_HEALTH_RECORDS_SUCCESS =
  "GET_PATIENT_HEALTH_RECORDS_SUCCESS";
export const GET_PATIENT_HEALTH_RECORDS_ERROR =
  "GET_PATIENT_HEALTH_RECORDS_ERROR";
export const ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS =
  "ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS";
export const ADD_PATIENT_HEALTH_RECORDS_SUCCESS =
  "ADD_PATIENT_HEALTH_RECORDS_SUCCESS";
export const ADD_PATIENT_HEALTH_RECORDS_ERROR =
  "ADD_PATIENT_HEALTH_RECORDS_ERROR";
export const EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS =
  "EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS";
export const EDIT_PATIENT_HEALTH_RECORDS_SUCCESS =
  "EDIT_PATIENT_HEALTH_RECORDS_SUCCESS";
export const EDIT_PATIENT_HEALTH_RECORDS_ERROR =
  "EDIT_PATIENT_HEALTH_RECORDS_ERROR";
export const DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS =
  "DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS";
export const DELETE_PATIENT_HEALTH_RECORDS_SUCCESS =
  "DELETE_PATIENT_HEALTH_RECORDS_SUCCESS";
export const DELETE_PATIENT_HEALTH_RECORDS_ERROR =
  "DELETE_PATIENT_HEALTH_RECORDS_ERROR";
export const PATIENT_HEALTH_RECORDS_DEFAULT = "PATIENT_HEALTH_RECORDS_DEFAULT";
export const GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS =
  "GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS";
export const GET_SINGLE_PATIENT_HEALTH_RECORDS_SUCCESS =
  "GET_SINGLE_PATIENT_HEALTH_RECORDS_SUCCESS";
export const GET_SINGLE_PATIENT_HEALTH_RECORDS_ERROR =
  "GET_SINGLE_PATIENT_HEALTH_RECORDS_ERROR";

export const ADD_BILLING_IN_PROGRESS = "ADD_BILLING_IN_PROGRESS";
export const ADD_BILLING_SUCCESS = "ADD_BILLING_SUCCESS";
export const ADD_BILLING_ERROR = "ADD_BILLING_ERROR";

export const UPDATE_BILLING_IN_PROGRESS = "UPDATE_BILLING_IN_PROGRESS";
export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const UPDATE_BILLING_ERROR = "UPDATE_BILLING_ERROR";

export const DELETE_BILLING_IN_PROGRESS = "DELETE_BILLING_IN_PROGRESS";
export const DELETE_BILLING_SUCCESS = "DELETE_BILLING_SUCCESS";
export const DELETE_BILLING_ERROR = "DELETE_BILLING_ERROR";

export const GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS =
  "GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS";
export const GET_BILLING_BY_INVOICE_LIST_SUCCESS =
  "GET_BILLING_BY_INVOICE_LIST_SUCCESS";
export const GET_BILLING_BY_INVOICE_LIST_ERROR =
  "GET_BILLING_BY_INVOICE_LIST_ERROR";

export const GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS =
  "GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS";
export const GET_BILLING_BY_PATIENT_LIST_SUCCESS =
  "GET_BILLING_BY_PATIENT_LIST_SUCCESS";
export const GET_BILLING_BY_PATIENT_LIST_ERROR =
  "GET_BILLING_BY_PATIENT_LIST_ERROR";

export const GET_DUE_BY_PATIENT_IN_PROGRESS = "GET_DUE_BY_PATIENT_IN_PROGRESS";
export const GET_DUE_BY_PATIENT_SUCCESS = "GET_DUE_BY_PATIENT_SUCCESS";
export const GET_DUE_BY_PATIENT_ERROR = "GET_DUE_BY_PATIENT_LIST_ERROR";

export const UNDO_BILL_IN_PROGRESS = "UNDO_BILL_IN_PROGRESS";
export const UNDO_BILL_SUCCESS = "UNDO_BILL_SUCCESS";
export const UNDO_BILL_ERROR = "UNDO_BILL_ERROR";

export const GET_PHARMACY_DASHBOARD_IN_PROGRESS =
  "GET_PHARMACY_DASHBOARD_IN_PROGRESS";
export const GET_PHARMACY_DASHBOARD_SUCCESS = "GET_PHARMACY_DASHBOARD_SUCCESS";
export const GET_PHARMACY_DASHBOARD_ERROR = "GET_PHARMACY_DASHBOARD_ERROR";
// APOINTMENT BY DOCTOR PATIENT
export const GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS =
  "GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS";
export const GET_APPOINTMENT_BY_DOCTOR_PATIENT_SUCCESS =
  "GET_APPOINTMENT_BY_DOCTOR_PATIENT_SUCCESS";
export const GET_APPOINTMENT_BY_DOCTOR_PATIENT_ERROR =
  "GET_APPOINTMENT_BY_DOCTOR_PATIENT_ERROR";

//pharmacy
export const GET_TABLET_CATEGORY_LIST_IN_PROGRESS =
  "GET_TABLET_CATEGORY_LIST_IN_PROGRESS";
export const GET_TABLET_CATEGORY_LIST_SUCCESS =
  "GET_TABLET_CATEGORY_LIST_SUCCESS";
export const GET_TABLET_CATEGORY_LIST_ERROR = "GET_TABLET_CATEGORY_LIST_ERROR";

export const ADD_TABLET_CATEGORY_IN_PROGRESS =
  "ADD_TABLET_CATEGORY_IN_PROGRESS";
export const ADD_TABLET_CATEGORY_SUCCESS = "ADD_TABLET_CATEGORY_SUCCESS";
export const ADD_TABLET_CATEGORY_ERROR = "ADD_TABLET_CATEGORY_ERROR";

export const EDIT_TABLET_CATEGORY_IN_PROGRESS =
  "EDIT_TABLET_CATEGORY_IN_PROGRESS";
export const EDIT_TABLET_CATEGORY_SUCCESS = "EDIT_TABLET_CATEGORY_SUCCESS";
export const EDIT_TABLET_CATEGORY_ERROR = "EDIT_TABLET_CATEGORY_ERROR";

export const DELETE_TABLET_CATEGORY_IN_PROGRESS =
  "DELETE_TABLET_CATEGORY_IN_PROGRESS";
export const DELETE_TABLET_CATEGORY_SUCCESS = "DELETE_TABLET_CATEGORY_SUCCESS";
export const DELETE_TABLET_CATEGORY_ERROR = "DELETE_TABLET_CATEGORY_ERROR";
export const DEFAULT_TABLET_CATEGORY = "DEFAULT_TABLET_CATEGORY";
export const DEFAULT_TABLET_LIST = "DEFAULT_TABLET_LIST";
export const GET_PRODUCT_TYPE_IN_PROGRESS = "GET_PRODUCT_TYPE_IN_PROGRESS";
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS";
export const GET_PRODUCT_TYPE_ERROR = "GET_PRODUCT_TYPE_IN_ERROR";
export const GET_PRODUCT_UNIT_IN_PROGRESS = "GET_PRODUCT_UNIT_IN_PROGRESS";
export const GET_PRODUCT_UNIT_SUCCESS = "GET_PRODUCT_UNIT_SUCCESS";
export const GET_PRODUCT_UNIT_ERROR = "GET_PRODUCT_UNIT_IN_ERROR";
export const GET_SUPPLIER_LIST_IN_PROGRESS = "GET_SUPPLIER_LIST_IN_PROGRESS";
export const GET_SUPPLIER_LIST_SUCCESS = "GET_SUPPLIER_LIST_SUCCESS";
export const GET_SUPPLIER_LIST_ERROR = "GET_SUPPLIER_LIST_ERROR";
export const ADD_SUPPLIER_IN_PROGRESS = "ADD_SUPPLIER_IN_PROGRESS";
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const ADD_SUPPLIER_ERROR = "ADD_SUPPLIER_ERROR";
export const EDIT_SUPPLIER_IN_PROGRESS = "EDIT_SUPPLIER_IN_PROGRESS";
export const EDIT_SUPPLIER_SUCCESS = "EDIT_SUPPLIER_SUCCESS";
export const EDIT_SUPPLIER_ERROR = "EDIT_SUPPLIER_ERROR";
export const DELETE_SUPPLIER_IN_PROGRESS = "DELETE_SUPPLIER_IN_PROGRESS";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_ERROR = "DELETE_SUPPLIER_ERROR";
export const DEFAULT_SUPPLIER = "DEFAULT_SUPPLIER";

export const GET_PHARMACY_PROFILE_IN_PROGRESS =
  "GET_PHARMACY_PROFILE_IN_PROGRESS";
export const GET_PHARMACY_PROFILE_SUCCESS = "GET_PHARMACY_PROFILE_SUCCESS";
export const GET_PHARMACY_PROFILE_ERROR = "GET_PHARMACY_PROFILE_ERROR";
export const EDIT_PHARMACY_PROFILE_IN_PROGRESS =
  "EDIT_PHARMACY_PROFILE_IN_PROGRESS";
export const EDIT_PHARMACY_PROFILE_SUCCESS = "EDIT_PHARMACY_PROFILE_SUCCESS";
export const EDIT_PHARMACY_PROFILE_ERROR = "EDIT_PHARMACY_PROFILE_ERROR";
export const CHANGE_PASSWORD_IN_PROGRESS = "CHANGE_PASSWORD_IN_PROGRESS";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const EXPIRY_STOCK_IN_PROGRESS = "EXPIRY_STOCK_IN_PROGRESS";
export const EXPIRY_STOCK_SUCCESS = "EXPIRY_STOCK_SUCCESS";
export const EXPIRY_STOCK_ERROR = "EXPIRY_STOCK_ERROR";
export const STOCK_ADJUSTMENT_LIST_IN_PROGRESS =
  "STOCK_ADJUSTMENT_LIST_IN_PROGRESS";
export const STOCK_ADJUSTMENT_LIST_SUCCESS = "STOCK_ADJUSTMENT_LIST_SUCCESS";
export const STOCK_ADJUSTMENT_LIST_ERROR = "STOCK_ADJUSTMENT_LIST_ERROR";
export const ADD_STOCK_ADJUSTMENT_IN_PROGRESS =
  "ADD_STOCK_ADJUSTMENT_IN_PROGRESS";
export const ADD_STOCK_ADJUSTMENT_SUCCESS = "ADD_STOCK_ADJUSTMENT_SUCCESS";
export const ADD_STOCK_ADJUSTMENT_ERROR = "ADD_STOCK_ADJUSTMENT_ERROR";
export const EDIT_STOCK_ADJUSTMENT_IN_PROGRESS =
  "EDIT_STOCK_ADJUSTMENT_IN_PROGRESS";
export const EDIT_STOCK_ADJUSTMENT_SUCCESS = "EDIT_STOCK_ADJUSTMENT_SUCCESS";
export const EDIT_STOCK_ADJUSTMENT_ERROR = "EDIT_STOCK_ADJUSTMENT_ERROR";
export const DELETE_STOCK_ADJUSTMENT_IN_PROGRESS =
  "DELETE_STOCK_ADJUSTMENT_IN_PROGRESS";
export const DELETE_STOCK_ADJUSTMENT_SUCCESS =
  "DELETE_STOCK_ADJUSTMENT_SUCCESS";
export const DELETE_STOCK_ADJUSTMENT_ERROR = "DELETE_STOCK_ADJUSTMENT_ERROR";

export const GET_PURCHASE_LIST_IN_PROGRESS = "GET_PURCHASE_LIST_IN_PROGRESS";
export const GET_PURCHASE_LIST_SUCCESS = "GET_PURCHASE_LIST_SUCCESS";
export const GET_PURCHASE_LIST_ERROR = "GET_PURCHASE_LIST_ERROR";
export const ADD_PURCHASE_IN_PROGRESS = "ADD_PURCHASE_IN_PROGRESS";
export const ADD_PURCHASE_SUCCESS = "ADD_PURCHASE_SUCCESS";
export const ADD_PURCHASE_ERROR = "ADD_PURCHASE_ERROR";
export const EDIT_PURCHASE_IN_PROGRESS = "EDIT_PURCHASE_IN_PROGRESS";
export const EDIT_PURCHASE_SUCCESS = "EDIT_PURCHASE_SUCCESS";
export const EDIT_PURCHASE_ERROR = "EDIT_PURCHASE_ERROR";
export const DELETE_PURCHASE_IN_PROGRESS = "DELETE_PURCHASE_IN_PROGRESS";
export const DELETE_PURCHASE_SUCCESS = "DELETE_PURCHASE_SUCCESS";
export const DELETE_PURCHASE_ERROR = "DELETE_PURCHASE_ERROR";

export const GET_PURCHASE_REQUEST_LIST_IN_PROGRESS =
  "GET_PURCHASE_REQUEST_LIST_IN_PROGRESS";
export const GET_PURCHASE_REQUEST_LIST_SUCCESS =
  "GET_PURCHASE_REQUEST_LIST_SUCCESS";
export const GET_PURCHASE_REQUEST_LIST_ERROR =
  "GET_PURCHASE_REQUEST_LIST_ERRORET_PURCHASE_LIST_ERROR";
export const ADD_PURCHASE_REQUEST_IN_PROGRESS =
  "ADD_PURCHASE_REQUEST_IN_PROGRESS";
export const ADD_PURCHASE_REQUEST_SUCCESS = "ADD_PURCHASE_REQUEST_SUCCESS";
export const ADD_PURCHASE_REQUEST_ERROR = "ADD_PURCHASE_REQUEST_ERROR";
export const EDIT_PURCHASE_REQUEST_IN_PROGRESS =
  "EDIT_PURCHASE_REQUEST_IN_PROGRESS";
export const EDIT_PURCHASE_REQUEST_SUCCESS = "EDIT_PURCHASE_REQUEST_SUCCESS";
export const EDIT_PURCHASE_REQUEST_ERROR = "EDIT_PURCHASE_REQUEST_ERROR";
export const DELETE_PURCHASE_REQUEST_IN_PROGRESS =
  "DELETE_PURCHASE_REQUEST_IN_PROGRESS";
export const DELETE_PURCHASE_REQUEST_SUCCESS =
  "DELETE_PURCHASE_REQUEST_SUCCESS";
export const DELETE_PURCHASE_REQUEST_ERROR = "DELETE_PURCHASE_REQUEST_ERROR";

export const GET_PURCHASE_RETURN_LIST_IN_PROGRESS =
  "GET_PURCHASE_RETURN_LIST_IN_PROGRESS";
export const GET_PURCHASE_RETURN_LIST_SUCCESS =
  "GET_PURCHASE_RETURN_LIST_SUCCESS";
export const GET_PURCHASE_RETURN_LIST_ERROR =
  "GET_PURCHASE_RETURN_LIST_ERRORET_PURCHASE_LIST_ERROR";
export const ADD_PURCHASE_RETURN_IN_PROGRESS =
  "ADD_PURCHASE_RETURN_IN_PROGRESS";
export const ADD_PURCHASE_RETURN_SUCCESS = "ADD_PURCHASE_RETURN_SUCCESS";
export const ADD_PURCHASE_RETURN_ERROR = "ADD_PURCHASE_RETURN_ERROR";
export const EDIT_PURCHASE_RETURN_IN_PROGRESS =
  "EDIT_PURCHASE_RETURN_IN_PROGRESS";
export const EDIT_PURCHASE_RETURN_SUCCESS = "EDIT_PURCHASE_RETURN_SUCCESS";
export const EDIT_PURCHASE_RETURN_ERROR = "EDIT_PURCHASE_RETURN_ERROR";
export const DELETE_PURCHASE_RETURN_IN_PROGRESS =
  "DELETE_PURCHASE_RETURN_IN_PROGRESS";
export const DELETE_PURCHASE_RETURN_SUCCESS = "DELETE_PURCHASE_RETURN_SUCCESS";
export const DELETE_PURCHASE_RETURN_ERROR = "DELETE_PURCHASE_RETURN_ERROR";
export const PURCHASE_REQUEST_DEFAULT_DATA = "PURCHASE_REQUEST_DEFAULT_DATA";

export const GET_TABLETS_ID_IN_PROGRESS = "GET_TABLETS_ID_IN_PROGRESS";
export const GET_TABLETS_ID_SUCCESS = "GET_TABLETS_ID_SUCCESS";
export const GET_TABLETS_ID_ERROR = "GET_TABLETS_ID_ERROR";

export const ADD_SALES_IN_PROGRESS = "ADD_SALES_IN_PROGRESS";
export const ADD_SALES_SUCCESS = "ADD_SALES_SUCCESS";
export const ADD_SALES_ERROR = "ADD_SALES_ERROR";

export const GET_LIST_SALES_IN_PROGRESS = "GET_LIST_SALES_IN_PROGRESS";
export const GET_LIST_SALES_SUCCESS = "GET_LIST_SALES_SUCCESS";
export const GET_LIST_SALES_ERROR = "GET_LIST_SALES_ERROR";

export const GET_SALES_IN_PROGRESS = "GET_SALES_IN_PROGRESS";
export const GET_SALES_SUCCESS = "GET_SALES_SUCCESS";
export const GET_SALES_ERROR = "GET_SALES_ERROR";

export const EDIT_SALES_IN_PROGRESS = "EDIT_SALES_IN_PROGRESS";
export const EDIT_SALES_SUCCESS = "EDIT_SALES_SUCCESS";
export const EDIT_SALES_ERROR = "EDIT_SALES_ERROR";
export const GET_SALES_LIST_IN_PROGRESS = "GET_SALES_LIST_IN_PROGRESS";
export const GET_SALES_LIST_SUCCESS = "GET_SALES_LIST_SUCCESS";
export const GET_SALES_LIST_ERROR = "GET_SALES_LIST_ERROR";
export const STOCK_ADJUSTMENT_DEFAULT_DATA = "STOCK_ADJUSTMENT_DEFAULT_DATA";

export const GET_DEPARTMENT_LIST_IN_PROGRESS =
  "GET_DEPARTMENT_LIST_IN_PROGRESS";
export const GET_DEPARTMENT_LIST_SUCCESS = "GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_ERROR = "GET_DEPARTMENT_LIST_ERROR";
export const ADD_DEPARTMENT_IN_PROGRESS = "ADD_DEPARTMENT_IN_PROGRESS";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR";
export const EDIT_DEPARTMENT_IN_PROGRESS = "EDIT_DEPARTMENT_IN_PROGRESS";
export const EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS";
export const EDIT_DEPARTMENT_ERROR = "EDIT_DEPARTMENT_ERROR";
export const DELETE_DEPARTMENT_IN_PROGRESS = "DELETE_DEPARTMENT_IN_PROGRESS";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";
export const DEPARTMENT_DEFAULT_DATA = "DEPARTMENT_DEFAULT_DATA";
export const GET_PHARMACY_LIST_IN_PROGRESS = "GET_PHARMACY_LIST_IN_PROGRESS";
export const GET_PHARMACY_LIST_SUCCESS = "GET_PHARMACY_LIST_SUCCESS";
export const GET_PHARMACY_LIST_ERROR = "GET_PHARMACY_LIST_ERROR";
export const GET_ITEM_TRANSFER__LIST_IN_PROGRESS =
  "GET_ITEM_TRANSFER__LIST_IN_PROGRESS";
export const GET_ITEM_TRANSFER_LIST_SUCCESS = "GET_ITEM_TRANSFER_LIST_SUCCESS";
export const GET_ITEM_TRANSFER_LIST_ERROR = "GET_ITEM_TRANSFER_LIST_ERROR";
export const ADD_ITEM_TRANSFER_IN_PROGRESS = "ADD_ITEM_TRANSFER_IN_PROGRESS";
export const ADD_ITEM_TRANSFER_SUCCESS = "ADD_ITEM_TRANSFER_SUCCESS";
export const ADD_ITEM_TRANSFER_ERROR = "ADD_ITEM_TRANSFER_ERROR";
export const EDIT_ITEM_TRANSFER_IN_PROGRESS = "EDIT_ITEM_TRANSFER_IN_PROGRESS";
export const EDIT_ITEM_TRANSFER_SUCCESS = "EDIT_ITEM_TRANSFER_SUCCESS";
export const EDIT_ITEM_TRANSFER_ERROR = "EDIT_ITEM_TRANSFER_ERROR";
export const DELETE_ITEM_TRANSFER_IN_PROGRESS =
  "DELETE_ITEM_TRANSFER_IN_PROGRESS";
export const DELETE_ITEM_TRANSFER_SUCCESS = "DELETE_ITEM_TRANSFER_SUCCESS";
export const DELETE_ITEM_TRANSFER_ERROR = "DELETE_ITEM_TRANSFER_ERROR";
export const ITEM_TRANSFER_DEFAULT_DATA = "ITEM_TRANSFER_DEFAULT_DATA";

export const ADD_BULK_MEDICINE_IN_PROGRESS = "ADD_BULK_MEDICINE_IN_PROGRESS";
export const ADD_BULK_MEDICINE_SUCCESS = "ADD_BULK_MEDICINE_SUCCESS";
export const ADD_BULK_MEDICINE_ERROR = "ADD_BULK_MEDICINE_ERROR";
export const DEFAULT_BULK_MEDICINE = "DEFAULT_BULK_MEDICINE";
export const DEFAULT_PURCHASE_RETURN = "DEFAULT_PURCHASE_RETURN";

export const DELETE_SALES_IN_PROGRESS = "DELETE_SALES_IN_PROGRESS";
export const DELETE_SALES_SUCCESS = "DELETE_SALES_SUCCESS";
export const DELETE_SALES_ERROR = "DELETE_SALES_ERROR";

export const SALES_PAYMENT_IN_PROGRESS = "SALES_PAYMENT_IN_PROGRESS";
export const SALES_PAYMENT_SUCCESS = "SALES_PAYMENT_SUCCESS";
export const SALES_PAYMENT_ERROR = "SALES_PAYMENT_ERROR";

export const UPDATE_SALES_PAYMENT_IN_PROGRESS =
  "UPDATE_SALES_PAYMENT_IN_PROGRESS";
export const UPDATE_SALES_PAYMENT_SUCCESS = "UPDATE_SALES_PAYMENT_SUCCESS";
export const UPDATE_SALES_PAYMENT_ERROR = "UPDATE_SALES_PAYMENT_ERROR";

export const SALES_PAYMENT_DUE_IN_PROGRESS = "SALES_PAYMENT_DUE_IN_PROGRESS";
export const SALES_PAYMENT_DUE_SUCCESS = "SALES_PAYMENT_DUE_SUCCESS";
export const SALES_PAYMENT_DUE_ERROR = "SALES_PAYMENT_DUE_ERROR";
export const DEFAULT_ITEM_TRANSFER = "DEFAULT_ITEM_TRANSFER";

export const SALES_CANCEL_UNDO_IN_PROGRESS = "SALES_CANCEL_UNDO_IN_PROGRESS";
export const SALES_CANCEL_UNDO_SUCCESS = "SALES_CANCEL_UNDO_SUCCESS";
export const SALES_CANCEL_UNDO_ERROR = "SALES_CANCEL_UNDO_ERROR";

export const DEFAULT_UPDATE_ADD_PAYMENT = "DEFAULT_UPDATE_ADD_PAYMENT";
//Add Roles
export const ADD_ROLES_IN_PROGRESS = "ADD_ROLES_IN_PROGRESS";
export const ADD_ROLES_IN_SUCCESS = "ADD_ROLES_IN_SUCCESS";
export const ADD_ROLES_IN_ERROR = "ADD_ROLES_IN_ERROR";
//get roles
export const GET_ROLESlIST_IN_PROGRESS = "GET_ROLESlIST_IN_PROGRESS";
export const GET_ROLESlIST_IN_SUCCESS = "GET_ROLESlIST_IN_SUCCESS";
export const GET_ROLESlIST_IN_ERROR = "GET_ROLESlIST_IN_ERROR";
//delete Roles
export const DELETE_ROLESlIST_IN_PROGRESS = "DELETE_ROLESlIST_IN_PROGRESS";
export const DELETE_ROLESlIST_IN_SUCCESS = "DELETE_ROLESlIST_IN_SUCCESS";
export const DELETE_ROLESlIST_IN_ERROR = "DELETE_ROLESlIST_IN_ERROR";
//update Roles
export const UPDATE_ROLESlIST_IN_PROGRESS = "UPDATE_ROLESlIST_IN_PROGRESS";
export const UPDATE_ROLESlIST_IN_SUCCESS = "UPDATE_ROLESlIST_IN_SUCCESS";
export const UPDATE_ROLESlIST_IN_ERROR = "UPDATE_ROLESlIST_IN_ERROR";
//Get Team Members
export const GET_TEAM_MEMBER_IN_PROGRESS = "GET_TEAM_MEMBER_IN_PROGRESS";
export const GET_TEAM_MEMBER_IN_SUCCESS = "GET_TEAM_MEMBER_IN_SUCCESS";
export const GET_TEAM_MEMBER_IN_ERROR = "GET_TEAM_MEMBER_IN_ERROR";
//add Team Members
export const ADD_TEAM_MEMBER_IN_PROGRESS = "ADD_TEAM_MEMBER_IN_PROGRESS";
export const ADD_TEAM_MEMBER_IN_SUCCESS = "ADD_TEAM_MEMBER_IN_SUCCESS";
export const ADD_TEAM_MEMBER_IN_ERROR = "ADD_TEAM_MEMBER_IN_ERROR";

//update Team Members
export const UPDATE_TEAM_MEMBER_IN_PROGRESS = "UPDATE_TEAM_MEMBER_IN_PROGRESS";
export const UPDATE_TEAM_MEMBER_IN_SUCCESS = "UPDATE_TEAM_MEMBER_IN_SUCCESS";
export const UPDATE_TEAM_MEMBER_IN_ERROR = "UPDATE_TEAM_MEMBER_IN_ERROR";
//delete Team Member
export const DELETE_TEAM_MEMBER_IN_PROGRESS = "DELETE_TEAM_MEMBER_IN_PROGRESS";
export const DELETE_TEAM_MEMBER_IN_SUCCESS = "DELETE_TEAM_MEMBER_IN_SUCCESS";
export const DELETE_TEAM_MEMBER_IN_ERROR = "DELETE_TEAM_MEMBER_IN_ERROR";

export const PATIENT_SALES_DUE_IN_PROGRESS = "PATIENT_SALES_DUE_IN_PROGRESS";
export const PATIENT_SALES_DUE_SUCCESS = "PATIENT_SALES_DUE_SUCCESS";
export const PATIENT_SALES_DUE_ERROR = "PATIENT_SALES_DUE_ERROR";

export const GET_DUE_PATIENT_IN_PROGRESS = "GET_DUE_PATIENT_IN_PROGRESS";
export const GET_DUE_PATIENT_SUCCESS = "GET_DUE_PATIENT_SUCCESS";
export const GET_DUE_PATIENT_ERROR = "GET_DUE_PATIENT_ERROR";

export const ADD_SALES_RETURN_IN_PROGRESS = "ADD_SALES_RETURN_IN_PROGRESS";
export const ADD_SALES_RETURN_SUCCESS = "ADD_SALES_RETURN_SUCCESS";
export const ADD_SALES_RETURN_ERROR = "ADD_SALES_RETURN_ERROR";

export const GET_SALES_RETURN_LIST_IN_PROGRESS =
  "GET_SALES_RETURN_LIST_IN_PROGRESS";
export const GET_SALES_RETURN_LIST_SUCCESS = "GET_SALES_RETURN_LIST_SUCCESS";
export const GET_SALES_RETURN_LIST_ERROR = "GET_SALES_RETURN_LIST_ERROR";

export const UPDATE_SALES_RETURN_IN_PROGRESS =
  "UPDATE_SALES_RETURN_IN_PROGRESS";
export const UPDATE_SALES_RETURN_SUCCESS = "UPDATE_SALES_RETURN_SUCCESS";
export const UPDATE_SALES_RETURN_ERROR = "UPDATE_SALES_RETURN_ERROR";

export const SALES_LIST_BY_PATIENT_PROGRESS = "SALES_LIST_BY_PATIENT_PROGRESS";
export const SALES_LIST_BY_PATIENT_SUCCESS = "SALES_LIST_BY_PATIENT_SUCCESS";
export const SALES_LIST_BY_PATIENT_ERROR = "SALES_LIST_BY_PATIENT_ERROR";
export const DEFAULT_HOLIDAY = "DEFAULT_HOLIDAY";

export const APPOINMENT_RESCHEDULE_PROGRESS = "APPOINMENT_RESCHEDULE_PROGRESS";
export const APPOINMENT_RESCHEDULE_SUCCESS = "APPOINMENT_RESCHEDULE_SUCCESS";
export const APPOINMENT_RESCHEDULE_ERROR = "APPOINMENT_RESCHEDULE_ERROR";

export const GET_DOCTOR_DASHBOARD_PROGRESS = "GET_DOCTOR_DASHBOARD_PROGRESS";
export const GET_DOCTOR_DASHBOARD_SUCCESS = "GET_DOCTOR_DASHBOARD_SUCCESS";
export const GET_DOCTOR_DASHBOARD_ERROR = "GET_DOCTOR_DASHBOARD_ERROR";

export const ADD_DISEASES_PROGRESS = "ADD_DISEASES_PROGRESS";
export const ADD_DISEASES_SUCCESS = "ADD_DISEASES_SUCCESS";
export const ADD_DISEASES_ERROR = "ADD_DISEASES_ERROR";

export const GET_DISEASES_LIST_PROGRESS = "GET_DISEASES_LIST_PROGRESS";
export const GET_DISEASES_LIST_SUCCESS = "GET_DISEASES_LIST_SUCCESS";
export const GET_DISEASES_LIST_ERROR = "GET_DISEASES_LIST_ERROR";

export const UPDATE_DISEASES_PROGRESS = "UPDATE_DISEASES_PROGRESS";
export const UPDATE_DISEASES_SUCCESS = "UPDATE_DISEASES_SUCCESS";
export const UPDATE_DISEASES_ERROR = "UPDATE_DISEASES_ERROR";

export const DELETE_DISEASES_PROGRESS = "DELETE_DISEASES_PROGRESS";
export const DELETE_DISEASES_SUCCESS = "DELETE_DISEASES_SUCCESS";
export const DELETE_DISEASES_ERROR = "DELETE_DISEASES_ERROR";

export const ADD_QUESTIONS_ANSWER_PROGRESS = "ADD_QUESTIONS_ANSWER_PROGRESS";
export const ADD_QUESTIONS_ANSWER_SUCCESS = "ADD_QUESTIONS_ANSWER_SUCCESS";
export const ADD_QUESTIONS_ANSWER_ERROR = "ADD_QUESTIONS_ANSWER_ERROR";

export const GET_QUESTIONS_ANSWER_LIST_PROGRESS =
  "GET_QUESTIONS_ANSWER_LIST_PROGRESS";
export const GET_QUESTIONS_ANSWER_LIST_SUCCESS =
  "GET_QUESTIONS_ANSWER_LIST_SUCCESS";
export const GET_QUESTIONS_ANSWER_LIST_ERROR =
  "GET_QUESTIONS_ANSWER_LIST_ERROR";

export const UPDATE_QUESTIONS_ANSWER_PROGRESS =
  "UPDATE_QUESTIONS_ANSWER_PROGRESS";
export const UPDATE_QUESTIONS_ANSWER_SUCCESS =
  "UPDATE_QUESTIONS_ANSWER_SUCCESS";
export const UPDATE_QUESTIONS_ANSWER_ERROR = "UPDATE_QUESTIONS_ANSWER_ERROR";

export const DELETE_QUESTIONS_ANSWER_PROGRESS =
  "DELETE_QUESTIONS_ANSWER_PROGRESS";
export const DELETE_QUESTIONS_ANSWER_SUCCESS =
  "DELETE_QUESTIONS_ANSWER_SUCCESS";
export const DELETE_QUESTIONS_ANSWER_ERROR = "DELETE_QUESTIONS_ANSWER_ERROR";

export const GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS =
  "GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS";
export const GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_SUCCESS =
  "GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_SUCCESS";
export const GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_ERROR =
  "GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_ERROR";

export const ADD_BULK_PURCHASE_IN_PROGRESS = "ADD_BULK_PURCHASE_IN_PROGRESS";
export const ADD_BULK_PURCHASE_SUCCESS = "ADD_BULK_PURCHASE_SUCCESS";
export const ADD_BULK_PURCHASE_ERROR = "ADD_BULK_PURCHASE_ERROR";
//super admin
export const GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS =
  "GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS";
export const GET_SUPER_ADMIN_DASHBOARD_SUCCESS =
  "GET_SUPER_ADMIN_DASHBOARD_SUCCESS";
export const GET_SUPER_ADMIN_DASHBOARD_ERROR =
  "GET_SUPER_ADMIN_DASHBOARD_ERROR";
export const GET_HOSPITAL_LIST_IN_PROGRESS = "GET_HOSPITAL_LIST_IN_PROGRESS";
export const GET_HOSPITAL_LIST_SUCCESS = "GET_HOSPITAL_LIST_SUCCESS";
export const GET_HOSPITAL_LIST_ERROR = "GET_HOSPITAL_LIST_ERROR";
export const ADD_HOSPITAL_IN_PROGRESS = "ADD_HOSPITAL_IN_PROGRESS";
export const ADD_HOSPITAL_SUCCESS = "ADD_HOSPITAL_SUCCESS";
export const ADD_HOSPITAL_ERROR = "ADD_HOSPITAL_SUCCESS";
export const EDIT_HOSPITAL_IN_PROGRESS = "EDIT_HOSPITAL_IN_PROGRESS";
export const EDIT_HOSPITAL_SUCCESS = "EDIT_HOSPITAL_SUCCESS";
export const EDIT_HOSPITAL_ERROR = "EDIT_HOSPITAL_ERROR";
export const DELETE_HOSPITAL_IN_PROGRESS = "DELETE_HOSPITAL_IN_PROGRESS";
export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS";
export const DELETE_HOSPITAL_ERROR = "DELETE_HOSPITAL_ERROR";
export const GET_PACKAGES_IN_PROGRESS = "GET_PACKAGES_IN_PROGRESS";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_ERROR = "GET_PACKAGES_ERROR";
export const ADD_PACKAGES_IN_PROGRESS = "ADD_PACKAGES_IN_PROGRESS";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_ERROR = "ADD_PACKAGE_SUCCESS";
export const EDIT_PACKAGE_IN_PROGRESS = "EDIT_PACKAGE_IN_PROGRESS";
export const EDIT_PACKAGE_SUCCESS = "EDIT_PACKAGE_SUCCESS";
export const EDIT_PACKAGE_ERROR = "EDIT_PACKAGE_ERROR";
export const DELETE_PACKAGE_SA_IN_PROGRESS = "DELETE_PACKAGE_SA_IN_PROGRESS";
export const DELETE_PACKAGE_SA__SUCCESS = "DELETE_PACKAGE_SA__SUCCESS";
export const DELETE_PACKAGE_SA_ERROR = "DELETE_PACKAGE_SA_ERROR";
export const PATIENT_DASHBOARD_IN_PROGRESS = "PATIENT_DASHBOARD_IN_PROGRESS";
export const PATIENT_DASHBOARD_SUCCESS = "PATIENT_DASHBOARD_SUCCESS";
export const PATIENT_DASHBOARD_ERROR = "PATIENT_DASHBOARD_ERROR";
export const GET_SUBSCRIPTION_LIST_IN_PROGRESS =
  "GET_SUBSCRIPTION_LIST_IN_PROGRESS";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_ERROR = "GET_SUBSCRIPTION_LIST_ERROR";
export const ADD_SUBSCRIPTION_IN_PROGRESS = "ADD_SUBSCRIPTION_IN_PROGRESS";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_ERROR = "ADD_SUBSCRIPTION_SUCCESS";
export const EDIT_SUBSCRIPTION_IN_PROGRESS = "EDIT_SUBSCRIPTION_IN_PROGRESS";
export const EDIT_SUBSCRIPTION_SUCCESS = "EDIT_SUBSCRIPTION_SUCCESS";
export const EDIT_SUBSCRIPTION_ERROR = "EDIT_SUBSCRIPTION_ERROR";
export const DELETE_SUBSCRIPTION_IN_PROGRESS =
  "DELETE_SUBSCRIPTION_IN_PROGRESS";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_ERROR = "DELETE_SUBSCRIPTION_ERROR";
export const DEFAULT_SUBSCRIPTION = "DEFAULT_SUBSCRIPTION";
export const GET_MODULE_PERMISSION_IN_PROGRESS =
  "GET_MODULE_PERMISSION_IN_PROGRESS";
export const GET_MODULE_PERMISSION_SUCCESS = "GET_MODULE_PERMISSION_SUCCESS";
export const GET_MODULE_PERMISSION_ERROR = "GET_MODULE_PERMISSION_ERROR";
export const GET_SA_ORG__LIST_IN_PROGRESS = "GET_S_ORG__LIST_IN_PROGRESS";
export const GET_SA_ORG__LIST_SUCCESS = "GET_SA_ORG__LIST_SUCCESS";
export const GET_SA_ORG__LIST_ERROR = "GET_SA_ORG__LIST_ERROR";
export const ADD_SA_ORG_IN_PROGRESS = "ADD_SA_ORG_IN_PROGRESS";
export const ADD_SA_ORG__SUCCESS = "ADD_SA_ORG__SUCCESS";
export const ADD_SA_ORG_ERROR = "ADD_SA_ORG_ERROR";
export const EDIT_SA_ORG_IN_PROGRESS = "EDIT_SA_ORG_IN_PROGRESS";
export const EDIT_SA_ORG__SUCCESS = "EDIT_SA_ORG__SUCCESS";
export const EDIT_SA_ORG_ERROR = "EDIT_SA_ORG__SUCCESS";
export const DELETE_SA_ORG_IN_PROGRESS = "DELETE_SA_ORG_IN_PROGRESS";
export const DELETE_SA_ORG__SUCCESS = "DELETE_SA_ORG__SUCCESS";
export const DELETE_SA_ORG_ERROR = "DELETE_SA_ORG_ERROR";
export const ADD_ORG_TIME_PROGRESS = "ADD_ORG_TIME_PROGRESS";
export const ADD_ORG_TIME_SUCCESS = "ADD_ORG_TIME_SUCCESS";
export const ADD_ORG_TIME_ERROR = "ADD_ORG_TIME_ERROR";

//ward-management
export const GET_WARD_LIST_IN_PROGRESS = "GET_WARD_LIST_IN_PROGRESS";
export const GET_WARD_LIST_SUCCESS = "GET_WARD_LIST_SUCCESS";
export const GET_WARD_LIST_ERROR = "GET_WARD_LIST_ERROR";
export const ADD_WARD_IN_PROGRESS = "ADD_WARD_IN_PROGRESS";
export const ADD_WARD_SUCCESS = "ADD_WARD_LIST_SUCCESS";
export const ADD_WARD_ERROR = "ADD_WARD_ERROR";
export const EDIT_WARD_IN_PROGRESS = "EDIT_WARD_IN_PROGRESS";
export const EDIT_WARD_SUCCESS = "EDIT_WARD_LIST_SUCCESS";
export const EDIT_WARD_ERROR = "EDIT_WARD_LIST_ERROR";
export const DELETE_WARD_IN_PROGRESS = "DELETE_WARD_IN_PROGRESS";
export const DELETE_WARD_SUCCESS = "DELETE_WARD_LIST_SUCCESS";
export const DELETE_WARD_ERROR = "DELETE_WARD_LIST_ERROR";
export const DEFAULT_WARD = "DEFAULT_WARD";

export const GET_BLOCK_LIST_IN_PROGRESS = "GET_BLOCK_LIST_IN_PROGRESS";
export const GET_BLOCK_LIST_SUCCESS = "GET_BLOCK_LIST_SUCCESS";
export const GET_BLOCK_LIST_ERROR = "GET_BLOCK_LIST_ERROR";
export const ADD_BLOCK_IN_PROGRESS = "ADD_BLOCK_IN_PROGRESS";
export const ADD_BLOCK_SUCCESS = "ADD_BLOCK_SUCCESS";
export const ADD_BLOCK_ERROR = "ADD_BLOCK_ERROR";
export const EDIT_BLOCK_IN_PROGRESS = "EDIT_BLOCK_IN_PROGRESS";
export const EDIT_BLOCK_SUCCESS = "EDIT_BLOCK_SUCCESS";
export const EDIT_BLOCK_ERROR = "EDIT_BLOCK_ERROR";
export const DELETE_BLOCK_IN_PROGRESS = "DELETE_BLOCK_IN_PROGRESS";
export const DELETE_BLOCK_SUCCESS = "DELETE_BLOCK_SUCCESS";
export const DELETE_BLOCK_ERROR = "DELETE_BLOCK_ERROR";
export const DEFAULT_BLOCK = "DEFAULT_BLOCK";
export const GET_WARD_LIST_BY_BLOCK_IN_PROGRESS =
  "GET_WARD_LIST_BY_BLOCK_IN_PROGRESS";
export const GET_WARD_LIST_BY_BLOCK_SUCCESS = "GET_WARD_LIST_BY_BLOCK_SUCCESS";
export const GET_WARD_LIST_BY_BLOCK_ERROR = "GET_WARD_LIST_BY_BLOCK_ERROR";

export const GET_ROOM_LIST_IN_PROGRESS = "GET_ROOM_LIST_IN_PROGRESS";
export const GET_ROOM_LIST_SUCCESS = "GET_ROOM_LIST_SUCCESS";
export const GET_ROOM_LIST_ERROR = "GET_ROOM_LIST_ERROR";
export const ADD_ROOM_IN_PROGRESS = "ADD_ROOM_IN_PROGRESS";
export const ADD_ROOM_SUCCESS = "ADD_ROOM_SUCCESS";
export const ADD_ROOM_ERROR = "ADD_ROOM_ERROR";
export const EDIT_ROOM_IN_PROGRESS = "EDIT_ROOM_IN_PROGRESS";
export const EDIT_ROOM_SUCCESS = "EDIT_ROOM_SUCCESS";
export const EDIT_ROOM_ERROR = "EDIT_ROOM_ERROR";
export const DELETE_ROOM_IN_PROGRESS = "DELETE_ROOM_IN_PROGRESS";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";
export const DELETE_ROOM_ERROR = "DELETE_ROOM_ERROR";
export const DEFAULT_ROOM = "DEFAULT_ROOM";
export const ALL_DOCTOR_LIST_IN_PROGRESS = "ALL_DOCTOR_LIST_IN_PROGRESS";
export const ALL_DOCTOR_LIST_SUCCESS = "ALL_DOCTOR_LIST_SUCCESS";
export const ALL_DOCTOR_LIST_ERROR = "ALL_DOCTOR_LIST_ERROR";

export const GET_AVAILABLE_ROOM_LIST_IN_PROGRESS =
  "GET_AVAILABLE_ROOM_LIST_IN_PROGRESS";
export const GET_AVAILABLE_ROOM_LIST_IN_SUCCESS =
  "GET_AVAILABLE_ROOM_LIST_IN_SUCCESS";
export const GET_AVAILABLE_ROOM_LIST_IN_FAILURE =
  "GET_AVAILABLE_ROOM_LIST_IN_FAILURE";
export const ROOM_BOOKING_IN_PROGRESS = "ROOM_BOOKING_IN_PROGRESS";
export const ROOM_BOOKING_IN_SUCCESS = "ROOM_BOOKING_IN_SUCCESS";
export const ROOM_BOOKING_IN_FAILURE = "ROOM_BOOKING_IN_FAILURE";
export const WARD_DASHBOARD_COUNT_IN_PROGRESS =
  "WARD_DASHBOARD_COUNT_IN_PROGRESS";
export const WARD_DASHBOARD_COUNT_IN_SUCCESS =
  "WARD_DASHBOARD_COUNT_IN_SUCCESS";
export const WARD_DASHBOARD_COUNT_IN_FAILURE =
  "WARD_DASHBOARD_COUNT_IN_FAILURE";
export const GET_BOOKED_ROOM_LIST_IN_PROGRESS =
  "GET_BOOKED_ROOM_LIST_IN_PROGRESS";
export const GET_BOOKED_ROOM_LIST_IN_SUCCESS =
  "GET_BOOKED_ROOM_LIST_IN_SUCCESS";
export const GET_BOOKED_ROOM_LIST_IN_FAILURE =
  "GET_BOOKED_ROOM_LIST_IN_FAILURE";
export const GET_TOTAL_ROOM_LIST_IN_PROGRESS =
  "GET_TOTAL_ROOM_LIST_IN_PROGRESS";
export const GET_TOTAL_ROOM_LIST_IN_SUCCESS = "GET_TOTAL_ROOM_LIST_IN_SUCCESS";
export const GET_TOTAL_ROOM_LIST_IN_FAILURE = "GET_TOTAL_ROOM_LIST_IN_FAILURE";
export const ROOM_SHIFT_IN_PROGRESS = "ROOM_SHIFT_IN_PROGRESS";
export const ROOM_SHIFT_IN_SUCCESS = "ROOM_SHIFT_IN_SUCCESS";
export const ROOM_SHIFT_IN_FAILURE = "ROOM_SHIFT_IN_FAILURE";
export const ROOM_DISCHARGE_IN_PROGRESS = "ROOM_DISCHARGE_IN_PROGRESS";
export const ROOM_DISCHARGE_IN_SUCCESS = "ROOM_DISCHARGE_IN_SUCCESS";
export const ROOM_DISCHARGE_IN_FAILURE = "ROOM_DISCHARGE_IN_FAILURE";
export const DEFAULT_SHIFT_ROOM = "DEFAULT_SHIFT_ROOM";
export const DEFAULT_ROOM_BOOKING = "DEFAULT_ROOM_BOOKING";
export const GET_PLAN_LIST_IN_PROGRESS = "GET_PLAN_LIST_IN_PROGRESS";
export const GET_PLAN_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS";
export const GET_PLAN_LIST_ERROR = "GET_PLAN_LIST_ERROR";
export const ADD_PLAN_IN_PROGRESS = "ADD_PLAN_IN_PROGRESS";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ADD_PLAN_ERROR = "ADD_PLAN_ERROR";
export const EDIT_PLAN_IN_PROGRESS = "EDIT_PLAN_IN_PROGRESS";
export const EDIT_PLAN_SUCCESS = "EDIT_PLAN_SUCCESS";
export const EDIT_PLAN_ERROR = "EDIT_PLAN_ERROR";
export const DELETE_PLAN_IN_PROGRESS = "DELETE_PLAN_IN_PROGRESS";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_ERROR = "DELETE_PLAN_ERROR";
export const ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS =
  "ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS";
export const ADD_SUBSCRIPTION_PAYMENT_SUCCESS =
  "ADD_SUBSCRIPTION_PAYMENT_SUCCESS";
export const ADD_SUBSCRIPTION_PAYMENT_ERROR = "ADD_SUBSCRIPTION_PAYMENT_ERROR";
export const DEFAULT_PLAN = "DEFAULT_PLAN";

//seen prescription
export const SEEN_PRESCRIPTION_IN_PROGRESS = "SEEN_PRESCRIPTION_IN_PROGRESS";
export const SEEN_PRESCRIPTION_IN_SUCCESS = "SEEN_PRESCRIPTION_IN_SUCCESS";
export const SEEN_PRESCRIPTION_IN_ERROR = "SEEN_PRESCRIPTION_IN_ERROR";
//health-management
export const GET_VITALS_LIST_INPROGRESS = "GET_VITALS_LIST_INPROGRESS";
export const GET_VITALS_LIST_SUCCESS = "GET_VITALS_LIST_SUCCESS";
export const GET_VITALS_LIST_ERROR = "GET_VITALS_LIST_ERROR";
export const ADD_VITALS_INPROGRESS = "ADD_VITALS_INPROGRESS";
export const ADD_VITALS_SUCCESS = "ADD_VITALS_SUCCESS";
export const ADD_VITALS_ERROR = "ADD_VITALS_ERROR";
export const GET_VITALS_LIST_TYPE_INPROGRESS =
  "GET_VITALS_LIST_TYPE_INPROGRESS";
export const GET_VITALS_LIST_TYPE_SUCCESS = "GET_VITALS_LIST_TYPE_SUCCESS";
export const GET_VITALS_LIST_TYPE_ERROR = "GET_VITALS_LIST_TYPE_ERROR";

export const GET_BMI_LIST_INPROGRESS = "GET_BMI_LIST_INPROGRESS";
export const GET_BMI_LIST_SUCCESS = "GET_BMI_LIST_SUCCESS";
export const GET_BMI_LIST_ERROR = "GET_BMI_LIST_ERROR";
export const ADD_BMI_INPROGRESS = "ADD_BMI_INPROGRESS";
export const ADD_BMI_SUCCESS = "ADD_BMI_SUCCESS";
export const ADD_BMI_ERROR = "ADD_BMI_ERROR";

export const GET_BLOOD_GLUCOSE_LIST_INPROGRESS =
  "GET_BLOOD_GLUCOSE_LIST_INPROGRESS";
export const GET_BLOOD_GLUCOSE_LIST_SUCCESS = "GET_BLOOD_GLUCOSE_LIST_SUCCESS";
export const GET_BLOOD_GLUCOSE_LIST_ERROR = "GET_BLOOD_GLUCOSE_LIST_ERROR";
export const ADD_BLOOD_GLUCOSE_INPROGRESS = "ADD_BLOOD_GLUCOSE_INPROGRESS";
export const ADD_BLOOD_GLUCOSE_SUCCESS = "ADD_BLOOD_GLUCOSE_SUCCESS";
export const ADD_BLOOD_GLUCOSE_ERROR = "ADD_BLOOD_GLUCOSE_ERROR";

export const GET_INSULIN_LIST_INPROGRESS = "GET_INSULIN_LIST_INPROGRESS";
export const GET_INSULIN_LIST_SUCCESS = "GET_INSULIN_LIST_SUCCESS";
export const GET_INSULIN_LIST_ERROR = "GET_INSULIN_LIST_ERROR";
export const ADD_INSULIN_INPROGRESS = "ADD_INSULIN_INPROGRESS";
export const ADD_INSULIN_SUCCESS = "ADD_INSULIN_SUCCESS";
export const ADD_INSULIN_ERROR = "ADD_INSULIN_ERROR";

export const DEFAULT_VITAL = "DEFAULT_VITAL";
export const DEFAULT_BMI = "DEFAULT_BMI";
export const DEFAULT_BLOOD_GLUCOSE = "DEFAULT_BLOOD_GLUCOSE";
export const DEFAULT_INSULIN = "DEFAULT_INSULIN";
export const DEFAULT_ORGANIZATION = "DEFAULT_ORGANIZATION";

export const BULK_MEDICINE_CATEGORY_INPROGRESS =
  "BULK_MEDICINE_CATEGORY_INPROGRESS";
export const BULK_MEDICINE_CATEGORY_SUCCESS = "BULK_MEDICINE_CATEGORY_SUCCESS";
export const BULK_MEDICINE_CATEGORY_ERROR = "BULK_MEDICINE_CATEGORY_ERROR";
export const GET_GST_LIST_INPROGRESS = "GET_GST_LIST_INPROGRESS";
export const GET_GST_LIST_SUCCESS = "GET_GST_LIST_SUCCESS";
export const GET_GST_LIST_ERROR = "GET_GST_LIST_ERROR";
export const DEFAULT_BULK_MEDICINE_CATEGORY = "DEFAULT_BULK_MEDICINE_CATEGORY";
export const ADD_SUBSCRIPTION_DETAILS_INPROGRESS =
  "ADD_SUBSCRIPTION_DETAILS_INPROGRESS";
export const ADD_SUBSCRIPTION_DETAILS_SUCCESS =
  "ADD_SUBSCRIPTION_DETAILS_SUCCESS";
export const ADD_SUBSCRIPTION_DETAILS_ERROR = "ADD_SUBSCRIPTION_DETAILS_ERROR";
export const GET_SCHEDULE_IN_DEFAULT = "GET_SCHEDULE_IN_DEFAULT";
export const DEFAULT_SUBSCRIPTION_PAYMENT = "DEFAULT_SUBSCRIPTION_PAYMENT";
//dasboard
//Get DASHBOARD
export const GET_DASHBOARD_IN_PROGRESS = "GET_DASHBOARD_IN_PROGRESS";
export const GET_DASHBOARD_IN_SUCCESS = "GET_DASHBOARD_IN_SUCCESS";
export const GET_DASHBOARD_IN_ERROR = "GET_DASHBOARD_IN_ERROR";

//Get Pharmacy Report
export const GET_PHARMACYREPORT_IN_PROGRESS = "GET_PHARMACYREPORT_IN_PROGRESS";
export const GET_PHARMACYREPORT_IN_SUCCESS = "GET_PHARMACYREPORT_IN_SUCCESS";
export const GET_PHARMACYREPORT_IN_ERROR = "GET_PHARMACYREPORT_IN_ERROR";
//Get Patient Billing Report
export const GET_PATIENT_BILLING_REPORT_IN_PROGRESS =
  "GET_PATIENT_BILLING_REPORT_IN_PROGRESS";
export const GET_PATIENT_BILLING_REPORT_IN_SUCCESS =
  "GET_PATIENT_BILLING_REPORT_IN_SUCCESS";
export const GET_PATIENT_BILLING_REPORT_IN_ERROR =
  "GET_PATIENT_BILLING_REPORT_IN_ERROR";
//Get Avabile Stock
export const GET_AVABILE_STOCK_IN_PROGRESS = "GET_AVABILE_STOCK_IN_PROGRESS";
export const GET_AVABILE_STOCK_IN_SUCCESS = "GET_AVABILE_STOCK_IN_SUCCESS";
export const GET_AVABILE_STOCK_IN_ERROR = "GET_AVABILE_STOCK_IN_ERROR";

export const GET_DOCTOR_PROFILE_IN_PROGRESS = "GET_DOCTOR_PROFILE_IN_PROGRESS";
export const GET_DOCTOR_PROFILE_SUCCESS = "GET_DOCTOR_PROFILE_SUCCESS";
export const GET_DOCTOR_PROFILE_ERROR = "GET_DOCTOR_PROFILE_ERROR";
export const UPDATE_DOCTOR_PROFILE_IN_PROGRESS =
  "UPDATE_DOCTOR_PROFILE_IN_PROGRESS";
export const UPDATE_DOCTOR_PROFILE_SUCCESS = "UPDATE_DOCTOR_PROFILE_SUCCESS";
export const UPDATE_DOCTOR_PROFILE_ERROR = "UPDATE_DOCTOR_PROFILE_ERROR";
export const GET_ORG_DETAILS_BY_ID_IN_PROGRESS =
  "GET_ORG_DETAILS_BY_ID_IN_PROGRESS";
export const GET_ORG_DETAILS_BY_ID_SUCCESS = "GET_ORG_DETAILS_BY_ID_SUCCESS";
export const GET_ORG_DETAILS_BY_ID_ERROR = "GET_ORG_DETAILS_BY_ID_ERROR";
export const SUBSCRIPTION_PAMENT_DEFAULT = "SUBSCRIPTION_PAMENT_DEFAULT";

export const GET_UPCOMING_APPOINTMENT_LIST_INPROGRESS =
  "GET_UPCOMING_APPOINTMENT_LIST_INPROGRESS";
export const GET_UPCOMING_APPOINTMENT_LIST_SUCCESS =
  "GET_UPCOMING_APPOINTMENT_LIST_SUCCESS";
export const GET_UPCOMING_APPOINTMENT_LIST_ERROR =
  "GET_UPCOMING_APPOINTMENT_LIST_ERROR";
