import React, { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import { Link } from "react-router-dom";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import "../../styles/modules/doctor.less";
import {
  ADMINMENUS,
  COMMONMENU,
  DASHBOARD,
  DOCTORMENUS,
  PATIENTMENUS,
  PHARMACYMENU,
  PRESCRIPTION,
  PROFILE,
  SUPERADMINMENU,
  SUPERADMINMENUS,
  TEAMMEMBERS,
  itemsData,
} from "../../constants/Routes";
import CustomScrollbars from "../../util/CustomScrollbars";
import { MailOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const [menu, setMenu] = useState(PHARMACYMENU);
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const location = useLocation();
  const pathname = location.pathname;
  const roleId = localStorage.getItem("role_id");

  const role_permision = localStorage.getItem("role_permision");
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);

  useEffect(() => {
    if (roleId === "1") {
      setMenu([...ADMINMENUS]);
    } else if (roleId === "2" || roleId === "4" || roleId === "5") {
      const newMenu = [...TEAMMEMBERS];
      const menu = newMenu
        ?.map((val) => {
          if (JSON.parse(role_permision)[val.key]?.noaccess === false) {
            return val;
          }
          if (val.subMenu) {
            const subMenus = val.subMenu
              .map((vals) => {
                if (JSON.parse(role_permision)[vals.key]?.noaccess === false) {
                  return vals;
                }
              })
              .filter((vals) => vals !== undefined);
            val.subMenu = subMenus;
            if (subMenus.length !== 0) {
              return val;
            }
          }
          if (roleId === "2") {
            if (val.key === "doctorProfile") {
              return val;
            }
          } else if (roleId === "4") {
            if (val.key === "pharmacyProFile") {
              return val;
            }
          } else {
            if (val.key === "profile") {
              return val;
            }
          }
        })
        .filter((val) => val !== undefined);
      setMenu([...DASHBOARD, ...menu, ...PROFILE, ...PRESCRIPTION]);
    } else if (roleId === "3") {
      setMenu(PATIENTMENUS);
    } else {
      setMenu(SUPERADMINMENU);
    }
  }, [roleId]);

  const onClick = ({ key }) => {
    setSelectedKey(key);
    localStorage.setItem("selectedKeys", key);
  };

  // useEffect(() => {

  //   if (selectedKey.length !== undefined) {
  //     if (selectedKey === pathname) {
  //       setSelectedClass("black");
  //     } else {
  //       setSelectedClass("yellow");
  //     }
  //   }
  // }, [selectedKey]);
  useEffect(() => {
    const parentPathname = pathname.split("/").slice(0, 2).join("/");
    const subMenuPath = pathname.split("/").slice(0, 3).join("/");
    menu.forEach((menuItem) => {
      if (menuItem.link === parentPathname) {
        setSelectedKey(menuItem.link);
      } else if (menuItem.subMenu) {
        menuItem.subMenu.forEach((subItem) => {
          if (subItem.link === subMenuPath) {
            setSelectedKey(subItem.link);
          }
        });
      }
    });
  }, [pathname, menu]);
  const storedSelectedKeys = localStorage.getItem("selectedKeys");
  const storedOpenKeys = localStorage.getItem("openKeys");
  // useEffect(() => {

  //   if (storedSelectedKeys === "purchaseRequest") {

  //     setSelectedKey("purchase");

  //   }
  // }, [storedSelectedKeys]);

  const defaultOpenKey = () => {
    const storedSelectedKeys = localStorage.getItem("selectedKeys");

    return storedSelectedKeys === "purchaseRequest" ||
      storedSelectedKeys === "purchasePurchase" ||
      storedSelectedKeys === "purchaseReturn"
      ? ["purchase"]
      : storedSelectedKeys === "sales" || storedSelectedKeys === "salesReturn"
      ? ["sales"]
      : ["appoinment"];
  };
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content mt-20  background-color-sidebar ">
        {/* <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar increased-height background-color-sidebar">
          {/* <Menu
            onClick={onClick}
            style={{
              width: 256,
            }}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            items={itemsData}
          />{" "} */}

          <Menu
            onClick={onClick}
            // style={{
            //   width: 256,
            // }}
            defaultSelectedKeys={selectedKeys}
            defaultOpenKeys={defaultOpenKey()}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            className=" background-color-sidebar"
          >
            {menu.map((menuItem, index) => {
              if (menuItem.subMenu) {
                return (
                  <SubMenu
                    key={menuItem.key || `sub${index}`}
                    title={
                      <span
                        className={` ${
                          selectedKey === menuItem.link
                            ? "selected-menu"
                            : "menu-text"
                        }`}
                      >
                        <i
                          className={`icon ${menuItem.icon} ${
                            selectedKey === menuItem.link
                              ? "selected-menu"
                              : "menu-text"
                          }`}
                        />
                        <span
                          className={` ${
                            selectedKey === menuItem.link
                              ? "selected-menu"
                              : "menu-text"
                          }`}
                        >
                          <IntlMessages id={menuItem.label} />
                        </span>
                      </span>
                    }
                  >
                    {menuItem.subMenu.map((subItem, subIndex) => (
                      <Menu.Item key={subItem.key || `child${subItem}`}>
                        <Link to={subItem.link}>
                          <i
                            className={`icon ${subItem.icon} ${
                              selectedKey === subItem.link
                                ? "selected-menu"
                                : "menu-text"
                            }`}
                          />

                          <span
                            className={
                              selectedKey === subItem.link
                                ? "selected-menu"
                                : "menu-text"
                            }
                          >
                            <IntlMessages id={subItem.label} />
                          </span>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              } else {
                return (
                  <>
                    <Menu.Item key={menuItem.key || `item${index}`}>
                      <Link to={menuItem.link}>
                        <i
                          className={`icon ${menuItem.icon} ${
                            selectedKey === menuItem.link
                              ? "selected-menu"
                              : "menu-text"
                          }`}
                        />
                        <span
                          className={
                            selectedKey === menuItem.link
                              ? "selected-menu"
                              : "menu-text"
                          }
                        >
                          <IntlMessages id={menuItem.label} />
                        </span>
                      </Link>
                    </Menu.Item>
                  </>
                );
              }
            })}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
