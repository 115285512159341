import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  Typography,
  Divider,
  Button,
  Modal,
} from "antd";
import "../../../../styles/modules/pharmacy.less";
import { APP_ROUTES } from "../../../../constants/Routes";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  CreditCardOutlined,
  EditFilled,
  LoadingOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  DeleteFilled,
  EyeOutlined,
} from "@ant-design/icons";

import ButtonComponent from "../../../../components/Button/ButtonComponent";
import FormInput from "../../../../components/Input/FormInput";
import IntlMessages from "../../../../util/IntlMessages";
import FormSelectBox from "../../../../components/SelectBox/SelectBox";
import { paymentTypeList, salesTypeList } from "../../../Doctor/data";
import moment from "moment";
import SalesProductForm from "./SalesProductForm";
import SelectorsAppointment from "../../../Appoinment/Selectors";
import { useSelector } from "react-redux";
import DoctorSelectAppointment from "../../../Appoinment/DoctorSelectBox";
import SalesFromTable from "./SalesFromTable";
import { currentDate, disablePastDate } from "../../../../util/utilz";
import {
  addSalesInProgress,
  editSalesInProgress,
  getTabletsIdInProgress,
  salesPaymentDueInProgress,
  slesPaymentInProgress,
  updateSalesPaymentInProgress,
} from "../../../../appRedux/Pharmacy/PharmacyAction";
import PrescriptionModel from "./PrescriptionModel";

const makeRow = (counter) => ({
  key: counter,
  product_name: counter,
  tablet_id: counter,
  batch_no: counter,
  quantity: counter,
  free_quantity: counter,
  purchase_price: counter,
  packing_unit: counter,
  gst_percentag: counter,
  sale_price: counter,
  discount: counter,
  total: counter,
  expiry_date: counter,
});
const AddSales = ({ viewData, salesData, editSales }) => {
  const formref2 = useRef(null);
  const formRef = useRef(null);

  const Ref = useRef(null);
  const [form] = Form.useForm();
  const location = useLocation();
  const prescription = location?.state?.record;

  const [form2] = Form.useForm();
  const [formData, setFormData] = useState();
  const [patientDetail, setPatientDetail] = useState();
  const [patientMobile, setPatientMobile] = useState();
  const [updatedProduct, setUpdatedProduct] = useState([]);
  const [salesType, setSalesType] = useState(1);
  const [findIndex, setFindIndex] = useState();
  const [paymentType, setpaymentType] = useState("");
  const [due, setDue] = useState("");
  const [editPaymentData, setEditPaymentData] = useState(false);
  const [payment, setPayment] = useState(editSales);
  const [paymentModal, setPaymentModal] = useState(false);
  const [prescriptionsModal, setPrescriptionstModal] = useState(false);
  const [paymentData, setpaymentData] = useState([]);
  const history = useHistory();
  const [input, setInput] = useState([makeRow(0)]);
  const [key, setKey] = useState();
  const [keys, setKeys] = useState();
  const [locationLoading, setLocationLoading] = useState(false);
  const [counter, setCounter] = useState(1);
  const [batch, setBatch] = useState([]);
  const dispatch = useDispatch();
  const [error, setError] = useState([]);
  const [discount, setDiscount] = useState();
  const [navigate, setNavigate] = useState(false);
  const [navigateEdit, setNavigateEdit] = useState(false);
  const [returnQunatity, setReturnQunatity] = useState([]);
  const values = form.getFieldsValue();
  const [idModel, setIdModel] = useState("");
  const [wallet, setWallet] = useState(0);
  const [timer, setTimer] = useState(false);
  // const orgName = useSelector((state) => state);

  const {
    getTabletsId,
    addSales,
    editSaless,
    updateSalesPayment,
    addSalesPayment,
    salesPaymentDue,
    getSales,
  } = useSelector((state) => state.pharmacy);

  const orgId = localStorage.getItem("org_id");

  useEffect(() => {
    if (salesPaymentDue?.data && Number(salesType) === 1) {
      form.setFieldValue(
        "old_due",
        Number(salesPaymentDue?.data?.totalDue).toFixed(2)
      );
      form.setFieldValue(
        "wallet_amount",
        Number(salesPaymentDue?.data?.totalWallet).toFixed(2)
      );
      setWallet(Number(salesPaymentDue?.data?.totalWallet).toFixed(2));
      setKey(1);
    }
  }, [salesPaymentDue?.data]);

  useEffect(() => {
    if (navigate && addSales?.data?.SalesInfo?.id) {
      history.push("/sales/edit", {
        salesData: { ...addSales?.data?.SalesInfo, ...values },
      });
      setNavigate(false);
      setWallet("");
    }
  }, [navigate, addSales?.data?.SalesInfo?.id]);

  useEffect(() => {
    if (navigateEdit && editSaless?.success) {
      history.push("/sales");
      setNavigateEdit(false);
      setWallet("");
    }
  }, [navigateEdit, editSaless?.success]);

  useEffect(() => {
    if (!timer || !getTabletsId?.data?.response?.data) {
      const fromData = { pagination_required: false, page: 1 };
      dispatch(getTabletsIdInProgress(fromData));
      form.setFieldValue("sales_date", currentDate);
    }
  }, [timer]);

  useEffect(() => {
    if (prescription) {
      setSalesType(Number(prescription?.patient_type));
      form.setFieldValue(
        "patient_type",
        salesTypeList.find(
          (id) => Number(id.value) === Number(prescription?.patient_type)
        )?.label
      );
      form.setFieldValue("sales_date", currentDate);
      form.setFieldValue("doctor_id", prescription?.doctor_id);
      form.setFieldValue("patient_id", prescription?.patient_id);
      form.setFieldValue("doctorName", prescription?.doctor_name);
      form.setFieldValue("patientName", prescription?.patient_name);
      form.setFieldValue("doctor", prescription?.doctor_name);
      form.setFieldValue("patient", prescription?.patient_name);
      form.setFieldValue("patient_mobile", salesData?.patient_mobile);
      const fetchData = async () => {
        const newInput = await Promise.all(
          prescription?.sales_details?.map(async (value, index) => {
            const data = await onChangeTablets(value, index);
            setBatch((prevBatch) => [...prevBatch, data]);
            return makeRow(index);
          })
        );

        if (newInput && newInput.length > 0) {
          setInput(newInput);
          setCounter(newInput.length);
        }
      };

      fetchData();
    }
  }, [prescription]);

  const onChangeTablets = async (data, key) => {
    form.setFieldValue(
      [`sales_information`, key, "product_name"],
      data?.medicine_name || data.product_name
    );
    form.setFieldValue(
      [`sales_information`, key, "tablet_id"],
      data?.medicine_name || data?.product_name
    );
    form.setFieldValue(
      [`sales_information`, key, "quantity"],
      data.available_stock ? data.available_stock : data.quantity || 0
    );

    const ids = await getTabletsId?.data?.response?.data?.find(
      (id) => id.id === data?.tablet_id
    );

    return { ids, key };
  };

  useEffect(() => {
    if (salesData) {
      setBatch([]);
      setpaymentData(salesData?.PharmacyBillings || []);
      setSalesType(
        Number(salesData?.patient_type) ||
          salesTypeList.find((id) => id.label === salesData?.patient_type)
            ?.value
      );
      form.setFieldValue(
        "patient_type",
        salesTypeList.find(
          (id) => Number(id.value) === Number(salesData?.patient_type)
        )?.label || salesData?.patient_type
      );
      form.setFieldValue("sales_date", salesData?.sales_date);
      form.setFieldValue("doctor_id", salesData?.doctor_id);
      form.setFieldValue("patient_id", salesData?.patient_id);
      form.setFieldValue("doctorName", salesData?.doctor_name);
      form.setFieldValue("patientName", salesData?.patient_name);
      form.setFieldValue("doctor", salesData?.doctor_name);
      form.setFieldValue("patient", salesData?.patient_name);
      form.setFieldValue("patient_mobile", salesData?.patient_mobile);
      form.setFieldValue("sub_total", Number(salesData?.sub_total).toFixed(2));
      form.setFieldValue("discount", Number(salesData?.discount).toFixed(2));
      form.setFieldValue("total", Number(salesData?.total).toFixed(2));
      form.setFieldValue("deposite", Number(salesData?.deposite).toFixed(2));
      form.setFieldValue(
        "wallet_amount",
        Number(salesData?.wallet_amount).toFixed(2)
      );
      setWallet(salesData?.wallet_amount);
      setDue(salesData?.due);
      if (Number(salesData?.due) >= 0) {
        form.setFieldValue("due", Number(salesData?.due).toFixed(2));
      } else {
        form.setFieldValue("due", 0.0);
      }

      form.setFieldValue(
        "discount_amount",
        (Number(salesData?.sub_total) * Number(salesData?.discount)) / 100
      );

      const newInput =
        salesData?.sales_details?.map((value, index) => {
          onChangeTablet(value, index, true);
          return makeRow(index);
        }) ||
        Object.values(salesData?.sales_information)?.map((value, index) => {
          onChangeTablet(value, index, true);
          return makeRow(index);
        });

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }
    }
  }, [salesData]);

  const onFinish = (datas) => {
    if (salesData) {
      {
        datas.doctor_name =
          datas?.doctorName || datas?.doctor || datas?.doctor?.label;
      }
      {
        datas.patient_name =
          datas?.patient || datas?.patientName || datas?.patient?.label;
      }
      // datas.doctor_name = datas?.doctor
      //   ? datas?.doctor
      //   : datas?.doctorName
      //   ? datas?.doctorName
      //   : datas?.doctor?.label;
      // datas.patient_name = datas?.patient
      //   ? datas?.patient
      //   : datas?.patientName
      //   ? datas?.patientName
      //   : datas?.patient?.label;
      datas.patient_mobile = datas?.patient_mobile
        ? datas?.patient_mobile
        : patientMobile
        ? patientMobile
        : "";
      datas.wallet_amount = datas?.wallet_amount ? datas?.wallet_amount : "";
      datas.due = due;
      datas.patient_type = datas.patient_type === "In Patient" ? 1 : 2;
      datas.sales_information = Object.values(datas.sales_information).filter(
        (subPackage) => subPackage !== null
      );
      datas.sales_information = Object.values(datas.sales_information).map(
        (subPackage) => {
          const sale_info = salesData?.sales_details?.find(
            (val) => val.product_name === subPackage.tablet_id
          );
          if (sale_info?.tablet_id) {
            subPackage.tablet_id = sale_info?.tablet_id;
          }
          return subPackage;
        }
      );
      const salesDatas = {
        formData: datas,
        id: salesData?.id,
      };

      dispatch(editSalesInProgress(salesDatas));
      setNavigateEdit(true);
    } else {
      datas.sales_date = moment(datas.sales_date).format("YYYY-MM-DD");
      datas.sales_information = Object.values(datas.sales_information).filter(
        (subPackage) => subPackage !== null
      );
      datas.patient_type = Number(datas.patient_type)
        ? datas.patient_type
        : datas.patient_type === "In Patient"
        ? 1
        : 2;
      {
        datas.doctor_name =
          datas?.doctorName || datas?.doctor?.label || datas?.doctor;
      }

      {
        datas.patient_name =
          datas?.patientName || datas?.patient?.label || datas?.patient;
      }
      datas.patient_mobile = datas?.patient_mobile
        ? datas?.patient_mobile
        : patientMobile
        ? patientMobile
        : "";
      datas.wallet_amount = datas?.wallet_amount ? datas?.wallet_amount : "";

      dispatch(addSalesInProgress(datas));
      setNavigate(true);
    }
  };
  const onFinishModal = (datas) => {
    datas.sales_id = salesData?.id;
    datas.date = moment(datas.date).format("YYYY-MM-DD");
    if (editPaymentData) {
      const formData = {
        id: idModel,
        datas,
      };
      dispatch(updateSalesPaymentInProgress(formData));
    } else {
      dispatch(slesPaymentInProgress(datas));
    }
  };

  useEffect(() => {
    if (addSalesPayment?.success || updateSalesPayment?.success) {
      setEditPaymentData(false);
      form2.resetFields();
      setPaymentModal(false);
    }
  }, [addSalesPayment?.success, updateSalesPayment?.success]);

  const handleEdit = (payment, index) => {
    setPaymentModal(true);
    setEditPaymentData(true);
    setFindIndex(index);

    form2.setFieldsValue({
      date: payment.date,
      Paid_amount: payment?.Paid_amount,
      payment_type: payment?.payment_type,
      remarks: payment?.remarks,
      sales_id: payment?.sales_id,
    });

    setIdModel(payment?.id);
    // formref2.current.setFieldValue("Paid_amount", payment?.Paid_amount);.
  };
  const handleModle = () => {
    setPaymentModal(true);
    form2.setFieldsValue({
      date: "",
      Paid_amount: "",
      payment_type: "",
      remarks: "",
    });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const paymentModalOk = () => {
    setPaymentModal(false);
    setPrescriptionstModal(false);
  };
  const changeSalesType = (value) => {
    setSalesType(value);
    form.setFieldValue("patient_id");
    form.setFieldValue("old_due", 0.0);
    form.setFieldValue("wallet_amount", 0.0);
    setWallet("");
    setPatientMobile("");
    form.setFieldValue("patient_mobile");
  };
  const changePaymentType = (value) => {
    setpaymentType(value);
  };

  useEffect(() => {
    const tabletMap = {};
    const repeatedIndices = [];

    if (Number(keys) >= 0) {
      Object.values(values?.sales_information).forEach((item, index) => {
        if (!item) {
          return;
        } else if (!item.batch_no) {
          return;
        }
        const tabletId = item.tablet_id;
        const batchNo = item.batch_no;

        const key = tabletId + "-" + batchNo;

        if (tabletMap[key] !== undefined) {
          repeatedIndices.push(index, tabletMap[key]);
        } else {
          tabletMap[key] = index;
        }
      });

      if (repeatedIndices.length > 0) {
        setError(repeatedIndices);
      } else {
        setError(null);
      }
    }

    setKeys();
  }, [keys]);

  const OnChangeDiscountPercentage = () => {
    let calculatedTotal = 0;
    let calculatedSubtotal = 0;
    let calculatedTotalDiscount = 0;
    let calculatedTotalRoundoff = 0;
    let calculatedDue = 0;

    const purchaseDetailsArray = values?.sales_information
      ? Object.values(values?.sales_information).filter(
          (value) => value != null
        )
      : [];
    purchaseDetailsArray?.forEach((product) => {
      const total = Number(product.total);
      calculatedSubtotal += total;
    });

    calculatedTotalDiscount =
      (calculatedSubtotal * Number(values?.discount)) / 100;

    calculatedTotal =
      calculatedSubtotal -
      (calculatedTotalDiscount ? calculatedTotalDiscount : 0);

    if (calculatedTotal - Math.floor(calculatedTotal) < 0.5) {
      calculatedTotalRoundoff = Math.floor(calculatedTotal);
    } else {
      calculatedTotalRoundoff = Math.ceil(calculatedTotal);
    }
    const old_due = Number(values?.old_due) ? Number(values?.old_due) : 0;
    const deposite = Number(values?.deposite) ? Number(values?.deposite) : 0;

    if (old_due + Number(calculatedTotalRoundoff) - deposite <= 0) {
      calculatedDue = old_due + Number(calculatedTotalRoundoff) - deposite;
    } else {
      calculatedDue =
        old_due + Number(calculatedTotalRoundoff) - (deposite + Number(wallet));
    }

    const calculateWallet = wallet - calculatedTotalRoundoff;
    form.setFieldValue("discount_amount", calculatedTotalDiscount.toFixed(2));
    form.setFieldValue("sub_total", calculatedSubtotal.toFixed(2));
    form.setFieldValue("total", calculatedTotalRoundoff.toFixed(2));

    if (calculateWallet >= 0) {
      form.setFieldValue("wallet_amount", calculateWallet.toFixed(2));
    } else {
      form.setFieldValue("wallet_amount", 0.0);
    }

    setDue(calculatedDue);
    if (Number(calculatedDue) >= 0) {
      form.setFieldValue("due", calculatedDue.toFixed(2));
    } else {
      form.setFieldValue("due", 0.0);
    }
    if (!salesData) {
      form.setFieldValue("deposite", 0.0);
    }
  };

  const handlePatient = (e) => {
    const patient_mobile = patientDetail?.data?.find(
      (val) => val.id === e.value
    ).contact;

    setPatientMobile(patient_mobile);
    form.setFieldValue("patient_mobile", patient_mobile);
    const formData = {
      patient_id: e.value,
    };
    dispatch(salesPaymentDueInProgress(formData));
  };

  useEffect(() => {
    if (Number(key) >= 0) {
      const quantity = form.getFieldValue([
        `sales_information`,
        key,
        "quantity",
      ]);
      const purchase_price = form.getFieldValue([
        `sales_information`,
        key,
        "unit_price",
      ]);
      const totalAmount = Number(quantity) * Number(purchase_price);
      if (totalAmount) {
        form.setFieldValue(
          [`sales_information`, key, "total"],
          totalAmount.toFixed(2)
        );
      } else if (Number(totalAmount) === 0) {
        form.setFieldValue([`sales_information`, key, "total"], 0.0);
      }
      setKey();
    }
    if (locationLoading) {
      OnChangeDiscountPercentage();
    }
    setLocationLoading(true);
    setKey();
  }, [key]);

  useEffect(() => {
    if (discount) {
      OnChangeDiscountPercentage();
    }
  }, [discount]);

  const onChangeTablet = (data, key, view, edit) => {
    if (edit) {
      form.setFieldValue(
        [`sales_information`, key, "tablet_id"],
        data?.medicine_name || data.product_name
      );
      setBatch((prevBatch) => [...prevBatch, { ids: data, key }]);
    } else {
      form.setFieldValue(
        [`sales_information`, key, "tablet_id"],
        data?.tablet_id || data?.id
      );
    }
    form.setFieldValue(
      [`sales_information`, key, "product_name"],
      data?.medicine_name || data.product_name
    );

    form.setFieldValue(
      [`sales_information`, key, "purchase_code"],
      data?.purchase_code
    );
    form.setFieldValue(
      [`sales_information`, key, "quantity"],
      data.available_stock ? 1 : data.quantity || 0
    );
    form.setFieldValue(
      [`sales_information`, key, "purchase_price"],
      data.purchase_price || 0
    );

    form.setFieldValue(
      [`sales_information`, key, "unit_price"],
      Number(data.unit_price) === 0 ? data.purchase_price : data.unit_price || 0
    );
    form.setFieldValue(
      [`sales_information`, key, "gst_percentage"],
      data.gst_percentage || 0
    );
    let availableStocks = 0;

    const index = returnQunatity.findIndex((values) => values.key === key);
    const availableStock =
      Number(data?.available_stock || data.quantity) + Number(availableStocks);
    if (index !== -1) {
      const updatedBatchNo = [...returnQunatity];

      updatedBatchNo[index] = { availableStock, key };
      setReturnQunatity(updatedBatchNo);
    } else {
      setReturnQunatity((prevBatchNo) => [
        ...prevBatchNo,
        { availableStock, key },
      ]);
    }
    if (view) {
      getTabletsId?.data?.response?.data?.forEach((ids) => {
        if (ids.id === data?.tablet_id) {
          const availableStock = ids?.Store_details?.find(
            (id) => id?.batch_no === data.batch_no
          )?.available_stock;

          // Use a callback function to ensure batch is updated after data is set
          setBatch((prevBatch) => [...prevBatch, { ids, key }]);
          // Assuming you need to update availableStocks as well
          availableStocks = availableStock;
        }
      });
    }
    form.setFieldValue(
      [`sales_information`, key, "free_quantity"],
      data.free_quantity || 0
    );

    if (view) {
      formRef.current.setFieldsValue({
        [`sales_information.${key}.batch_no`]: data.batch_no,
      });
      form.setFieldValue([`sales_information`, key, "batch_no"], data.batch_no);
    } else {
      form.setFieldValue([`sales_information`, key, "batch_no"], data.batch_no);
    }

    form.setFieldValue(
      [`sales_information`, key, "total"],

      (data.available_stock ? data.available_stock : data.quantity) *
        (Number(data.unit_price) === 0
          ? data.purchase_price
          : data.unit_price) || 0
    );
    form.setFieldValue(
      [`sales_information`, key, "packing_unit"],
      data.packing_unit || 0
    );
    form.setFieldValue(
      [`sales_information`, key, "sale_price"],
      data.sale_price || 0
    );
    form.setFieldValue(
      [`sales_information`, key, "discount"],
      data.discount || 0
    );
    form.setFieldValue(
      [`sales_information`, key, "expiry_date"],
      data.expiry_date
    );
  };
  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => prev + 1);
  };
  const deleteRow = (key) => {
    const updatedInput = input.filter((row) => row.key !== key);

    setInput(updatedInput);
    if (formData) {
      const formDataCopy = { ...formData, ...values };
      formDataCopy.sales_information[key] = null;
      setFormData(formDataCopy);
    } else {
      const formDataCopy = { ...values };
      formDataCopy.sales_information[key] = null;
      setFormData(formDataCopy);
    }
    setKey(key);
  };
  let timerId;

  const handleFilter = (input) => {
    setTimer(true);

    if (timerId) {
      clearTimeout(timerId);
    }

    // Set a new timer to dispatch the action after 500 milliseconds
    timerId = setTimeout(() => {
      const fromData = { pagination_required: false, page: 1, name: input };
      dispatch(getTabletsIdInProgress(fromData));
    }, 500); // Adjust the delay time (e.g., 500 milliseconds) as needed
  };
  const handlePrescriptionModle = () => {
    setPrescriptionstModal(true);
  };
  const columns = [
    {
      title: "Product Name",
      dataIndex: "tablet_id",
      render: (_, render) => (
        <>
          {" "}
          {viewData ? (
            <Form.Item shouldUpdate>
              {" "}
              <FormInput
                name={[`${render.key}`, "tablet_id"]}
                onChange={(e) => {
                  setKey(render.key);
                }}
                className="input len"
                type="text"
                readOnly={true}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Enter the  batch no"} />,
                  },
                ]}
              />{" "}
            </Form.Item>
          ) : (
            <Form.Item
              name={[`${render.key}`, "tablet_id"]}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"select the  tablet"} />,
                },
              ]}
            >
              <FormSelectBox
                options={
                  getTabletsId?.data?.response?.data.map((pack, i) => {
                    return { label: pack.medicine_name, value: pack.id };
                  }) || []
                }
                placeholder="Select"
                className="sel select-box"
                onSelect={(e) => {
                  const ids = getTabletsId?.data?.response?.data?.find((id) => {
                    if (id.id === e) {
                      return id;
                    }
                  });
                  if (batch.find((key) => key.key === render.key)) {
                    batch.splice(
                      batch.findIndex((val) => val.key === render.key),
                      1
                    );
                  }

                  setBatch([...batch, { ids, key: render.key }]);
                  setKeys(render.key);
                  form.setFieldValue([
                    `sales_information`,
                    render.key,
                    "batch_no",
                  ]);
                  // setKey(render.key);
                }}
                editMode="true"
                forFilter="true"
                showSearch="true"
                handleFilter={handleFilter}
              />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batch_no",

      render: (_, render) => (
        <Form.Item
          name={[`${render.key}`, "batch_no"]}
          initialValue={
            salesData?.sales_information?.[render.key]?.batch_no || ""
          }
          rules={[
            {
              required: true,
              message: <IntlMessages id={"select the  batch no"} />,
            },
            // {
            //   validator: (_, value) => {
            //     if (
            //       error?.find((data) => data === render.key) === 0 ||
            //       error?.find((data) => data === render.key)
            //     ) {
            //       return Promise.reject(
            //         `This batch number is already selected  ${value}`
            //       );
            //     }
            //     return Promise.resolve();
            //   },
            // },
          ]}
          shouldUpdate
        >
          <FormSelectBox
            options={
              batch
                .find((key) => key.key === render.key)
                ?.ids?.Store_details?.map((pack, i) => {
                  return {
                    label: `${pack.batch_no}- (${pack.available_stock})`,
                    value: pack.batch_no,
                  };
                }) || []
            }
            defaultValue={
              salesData?.sales_information
                ? Object.values(salesData?.sales_information)?.find(
                    (data, index) => index === render.key
                  )?.batch_no
                : salesData?.sales_details?.find(
                    (data, index) => index === render.key
                  )?.batch_no
            }
            placeholder="Select"
            className="sel select-box"
            onSelect={(e) => {
              const ids = batch
                .find((key) => key.key === render.key)
                ?.ids?.Store_details.find((id) => {
                  if (id.batch_no === e) {
                    return id;
                  }
                });

              onChangeTablet(ids, render.key);
              setKey(render.key);
              setKeys(render.key);
            }}
            // onChange={(e) => {
            //   const ids = batch
            //     .find((key) => key.key === render.key)
            //     ?.ids?.Store_details.find((id) => {
            //       if (id.batch_no === e) {
            //         return id;
            //       }
            //     });

            //   onChangeTablet(ids, render.key);
            //   setKey(render.key);
            //   setKeys(render.key);
            // }}
            // ref={Ref}
            editMode="true"
            forFilter="true"
            showSearch="true"
          />

          {error?.find((data) => data === render.key) === 0 ||
          error?.find((data) => data === render.key) ? (
            <Typography className="red-text">
              The selected batch no is already exists
            </Typography>
          ) : null}
        </Form.Item>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly={viewData}
            min={1}
            name={[`${render.key}`, "quantity"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the quantity"} />,
              },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    value <=
                      Number(
                        returnQunatity.find((val) => val?.key === render?.key)
                          ?.availableStock
                      )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    Number(
                      returnQunatity.find((val) => val?.key === render?.key)
                        ?.availableStock
                    )
                      ? `Available Stock are ${Number(
                          returnQunatity.find((val) => val?.key === render?.key)
                            ?.availableStock
                        )}`
                      : ""
                  );
                },
              },
            ]}
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "Expiry date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            name={[`${render.key}`, "expiry_date"]}
            className="input len"
            type="date"
            placeholder="date"
            min={disablePastDate()}
            defaultValue={currentDate}
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id={"select the expiry date"} />,
            //   },
            // ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      key: "purchase_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={1}
            name={[`${render.key}`, "purchase_price"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id={"Enter the purchase price"} />,
            //   },
            // ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Sale Price",
      dataIndex: "sale_price",
      key: "sale_price",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={1}
            name={[`${render.key}`, "sale_price"]}
            className="input len"
            type="number"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id={"Enter the sale price"} />,
            //   },
            // ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",

      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={0}
            name={[`${render.key}`, "unit_price"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id={"Enter the unit price"} />,
            //   },
            // ]}
          />{" "}
        </Form.Item>
      ),
    },
    {
      title: "GST (%)",
      dataIndex: "gst_percentage",
      key: "gst_percentage",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            min={0}
            name={[`${render.key}`, "gst_percentage"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id={"Enter the gst "} />,
            //   },
            // ]}
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          {" "}
          <FormInput
            readOnly
            name={[`${render.key}`, "total"]}
            onChange={() => {
              setKey(render.key);
            }}
            className="input len"
            type="number"
          />{" "}
        </Form.Item>
      ),
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, record) => (
        <>
          {!viewData && (
            <ButtonComponent
              icon={<DeleteFilled />}
              className="btn-edits"
              type="danger"
              onClick={() => {
                deleteRow(record.key);
                setKeys(record.key);
              }}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Spin
      indicator={antIcon}
      spinning={
        addSales?.inProgress ||
        editSaless?.inProgress ||
        updateSalesPayment?.inProgress ||
        addSalesPayment?.inProgress ||
        getSales?.inProgress
      }
    >
      <Card>
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          ref={formRef}
        >
          <Row className="register-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography className="header-tag">
                {editSales ? (
                  <>
                    <EditFilled />
                    <IntlMessages id={"label.editSale"} />
                  </>
                ) : (
                  <>
                    <PlusCircleFilled /> <IntlMessages id={"label.addSale"} />
                  </>
                )}
              </Typography>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<IntlMessages id={"Addnew.pharmacy.label.type"} />}
                name="patient_type"
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Select the patient type"} />,
                  },
                ]}
              >
                <FormSelectBox
                  editMode="true"
                  forFilter="true"
                  showSearch="true"
                  placeholder={"Select Type"}
                  value={
                    salesTypeList.find((data) => Number(data.value) === 1)
                      ?.label
                  }
                  options={salesTypeList}
                  onChange={changeSalesType}
                  className="responsive-select"
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="sales_date"
                label={<IntlMessages id={"Addnew.pharmacy.label.date"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.add_date"} />
                    ),
                  },
                ]}
              >
                <FormInput
                  type="date"
                  placeholder="date"
                  name="sales_date"
                  min={salesData ? null : disablePastDate()}
                  defaultValue={currentDate}
                  labelCol={"24"}
                  wrapperCol={"24"}
                />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} lg={24} sm={24} xs={24}>
              <Divider style={{ borderColor: "black", borderWidth: "1px" }} />
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="px-30">
              {" "}
              <Form.Item
                label={<IntlMessages id={"Addnew.pharmacy.label.doctor"} />}
                name="doctor"
                // rules={[
                //   {
                //     required: true,
                //     message: (
                //       <IntlMessages id={"Addnew.pharmacy.validator.doctor"} />
                //     ),
                //   },
                // ]}
              >
                <DoctorSelectAppointment
                  margin="mb-30"
                  label={""}
                  name={"doctor_id"}
                  placeholder={"Select Doctor"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Select the  Doctor",
                    },
                  ]}
                  className={`responsive-select`}
                  onChange={(e) => {
                    form.setFieldValue("doctorName", e?.label);
                  }}
                />
              </Form.Item>
            </Col>
            {Number(salesType) === 2 ? (
              <>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"doctorName"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.doctorName"} />
                    }
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"patientName"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.patientName"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"patient Name is required"} />
                        ),
                      },
                    ]}
                  />
                </Col>{" "}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"patient_mobile"}
                    label={
                      <IntlMessages
                        id={"Addnew.pharmacy.label.patientMobile"}
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"patient mobile is required"} />
                        ),
                      },
                      {
                        pattern: /^[6789][0-9]{9}$/,
                        message: "Please enter a valid 10-digit mobile number!",
                      },
                    ]}
                  />
                  {/* <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="number"
                    name={"patient_mobile"}
                    // min={10}
                    // max={10}
                    label={
                      <IntlMessages
                        id={"Addnew.pharmacy.label.patientMobile"}
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"patient mobile is required"} />
                        ),
                      },
                    ]}
                  /> */}
                </Col>{" "}
              </>
            ) : (
              <>
                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="px-30">
                  <Form.Item
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.patient"} />
                    }
                    name="patient"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: (
                    //       <IntlMessages
                    //         id={"Addnew.pharmacy.validator.patient"}
                    //       />
                    //     ),
                    //   },
                    // ]}
                  >
                    <SelectorsAppointment
                      margin="mb-10"
                      // wrapperCol={24}
                      // labelCol={24}
                      label={""}
                      name={"patient_id"}
                      placeholder={"Select  Patient"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: "Select the  patient",
                        },
                      ]}
                      getApiData={setPatientDetail}
                      className={`responsive-select`}
                      onChange={handlePatient}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row gutter={[0, 16]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="display-flex"
            >
              <Typography className="header-tag">
                {<IntlMessages id={"label.medicines"} />}
              </Typography>
              {prescription && (
                <ButtonComponent
                  label={"prescription"}
                  icon={<EyeOutlined />}
                  className="prescriptions"
                  onClick={() => {
                    handlePrescriptionModle();
                  }}
                />
              )}
            </Col>

            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography className="header-tag">
                {<IntlMessages id={"label.medicines"} />}
              </Typography>
            </Col> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.List name={"sales_information"}>
                {(fields, { add }) => (
                  <SalesFromTable
                    addRow={addRow}
                    add={add}
                    columns={columns}
                    input={input}
                    // viewData
                    // disabled={error}
                  />
                )}
              </Form.List>
              {input.length === 0 && (
                <Typography className="red-text">
                  select the sales informations !
                </Typography>
              )}
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <SalesProductForm
                wallet={wallet}
                salesInformationData={salesData}
                // setSaleseData={setPurchaseData}
                editSales={editSales}
                onFinish={onFinish}
                paymentData={paymentData}
                form={form}
                setUpdatedProduct={setUpdatedProduct}
                setDiscount={setDiscount}
              />
            </Col>
          </Row>
          <Row className="mt-20" justify={"space-between"}>
            <Col xl={12} align="left" className="button-alignment-pharmacy">
              {payment && (
                <Row guttur={[0, 16]} className="margin-row">
                  <Col xl={24}>
                    <Typography className="payment-heading">
                      Payments
                    </Typography>
                  </Col>
                  <Col xl={24} lg={24} xs={24} sm={24} md={24}>
                    <Row className="margin-row">
                      {paymentData &&
                        paymentData?.map((payment, index) => (
                          <Col
                            key={index}
                            xl={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                          >
                            <Typography>
                              {` ${index + 1}. Paid  ${
                                payment?.Paid_amount
                              } by  ${
                                payment?.payment_type
                                  ? paymentTypeList?.find(
                                      (val) =>
                                        Number(val.value) ===
                                        Number(payment?.payment_type)
                                    )?.label
                                  : null
                              } on ${moment(payment?.date).format(
                                "DD/MM/YYYY"
                              )}`}{" "}
                              <ButtonComponent
                                type={"primary"}
                                icon={<EditFilled />}
                                size="small"
                                className={"mt-10 ml-10"}
                                onClick={() => {
                                  handleEdit(payment, index);
                                }}
                              ></ButtonComponent>
                            </Typography>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col xl={24} className="py-2">
                    <ButtonComponent
                      label="Record Payments"
                      type="primary"
                      icon={<PlusCircleOutlined />}
                      onClick={handleModle}
                    />
                  </Col>
                </Row>
              )}
            </Col>

            <Col xl={12} align="right" className="button-alignment-pharmacy">
              <Link to={APP_ROUTES.SALES}>
                <ButtonComponent
                  label="Cancel"
                  className="btn-cancel secondary-color"
                />
              </Link>

              <ButtonComponent
                icon={<CreditCardOutlined />}
                label={editSales ? "Save" : "Submit"}
                htmlType="submit"
                type="primary"
                disabled={error?.length > 0 || input.length === 0}
              />
            </Col>
          </Row>
        </Form>
      </Card>

      <Modal
        title={<Typography className="cancel-sales">Record Payment</Typography>}
        open={paymentModal}
        width={800}
        closable={false}
        footer={[
          <Button key="back" type="danger" onClick={paymentModalOk}>
            Close
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinishModal}
          autoComplete="off"
          form={form2}
          ref={formref2}
        >
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="number"
                name={"Paid_amount"}
                label={<IntlMessages id={"Addnew.pharmacy.label.amountPaid"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.amountPaid"} />
                    ),
                  },
                  {
                    validator: async (_, value) => {
                      if (value <= 0) {
                        return Promise.reject(
                          "Amount paid must be greater than 0"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name={"date"}
                label={<IntlMessages id={"Addnew.pharmacy.label.date"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.add_date"} />
                    ),
                  },
                ]}
              >
                <FormInput
                  type="date"
                  placeholder="date"
                  name={"date"}
                  min={disablePastDate()}
                  labelCol={"24"}
                  wrapperCol={"24"}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={
                  <IntlMessages id={"Addnew.pharmacy.label.paymentType"} />
                }
                name="payment_type"
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Medicine.validator.paymentType"} />
                    ),
                  },
                ]}
              >
                <FormSelectBox
                  editMode="true"
                  forFilter="true"
                  showSearch="true"
                  value={paymentType}
                  options={paymentTypeList}
                  onChange={changePaymentType}
                  className="responsive-select"
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"remarks"}
                label={<IntlMessages id={"Addnew.pharmacy.label.remarks"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Medicine.validator.remarks"} />,
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <ButtonComponent label="Save" htmlType="submit" type="primary" />
            </Col>
          </Row>
        </Form>
      </Modal>
      <PrescriptionModel
        prescriptionsModal={prescriptionsModal}
        paymentModalOk={paymentModalOk}
        prescription_details={prescription?.prescriptionDetails}
      />
    </Spin>
  );
};

export default AddSales;
