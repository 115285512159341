import React, { useState, useEffect } from "react";
import { Steps, Button, Card } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import DoctorList from "./DoctorList";
import PatientList from "./PatientList";
import BlockList from "./BlockList"; // Assuming a similar component for Block
import WardList from "./WardList"; // Assuming a similar component for Ward
import RoomList from "./RoomList";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getAvailableRoomsListInProgress,
  roomBookInProgress,
  roomListsInProgress,
} from "../../../appRedux/Ward/WardAction";
import { useDispatch, useSelector } from "react-redux";
import Confirmation from "./Confirmation";

const { Step } = Steps;

const BookRoomScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [selectedWardId, setSelectedWardId] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [confirmationDetals, setconfirmationDetails] = useState({});
  const [otherDetails, setOtherDetails] = useState(false);
  const [allRoomDetails, setAllRomDetails] = useState({});

  const roomData = location?.state?.room;
  const roomListData = useSelector((state) => state.ward.availableRoomsList);
  const roomBookedData = useSelector((state) => state?.ward?.roomBook);
  const roomList = roomListData?.data?.data.filter(
    (room) => room.ward_id === selectedWardId
  );

  const steps = [
    {
      title: "Select Doctor",
      content: (selectedDoctorId, onDoctorSelect) => (
        <DoctorList
          selectedDoctorId={selectedDoctorId}
          onDoctorSelect={onDoctorSelect}
        />
      ),
    },
    {
      title: "Select Patient",
      content: (selectedPatientId, onPatientSelect) => (
        <PatientList
          selectedPatientId={selectedPatientId}
          onPatientSelect={onPatientSelect}
        />
      ),
    },
    {
      title: "Select Block",
      content: (selectedBlockId, onBlockSelect) => (
        <BlockList
          selectedBlockId={selectedBlockId}
          onBlockSelect={onBlockSelect}
        />
      ),
    },

    {
      title: "Select Ward",
      content: (selectedWardId, onWardSelect, setSelectedWardId) => (
        <WardList
          selectedWardId={selectedWardId}
          setSelectedWardId={setSelectedWardId}
          onWardSelect={onWardSelect}
          selectedBlockId={selectedBlockId}
        />
      ),
    },
    {
      title: "Select Room",
      content: (selectedRoomId, onRoomSelect) => (
        <RoomList
          selectedRoomId={selectedRoomId}
          onRoomSelect={onRoomSelect}
          roomList={roomList}
          setAllRomDetails={setAllRomDetails}
          roomBookedData={roomBookedData}
          handleRoomPage={handleRoomPage}
        />
      ),
    },
    {
      title: "Confirmation",
      content: () => (
        <Confirmation
          setconfirmationDetails={setconfirmationDetails}
          setOtherDetails={setOtherDetails}
          otherDetails={otherDetails}
        />
      ),
    },
  ];

  useEffect(() => {
    if (
      (current === 0 && selectedDoctorId !== null) ||
      (current === 1 && selectedPatientId !== null) ||
      (current === 2 && selectedBlockId !== null) ||
      (current === 3 && selectedWardId !== null) ||
      (current === 4 && selectedRoomId !== null)
    ) {
      setIsNextEnabled(true);
    } else {
      setIsNextEnabled(false);
    }
  }, [
    current,
    selectedDoctorId,
    selectedPatientId,
    selectedBlockId,
    selectedWardId,
    selectedRoomId,
  ]);

  const next = () => {
    setCurrent(current + 1);
    setIsNextEnabled(false); // Reset the next button state
  };

  const prev = () => {
    setCurrent(current - 1);
    if (
      (current === 1 && selectedDoctorId !== null) ||
      (current === 2 && selectedPatientId !== null) ||
      (current === 3 && selectedBlockId !== null) ||
      (current === 4 && selectedWardId !== null) ||
      (current === 5 && selectedRoomId !== null)
    ) {
      setIsNextEnabled(true);
    }
  };

  const handleFinish = () => {
    const formData = {
      patient_id: selectedPatientId,
      doctor_id: selectedDoctorId,
      block_id: selectedBlockId,
      ward_id: selectedWardId,
      room_id: selectedRoomId,
      room_type: allRoomDetails.room_type,
      check_in_date: confirmationDetals?.check_in_date,
      check_in_incharge: confirmationDetals?.check_in_incharge || "",
      check_in_reason: confirmationDetals?.check_in_reason,
      follow_procedure: confirmationDetals?.follow_procedure || "",
      checkin_by: confirmationDetals?.checkin_by || "",
      price_per_day: confirmationDetals?.price_per_day || "0",
      ...(allRoomDetails.room_type === "1"
        ? { ac_room_price: allRoomDetails.ac_room_price }
        : { non_ac_room_price: allRoomDetails.non_ac_room_price }),
    };
    console.log("priceeeeeeeeeeee", formData);
    dispatch(roomBookInProgress(formData));
  };

  const onDoctorSelect = (doctorId) => {
    setSelectedDoctorId(doctorId);
    setIsNextEnabled(doctorId !== null);
  };

  const onPatientSelect = (patientId) => {
    setSelectedPatientId(patientId);
    setIsNextEnabled(patientId !== null);
  };

  const onBlockSelect = (blockId) => {
    setSelectedBlockId(blockId);
    setIsNextEnabled(blockId !== null);
  };

  const onWardSelect = (wardId) => {
    setSelectedWardId(wardId);
    setIsNextEnabled(wardId !== null);
  };
  const onRoomSelect = (roomId) => {
    setSelectedRoomId(roomId);
    setIsNextEnabled(roomId !== null);
  };
  useEffect(() => {
    setSelectedBlockId(roomData?.block_id);
    setSelectedWardId(roomData?.ward_id);
  }, [roomData]);

  useEffect(() => {
    const payload = {
      pagination: "true",
      pageNo: "1",
      pageSize: "10",
      ward_id: selectedWardId,
    };
    if (selectedWardId != null || selectedWardId != undefined)
      dispatch(roomListsInProgress(payload));
  }, [selectedWardId]);
  useEffect(() => {
    if (roomBookedData?.success) {
      history.push("/ward-management");
    }
  }, [roomBookedData]);
  const handleRoomPage = (page) => {
    // const payload = {
    //   pagination: "true",
    //   pageNo: page,
    //   pageSize: "10",
    //   ward_id: selectedWardId,
    // };
    if (selectedWardId != null || selectedWardId != undefined) {
      const payload = { pagination: "true", pageNo: page, pageSize: "10" };
      dispatch(getAvailableRoomsListInProgress(payload));
    }
    // dispatch(roomListsInProgress(payload));
  };
  return (
    <Card>
      <Steps current={current}>
        {steps.map((item, index) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: "20px" }}>
        {steps[current].content(
          current === 0
            ? selectedDoctorId
            : current === 1
            ? selectedPatientId
            : current === 2
            ? selectedBlockId
            : current === 3
            ? selectedWardId
            : current === 4
            ? selectedRoomId
            : null,
          current === 0
            ? onDoctorSelect
            : current === 1
            ? onPatientSelect
            : current === 2
            ? onBlockSelect
            : current === 3
            ? onWardSelect
            : current === 4
            ? onRoomSelect
            : null
        )}
      </div>
      <div
        className="steps-action"
        style={{ marginTop: "20px", textAlign: "right" }}
      >
        {current > 0 && (
          <Button
            // style={{ margin: "0 8px" }}
            onClick={() => prev()}
            className="mb-10 secondary-color"
          >
            <ArrowLeftOutlined /> Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => next()}
            disabled={!isNextEnabled}
          >
            Next <ArrowRightOutlined />
          </Button>
        )}
        {current === steps.length - 1 && otherDetails && (
          <Button type="primary" onClick={handleFinish}>
            Done
          </Button>
        )}
      </div>
    </Card>
  );
};

export default BookRoomScreen;
