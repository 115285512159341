import { Col, Layout, Modal, Row } from "antd";
import React, { useState } from "react";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import { Link } from "react-router-dom";
const { Header, Footer, Sider, Content } = Layout;

const ViewSchedule = (props) => {
  const ViewData = props.location.state;

  const headerStyle = {
    textAlign: "center",
    color: "#fff",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#7dbcea",
    marginBottom: "20px",
  };
  const contentStyle = {
    textAlign: "center",
    minHeight: 120,
    lineHeight: "30px",
  };
  const siderStyle = {
    lineHeight: "30px",
    fontWeight: "500",
    fontSize: "16px",
  };
  const footerStyle = {
    textAlign: "center",
  };

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        closable={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Layout>
          <Header style={headerStyle}>SCHEDUILE ḌETAILS</Header>
          <Layout>
            <Sider style={siderStyle}>
              {" "}
              <Row style={{ textAlign: "center" }}>
                <Col span={24}>Day</Col> <Col span={24}>Start Time</Col>{" "}
                <Col span={24}>End Time</Col> <Col span={24}>Interval</Col>
              </Row>
            </Sider>
            <Content style={contentStyle}>
              <Row style={{ textAlign: "left", marginLeft: "20px" }}>
                <Col span={24}>{ViewData?.week_day}</Col>{" "}
                <Col span={24}>{ViewData?.start_time}</Col>{" "}
                <Col span={24}>{ViewData?.end_time}</Col>
                <Col span={24}>{ViewData?.interval}</Col>
              </Row>
            </Content>{" "}
          </Layout>{" "}
          <Footer style={footerStyle}>
            {" "}
            <Row className="button-row">
              <Col className="register-button">
                <Link to={"/schedule"}>
                  <ButtonComponent type="primary" label="Back" />
                </Link>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Modal>
    </div>
  );
};

export default ViewSchedule;
