import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal, Tooltip, Typography } from "antd";
import { DeleteFilled, LoadingOutlined, EditFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import TableComponent from "../../../components/Table/TableComponent";
import moment from "moment";

import {
  defaultHoliday,
  deleteHolidaysListInProgress,
  getHolidaysListInProgress,
} from "../../../appRedux/doctor/actions/Holiday";
import { access } from "../../../util/utilz";

const HolidaysTable = () => {
  const [loading, setLoading] = useState(false);
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const holidayListData = useSelector((state) => state?.holiday?.holidaylist);
  const addHolidayData = useSelector((state) => state?.holiday?.addholidaylist);
  const updateHolidayData = useSelector(
    (state) => state?.holiday?.updateholidaylist
  );
  const deleteHoliday = useSelector((state) => state.holiday.deleteholidaylist);
  const totalRecords = holidayListData?.data?.totalRecords;
  const holidayList = holidayListData?.data?.data;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteHolidaysListInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Note",
      dataIndex: "note",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("holidays", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  <Link
                    to={{
                      pathname: `/holidays/edit`,
                      state: { holidayData: record },
                    }}
                  >
                    <ButtonComponent type="primary" icon={<EditFilled />} />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("holidays", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteFilled />}
                      onClick={() => showModal(record.id)}
                    />
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    const page = 1;
    dispatch(getHolidaysListInProgress(page));
  }, []);

  useEffect(() => {
    dispatch(getHolidaysListInProgress());
  }, [deleteHoliday]);
  const handlePage = (page) => {
    dispatch(getHolidaysListInProgress(page));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(holidayList);
    } else {
      const newData = holidayList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    if (holidayListData?.progressing) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [holidayListData]);
  useEffect(() => {
    if (addHolidayData?.success || updateHolidayData?.success)
      dispatch(defaultHoliday());
  }, [addHolidayData, updateHolidayData]);
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : holidayList}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        itemList={holidayList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"note"}
        rowselector={handleSelectChange}
        documentTitle={"Holiday"}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
        // pagination={{
        //   total: totalRecords,
        //   onChange: (page) => handlePage(page),
        // }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default HolidaysTable;
