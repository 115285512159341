import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Form, Input, Spin } from "antd";
import {
  getPatientListByIdInProgress,
  patientUpdateListInProgress,
} from "../../../appRedux/admin/patient/action/action";

import { Link, useParams } from "react-router-dom";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import CountrySelector from "../../Doctor/component/CountrySelector";
import StateSelector from "../../Doctor/component/StateSelector";
import CitySelector from "../../Doctor/component/CitySelector";
import FormItem from "antd/es/form/FormItem";
import { APP_ROUTES } from "../../../constants/Routes";
import { LoadingOutlined } from "@ant-design/icons";

import BloodGroupSelectBox from "../bloodGroupSelectBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Select } from "antd";
import { validateDateFormat } from "../../../util/utilz";
const { Option } = Select;

function EditPatient() {
  const organization_id = localStorage.getItem("org_id");
  const [country, setCountry] = useState("");
  const history = useHistory();
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const [image, setImage] = useState(null);
  const [photo123, setphoto123] = useState(null);
  const selectedCityID = useRef("");

  const onChangeCountry = (value) => {
    setCountry(value);
    setCity("");
    setState("");
    form.setFieldValue("state", "");
    form.setFieldValue("city", "");
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
    setCity(null);
    form.setFieldValue("city", "");
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedCityID.current = city;
  }, [city]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);
  const [imageUrl, setImageUrl] = useState(null);

  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }
  const [file, setFile] = React.useState(null);

  const fileHandler = (e) => {
    setFile(e.target.files[0]);
  };
  const [Loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { getListById } = useSelector((state) => state.patient);
  const { updateList } = useSelector((state) => state.patient);

  const patientList = getListById?.data?.data;

  const userid = getListById?.data?.data?.user_id;
  const roleid = getListById?.data?.data?.role_id;

  let id = useParams();
  const onFinish = (datas) => {
    const handleSuccess = () => {
      history.push(APP_ROUTES.PATIENT);
    };
    let form = new URLSearchParams();
    form.append("firstName", datas.firstName);
    form.append("lastName", datas.lastName);
    form.append("contact", datas.contact);
    // form.append("country_code", selectedCountryID?.current?.id);
    // form.append("city", selectedCityID?.current?.id);
    // form.append("state", selectedStateID?.current?.id);
    form.append("country_code", datas.country_code);
    form.append("city", datas.city);
    form.append("state", datas.state);
    form.append("email", datas.email);
    form.append("address1", datas.address1);
    form.append("address2", datas.address2);
    form.append("dob", datas.dob);
    form.append("organization_id", organization_id);
    form.append("role_id", roleid);
    form.append("user_id", userid);
    form.append("blood_group_id", datas.blood_group_id);
    form.append("profile_image", image);
    form.append("gender_type", datas.gender_type);

    dispatch(patientUpdateListInProgress({ form, id: id.id, handleSuccess }));
  };
  useEffect(() => {
    const payload = {
      id: id.id,
      organization_id: organization_id,
    };
    dispatch(getPatientListByIdInProgress(payload));
  }, []);
  useEffect(() => {
    if (patientList) {
      const initialValues = {
        id: patientList.id,
        firstName: patientList.firstName,
        lastName: patientList.lastName,
        dob: patientList.dob,
        email: patientList.email,
        contact: patientList.contact,
        address1: patientList.address1 || "",
        address2: patientList.address2 || "",
        city: patientList.city || "",
        state: patientList.state || "",
        country_code: patientList.country_code || "",
        organization_id:
          patientList?.OrganizationPatientAccesses?.[0]?.Organization?.id,
        gender_type: patientList.gender_type,
        blood_group_id: patientList.blood_group_id || "",
        profile_image: null,
      };

      form.setFieldsValue(initialValues);

      // Set the profile image if it exists
      if (patientList.profile_image) {
        setphoto123(patientList.profile_image);
      }
    }
  }, [patientList]);

  useEffect(() => {
    if (getListById.inProgress) {
      setLoading(true);
    } else if (getListById.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [getListById.inProgress, getListById.success]);
  useEffect(() => {
    if (updateList.inProgress) {
      setLoader(true);
    } else if (updateList.success) {
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, [updateList.inProgress, updateList.success]);

  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];

  return (
    <div>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            form={form}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Col className="img">
              <img className="img-btn" src={photo123} />
            </Col>

            <Row className="register-row">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {/* <img src={file? URL.createObjectURL(file) : null} alt={file? file.name : null}/>
            <input type="file" onChange={fileHandler} /> */}

                <FormItem
                  label="Profile"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please upload your image!",
                  //   },
                  // ]}
                  name="profile_image"
                  valuePropName="fileList"
                >
                  <Input
                    type={"file"}
                    accept="image/*"
                    name="profile_image"
                    onChange={(e) => encodeImageFileAsURL(e)}
                  />
                </FormItem>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="FirstName"
                  name={"firstName"}
                  label={<IntlMessages id={"Addnew.patient.label.firstName"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.patient.validator.firstName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="LastName"
                  name={"lastName"}
                  label={<IntlMessages id={"Addnew.patient.label.lastName"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.patient.validator.lastName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="contact"
                  label={<IntlMessages id={"Addnew.patient.label.contact"} />}
                  name={"contact"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.contact"} />
                      ),
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  ]}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormInput
          labelCol={"24"}
          wrapperCol={"24"}
            type="text"
            placeholder="street"
            label={<IntlMessages id={"Addnew.patient.label.street"} />}
            name={"street"}
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"Addnew.patient.validator.street"} />
                ),
              },
            ]}
          />
        </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CountrySelector
                  margin="mb-30"
                  label={"Country"}
                  name={"country_code"}
                  onChange={onChangeCountry}
                  country={country}
                  placeholder={"Select Your Country"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Country",
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StateSelector
                  margin="mb-30"
                  label={"State"}
                  name={"state"}
                  onChange={onChangeState}
                  country={state}
                  countryId={country?.id}
                  placeholder={"Select Your State"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your State",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CitySelector
                  margin="mb-30"
                  label={"City"}
                  name={"city"}
                  onChange={onChangeCity}
                  country={city}
                  StateId={state?.id}
                  placeholder={"Select Your City"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Organization",
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="email"
                  placeholder="Email"
                  name={"email"}
                  label={<IntlMessages id={"Addnew.patient.label.email"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.email"} />
                      ),
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="date"
                  placeholder="DOB"
                  label={<IntlMessages id={"Addnew.patient.label.dob"} />}
                  name={"dob"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.dob"} />
                      ),
                    },
                    {
                      validator: validateDateFormat,
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="Address1"
                  label={<IntlMessages id={"Addnew.patient.label.address1"} />}
                  name={"address1"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.patient.validator.address1"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="address2"
                  label={<IntlMessages id={"Addnew.patient.label.address2"} />}
                  name={"address2"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: (
                  //       <IntlMessages
                  //         id={"Addnew.patient.validator.address2"}
                  //       />
                  //     ),
                  //   },
                  // ]}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormInput
            labelCol={"24"}
            wrapperCol={"24"}
            type = "text"
            placeholder="role_id"
            readOnly={true}
              label={<IntlMessages id={"Addnew.patient.label.role_id"} />}
              name={"role_id"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.patient.validator.role_id"} />
                  ),
                },
              ]}
            />
            
          </Col> */}
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormInput
            labelCol={"24"}
            wrapperCol={"24"}
            type = "text"
            placeholder="user_id"
              label={<IntlMessages id={"Addnew.patient.label.user_id"} />}
              name={"user_id"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.patient.validator.user_id"} />
                  ),
                },
              ]}
            />
            
          </Col> */}
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <OrganizationSelector
                margin="mb-30"
                label={"Organization"}
                name={"organization_id"}
                placeholder={"Select Your Organization"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter your Organization",
                  },
                ]}
              />
          </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <BloodGroupSelectBox
                  margin="mb-30"
                  label={"Blood Group"}
                  name={"blood_group_id"}
                  placeholder={"Select Your blood group"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your blood group",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Gender"
                  name="gender_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select gender",
                    },
                  ]}
                >
                  <Select placeholder="Select gender">
                    {genderOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <FormInput name="id" type="hidden" />
            </Row>
            <Row className="button-row">
              <Col className="register-button">
                <Link to={APP_ROUTES.CANCELPATIENTUPDATE}>
                  <ButtonComponent
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>
                <ButtonComponent
                  type="primary"
                  label="Update"
                  htmlType="submit"
                  loading={loader}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}

export default EditPatient;
