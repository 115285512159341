import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  showAuthMessage,
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  userTwitterSignInSuccess,
  VideoCallError,
  videoCallSuccess,
} from "../actions/Auth";

import {
  SIGNUP_USER,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  REQUEST_VIDEO_CALL_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import {
  facebookAuthProvider,
  auth,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../../../firebase/firebase";
import RegisterSaga from "./RegisterSaga";
import { message } from "antd";
import Message from "../../../components/Message/Message";
import { axiosInstance, getErrorMessageFromAPI } from "../../../util/utilz";
import { axiosConfig } from "../../../util/axiosInstance";

const createUserWithEmailPasswordRequest = async (formData) => {
  const SigninData = axios({
    url: `${axiosInstance}/auth/register`,
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });

  return SigninData;
};

const signInUserWithEmailPasswordRequest = async (formData) => {
  const LoginData = axios({
    url: `${axiosInstance}/auth/login`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return LoginData;
};
const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);
export const getAgoraToken = (params) => {
  return axios.get(`${axiosConfig.baseURL}/auth/generate-agora-token`, {
    params,
  });
};
function* createUserWithEmailPassword({ payload }) {
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);

    // yield put(showAuthMessage(signUpUser.data.message));
    yield put(userSignUpSuccess(signUpUser?.data?.userInfo));

    // if (signUpUser.data.message) {
    //   yield put(showAuthMessage(signUpUser.data.message));
    // } else {
    //   localStorage.setItem("user_id", signUpUser.user.uid);
    //   yield put(userSignUpSuccess(signUpUser.user.uid));
    // }
  } catch (error) {
    yield put(showAuthMessage("invalid details"));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);

    if (signInUser?.data?.message) {
      //  yield put(showAuthMessage(signInUser?.data?.message));
    } else {
      localStorage.setItem("user_id", signInUser?.data?.token);
      localStorage.setItem("doctor_id", signInUser?.data?.user?.doctor_id);
      localStorage.setItem("patient_id", signInUser?.data?.user?.patient_id);
      localStorage.setItem("id", signInUser?.data?.user?.user_id);
      localStorage.setItem("pass", signInUser?.data?.user?.password);
      localStorage.setItem("role_id", signInUser?.data?.user?.role_id);
      localStorage.setItem("email", signInUser?.data?.user?.email);
      localStorage.settItem("role_permision", signInUser?.data?.user?.access);

      yield put(userSignInSuccess(signInUser?.data?.user));
      // yield call(Message, {
      //   type: "error",
      //   content: signInUser?.data?.message,
      // });
      yield call(Message, { type: "success", content: "Login Successfully" });
    }
  } catch (error) {
    // message.error(getErrorMessageFromAPI(error));
    // yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("doctor_id");
      localStorage.removeItem("email");
      localStorage.removeItem("patient_id");
      localStorage.removeItem("id");
      localStorage.removeItem("pass");
      localStorage.removeItem("role_id");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function* VideocallWatcher({ payload }) {
  try {
    let { data } = yield call(getAgoraToken, payload);

    yield put(
      videoCallSuccess({
        data: data,
        message: data["message"],
        videoSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      VideoCallError({
        videoError: true,
        message: e["message"],
      })
    );
  }
}
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}
export function* videoCallStart() {
  yield takeEvery(REQUEST_VIDEO_CALL_IN_PROGRESS, VideocallWatcher);
}
export default function* authSagas() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
    fork(videoCallStart),
  ]);
}
