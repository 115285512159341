import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Modal, Tooltip } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultRoom,
  deleteRoomInProgress,
  roomListsInProgress,
} from "../../../appRedux/Ward/WardAction";
import Meta from "antd/lib/card/Meta";

const RoomsTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const wardData = location.state?.wardData;
  const history = useHistory();
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const roomListByWard = useSelector((state) => state?.ward?.roomList);
  const addRoomData = useSelector((state) => state?.ward?.addRoom);
  const editRoomResponse = useSelector((state) => state?.ward?.editRoom);
  const deleteRoom = useSelector((state) => state?.ward?.deleteRoom);
  const wardList = roomListByWard?.data?.data;
  const totalRecords = roomListByWard?.data?.data?.totalRecords;
  const goBackHandler = () => {
    history.goBack();
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteRoomInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Room Number",
      dataIndex: "room_no",
      key: "room_no",
    },
    {
      title: "Room Incharge Name",
      dataIndex: "room_incharge_name",
      key: "room_incharge_name",
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
      render: (text, record) => {
        return record.room_type === "1" ? "AC ROOM" : "NON AC ROOM";
      },
    },
    {
      title: "Room Status",
      dataIndex: "room_status",
      key: "room_status",
      render: (text, record) => {
        return record.room_type === "1" ? "Active" : "Inactive";
      },
    },
    {
      title: "Room Price",
      dataIndex: "room_type",
      key: "room_type",
      render: (text, record) => {
        return record.room_type === "1"
          ? record.ac_room_price
          : record.non_ac_room_price;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/rooms/edit`,
                  state: { roomData: record },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  icon={<EditFilled />}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteFilled />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = {
      pagination: "true",
      pageNo: page,
      pageSize: "10",
      ward_id: wardData?.id,
    };

    dispatch(roomListsInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(wardList);
    } else {
      const newData = wardList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    if (roomListByWard?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [roomListByWard]);
  useEffect(() => {
    const payload = {
      pagination: "true",
      pageNo: "1",
      pageSize: "10",
      ward_id: wardData?.id,
    };

    dispatch(roomListsInProgress(payload));
  }, []);

  useEffect(() => {
    if (addRoomData?.success || editRoomResponse?.success) {
      dispatch(defaultRoom());
    }
  }, [addRoomData, editRoomResponse]);
  useEffect(() => {
    if (deleteRoom?.success) {
      const payload = {
        pagination: "true",
        pageNo: "1",
        pageSize: "10",
        ward_id: wardData?.id,
      };

      dispatch(roomListsInProgress(payload));
    }
  }, [deleteRoom]);

  return (
    <Card>
      <Row style={{ display: "flex", justifyContent: "end" }} className="mx-20">
        <Link
          to={{
            pathname: `/ward`,
            state: {
              blockData: location?.state?.editRoom
                ? { id: wardData?.block_id, block_name: wardData?.block_name }
                : {
                    id: wardData?.Block?.id,
                    block_name: wardData?.Block?.block_name,
                  },
            },
          }}
        >
          <ButtonComponent type="danger" icon={<ArrowLeftOutlined />} />
        </Link>
      </Row>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.rooms"} />}
            Ward - {""}
            {wardData?.ward_name}
          </Typography>
        </Col>
        <Col>
          <Link
            to={{
              pathname: `/rooms/add`,
              state: { id: wardData },
            }}
          >
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newRoom"} />}
            />
          </Link>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        {" "}
        <Row gutter={[16, 16]}>
          {wardList?.map((mapRoom) => (
            <Col xl={6} lg={6} md={8} sm={12} xs={24}>
              {" "}
              <Card
                type="inner"
                title={
                  <Typography className="ward-heading">{`Room Number - ${mapRoom?.room_no}`}</Typography>
                }
                actions={[
                  <Tooltip placement="top" title={"Edit"}>
                    <Link
                      to={{
                        pathname: `/rooms/edit`,
                        state: { roomData: mapRoom },
                      }}
                    >
                      <ButtonComponent
                        className="secondary-color"
                        icon={<EditFilled />}
                        size={"small"}
                      ></ButtonComponent>
                    </Link>
                  </Tooltip>,
                  <Tooltip placement="top" title={"Delete"}>
                    <Typography>
                      {" "}
                      <ButtonComponent
                        type="danger"
                        icon={<DeleteFilled />}
                        size={"small"}
                        onClick={() => showModal(mapRoom.id)}
                      ></ButtonComponent>
                    </Typography>
                  </Tooltip>,
                ]}
              >
                <Row gutter={[16, 16]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {" "}
                    <Meta
                      description={`${
                        mapRoom.room_type === "1" ? "AC ROOM" : "NON AC ROOM"
                      } - (${
                        mapRoom.room_type === "1"
                          ? mapRoom.ac_room_price
                          : mapRoom.non_ac_room_price
                      })`}
                      title={`Room Incharge - ${mapRoom?.room_incharge_name}`}
                    />
                  </Col>

                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Typography>
                      {" "}
                      {`Room Status - ${
                        mapRoom?.room_status === "1" ? "Active" : "Inactive"
                      }`}
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default RoomsTable;
