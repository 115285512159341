import { Card, Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddConsultationInProgress,
  GetConsultationInProgress,
  UpdateConsultationInProgress,
} from "../../../appRedux/doctor/actions/consultation";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";
import { LoadingOutlined } from "@ant-design/icons";

const AddConsultationForm = () => {
  const [buttonloading, Setbuttonloading] = useState();

  const [form] = Form.useForm();
  const getconsultation = useSelector(
    (state) => state.consultation?.getconsultation
  );
  const addconsultation = useSelector(
    (state) => state.consultation?.addconsultation
  );
  const updateconsultation = useSelector(
    (state) => state.consultation?.updateconsultation
  );
  const getConsultation_Success = getconsultation && getconsultation?.success;
  const consultationId = getconsultation?.data?.data?.id;

  const dispatch = useDispatch();
  const doctor_id = localStorage.getItem("doctor_id");
  const user_id = localStorage.getItem("id");

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const onFinish = (datas) => {
    const { inperson_consultation_fee, tele_consultation_fee } = datas;

    {
      getconsultation?.data == "No fee found for this doctorId"
        ? dispatch(
            AddConsultationInProgress({
              doctor_id,
              user_id,
              inperson_consultation_fee,
              tele_consultation_fee,
            })
          )
        : dispatch(
            UpdateConsultationInProgress({
              doctor_id: doctor_id,
              user_id: user_id,
              inperson_consultation_fee: inperson_consultation_fee,
              tele_consultation_fee: tele_consultation_fee,
              consultationId: consultationId,
            })
          );
    }

    Setbuttonloading(true);
  };
  useEffect(() => {
    dispatch(GetConsultationInProgress(doctor_id));
  }, []);
  useEffect(() => {
    if (updateconsultation?.success)
      dispatch(GetConsultationInProgress(doctor_id));
  }, [updateconsultation?.success]);
  form.setFieldsValue(getconsultation?.data?.data);
  useEffect(() => {
    if (
      addconsultation?.success ||
      updateconsultation?.success ||
      updateconsultation?.error
    ) {
      Setbuttonloading(false);
    }
  }, [
    addconsultation?.success,
    updateconsultation?.success,
    updateconsultation?.error,
  ]);

  return (
    <Auxiliary>
      <Card>
        <Spin indicator={antIcon} spinning={getconsultation?.inProgress}>
          {" "}
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            form={form}
            onFinish={onFinish}
          >
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Inperson Consultation"
                  name="inperson_consultation_fee"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"consultation.fee.inperson"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"consultation.fee.validator.inperson"}
                        />
                      ),
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: (
                        <IntlMessages
                          id={"consultation.fee.validator.numeric"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Tele consultation"
                  name="tele_consultation_fee"
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={
                    <IntlMessages id={"consultation.fee.teleconsultation"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"consultation.fee.validator.teleconsultation"}
                        />
                      ),
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: (
                        <IntlMessages
                          id={"consultation.fee.validator.numeric"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row className="space">
              <Col className="btn-pos">
                {/* <Link to={"/consultation"}>
                  {" "}
                  <ButtonComponent
                    className={"secondary-color mr-10"}
                    label="Cancel"
                  />
                </Link> */}

                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  label="Submit"
                  loading={buttonloading}
                />
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </Auxiliary>
  );
};

export default AddConsultationForm;
