import { call, put, takeLatest } from "@redux-saga/core/effects";
import Message from "../../../../components/Message/Message";
import {
  addRolesError,
  addRolesSuccess,
  deleteROLESError,
  deleteROLESSuccess,
  getRolesListInSuccess,
  updateRolesError,
  updateRolesSuccess,
} from "../action/rolesAction";
import {
  addRolesInstanceListEffect,
  deleteRolesInstanceListEffect,
  getRolesInstanceListEffect,
  updateRolesInstanceListEffect,
} from "./effects/roleseffect";
import { getErrorMessageFromAPI } from "../../../../util/utilz";
import {
  ADD_ROLES_IN_PROGRESS,
  DELETE_ROLESlIST_IN_PROGRESS,
  GET_ROLESlIST_IN_PROGRESS,
  UPDATE_ROLESlIST_IN_PROGRESS,
} from "../../../../constants/ActionTypes";

function* addRoles({ payload }) {
  try {
    let { data } = yield call(addRolesInstanceListEffect, payload.rolesPayload);

    yield put(
      addRolesSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
  } catch (e) {
    yield put(
      addRolesError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
  }
}
function* getRolesList({ payload }) {
  try {
    const { data } = yield call(getRolesInstanceListEffect, payload);

    yield put(
      getRolesListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    // yield put(
    //     getTeamMemberListInError({
    //     message: getErrorMessageFromAPI(e),
    //     error: true,
    //   })
    // );
    // message.error(getErrorMessageFromAPI(e));
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
  }
}
function* deleteRoles({ payload }) {
  try {
    let { data } = yield call(deleteRolesInstanceListEffect, payload);

    yield put(
      deleteROLESSuccess({
        data: data,
        success: true,
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteROLESError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
  }
}
function* updateRoles({ payload }) {
  try {
    let { data } = yield call(
      updateRolesInstanceListEffect,
      payload.rolesPayload
    );

    yield put(
      updateRolesSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
  } catch (e) {
    // yield put(
    //   updateRolesError({
    //     error: true,
    //     message: e["message"],
    //   })
    // );
    if (e?.response?.data?.message) {
      yield call(Message, {
        type: "error",
        content: getErrorMessageFromAPI(e),
      });
    } else {
      yield call(Message, {
        type: "error",
        content: getErrorMessageFromAPI(e),
      });
    }

    if (e?.response?.data?.expired === "Y") {
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      window.location.reload();
    }
  }
}

export default function* rolesSaga() {
  yield takeLatest(ADD_ROLES_IN_PROGRESS, addRoles);
  yield takeLatest(GET_ROLESlIST_IN_PROGRESS, getRolesList);
  yield takeLatest(DELETE_ROLESlIST_IN_PROGRESS, deleteRoles);
  yield takeLatest(UPDATE_ROLESlIST_IN_PROGRESS, updateRoles);
}
