import {
  DELETE_USERS_LIST_ERROR,
  DELETE_USERS_LIST_PROGRESS,
  DELETE_USERS_LIST_SUCCESS,
  GET_USERS_LIST_ERROR,
  GET_USERS_LIST_PROGRESS,
  GET_USERS_LIST_SUCCESS,
  GET_USER_UPDATE_LIST_ERROR,
  GET_USER_UPDATE_LIST_PROGRESS,
  GET_USER_UPDATE_LIST_SUCCESS,
  USER_UPDATE_IN_ERROR,
  USER_UPDATE_IN_PROGRESS,
  USER_UPDATE_IN_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  userslist: { ...defaultStatus },
  deleteuserslist: { ...defaultStatus },
  getuserupdatelist: { ...defaultStatus },
  updateUser: { ...defaultStatus },
};
const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USERS_LIST_PROGRESS:
      return {
        ...state,
        userslist: {
          ...defaultStatus,
          progressing: true,
        },
      };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        userslist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_USERS_LIST_ERROR:
      return {
        ...state,
        userslist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DELETE_USERS_LIST_PROGRESS:
      return {
        ...state,
        deleteuserslist: {
          ...defaultStatus,
          progressing: true,
          ...payload,
        },
      };
    case DELETE_USERS_LIST_SUCCESS:
      return {
        ...state,
        deleteuserslist: {
          ...defaultStatus,
          success: true,
          ...payload,
        },
      };
    case DELETE_USERS_LIST_ERROR:
      return {
        ...state,
        deleteuserslist: {
          ...defaultStatus,
          error: true,
          ...payload,
        },
      };
    case GET_USER_UPDATE_LIST_PROGRESS:
      return {
        ...state,
        getuserupdatelist: { ...defaultStatus, ...payload, progressing: true },
      };
    case GET_USER_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        getuserupdatelist: { ...defaultStatus, ...payload.data, success: true },
      };
    case GET_USER_UPDATE_LIST_ERROR:
      return {
        ...state,
        getuserupdatelist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
