import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

const userLoginEffect = (formData) => {
  const LoginData = axiosInstance.request({
    url: "/auth/login",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return LoginData;
};
const RegisterEffect = (formData) => {
  const SigninData = axiosInstance.request({
    url: "/auth/register",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });

  return SigninData;
};

const getAccessToken = (data) => {
  const SigninData = axiosInstance.request({
    url: "/auth/refreshtoken",
    method: "POST",
    data: data,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });

  return SigninData;
};
export { userLoginEffect, RegisterEffect, getAccessToken };
