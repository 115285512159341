import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

//add1
export const addPrescriptionEffect = (formData, token) => {
  return axiosInstance.request({
    url: "/prescription/add",
    method: "POST",
    data: formData,
    headers: {
      organizationId: localStorage.getItem("org_id"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
//list
export const prescriptionListEffect = (formData, token) => {
  return axiosInstance.request({
    url: `/prescription/organization?pageNo=${formData}&pagesize=10&pagination_required=false`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
//no need
export const prescriptionDataEffect = (formData, token) => {
  return axiosInstance.request({
    url: "prescription/list",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
//noneed
export const prescriptionByPatientEffect = (formData, token) => {
  return axiosInstance.request({
    url: `/prescription/appointment/${formData}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
//add rowww
export const SavePrescriptionEffect = ({
  prescriptionId,
  prescription_details,
  special_instructions,
}) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/prescription/savePrescription/${prescriptionId}`,
    method: "PUT",
    data: {
      prescription_details: prescription_details,
      special_instructions: special_instructions,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
//delete
export const CancelPrescriptionEffect = (formData, token) => {
  const id = formData;

  return axiosInstance.request({
    url: `/prescription/cancelPrescription/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
//finsl submits
export const SubmitPrescriptionEffect = ({
  prescriptionId,
  prescription_details,
}) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/prescription/submitPrescription/${prescriptionId}`,
    method: "PUT",
    data: { prescription_details: prescription_details },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const seenPrescriptionEffect = ({ prescriptionId }) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `prescription/pharmacies/${prescriptionId}`,
    method: "GET",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
