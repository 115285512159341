import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { getBlockListInProgress } from "../../../appRedux/Ward/WardAction";
import TableComponent from "../../../components/Table/TableComponent";

const BlockList = ({ selectedBlockId, onBlockSelect }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const blockData = useSelector((state) => state.ward.blockList);
  const blockList = blockData?.data?.data;
  const handleBlockSelect = (record) => {
    if (onBlockSelect) {
      onBlockSelect(record.id);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Block Name",
      dataIndex: "block_name",
      key: "block_name",
    },
    {
      title: "Block Status",
      dataIndex: "block_status",
      key: "block_status",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => (
        <Row className="table-button">
          <Col className="button-edit">
            {selectedBlockId === record.id ? (
              <ButtonComponent
                className={"secondary-color"}
                label={"Selected Block"}
                onClick={() => handleBlockSelect(record)}
              />
            ) : (
              <ButtonComponent
                type="primary"
                label={"Select Block"}
                onClick={() => handleBlockSelect(record)}
              />
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (blockData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [blockData]);

  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  }, [dispatch]);
  const [filteredUsers, setFilteredUsers] = useState("");
  const totalRecords = blockData?.data?.totalRecords;
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(blockList);
    } else {
      const newData = blockList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const handlePage = (page) => {
    const payload = { pagination: "true", pageNo: page, pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  };
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.blockList"} />}
          </Typography>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : blockList}
          rowselector={handleSelectChange}
          itemList={blockList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          field={"block_name"}
          documentTitle="Block List"
          disableTableHeaderButtons={true}
        />
      </Spin>
    </Card>
  );
};

export default BlockList;
