import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

export const getDoctorListEffect = () => {
  const token = localStorage.getItem("user_id");

  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/doctors/organization?organization_id=${organization_id}&pagesize=10&pagination_required=false`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const doctorConsultationFeeEffect = (doctor_id) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/consultantFee/details?doctor_id=${doctor_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getpatientListAppointmentEffect = () => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/patients/organization?organization_id=${organization_id}&pagesize=10&pageNo=1&pagination_required=false`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getscheduleListEffect = (
  { date, doctor_id, currentDate, currentTime },
  token
) => {
  const tokens = localStorage.getItem("user_id") || token;
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");

  return axiosInstance.request({
    url: `/appointment/getSlot?date=${date}&doctor_id=${doctor_id}&current_date=${currentDate}&current_time=${currentTime}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
export const getpatientAppointmentListEffect = ({
  page,
  currentDate,
  currentTime,
}) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/appointment/patient/?patient_id=${patient_id}&pageNo=${page}&type=COM&isPrev=1&pagesize=10&pagination_required=true&current_time=${currentTime}&current_date=${currentDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getUpcomingPatientAppointmentListEffect = ({
  page,
  currentDate,
  currentTime,
}) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/appointment/patient/?patient_id=${patient_id}&pageNo=${page}&type=COM&isPrev=0&pagesize=10&pagination_required=true&current_time=${currentTime}&current_date=${currentDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPatientAppointmentEffect = (form) => {
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: "/appointment/book",
    method: "POST",
    data: form,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updatePatientAppointmentEffect = ({ id, form }) => {
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: `/appointment/update/${id}`,
    method: "PUT",
    data: form,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
export const getpatientAppointmentByIdEffect = (form, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/appointment/data/${form}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
export const deletePatientAppointmentListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/appointment/cancel/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteUpcomingPatientAppointmentListEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/appointment/cancel/${formData}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getPatientHealthRecordsEffect = () => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/healthRecord/allList?pagination_required=true&pageNo=1&pagesize=10",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const editPatientHealthRecordsEffect = ({ id, formData }) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/healthRecord/update/${id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const deletePatientHealthRecordsEffect = (id) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/healthRecord/delete/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addPatientHealthRecordsEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/healthRecord/insert",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getSinglePatientHealthRecordsEffect = (id) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `healthRecord/details?patient_id=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getAppoinmentByDoctorPatientEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `appointment/appointmentsByDoctorPatient/?doctor_id=${formData?.doctor_id}&patient_id=${formData?.patient_id}&pagesize=10&pageNo=${formData?.pageNo}&isPrev=${formData?.isPrev}&pagination_required=${formData?.pagination}&current_time=${formData?.current_time}&current_date=${formData?.current_date}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getPatientDashboardEffcet = () => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");

  return axiosInstance.request({
    url: "/user/patientdashboard",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      organizationId: localStorage.getItem("org_id"),
    },
  });
};
export const getVitalListEffect = () => {
  const id = localStorage.getItem("patient_id");
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: `/vitals/list?pagination_required=false&patient_id=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getVitalsByTypeEffect = (formData) => {
  const token = localStorage.getItem("user_id");

  const id = localStorage.getItem("patient_id");
  return axiosInstance.request({
    url: `/vitals/list?Vital_type=${formData}&patient_id=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addVitalEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/vitals/add",
    data: formData,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getBmiListEffect = () => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/bmi/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addBmiEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/bmi/insert",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getInsulinListEffect = () => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  const id = localStorage.getItem("patient_id");
  return axiosInstance.request({
    url: `/insulin/list?pagination_required=false&patient_id=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addInsulinEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/insulin/add",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getBloodGlucoseListEffect = () => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  const id = localStorage.getItem("patient_id");
  return axiosInstance.request({
    url: `/bloodglucose/list?pagination_required=false&patient_id=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addBloodGlucoseEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/bloodglucose/add",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getPatientByIdEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/patients/patientByserialno?param=${formData}&organization_id=${organization_id}`,
    method: "get",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getDoctorByIdEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patient_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/bloodglucose/add",
    method: "get",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
