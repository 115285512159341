import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Modal, Tooltip, Typography } from "antd";
import { LoadingOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";

import { Link } from "react-router-dom";
import "../../../styles/modules/superAdmin.less";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import SubscriptionHeader from "./SubscriptionHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultSubscription,
  deleteSubscriptionInProgress,
  getsubscriptionListInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";
import { getGstListInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";

const SubscriptionTable = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();

  const listData = useSelector((state) => state.superAdmin?.subscriptionList);
  const addData = useSelector((state) => state.superAdmin?.addSubscription);
  const editData = useSelector((state) => state.superAdmin?.editSubscription);
  const gstData = useSelector((state) => state.pharmacy?.gstList);
  const deleteData = useSelector(
    (state) => state.superAdmin?.deleteSubscription
  );
  const totalRecords = listData?.data?.totalRecords;
  const subscriptionList = listData?.data?.data;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteSubscriptionInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [hospitalListData, setHospitalListData] = useState(subscriptionList);
  const toggleStatus = (key) => {
    setHospitalListData(
      hospitalListData.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            status: item.status === "active" ? "inactive" : "active",
          };
        }

        return item;
      })
    );
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Plan Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Period",
      dataIndex: "periods",
      key: "periods",
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      render: (text, record, index) =>
        gstData?.data?.data.find((item) => item.id === text)?.gst,
    },
    {
      title: "Total Amount",
      dataIndex: "toatl",
      key: "toatl",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Edit"}>
                {" "}
                <Link
                  to={{
                    pathname: `/subscription/edit`,
                    state: { subscriptionData: record },
                  }}
                >
                  <ButtonComponent
                    className="secondary-color"
                    icon={<EditFilled />}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>

            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Delete"}>
                <Typography>
                  {" "}
                  <ButtonComponent
                    type="danger"
                    icon={<DeleteFilled />}
                    onClick={() => showModal(record.id)}
                  ></ButtonComponent>
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = { pagination: true, pageSize: "10", pageNo: page };
    dispatch(getsubscriptionListInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(subscriptionList);
    } else {
      const newData = subscriptionList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    const payload = { pagination: true, pageSize: "10", pageNo: 1 };
    dispatch(getsubscriptionListInProgress(payload));
    if (editData?.success || addData?.success) {
      dispatch(defaultSubscription());
    }
  }, []);
  useEffect(() => {
    if (listData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [listData]);
  useEffect(() => {
    if (deleteData?.success) {
      const payload = { pagination: true, pageSize: "10", pageNo: 1 };
      dispatch(getsubscriptionListInProgress(payload));
    } else if (deleteData?.inProgress) {
      setLoading(true);
    }
  }, [deleteData]);

  useEffect(() => {
    dispatch(getGstListInProgress());
  }, []);
  return (
    <Card>
      <SubscriptionHeader />
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : subscriptionList}
          rowselector={handleSelectChange}
          itemList={hospitalListData}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"hospital_name"}
          documentTitle={"Subscrption List"}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
        />
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default SubscriptionTable;
