import React, { useEffect, useState } from "react";
import Auxiliary from "../../../util/Auxiliary";
import { Col, Form, Row } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
const AddPayment = ({ form }) => {
  const [key, setKey] = useState();
  const [payment, setPayment] = useState(true);
  const subTotal = form?.getFieldValue("sub_total") || 0;
  const oldDue = form?.getFieldValue("old_due_amount") || 0;
  const BillingPayments = form?.getFieldValue("BillingPayments") || 0;
  const discount = form?.getFieldValue("discount") || 0;
  useEffect(() => {
    let newPayment = 0;
    if (BillingPayments) {
      BillingPayments?.forEach((element) => {
        newPayment += Number(
          element ? (element?.paid_amount ? element?.paid_amount : 0) : 0
        );
      });
    }

    form.setFieldsValue({
      grand_total: Number(subTotal) + Number(oldDue) - Number(discount),
    });
    form.setFieldsValue({
      due: Number(subTotal) + Number(oldDue) - (newPayment + Number(discount)),
    });
    setPayment();
  }, [subTotal, oldDue, payment]);

  const handleDiscount = (e) => {
    const subTotal = form?.getFieldValue("sub_total") || 0;
    const discount = form?.getFieldValue("discount") || 0;

    let newPayment = 0;
    if (BillingPayments) {
      BillingPayments?.forEach((element) => {
        newPayment += Number(
          element ? (element?.paid_amount ? element?.paid_amount : 0) : 0
        );
      });
    }

    form.setFieldsValue({
      grand_total: Number(subTotal) + Number(oldDue) - Number(discount),
    });
    form.setFieldsValue({
      due: Number(subTotal) + Number(oldDue) - (newPayment + Number(discount)),
    });
  };
  const handleDue = (e) => {
    const BillingPayments = form?.getFieldValue("BillingPayments");
    const grand_total = form?.getFieldValue("grand_total");
    let newPayment = 0;
    BillingPayments.forEach((element) => {
      newPayment += Number(
        element ? (element?.paid_amount ? element?.paid_amount : 0) : 0
      );
    });
    form.setFieldsValue({ due: Number(grand_total) - newPayment });
  };
  const depositType = [
    { key: 1, value: "cash" },
    { key: 3, value: "online" },
    { key: 2, value: "cheque" },
    { key: 4, value: "card" },
  ];
  let billings = form?.getFieldValue("BillingPayments") || [];
  let ref = billings?.map((val) => val?.Deposit_type);

  useEffect(() => {
    if (key) {
      ref = billings?.map((val) => val?.Deposit_type);
    }
  }, [key]);

  return (
    <Auxiliary>
      <Row style={{ flexDirection: "row" }}>
        {" "}
        <Col xs={24} md={12} lg={8} xl={8}>
          <FormInput
            type="text"
            name="sub_total"
            label={"Sub Total"}
            readOnly
            rules={[
              {
                required: true,
                message: "Enter the sub total",
              },
            ]}
          />
        </Col>
        <Col xs={24} md={12} lg={8} xl={8}>
          <FormInput
            type="number"
            name="discount"
            label={"Discount"}
            rules={[
              {
                required: true,
                message: "Enter the Discount",
              },
            ]}
            onChange={handleDiscount}
          />
        </Col>
        <Col xs={24} md={12} lg={8} xl={8}>
          <FormInput
            type="text"
            name="grand_total"
            label={"Gross Total"}
            rules={[
              {
                required: true,
                message: "Enter the Grant Total",
              },
            ]}
            readOnly
          />
        </Col>
        <Col xs={24} p={0} className="p-0">
          <Form.List name="BillingPayments">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <>
                    {/* <Typography>Add Payment</Typography> */}
                    <Row style={{ flexDirection: "row" }}>
                      {" "}
                      <Col xs={24} md={12} lg={8} xl={8}>
                        <Form.Item
                          label={"Deposit Type"}
                          name={[field.name, "Deposit_type"]}
                          rules={[
                            {
                              required: true,
                              message: (
                                <IntlMessages id={"Select the deposit mode"} />
                              ),
                            },
                          ]}
                        >
                          <FormSelectBox
                            options={depositType}
                            placeholder="Select"
                            className="sel"
                            onChange={() => {
                              setKey(depositType);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      {ref[index] !== "cash" && (
                        <Col xs={24} md={12} lg={8} xl={8}>
                          <Form.Item>
                            <FormInput
                              type="text"
                              name={[field.name, "ref_no"]}
                              label={"Ref No"}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter the Paid Amount",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col
                        xs={24}
                        md={12}
                        lg={8}
                        xl={8}
                        className="row-reverser"
                      >
                        {" "}
                        {index != 0 ? (
                          <ButtonComponent
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              setPayment(index);
                              remove(index);
                            }}
                            type="text"
                            danger
                            className={"m-0 p-0"}
                          />
                        ) : null}
                        <Form.Item className={"m-0"}>
                          <FormInput
                            type="number"
                            name={[field.name, "paid_amount"]}
                            label={"Paid Amount"}
                            rules={[
                              {
                                required: true,
                                message: "Enter the Paid Amount",
                              },
                            ]}
                            onChange={handleDue}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ))}
                <Col xs={24}>
                  <ButtonComponent
                    label={"Add Payment"}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      add();
                    }}
                  />
                </Col>
              </>
            )}
          </Form.List>
        </Col>
        <Col xs={24} md={12} lg={8} xl={8}>
          <FormInput
            type="text"
            name="due"
            label={"Due Amount"}
            readOnly
            rules={[
              {
                required: true,
                message: "Enter the Paid Amount",
              },
            ]}
          />
        </Col>
        <Col xs={24} md={12} lg={8} xl={8}>
          <FormInput
            label={"Note"}
            type="text"
            name="remarks"
            defaultValue={""}
          />
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default AddPayment;
