import React from "react";
import { Card } from "antd";

import Auxiliary from "../../util/Auxiliary";

import SubPackagesHeader from "./SubPackagesHeader";
import SubPackageTable from "./SubPackageTable";

const SubpackagesScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <SubPackagesHeader />

        {/* <BillingPackageHeader />
        <BillingpackageTable /> */}
        <SubPackageTable />
      </Card>
    </Auxiliary>
  );
};
export default SubpackagesScreen;
