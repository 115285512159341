import { Button, Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Auxiliary from "../../util/Auxiliary";
import FormInput from "../../components/Input/FormInput";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import AppointmentQuestionsHeader from "./AppointmentQuestionsHeader";
import SelectBoxAppointment from "../Appoinment/SelectBox";
import { answerType } from "./data";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  addQuestionAnswerInProgress,
  getDiseaseListInProgress,
  updateQuestionAnswerInProgress,
} from "../../appRedux/doctor/actions/appointmentQuestions";
import { APP_ROUTES } from "../../constants/Routes";
import { useHistory } from "react-router-dom";

function AppointmentQuestions({ edit, editData, view }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [showAnswer, setShowAnswer] = useState(2);

  const { getListDiseases, addQuestionAnswer, updateQuestionAnswer } =
    useSelector((state) => state?.appointmentQuestions);

  useEffect(() => {
    if (
      (addQuestionAnswer?.success && navigate) ||
      (updateQuestionAnswer?.success && navigate)
    ) {
      history.push(APP_ROUTES.APPOINMENTQUESTIONSCREEN);

      setNavigate(false);
    }
  }, [addQuestionAnswer?.success, updateQuestionAnswer?.success]);
  useEffect(() => {
    const payLoad = {
      pagination: false,
      page: 1,
    };
    dispatch(getDiseaseListInProgress(payLoad));
  }, []);
  useEffect(() => {
    if (editData) {
      form.setFieldValue("question", editData?.question?.questions);
      form.setFieldValue("diseases", editData?.question?.disease_id);
      form.setFieldValue("answer_type", editData?.question?.answers_type);
      if (editData?.question?.answers) {
        editData?.question?.answers.map((answer, index) => {
          form.setFieldValue([`answers`, index, "answer"], answer.answer);
        });
      }

      setShowAnswer(
        editData?.question?.answers_type
          ? editData?.question?.answers_type
          : showAnswer
      );
    }
  }, [editData]);
  const handleAnswerType = (e) => {
    setShowAnswer(e.value);
  };
  const onFinish = (values) => {
    if (editData) {
      values.disease_id = values.diseases;
      values.answers_type = values.answer_type;
      values.questions = values.question;
      values.answers = values.answers || "";
      dispatch(
        updateQuestionAnswerInProgress({
          data: values,
          id: editData?.question?.id,
        })
      );
      setNavigate(true);
    } else {
      values.disease_id = values.diseases;
      values.answers_type = values.answer_type;
      values.answers = values.answers || "";
      dispatch(addQuestionAnswerInProgress(values));
      setNavigate(true);
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <Auxiliary>
      <Spin
        indicator={antIcon}
        spinning={
          addQuestionAnswer?.progressing || updateQuestionAnswer?.progressing
        }
      >
        <AppointmentQuestionsHeader />
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          // initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
          initialValues={{ answers: [{}] }}
        >
          <Row>
            <Col xs={24}>
              <SelectBoxAppointment
                margin="mb-10"
                label={<IntlMessages id={"Addnew.questions.diseases"} />}
                name={"diseases"}
                placeholder={"Select  Disease Type"}
                editMode={view ? false : true}
                // disabled={view}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Select the diseases type"} />,
                  },
                ]}
                options={
                  getListDiseases?.data?.data?.map((val) => {
                    return { label: val.name, value: val.id };
                  }) || []
                }
                // onChange={handleAnswerType}
              />
            </Col>
            <Col xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name="question"
                readOnly={view}
                placeholder={"Enter the questions"}
                label={<IntlMessages id={"Addnew.appointment.questions"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Enter the questions"} />,
                  },
                ]}
              />
            </Col>
            <Col xs={24}>
              <SelectBoxAppointment
                margin="mb-10"
                label={<IntlMessages id={"Addnew.questions.answer.type"} />}
                name={"answer_type"}
                placeholder={"Select Answer Type"}
                editMode={view ? false : true}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Select the answer type"} />,
                  },
                ]}
                options={answerType}
                onChange={handleAnswerType}
              />
            </Col>
          </Row>

          {showAnswer !== 2 && showAnswer !== 4 && (
            <Form.List name="answers">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Form.Item
                        {...restField}
                        rules={[{ required: true, message: "Missing answer" }]}
                      >
                        <Row>
                          <Col xs={23}>
                            <FormInput
                              labelCol={"24"}
                              wrapperCol={"24"}
                              type="text"
                              name={[name, "answer"]}
                              placeholder={"Enter the answer"}
                              label={
                                <IntlMessages id={"Addnew.questions.answer"} />
                              }
                              readOnly={view}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <IntlMessages id={"Enter the answer"} />
                                  ),
                                },
                              ]}
                            />
                          </Col>
                          <Col xs={1} className="remove-button">
                            {view ? null : (
                              <>
                                {" "}
                                {fields.length > 1 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(name)}
                                  />
                                ) : null}
                              </>
                            )}
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  ))}
                  <Form.Item>
                    {view ? null : (
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add field
                      </Button>
                    )}
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}

          <Form.Item>
            {view ? null : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Auxiliary>
  );
}

export default AppointmentQuestions;
