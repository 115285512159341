import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Typography, Tooltip } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import TableComponent from "../../../components/Table/TableComponent";
import {
  defaultSupplier,
  deleteSupplierInProgress,
  getSupplierListInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { access } from "../../../util/utilz";

const SuppliersTable = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const supplierListData = useSelector(
    (state) => state?.pharmacy?.getSupplierList
  );
  const deleteSupplierData = useSelector(
    (state) => state?.pharmacy?.deleteSupplier
  );
  const supplierList = supplierListData.data?.data;

  const totalRecords = supplierListData.data?.totalRecords;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const columns = [
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
    },
    {
      title: "Supplier Name",
      dataIndex: "company_name",
      sortDirections: ["ascend", "descend"],
      key: "supplierName",
      sorter: (a, b) => {
        if (a && b && a.supplierName && b.supplierName) {
          return a.supplierName.localeCompare(b.supplierName);
        }
        return 0;
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      sortDirections: ["ascend", "descend"],
      key: "email",
      sorter: (a, b) => {
        if (a && b && a.email && b.email) {
          return a.email.localeCompare(b.email);
        }
        return 0;
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sortDirections: ["ascend", "descend"],
      key: "mobile",
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      sortDirections: ["ascend", "descend"],
      key: "contact_person",
      sorter: (a, b) => {
        if (a && b && a.contactPerson && b.contactPerson) {
          return a.contactPerson.localeCompare(b.contactPerson);
        }
        return 0;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      sortDirections: ["ascend", "descend"],
      key: "address",
      sorter: (a, b) => {
        if (a && b && a.address && b.address) {
          return a.address.localeCompare(b.address);
        }
        return 0;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"View"}>
                {" "}
                <Link
                  to={{
                    pathname: "/supplier/view",
                    state: { supplierData: record },
                  }}
                >
                  <ButtonComponent
                    type="primary"
                    icon={<EyeOutlined />}
                    // onClick={()=>editPatient(record.id)}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {access("suppliers", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/supplier/edit`,
                      state: { supplierData: record },
                    }}
                  >
                    <ButtonComponent
                      className="secondary-color"
                      icon={<EditOutlined />}
                      // onClick={()=>editPatient(record.id)}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("suppliers", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
                <br />
                <br />
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteSupplierInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const payload = { pagination: true, pageSize: 10, pageNo: page };
    dispatch(getSupplierListInProgress(payload));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(supplierList);
    } else {
      const newData = supplierList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    if (deleteSupplierData?.success) {
      dispatch(getSupplierListInProgress(true));
    }
  }, [deleteSupplierData]);
  useEffect(() => {
    const payload = { pagination: true, pageSize: 10, pageNo: 1 };
    dispatch(getSupplierListInProgress(payload));
    dispatch(defaultSupplier());
  }, []);
  useEffect(() => {
    if (supplierListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [supplierListData]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      {access("suppliers", "view") && (
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : supplierList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          rowselector={handleSelectChange}
          itemList={supplierList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"company_name"}
          documentTitle={"Supplier List"}
        />
      )}
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <Typography>Are you sure, you want to delete the Data</Typography>
      </Modal>
    </Spin>
  );
};

export default SuppliersTable;
