import { Row, Col, Form } from "antd";
import React, { useEffect, useState } from "react";

import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";
import { disablePastDate } from "../../../util/utilz";
import FormSelectBox from "../../../components/SelectBox/SelectBox";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubscriptionDetailsInProgress,
  getOrgDetailsByIdInProgress,
  getOrganisationListInProgress,
  getPlanListInProgress,
  getsubscriptionListInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";

const RenewalForm = ({ selectedHospital, setIsModalOpen, type }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [gstAmount, setGstAmount] = useState();
  const [orgValue, setOrgValue] = useState();
  const [subscriptionName, setSubscriptionName] = useState();

  const [orgSubscriptionData, setOrgSubscriptionData] = useState([]);
  const [subscriptionValue, setSubscriptionValue] = useState("Yes");

  const planListDetails = useSelector((state) => state?.superAdmin?.planList);
  const subscriptionistData = useSelector(
    (state) => state.superAdmin?.subscriptionList
  );
  const subscriptionDetailsData = useSelector(
    (state) => state.superAdmin?.addSubscriptionDetails
  );
  const orgListData = useSelector(
    (state) => state?.superAdmin?.orgList?.data?.data
  );
  const orgDetails = useSelector(
    (state) => state?.superAdmin?.getOrgDetailsById?.data?.data
  );
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (datas) => {
    const payload = {
      subscription_id: selectedHospital?.subscription_id,
      organization_id: selectedHospital?.id,
      start_date: datas?.date,
      pricing: datas?.pricing,
      periods: datas?.period,
      payment_status: "1",
    };
    dispatch(addSubscriptionDetailsInProgress(payload));
  };
  const changeOrganization = (value) => {
    setOrgValue(value);

    dispatch(getOrgDetailsByIdInProgress(value));
  };

  useEffect(() => {
    form.setFieldsValue({
      hospital_name: selectedHospital?.Organization?.name,
      date: moment().format("YYYY-MM-DD"),
    });
  }, [selectedHospital]);
  useEffect(() => {
    const payload = { pagination: false, pageSize: "10", pageNo: 1 };
    dispatch(getsubscriptionListInProgress(payload));
  }, []);

  useEffect(() => {
    if (subscriptionDetailsData?.success) {
      setIsModalOpen(false);
    }
  }, [subscriptionDetailsData]);
  useEffect(() => {
    const payload = { pagination: false, pageSize: "10", pageNo: "1" };

    dispatch(getOrganisationListInProgress(payload));

    dispatch(
      getPlanListInProgress({
        pagination: "false",
        pageNo: "1",
        pageSize: "10",
      })
    );
  }, []);

  useEffect(() => {
    if (orgDetails?.organization_serial_no) {
      const orgSubscriptionData = planListDetails?.data?.data?.filter(
        (room) => room?.Organization?.id === orgDetails?.id
      );

      form.setFieldsValue({
        hospital_name: orgSubscriptionData[0]?.Organization?.name,
        date: moment().format("YYYY-MM-DD"),
      });
      setOrgSubscriptionData(orgSubscriptionData);

      if (orgSubscriptionData[0]?.paymentCompleted === "No") {
        form.setFieldsValue({
          pricing: orgSubscriptionData[0]?.pricing,
          period: orgSubscriptionData[0]?.periods,
          subscription: orgSubscriptionData[0]?.subscription_id,
          pendingAmount: orgSubscriptionData[0]?.pendingAmount,
        });
      }
    }
  }, [orgDetails]);
  useEffect(() => {
    if (type === "Org") {
      const selectedSubscription = subscriptionistData?.data?.data.find(
        (item) => item.id === selectedHospital?.subscription_id
      );

      form.setFieldsValue({
        pricing: selectedSubscription?.pricing,
        period: selectedSubscription?.periods,
        subscription: selectedSubscription?.id,
        hospital_name: selectedHospital?.name,
      });
      setGstAmount(selectedSubscription?.gst_amount);
      setSubscriptionName(selectedSubscription?.name);
    }
  }, [type]);
  return (
    <Form
      initialValues={{
        remember: true,
      }}
      colon={"true"}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <FormInput
            labelCol={"24"}
            wrapperCol={"24"}
            name={"hospital_name"}
            label={
              <IntlMessages id={"Addnew.superAdmin.label.hospital_name"} />
            }
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"superAdmin.validator.hospital_name"} />
                ),
              },
            ]}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <FormInput
            type="date"
            placeholder="date"
            name={"date"}
            min={disablePastDate()}
            labelCol={"24"}
            wrapperCol={"24"}
            label={<IntlMessages id={"Addnew.superAdmin.label.date"} />}
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Date is required"} />,
              },
            ]}
          />
        </Col>{" "}
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            readOnly="true"
            label={<IntlMessages id={"Addnew.admin.label.subscription"} />}
            name={"subscription"}
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"Addnew.admin.validator.subscription"} />
                ),
              },
            ]}
          >
            <FormSelectBox
              placeholder="Select Subscription"
              value={subscriptionValue}
              options={
                subscriptionistData?.data?.data?.map((pack, i) => ({
                  label: pack.name,
                  value: pack.id,
                  key: pack.id,
                })) || []
              }
              onChange={changeOrganization}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <FormInput
            labelCol={"24"}
            wrapperCol={"24"}
            name={"period"}
            readOnly={true}
            label={<IntlMessages id={"Addnew.superAdmin.label.periods"} />}
            rules={[
              {
                required: true,
                message: <IntlMessages id={"superAdmin.validator.periods"} />,
              },
            ]}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <FormInput
            labelCol={"24"}
            wrapperCol={"24"}
            name={"pricing"}
            readOnly={true}
            label={<IntlMessages id={"Addnew.superAdmin.label.amount"} />}
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"superAdmin.validator.paidAmount"} />
                ),
              },
            ]}
          />
        </Col>{" "}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <ButtonComponent
            label={"Proceed to pay"}
            htmlType="submit"
            type="primary"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default RenewalForm;
