import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Modal, Tooltip } from "antd";
import {
  LoadingOutlined,
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import { APP_ROUTES } from "../../../constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultBlock,
  deleteBlockInProgress,
  getBlockListInProgress,
} from "../../../appRedux/Ward/WardAction";

import Meta from "antd/lib/card/Meta";
import "../../../styles/modules/ward.less";
const BlockTable = () => {
  const dispatch = useDispatch();
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");

  const blockListData = useSelector((state) => state?.ward?.blockList);
  const addBlockData = useSelector((state) => state?.ward?.addBlock);
  const editBlockResponse = useSelector((state) => state?.ward?.editBlock);
  const deleteBlock = useSelector((state) => state?.ward?.deleteBlock);
  const blockList = blockListData?.data?.data;

  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteBlockInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Block  Name",
      dataIndex: "block_name",
      key: "block_name",
    },
    {
      title: "Block Status",
      dataIndex: "block_status",
      key: "block_status",
      render: (text, record) => {
        return record.block_status === "1" ? "Active" : "Inactive";
      },
    },
    {
      title: "Block Number",
      dataIndex: "block_number",
      key: "block_number",
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/ward`,
                  state: { blockData: record },
                }}
              >
                <ButtonComponent
                  type="primary"
                  label={"Ward List"}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/block/edit`,
                  state: { blockData: record },
                }}
              >
                <ButtonComponent
                  className="secondary-color"
                  icon={<EditFilled />}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteFilled />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = { pagination: "true", pageNo: page, pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(blockList);
    } else {
      const newData = blockList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    if (blockListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [blockListData]);
  useEffect(() => {
    const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    dispatch(getBlockListInProgress(payload));
  }, []);

  useEffect(() => {
    if (addBlockData?.success || editBlockResponse?.success) {
      dispatch(defaultBlock());
    }
  }, [addBlockData, editBlockResponse]);
  useEffect(() => {
    if (deleteBlock?.success) {
      const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
      dispatch(getBlockListInProgress(payload));
    }
  }, [deleteBlock]);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.blocks"} />}
          </Typography>
        </Col>
        <Col>
          <Link to={APP_ROUTES.ADD_BLOCK}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newBlock"} />}
            />
          </Link>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <Row gutter={[16, 16]}>
          {blockList?.map((mapBlock) => (
            <Col xl={6} lg={6} md={8} sm={12} xs={24}>
              {" "}
              <Card
                type="inner"
                title={
                  <Typography className="ward-heading">{`Block Name - ${mapBlock?.block_name}`}</Typography>
                }
                actions={[
                  <Tooltip placement="top" title={"Edit"}>
                    {" "}
                    <Link
                      to={{
                        pathname: `/block/edit`,
                        state: { blockData: mapBlock },
                      }}
                    >
                      <ButtonComponent
                        className="secondary-color"
                        icon={<EditFilled />}
                        size={"small"}
                      ></ButtonComponent>
                    </Link>
                  </Tooltip>,
                  <Tooltip placement="top" title={"Delete"}>
                    <Typography>
                      <ButtonComponent
                        type="danger"
                        icon={<DeleteFilled />}
                        size={"small"}
                        onClick={() => showModal(mapBlock.id)}
                      ></ButtonComponent>
                    </Typography>
                  </Tooltip>,
                ]}
              >
                <Row gutter={[16, 16]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {" "}
                    <Meta
                      description={`Block Status - ${
                        mapBlock?.block_status === "1" ? "Active" : "Inactive"
                      }`}
                      title={`Block Number - ${mapBlock?.block_number}`}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Link
                      to={{
                        pathname: `/ward`,
                        state: { blockData: mapBlock },
                      }}
                    >
                      <ButtonComponent
                        type="primary"
                        label={"Ward List"}
                      ></ButtonComponent>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default BlockTable;
