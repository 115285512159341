import { Form } from "antd";
import React from "react";

const AppointmentBookForm = ({ title, onFinish, buttonName }) => {
  const Submit = (data) => {
    console.log("Formdata", data);
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      onFinish={Submit}
    ></Form>
  );
};

export default AppointmentBookForm;
