import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getWardListByBlockInProgress,
  getWardListInProgress,
} from "../../../appRedux/Ward/WardAction";
import TableComponent from "../../../components/Table/TableComponent";

const WardList = ({
  selectedWardId,
  onWardSelect,
  setSelectedWardId,
  selectedBlockId,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const wardData = useSelector((state) => state.ward.wardListByBlock);
  const wardList = wardData?.data?.data;
  const handleWardSelect = (record) => {
    if (onWardSelect) {
      onWardSelect(record.id);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Ward Name",
      dataIndex: "ward_name",
      key: "ward_name",
    },
    {
      title: "Ward Status",
      dataIndex: "ward_status",
      key: "ward_status",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => (
        <Row className="table-button">
          <Col className="button-edit">
            {selectedWardId === record.id ? (
              <ButtonComponent
                className={"secondary-color"}
                label={"Selected Ward"}
                onClick={() => handleWardSelect(record)}
              />
            ) : (
              <ButtonComponent
                type="primary"
                label={"Select Ward"}
                onClick={() => handleWardSelect(record)}
              />
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (wardData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [wardData]);

  useEffect(() => {
    // const payload = { pagination: "true", pageNo: "1", pageSize: "10" };
    // dispatch(getWardListInProgress(payload));
    const payload = {
      pagination: "true",
      pageNo: "1",
      pageSize: "10",
      block_id: selectedBlockId,
    };
    dispatch(getWardListByBlockInProgress(payload));
  }, [dispatch]);
  const [filteredUsers, setFilteredUsers] = useState("");
  const totalRecords = wardData?.data?.totalRecords;
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(wardList);
    } else {
      const newData = wardList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const handlePage = (page) => {
    const payload = { pagination: "true", pageNo: page, pageSize: "10" };
    dispatch(getWardListInProgress(payload));
  };
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.wardList"} />}
          </Typography>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : wardList}
          rowselector={handleSelectChange}
          itemList={wardList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          field={"ward_name"}
          documentTitle="Ward List"
          disableTableHeaderButtons={true}
        />
      </Spin>
    </Card>
  );
};

export default WardList;
