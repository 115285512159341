import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Spin, Typography, Upload, message } from "antd";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  CreditCardOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";
import { useDispatch, useSelector } from "react-redux";

import {
  addPatientHeathRecordsInProgress,
  editPatientHeathRecordsInProgress,
} from "../../../appRedux/Patient/Action/Action";

const AddPatientHealthRecord = ({ editData, editItem }) => {
  const addHealthRecord = useSelector(
    (state) => state?.patientAppointment?.addPatientHealthRecords
  );
  const editHealthRecord = useSelector(
    (state) => state?.patientAppointment?.editPatientHealthRecords
  );
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState("");
  const [URI, setURI] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const patientId = localStorage.getItem("patient_id");
  const orgId = localStorage.getItem("org_id");
  const onFinish = (datas) => {
    const jsonString = JSON.stringify(datas);

    try {
      const data = JSON.parse(jsonString);

      const files = {
        uri: base64,
        originalname: data?.File[0].name,
        type: data?.File[0].type,
      };

      const formData = new FormData();
      const stringifiedObject = JSON.stringify(files);
      formData.append("file", stringifiedObject);
      formData.append("fileName", data?.fileName);
      formData.append("patient_id", patientId);
      formData.append("organization_id", orgId);

      if (editItem) {
        dispatch(
          editPatientHeathRecordsInProgress({ formData, id: editData?.id })
        );
      } else {
        dispatch(addPatientHeathRecordsInProgress(formData));
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const [Loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const normFile = (e) => {
    let selectedFile = e.fileList;

    if (selectedFile?.length > 0) {
      let fileToLoad = selectedFile[0].originFileObj;
      let fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        let base64EncodedFile = fileLoadedEvent.target.result;
        const base64WithoutPrefix = base64EncodedFile.slice(28);
        const uri = JSON.stringify(base64WithoutPrefix);
        setBase64(uri);
      };

      fileReader.readAsDataURL(fileToLoad);
    }
    if (Array.isArray(e)) {
      return e;
    }
    if (
      e &&
      e.fileList &&
      e.fileList.length > 0 &&
      e.fileList[0].name.endsWith(".pdf")
    ) {
      return e.fileList;
    }
    return [];
  };

  const validateFile = (rule, value) => {
    if (!value || value.length === 0) {
      return Promise.reject("Please upload a PDF file.");
    }

    const file = value[0].originFileObj;

    // if (!file || !file.name.endsWith(".pdf")) {
    //   return Promise.reject("Please upload a PDF file.");
    // }

    return Promise.resolve();
  };

  const convertToContentURI = (fileURL) => {
    const contentURI = fileURL.replace(
      "https://yaa-docket.s3.ap-northeast-1.amazonaws.com",
      "rc-upload-"
    );
    return contentURI;
  };

  const [pdfBase64, setPdfBase64] = useState("");
  useEffect(() => {
    if (editItem) {
      form.setFieldsValue(editData);
      const contentURI = convertToContentURI(editData?.file);
      const fileName = editData?.fileName + ".pdf";
      const setData = [
        {
          uid: editData?.file,
          name: fileName,

          type: "application/pdf",

          originFileObj: {
            uid: editData?.file,
          },
          status: "done",
          response: {
            success: true,
          },
          xhr: {},
        },
      ];
      form.setFieldsValue({
        File: setData,
      });
    }
  }, [editItem]);
  // const defaultFileList = [
  //   {
  //     uid: "1",
  //     name: "defaultFile.png",
  //     status: "done",
  //     url: "https://www.example.com/defaultFile.png",
  //   },
  // ];
  // const fetchPdf = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://yaa-docket.s3.ap-northeast-1.amazonaws.com/ed8c038f-cd72-4342-97ff-bf3d283d1478/faffb6f4-c775-41e0-b4da-21993951be21/health%20data%205-02/23/202-2:36:26.pdf"
  //     );
  //     const blob = await response.blob();
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const base64data = reader.result.split(",")[1];
  //     };
  //     reader.readAsDataURL(blob);
  //   } catch (error) {
  //     console.error("Error fetching PDF:", error);
  //   }
  // };
  const props = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  useEffect(() => {
    if (addHealthRecord?.success || editHealthRecord?.success) {
      history.push("/patient/healthRecord");
    }
    if (addHealthRecord?.inProgress || editHealthRecord?.inProgress) {
      setLoading("true");
    }
  }, [addHealthRecord, editHealthRecord]);

  return (
    <Card>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {editData ? (
                    <IntlMessages id={"label.editHealthRecord"} />
                  ) : (
                    <IntlMessages id={"label.addHealthRecord"} />
                  )}
                </Typography>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"fileName"}
                  label={
                    <IntlMessages id={"Addnew.healthRecord.label.recordName"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.healthRecord.validator.recordName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="File"
                  label={<IntlMessages id={"Addnew.healthRecord.label.file"} />}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      validator: validateFile,
                      message: (
                        <IntlMessages
                          id={"Addnew.healthRecord.validator.pdf"}
                        />
                      ),
                    },
                  ]}
                >
                  <Upload
                    name="file"
                    maxCount={1}
                    fileList={fileList}
                    // {...props}
                    // defaultFileList={editItem ? defaultFileList : false}
                  >
                    <ButtonComponent
                      icon={<UploadOutlined />}
                      label={
                        <IntlMessages
                          id={"Addnew.healthRecord.label.chooseFile"}
                        />
                      }
                    />
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.PATIENTHEALTHRECORD}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editItem ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </Card>
  );
};

export default AddPatientHealthRecord;
