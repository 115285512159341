import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

export const addDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/disease/insert`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const organization_id = localStorage.getItem("org_id");

  return axiosInstance.request({
    url: `/disease/getlist?pagination_required=${formData?.pagination}&pageNo=${formData?.page}&pagesize=10`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      organizationId: localStorage.getItem("org_id"),
    },
  });
};
export const updateDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/disease/update/${formData.id}`,
    method: "PUT",
    data: formData.data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/disease/delete/${formData.id}`,
    method: "DELETE",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const addQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/answer/insertanswer`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/answer/getlist?pagination_required=${formData?.pagination}&pageNo=${formData?.page}&pagesize=10`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");

  return axiosInstance.request({
    url: `/answer/updateanswer/${formData.id}`,
    method: "PUT",
    data: formData.data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteQuestionAnswerEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/answer/deleteanswer/${formData.id}`,
    method: "DELETE",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getQuestionAnswerByDiseasesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/answer/getanswer?pagination_required=false&disease_id=${formData.id}`,
    method: "GET",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      organizationId: localStorage.getItem("org_id"),
    },
  });
};
