import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddWard from "./AddWard";

const EditWard = () => {
  const location = useLocation();
  const wardData = location.state?.wardData;


  return (
    <AddWard
      editWardData={wardData}
      editWard={"true"}
      blockId={location.state?.blockData}
    />
  );
};

export default EditWard;
