import React, { Fragment, useState, useRef, useEffect } from "react";
import { Col, Row, Table, Typography } from "antd";

import FormSelectBox from "../SelectBox/SelectBox";
import ButtonComponent from "../Button/ButtonComponent";
import SearchBox from "../SearchBox";
import IntlMessages from "../../util/IntlMessages";
import ReactDOM from "react-dom";
import { Table_Row } from "../../constants/Patient";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import { CSVDownload, CSVLink } from "react-csv";
import ExcelJS from "exceljs";
import Papa from "papaparse";

import moment from "moment";
import PrintTable from "./PrintTable";
import "../../styles/modules/doctor.less";

import { getOrgNameInProgress } from "../../appRedux/doctor/actions/organisation";

import { answerType } from "../../screens/AppointmentQuestions/data";
import { salesTypeList } from "../../screens/Doctor/data";
const TableComponent = ({
  columns,
  dataSource,
  pagination,
  bordered,
  size,
  onChange,
  patientapi,
  patientFilter,
  doctorapi,
  doctorFilter,
  rowselector,
  doctorRowselectors,
  itemList,
  setFilteredUsers,
  filteredUsers,
  filteredData,
  field,
  Allappointments,
  AllappointmentsFilter,
  type,
  headers,
  pdf,
  disableTableHeaderButtons,
  documentTitle,
  disableTablePageRow,
  handleDownloadPDF,
}) => {
  const tableRef = useRef();
  const ref = useRef();

  const dispatch = useDispatch();
  const orgName = useSelector(
    (state) => state?.org?.getOrgName?.data?.data?.name
  );

  const orgId = localStorage.getItem("org_id");

  const getDoctorList = useSelector((state) => state.doctor.getlist);
  const appointmentslist = useSelector((state) => state.appointment);

  const orgList = useSelector((state) => state.org);
  const qualificationList = useSelector((state) => state.qualification);

  const designationList = useSelector((state) => state.designation);
  const specializationList = useSelector((state) => state.specialization);
  const allusersData = useSelector((state) => state.Users);
  const usersList = allusersData?.userslist?.data?.data;

  const doctorsLists = getDoctorList?.data?.data;
  const appointmentLists = appointmentslist?.appointmentlist?.data?.data;

  const orgListData = orgList?.org?.data?.data;
  const qualificationListData =
    qualificationList?.qualificationlist?.data?.data;
  const designationListData = designationList?.designation?.data?.data;
  const specializationListData = specializationList?.Specialization?.data?.data;
  // const generateCsvContent = (data, columns) => {
  //   // Extracting CSV headers
  //   const csvHeaders = columns.map((column) => column.title).join(",");

  //   // Extracting CSV data
  //   const csvData = data
  //     .map((row) => columns.map((column) => row[column.dataIndex]).join(","))
  //     .join("\n");

  //   // Creating CSV content
  //   return `${csvHeaders}\n${csvData}`;
  // };
  const generateCsvContent = (data, columns) => {
    // Filter out columns titled "Options"
    const exportableColumns = columns.filter(
      (column) => column.title !== "Options"
    );

    // Extracting CSV headers
    const csvHeaders = exportableColumns
      .map((column) => column.title)
      .join(",");

    // Extracting CSV data
    const csvData = data
      .map((row, rowIndex) =>
        exportableColumns
          .map((column) => {
            if (column.title === "S.No") {
              return rowIndex + 1;
            }
            if (
              column.title === "Block Name" &&
              documentTitle === "Total Room List"
            ) {
              return row.Block?.block_name;
            } else if (
              column.title === "Ward Name" &&
              documentTitle === "Total Room List"
            ) {
              return row.Ward?.ward_name;
            } else if (column.title === "Block Status") {
              return row?.block_status === "1" ? "Active" : "Inactive";
            }
            return row[column.dataIndex];
          })
          .join(",")
      )
      .join("\n");

    // Creating CSV content
    return `${csvHeaders}\n${csvData}`;
  };

  const handleClick = () => {
    if (type === "patientbillingReport") {
      handleDownloadPDF();
    }
    // if (type === "doctor" || type === "doctor_list") {
    //   exportDoctorPDF();
    // }
    //  else if (type === "Appointment") {
    //   exportAppointmentPDF();
    // }
    else if (orgList?.org?.success) {
      exportORG();
    } else if (qualificationList?.qualificationlist?.success) {
      exportQUALIFICATION();
    } else if (designationList?.designation?.success) {
      exportDESIGNATION();
    } else if (specializationList?.Specialization?.success) {
      exportSPECIALIZATION();
    } else if (allusersData?.userslist?.success) {
      exportAllusers();
    } else if ((type = "patientBilling")) {
      downloadPDF();
    } else {
      downloadPDF();
    }
  };

  const exportAppointmentPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Appointment Details";
    const headers = [
      [
        "doctor_name",
        "patient_name",
        "week_day",
        "start_time",
        "end_time",
        "date",
        "reason",
        "symptom",
      ],
    ];
    const dataValues = appointmentLists.map((d) => [
      d.doctor_name,
      d.patient_name,
      d.week_day,
      moment(d.start_time, "h:mm A").format("hh:mm A"),
      moment(d.end_time, "h:mm A").format("hh:mm A"),
      d.date,
      d.reason,
      d.symptom,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("appointment.pdf");
  };
  const exportDoctorPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const title = "Doctor Details";
    const headers = [
      [
        "FirstName",
        "LastName",
        "DOB",
        "Email",
        "Address1",
        "Address2",
        "City",
        "State",
        "Country Code",
        "Qualification",
      ],
    ];
    const dataValues = doctorsLists.map((d) => [
      d.firstName,
      d.lastName,
      d.dob,
      d.email,
      d.address1,
      d.address2,
      d.city,
      d.state,
      d.country_code,
      d.qualification,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
      // cellPadding:5
      columnStyles: {
        0: {
          columnWidth: 80,
        },
        1: {
          columnWidth: 80,
        },
        2: {
          columnWidth: 80,
        },
        3: {
          columnWidth: 80,
        },
        4: {
          columnWidth: 80,
        },
        5: {
          columnWidth: 80,
        },
        6: {
          columnWidth: 80,
        },
        7: {
          columnWidth: 80,
        },
        8: {
          columnWidth: 80,
        },
        9: {
          columnWidth: 80,
        },
        10: {
          columnWidth: 80,
        },
        11: {
          columnWidth: 80,
        },
      },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("doctor.pdf");
  };
  const exportORG = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Organization Details";
    const headers = [
      ["Name", "Email", "Contact", "Alternate_contact", "Address"],
    ];
    const dataValues = orgListData.map((d) => [
      d.name,
      d.email,
      d.contact,
      d.alternate_contact,
      d.address,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("organisation.pdf");
  };
  const exportQUALIFICATION = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Qualification Details";
    const headers = [["Name", "Description"]];
    const dataValues = qualificationListData.map((d) => [
      d.name,
      d.description,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("qualification.pdf");
  };
  const exportDESIGNATION = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Designation Details";
    const headers = [["Name", "CreatedAt", "UpdatedAt"]];
    const dataValues = designationListData.map((d) => [
      d.Name,
      d.createdAt,
      d.updatedAt,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("designation.pdf");
  };
  const exportSPECIALIZATION = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Specialization Details";
    const headers = [["ID", "Name", "CreatedAt", "UpdatedAt"]];
    const dataValues = specializationListData.map((d) => [
      d.id,
      d.Name,
      d.createdAt,
      d.updatedAt,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("specialization.pdf");
  };
  const exportAllusers = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "All Users Details";
    const headers = [
      [
        "FirstName",
        "LastName",
        "Contact",
        "Email",
        "Address1",
        "Address2",
        "City",
        "State",
        "Country Code",
        "Rolename",
        "Status",
      ],
    ];
    const dataValues = usersList.map((d) => [
      d.firstName,
      d.lastName,
      d.contact,
      d.email,
      d.address1,
      d.address2,
      d.city,
      d.state,
      d.country_code,
      d.role_name,
      d.status_name,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
      columnStyles: {
        0: {
          columnWidth: 70,
        },
        1: {
          columnWidth: 70,
        },
        2: {
          columnWidth: 70,
        },
        3: {
          columnWidth: 70,
        },
        4: {
          columnWidth: 70,
        },
        5: {
          columnWidth: 70,
        },
        6: {
          columnWidth: 70,
        },
        7: {
          columnWidth: 70,
        },
        8: {
          columnWidth: 70,
        },
        9: {
          columnWidth: 70,
        },
        10: {
          columnWidth: 70,
        },
        11: {
          columnWidth: 70,
        },
      },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("AllUsers.pdf");
  };

  // csv
  let csvData = "";
  try {
    csvData = Papa.unparse(itemList);
  } catch (err) {
    // console.error(err);
  }

  const exportCSV = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    if (dataSource?.length > 0) {
      if (type === "doctor_list") {
        const columnsToIncludeStudents = [
          { header: "First Name", key: "firstName" },
          { header: "Last Name", key: "lastName" },
          { header: "Date of Birth", key: "dob" },
          { header: "Email", key: "email" },
          { header: "Contact", key: "contact" },
          { header: "Address 1", key: "address1" },
          { header: "Address 2", key: "address2" },
          { header: "City", key: "city" },
          { header: "State", key: "state" },
          { header: "Country Code", key: "country_code" },
          { header: "Qualification", key: "qualification" },

          { header: "License Number", key: "license_no" },
          ,
          { header: "Gender Type", key: "gender_type" },
          { header: "Experience", key: "experience" },

          { header: "In-person Consultation", key: "inperson_consultation" },
          { header: "Tele Consultation", key: "tele_consultation" },

          { header: "Specialization Name", key: "specialization_name" },
          { header: "Organization Name", key: "organization_name" },
          { header: "Designation Name", key: "designation_name" },
          {
            header: "Organization Serial Number",
            key: "organization_serial_no",
          },
          { header: "Profile Image", key: "profile_image" },
          { header: "Doctor Experience", key: "doctor_experience" },
          { header: "Is Video Call Allowed", key: "isVideoCallAllowed" },
        ];
        // Adding column headers
        worksheet.columns = columnsToIncludeStudents.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeStudents.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "doctors.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "teamMember") {
        const columnsToIncludeMembers = [
          { header: "Access Role ID", key: "access_role_id" },
          { header: "Access Role Name", key: "access_role_name" },
          { header: "Address", key: "address" },
          { header: "Created At", key: "createdAt" },

          { header: "Date of Birth", key: "dob" },
          { header: "Email", key: "email" },
          { header: "First Name", key: "firstName" },
          { header: "Gender Type", key: "gender_type" },

          { header: "Last Name", key: "lastName" },
          { header: "Members Serial Number", key: "members_serial_no" },

          { header: "Phone", key: "phone" },
        ];

        // Adding column headers
        worksheet.columns = columnsToIncludeMembers.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeMembers.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "teamMembers.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "patientlist") {
        const columnsToIncludePatients = [
          { header: "Address 1", key: "address1" },
          { header: "Address 2", key: "address2" },
          { header: "Blood Group", key: "blood_group" },
          { header: "Blood Group ID", key: "blood_group_id" },
          { header: "City", key: "city" },
          { header: "Contact", key: "contact" },
          { header: "Country Code", key: "country_code" },
          { header: "Created At", key: "createdAt" },
          { header: "Delete At", key: "deleteAt" },
          { header: "Date of Birth", key: "dob" },
          { header: "Doctor Name", key: "doctorName" },
          { header: "Email", key: "email" },
          { header: "First Name", key: "firstName" },
          { header: "Gender Type", key: "gender_type" },
          { header: "ID", key: "id" },
          { header: "Is Prescription Added", key: "isPrescriptionAdded" },
          { header: "Last Name", key: "lastName" },
          { header: "Patient Serial Number", key: "patient_serial_no" },
          { header: "Profile Image", key: "profile_image" },
          { header: "Profile URL", key: "profile_url" },
          { header: "Role ID", key: "role_id" },
          { header: "State", key: "state" },
          { header: "Updated At", key: "updatedAt" },
          { header: "User ID", key: "user_id" },
        ];
        worksheet.columns = columnsToIncludePatients.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludePatients.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "patientsList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "patientBilling") {
        const columnsToIncludeBillingPayments = [
          { header: "Date", key: "DATE" },

          { header: "Discount", key: "discount" },
          { header: "Doctor ID", key: "doctor_id" },
          { header: "Doctor Name", key: "doctor_name" },
          { header: "Doctor Serial Number", key: "doctor_serial_no" },

          { header: "Invoice Number", key: "invoice_no" },
          { header: "Main Packages ID", key: "mainPackagesId" },
          { header: "Next Review Date", key: "next_review_date" },
          { header: "Old Due Amount", key: "old_due_amount" },
          { header: "Organization ID", key: "organization_id" },
          { header: "Paid Amount", key: "paid_amount" },
          { header: "Patient Address 1", key: "patient_address1" },
          { header: "Patient Address 2", key: "patient_address2" },
          { header: "Patient City", key: "patient_city" },
          { header: "Patient ID", key: "patient_id" },
          { header: "Patient Name", key: "patient_name" },
          { header: "Patient Serial Number", key: "patient_serial_no" },
          { header: "Patient State", key: "patient_state" },
          { header: "Reason", key: "reason" },
          { header: "Referred By Doctor", key: "ref_by_doctor" },
          { header: "Referring Doctor Name", key: "ref_doc_name" },
          { header: "Remarks", key: "remarks" },

          { header: "Due", key: "due" },
          { header: "Due Pending", key: "duePending" },
          { header: "Sub Total", key: "sub_total" },
          { header: "Grand Total", key: "grand_total" },
        ];

        worksheet.columns = columnsToIncludeBillingPayments.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeBillingPayments.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "biilingList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "Appointment") {
        const columnsToIncludeAppointments = [
          { header: "Week Day", key: "week_day" },
          { header: "Start Time", key: "start_time" },
          { header: "End Time", key: "end_time" },
          { header: "Date", key: "date" },
          { header: "Reason", key: "reason" },
          { header: "Symptom", key: "symptom" },
          { header: "Is Cancel", key: "is_cancel" },
          { header: "Consultation Type", key: "consultation_type" },
          { header: "Consultation Fee", key: "consultation_fee" },
          { header: "Appointment Status", key: "appointment_status" },

          { header: "Doctor Serial Number", key: "doctor_serial_no" },
          { header: "Doctor Name", key: "doctor_name" },
          { header: "Patient Serial Number", key: "patient_serial_no" },
          { header: "Patient Name", key: "patient_name" },
          { header: "Patient Date of Birth", key: "patient_dob" },
          { header: "Patient Gender", key: "patient_gender" },
          { header: "Qualification", key: "qualification" },
          { header: "Specialization Name", key: "specialization_name" },
          { header: "Doctor Gender", key: "doctor_gender" },
          { header: "Designations Name", key: "designations_name" },
          { header: "Organizations Name", key: "organizations_name" },
          {
            header: "Organization Serial Number",
            key: "organization_serial_no",
          },
          { header: "Consultation Name", key: "consultation_name" },
          { header: "Patient Blood Group", key: "patient_blood_group" },
          { header: "Appointment Status Name", key: "appointment_status_name" },
          { header: "Is Previous", key: "isPrev" },
        ];
        worksheet.columns = columnsToIncludeAppointments.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeAppointments.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "appointmentList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "appointmentQuestions") {
        const columnsToIncludeAnswers = [
          { header: "Answers Type", key: "answers_type" },

          { header: "Disease", key: "disease" },
          { header: "Disease ID", key: "disease_id" },

          { header: "Questions", key: "questions" },
        ];
        worksheet.columns = columnsToIncludeAnswers.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeAnswers.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "appointmentQuestionList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "billingPackages") {
        const columnsToIncludePackages = [
          { header: "Discount", key: "discount" },
          { header: "Gross Total", key: "gross_total" },

          { header: "Organization ID", key: "organization_id" },
          { header: "Package Name", key: "package_name" },
          { header: "Status", key: "status" },

          { header: "Total Amount", key: "total_amount" },
        ];
        worksheet.columns = columnsToIncludePackages.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludePackages.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "billingPackages.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "PackageItems") {
        const columnsToIncludePackages = [
          { header: "quantity", key: "quantity" },

          { header: "Package Name", key: "package_name" },

          { header: "Total Amount", key: "amount" },
        ];
        worksheet.columns = columnsToIncludePackages.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludePackages.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "PackageItems.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else {
        const csvContent = generateCsvContent(dataSource, columns);
        exportToCsv(csvContent, "dockmotez.csv");
      }
    }
  };

  const exportToCsv = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const handlePdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Details";
    const defaultColumnWidth = 60;
    console.log("columns", columns);
    let columnsToRemove = [
      "createdAt",
      "updatedAt",
      "id",
      "ref_by_doctor",
      "PatientBillingSubPackages",
      "BillingPayments",
      "status",
      "next_review_date",
      "remarks",
      "patient_address1",
      "patient_address2",
      "patient_city",
      "patient_state",
      "patient_serial_no",
      "doctor_serial_no",
      "ref_doc_name",
      "deleteAt",
      "profile_url",
      "options",
    ];
    const cleanedDataSource = dataSource.map((obj) => {
      const keysWithObjects = Object.keys(obj).filter(
        (key) => typeof obj[key] === "object"
      );
      Object.keys(obj).forEach((key) => {
        if (
          columnsToRemove.includes(key) ||
          key.toLowerCase().endsWith("id") ||
          keysWithObjects.includes(key)
        ) {
          delete obj[key];
        }
      });
      return obj;
    });
    const removeProperties = (obj, propsToRemove) => {
      for (const prop in obj) {
        if (propsToRemove.includes(prop)) {
          delete obj[prop];
        } else if (typeof obj[prop] === "object") {
          removeProperties(obj[prop], propsToRemove);
        }
      }
    };

    removeProperties(cleanedDataSource, columnsToRemove);
    const dataSourceKeys = Object.keys(cleanedDataSource[0]);
    const headers = [dataSourceKeys.map((key) => key)];
    const dataValues = cleanedDataSource.map((row) => {
      const rowData = [];
      headers[0].forEach((header) => {
        rowData.push(row[header]);
      });
      return rowData;
    });
    const totalColumns = headers.length;
    const columnStyles = {};
    for (let i = 0; i < totalColumns; i++) {
      columnStyles[i] = { columnWidth: defaultColumnWidth };
    }
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
      columnStyles: columnStyles,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("patient.pdf");
  };

  const handlePrint = () => {
    // dispatch(getOrgNameInProgress(orgId));

    const tableData = dataSource.map((row, rowIndex) =>
      columns.map((col, colIndex) => {
        if (col.title === "S.No") {
          return rowIndex + 1;
        } else if (col.title === "Room Type") {
          return row.room_type === "1" ? "AC" : "NON-AC";
        } else if (col.title === "Room Status") {
          return row.room_status === "1" ? "Active" : "Inactive";
        } else if (col.title === "Room Price") {
          return row.room_type === "1"
            ? row.ac_room_price
            : row.non_ac_room_price;
        } else if (
          col.title === "Block Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Block?.block_name;
        } else if (
          col.title === "Ward Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Ward?.ward_name;
        } else if (col.title === "Answer") {
          console.log(" row.answers", row.answers);

          if (Array.isArray(row.answers)) {
            return (
              <div>
                {row.answers.map((ans, i) => (
                  <Typography key={i} className="text-wrap">
                    {`${i + 1}. ${ans?.answer}`}
                  </Typography>
                ))}
              </div>
            );
          } else {
            return "No Data";
          }
        } else if (col.title === "Answer Method") {
          const answerMethod = answerType.find(
            (val) => val.value === row?.answers_type
          );
          return answerMethod ? answerMethod.label : "Unknown";
        } else if (col.title === "Period") {
          return row?.Subscription?.periods;
        } else if (col.title === "Hospital Name") {
          return row?.Organization?.name;
        } else if (
          col.title === "Package Name" &&
          documentTitle === "Organization List"
        ) {
          if (row?.Subscription === null) {
            return "";
          } else if (row?.Subscription?.name) return row?.Subscription?.name;
        } else if (
          col.title === "Supplier Company Name" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.company_name;
        } else if (
          col.title === "Contact Person" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.contact_person;
        } else if (col.title === "Medicine" && documentTitle === "Sales List") {
          if (Array.isArray(row.sales_details)) {
            return (
              <div>
                {row.sales_details.map((ans, i) => (
                  <Typography key={i} className="text-wrap">
                    {`${i + 1}. ${ans?.product_name}`}
                  </Typography>
                ))}
              </div>
            );
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Patient Type" &&
          documentTitle === "Sales List"
        ) {
          return salesTypeList.find(
            (data) => Number(data.value) === Number(row?.patient_type)
          )?.label;
        } else if (
          col.title === "Packages" &&
          documentTitle === "Billing Packages List"
        ) {
          if (Array.isArray(row.subPackages)) {
            const answersList = row.subPackages.map(
              (data) => data?.package_name
            );
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Sales Id" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.Sale?.sales_code;
        } else if (
          col.title === "Return Medicines" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.sales_return_details?.length;
        } else if (
          col.title === "DOB" ||
          col.title === "Expiry Date" ||
          col.title === "Registered At" ||
          col.title === "Date" ||
          col.title === "Purchase Date" ||
          col.title === "Order Date" ||
          col?.title === "Invoice Date"
        ) {
          console.log("row?.dob", row?.dob);
          return moment(
            row?.dob ||
              row?.start_date ||
              row?.expiry_date ||
              row?.date ||
              row?.createdAt ||
              row?.invoice_date
          ).format("DD-MM-YYYY");
        } else if (
          col.title === "Address" &&
          documentTitle === "Organization List"
        ) {
          return (
            (row?.address ? row?.address : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country ? ", " + row?.country : "")
          );
        } else if (
          col.title === "Address" &&
          documentTitle === "Patient Due List"
        ) {
          return (
            (row?.address1 ? row?.address1 : "") +
            (row?.address2 ? ", " + row?.address2 : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country_code ? ", " + row?.country_code : "")
          );
        } else {
          return row[col.dataIndex];
        }
      })
    );
    const exportableColumns = columns.filter((col) => col.title !== "Action");
    const tableColumnHeaders = exportableColumns.map((col) => col.title);
    const selectedColumn = columns
      .filter(
        (column) => column.title !== "Options" && column.title !== "Action"
      )
      .map((column) => ({
        title: tableColumnHeaders,
        dataIndex: tableData,
        key: column.key,
      }));

    const newWindow = window.open("", "_blank");
    if (newWindow) {
      newWindow.document.write("<html><head><title>Print</title>");
      newWindow.document.write(
        "<link rel='stylesheet'  href='../../styles/modules/doctor.less'>"
      );
      newWindow.document.write("</head><body>");
      newWindow.document.write("<div id='print-content'>");
      ReactDOM.render(
        <PrintTable
          dataSource={tableData}
          columns={tableColumnHeaders}
          documentTitle={documentTitle}
        />,
        newWindow.document.getElementById("print-content")
      );

      newWindow.document.write("</div>");
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      console.error("Failed to open new window for printing.");
    }
  };
  const downloadPDF = () => {
    const doc = new jsPDF();
    const title = documentTitle;
    console.log("dataSource", dataSource);
    // doc.text("My Table Title", 14, 22);
    // Extract the data from Ant Design table
    const tableData = dataSource.map((row, rowIndex) =>
      columns.map((col, colIndex) => {
        if (col.title === "S.No") {
          return rowIndex + 1;
        } else if (col.title === "Room Type") {
          return row.room_type === "1" ? "AC" : "NON-AC";
        } else if (col.title === "Room Status") {
          return row.room_status === "1" ? "Active" : "Inactive";
        } else if (col.title === "Room Price") {
          return row.room_type === "1"
            ? row.ac_room_price
            : row.non_ac_room_price;
        } else if (
          col.title === "Block Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Block?.block_name;
        } else if (
          col.title === "Ward Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Ward?.ward_name;
        } else if (col.title === "Block Status") {
          return row.block_status === "1" ? "Active" : "Inactive";
        } else if (col.title === "Answer") {
          if (Array.isArray(row.answers)) {
            // Create a list of answers from row.answers
            const answersList = row.answers.map((data) => data?.answer);
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (col.title === "Answer Method") {
          const answerMethod = answerType.find(
            (val) => val.value === row?.answers_type
          );
          return answerMethod ? answerMethod.label : "Unknown";
        } else if (col.title === "Period") {
          return row?.Subscription?.periods;
        } else if (col.title === "Hospital Name") {
          return row?.Organization?.name;
        } else if (
          col.title === "Package Name" &&
          documentTitle === "Organization List"
        ) {
          return row?.Subscription?.name;
        } else if (
          col.title === "Supplier Company Name" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.company_name;
        } else if (
          col.title === "Contact Person" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.contact_person;
        } else if (col.title === "Medicine" && documentTitle === "Sales List") {
          if (Array.isArray(row.sales_details)) {
            const answersList = row.sales_details.map(
              (data) => data?.product_name
            );
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Patient Type" &&
          documentTitle === "Sales List"
        ) {
          return salesTypeList.find(
            (data) => Number(data.value) === Number(row?.patient_type)
          )?.label;
        } else if (
          col.title === "Packages" &&
          documentTitle === "Billing Packages List"
        ) {
          console.log("row", row);
          if (Array.isArray(row.subPackages)) {
            const answersList = row.subPackages.map(
              (data) => data?.package_name
            );
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Sales Id" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.Sale?.sales_code;
        } else if (
          col.title === "Return Medicines" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.sales_return_details?.length;
        } else if (
          col.title === "DOB" ||
          col.title === "Expiry Date" ||
          col.title === "Registered At" ||
          col.title === "Date" ||
          col.title === "Purchase Date" ||
          col.title === "Order Date" ||
          col?.title === "Invoice Date"
        ) {
          console.log("row?.dob", row?.createdAt);
          return moment(
            row?.dob ||
              row?.start_date ||
              row?.expiry_date?.row?.date ||
              row?.createdAt ||
              row?.invoice_date
          ).format("DD-MM-YYYY");
        } else if (
          col.title === "Address" &&
          documentTitle === "Patient Due List"
        ) {
          return (
            (row?.address1 ? row?.address1 : "") +
            (row?.address2 ? ", " + row?.address2 : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country_code ? ", " + row?.country_code : "")
          );
        } else if (
          col.title === "Address" &&
          documentTitle === "Organization List"
        ) {
          return (
            (row?.address ? row?.address : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country ? ", " + row?.country : "")
          );
        } else {
          return row[col.dataIndex];
        }
      })
    );
    const exportableColumns = columns.filter((col) => col.title !== "Action");
    const tableColumnHeaders = exportableColumns.map((col) => col.title);

    doc.text(title, 10, 10);
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableData,
    });

    doc.save(`${documentTitle}.pdf`);
  };

  return (
    <Fragment>
      {!disableTablePageRow ? (
        <Row className="Header-row hide-on-print">
          <Col xs={24} md={7} lg={6}>
            {/* <FormSelectBox
              // value={row}
              options={Table_Row}
              onChange={rowselector || doctorRowselectors}
              placeholder="select a value"
              className="select-table-row"
            /> */}
          </Col>
          <Col xs={24} sm={24} md={9} lg={5} className="Button-Tool">
            {!disableTableHeaderButtons ? (
              <>
                <ButtonComponent
                  type="primary"
                  label={<IntlMessages id={"Addnew.patient.csv"} />}
                  className="button-tools"
                  onClick={exportCSV}
                />
                <CSVLink
                  data={csvData}
                  filename={"data-csv"}
                  style={{ display: "none" }}
                  ref={ref}
                >
                  CSV
                </CSVLink>
                <ButtonComponent
                  label={<IntlMessages id={"Addnew.patient.pdf"} />}
                  className="button-tools secondary-color"
                  onClick={handleClick}
                  // exportDoctorPDF();
                />
                <ButtonComponent
                  type="primary"
                  label={<IntlMessages id={"Addnew.patient.print"} />}
                  className="button-tools "
                  onClick={handlePrint}
                />
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col xs={24} md={8}>
            {/* <SearchBox
              styleName="gx-lt-icon-search-bar search-btn"
              placeholder="Search in app..."
              // onChange={onChange}
              // value={searchValue}
              patientList={patientapi}
              setFilteredData={patientFilter}
              doctorList={doctorapi}
              setFilteredDataDoctor={doctorFilter}
              itemList={itemList}
              filteredData={filteredData}
              filteredUsers={filteredUsers}
              setFilteredUsers={setFilteredUsers}
              field={field}
              AllAppointmentList={Allappointments}
              setFilteredAllApp={AllappointmentsFilter}
              dataSource={dataSource}
            /> */}
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        bordered={bordered}
        size={size}
        scroll={{ x: true }}
        onChange={onChange}
        ref={tableRef}
      />
    </Fragment>
  );
};

export default TableComponent;
