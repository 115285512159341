import React from "react";
import { Col, Divider, Row } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import { APP_ROUTES } from "../../../constants/Routes";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { PlusCircleOutlined } from "@ant-design/icons";

const PatientHealthRecordHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"label.healthRecords"} />}
          </p>
        </Col>
        <Col>
          <Link to={APP_ROUTES.ADDPATIENTHEALTHRECORD}>
            <ButtonComponent
              type="primary"
              label={<IntlMessages id={"Addnew.healthRecord"} />}
              icon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default PatientHealthRecordHeader;
