import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddPurchaseRequest from "./AddPurchaseRequest";

const ViewPurchaseRequest = () => {
  const location = useLocation();
  const purchaseData = location.state?.purchaseRequestData;

  return <AddPurchaseRequest viewPurchaseData={purchaseData} viewData="true" />;
};

export default ViewPurchaseRequest;
