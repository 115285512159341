import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Typography } from "antd";
import { userSignOut } from "../../appRedux/doctor/actions/Auth";

import ProfileForm from "../../screens/Profile/Components/ProfileForm";
import { APP_ROUTES } from "../../constants/Routes";

import { useHistory } from "react-router-dom";
import profile from "../../assets/images/DocMotez/contactProfile.png";

const UserProfile = () => {
  ProfileForm();
  const { getprofile } = useSelector((state) => state.profile);
  const getprofiledata = getprofile?.data;

  const history = useHistory();

  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );
  const handleNavigate = () => {
    history.push(APP_ROUTES.LOGOUT);
  };
  return (
    <div className="gx-flex-row gx-align-items-center  gx-avatar-row">
      {/* <Popover
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    > */}
      <Avatar
        src={getprofiledata?.profile_image || profile}
        className="gx-size-40 gx-pointer gx-mr-3"
        alt=""
      />
      <div>
        <Typography className="gx-avatar-name menu-text mt-5">
          {getprofiledata.firstName} {getprofiledata.lastName}
          {/* <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /> */}
        </Typography>

        <Button
          className="m-0 p-0 menu-text f-13"
          type="text"
          onClick={() => handleNavigate()}
        >
          {" "}
          Log Out
        </Button>
      </div>
      {/* </Popover> */}
    </div>
  );
};

export default UserProfile;
