import React from "react";

import AddPatientHealthRecord from "./AddPatientHealthRecord";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const EditPatientHealthRecord = () => {
  const location = useLocation();
  const healthRecordData = location.state?.healthRecord;

  return (
    <div>
      <AddPatientHealthRecord editItem="true" editData={healthRecordData} />
    </div>
  );
};

export default EditPatientHealthRecord;
