import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_TABLET_LIST_IN_PROGRESS,
  TABLET_BY_ORG_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import Message from "../../../components/Message/Message";
import {
  getTabletsListError,
  getTabletsListSuccess,
  TabletByOrgError,
  TabletByOrgSuccess,
} from "../actions/Tablets";
import { TabletListEffect, TabletByOrgEffect } from "./effects/TabletsEffects";

function* TabletListWatcher({ payload }) {
  try {
    let { data } = yield call(TabletListEffect, payload);

    yield put(
      getTabletsListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getTabletsListError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* TabletByOrgWatcher({ payload }) {
  try {
    let { data } = yield call(TabletByOrgEffect, payload);

    yield put(
      TabletByOrgSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      TabletByOrgError({
        error: true,
        message: e["message"],
      })
    );
  }
}
export default function* TabletSaga() {
  yield takeLatest(GET_TABLET_LIST_IN_PROGRESS, TabletListWatcher);

  yield takeLatest(TABLET_BY_ORG_IN_PROGRESS, TabletByOrgWatcher);
}
