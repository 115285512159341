import React from "react";

import { Card } from "antd";
import ProfileHeader from "./Components/ProfileHeader";
import ProfileForm from "./Components/ProfileForm";
import DoctorProfile from "./Components/DoctorProfile";

const ProfileScreen = () => {
  const roleId = localStorage.getItem("role_id");

  return (
    <Card>
      <ProfileHeader />
      {roleId === "2" ? <DoctorProfile /> : <ProfileForm />}
    </Card>
  );
};

export default ProfileScreen;
