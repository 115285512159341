import React, { useEffect, useState } from "react";
import { DesignationEffect } from "../../../appRedux/doctor/sagas/effects/designationEffect";
import DoctorSelectBox from "./DoctorSelectBox";

const DesignationSelector = ({
  placeholder = "Select Designation",
  label = "",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getStatusList();
  }, []);

  const getStatusList = async () => {
    try {
      const { data } = await DesignationEffect();

      const desigList = data?.data;
      let options = desigList.map((row) => ({
        label: row.Name,
        value: row.id,
      }));
      setOptions(options);
    } catch (error) {
      setOptions([]);
    }
  };

  return (
    <DoctorSelectBox
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
    />
  );
};

export default DesignationSelector;
