import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  Typography,
  Checkbox,
  Switch,
  Modal,
} from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import CountrySelector from "../../Doctor/component/CountrySelector";
import { useDispatch, useSelector } from "react-redux";
import StateSelector from "../../Doctor/component/StateSelector";
import CitySelector from "../../Doctor/component/CitySelector";
import {
  addOrganisationInProgress,
  editOrganisationInProgress,
  getsubscriptionListInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";
import { currentDate } from "../../../util/utilz";
import PaymentForm from "../Payment/PaymentForm";
import RenewalForm from "../Payment/RenewalForm";
const CheckboxGroup = Checkbox.Group;
const { Text } = Typography;
const AddHospital = ({ editHospital, editHospitalData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [packageValue, setPackageValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [orgStatus, setOrgStatus] = useState();
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const [frontendValue, setFrontendValue] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const selectedCityID = useRef("");
  const subscriptionistData = useSelector(
    (state) => state.superAdmin?.subscriptionList
  );
  const addOrgData = useSelector((state) => state?.superAdmin?.addOrg);
  const editOrgData = useSelector((state) => state?.superAdmin?.editOrg);
  const addPaymentData = useSelector(
    (state) => state.superAdmin?.addubscriptionPayment
  );
  const addSubscriptionDetails = useSelector(
    (state) => state.superAdmin?.addSubscriptionDetails
  );

  const onFinish = (datas) => {
    const selectedSubscription = subscriptionistData?.data?.data.find(
      (item) => item.id === datas?.subscription
    );

    const payload = {
      firstName: datas.firstName,
      lastName: datas.name,
      name: datas.name,
      email: datas.email,
      contact: datas.contact,
      alternate_contact: datas.alternate_contact,
      address: datas.address,
      street: datas.street,
      city: datas.city,
      state: datas.state,
      country: datas.country,
      country_code: datas.country_code,
      currency_code: datas.currency_code,
      license_no: datas.license_no,
      status: editHospital ? (datas?.status ? "1" : "3") : "3",
      password: datas.password,
      subscription_id: datas?.subscription,
      start_date: currentDate,
      pricing: selectedSubscription?.pricing,
      periods: selectedSubscription?.periods,
      payment_status: "1",

      role_id: 1,
    };
    if (editHospital) {
      dispatch(
        editOrganisationInProgress({ id: editHospitalData?.id, payload })
      );
    } else {
      dispatch(addOrganisationInProgress(payload));
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [Loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (editHospital) {
      form.setFieldsValue(editHospitalData);
      editHospitalData?.package === "manually"
        ? setPackageValue("manually")
        : setPackageValue("premium");
      setFrontendValue(editHospitalData?.Subscription?.id);
      form.setFieldValue("subscription", editHospitalData?.Subscription?.id);
      form.setFieldValue("status", editHospitalData?.status);
      editHospitalData?.status === 1 ? setOrgStatus(true) : setOrgStatus(false);
      // form.setFieldValue("subscription", editHospitalData?.Subscription?.id);
    }
  }, [editHospital]);

  const changeFrontend = (value) => {
    setFrontendValue(value);
  };
  const onChangeCountry = (value) => {
    setCountry(value);
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);
  useEffect(() => {
    const payload = { pagination: false, pageSize: "10", pageNo: 1 };
    dispatch(getsubscriptionListInProgress(payload));
  }, []);
  useEffect(() => {
    if (addOrgData?.inProgress || editOrgData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addOrgData, editOrgData]);

  useEffect(() => {
    if (editOrgData?.success) {
      history.push("/organization");
    } else if (addOrgData?.success) {
      setIsModalOpen(true);
    }
  }, [addOrgData, editOrgData]);
  useEffect(() => {
    if (addPaymentData?.success) {
      history.push("/organization");
    }
  }, [addPaymentData]);
  const onChangeStatus = (checked) => {
    setOrgStatus(checked);
    form.setFieldsValue({ status: checked });
  };
  const handleOkPayment = () => {
    setPaymentModal(false);
  };

  const handleCancelPayment = () => {
    setPaymentModal(false);
  };
  useEffect(() => {
    if (addSubscriptionDetails?.success) {
      setPaymentModal(true);
    }
  }, [addSubscriptionDetails]);

  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row>
              {" "}
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="my-20">
                <Typography className="header-tag">
                  {!editHospital ? (
                    <IntlMessages id={"label.addHospital"} />
                  ) : (
                    <IntlMessages id={"label.editHospital"} />
                  )}
                </Typography>
              </Col>{" "}
              {!editHospital && (
                <>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <FormInput
                      labelCol={24}
                      wrapperCol={24}
                      type="text"
                      placeholder="First Name"
                      name={"firstName"}
                      label={<IntlMessages id={"Addnew.org.label.firstName"} />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.org.validator.firstName"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      placeholder="Last Name"
                      name={"lastName"}
                      label={<IntlMessages id={"Addnew.org.label.lastName"} />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.org.validator.lastName"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </>
              )}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="Name"
                  name={"name"}
                  label={<IntlMessages id={"Addnew.org.label.name"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.org.validator.name"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="email"
                  placeholder="Email"
                  name={"email"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.email"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.org.validator.email"} />
                      ),
                    },
                  ]}
                />
              </Col>{" "}
              {!editHospital && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    type="password"
                    placeholder="password"
                    name={"password"}
                    labelCol={"24"}
                    wrapperCol={"24"}
                    label={<IntlMessages id={"Addnew.org.label.password"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.org.validator.password"} />
                        ),
                      },
                    ]}
                  />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {" "}
                <FormInput
                  type="text"
                  placeholder="Contact"
                  name={"contact"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.contact"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.org.validator.contact"} />
                      ),
                    },
                    {
                      pattern: /^[6789][0-9]{9}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Aternative Contact"
                  name={"alternate_contact"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.alter_contact"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.org.validator.alter_contact"}
                        />
                      ),
                    },
                    {
                      pattern: /^[6789][0-9]{9}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Address"
                  name={"address"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.address"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.org.validator.address"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Street"
                  name={"street"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.street"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.org.validator.street"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CountrySelector
                  label={"Country Name"}
                  name={"country"}
                  onChange={onChangeCountry}
                  country={country}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your country!",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StateSelector
                  label={"State"}
                  name={"state"}
                  onChange={onChangeState}
                  country={state}
                  countryId={country?.id}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your state!",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CitySelector
                  label={"City"}
                  name={"city"}
                  onChange={onChangeCity}
                  country={city}
                  StateId={state?.id}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your city!",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Country Code"
                  name={"country_code"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.countrycode"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.org.validator.countrycode"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="Currency Code"
                  name={"currency_code"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.currencycode"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.org.validator.currencycode"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  type="text"
                  placeholder="license_no"
                  name={"license_no"}
                  labelCol={"24"}
                  wrapperCol={"24"}
                  label={<IntlMessages id={"Addnew.org.label.license_no"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.org.validator.license_no"} />
                      ),
                    },
                  ]}
                />
              </Col>{" "}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  label={
                    <IntlMessages id={"Addnew.admin.label.subscription"} />
                  }
                  name={"subscription"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.admin.validator.subscription"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select Subscription"
                    value={frontendValue}
                    options={
                      subscriptionistData?.data?.data?.map((pack, i) => ({
                        label: pack.name,
                        value: pack.id,
                        key: pack.id,
                      })) || []
                    }
                    onChange={changeFrontend}
                  />
                </Form.Item>
              </Col>{" "}
              {editHospital && (
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    label={<IntlMessages id={"Addnew.admin.label.Status"} />}
                    name={"status"}
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      onChange={onChangeStatus}
                      checked={orgStatus}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.HOSPITAL}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editHospital ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
          <Modal
            title="Renewal Payment"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={null}
          >
            <RenewalForm
              selectedHospital={addOrgData?.data?.OrganizationsInfo}
              setIsModalOpen={setIsModalOpen}
              paymentModal={paymentModal}
              setPaymentModal={setPaymentModal}
              type="Org"
            />
          </Modal>
          <Modal
            title={"Payment Details"}
            open={paymentModal}
            onOk={handleOkPayment}
            onCancel={handleCancelPayment}
            width={600}
            footer={null}
            destroyOnClose={handleCancelPayment}
          >
            <PaymentForm
              // selectedHospital={selectedHospital}
              setPaymentModal={setPaymentModal}
              paymentModal={paymentModal}
            />
          </Modal>
        </Card>
      </Spin>
    </>
  );
};

export default AddHospital;
