import { Form, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { showAuthLoader } from "../../../appRedux/doctor/actions/Auth";
import { updateScheduleInProgress } from "../../../appRedux/doctor/actions/Schedule";

import ScheduleForm from "./ScheduleForm";

const EditSchedule = (props) => {
  const [loading, setLoading] = useState(false);
  const EditData = props.location.state;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let history = useHistory();

  const scheduleData = useSelector((state) => state.schedule);

  const scheduleList = scheduleData?.schedule?.data?.data;

  const doctorId = localStorage.getItem("doctor_id");
  const onFinish = (datas) => {
    dispatch(showAuthLoader());

    var formatted_start_time = moment(datas.start_time.toString()).format(
      "HH:mm:ss"
    );
    var formatted_end_time = moment(datas.end_time.toString()).format(
      "HH:mm:ss"
    );
    let formData = new URLSearchParams();
    formData.append("doctor_id", doctorId);
    formData.append("week_day_id", datas.week_day_id);
    formData.append("start_time", formatted_start_time);
    formData.append("end_time", formatted_end_time);
    formData.append("interval", datas.interval);
    formData.append("schedule_status", datas.status);
    dispatch(updateScheduleInProgress({ formData, id: EditData.id }));
  };
  // useEffect(() => {
  //   dispatch(getDataScheduleInProgress(slug.id));
  // }, []);

  // const EditList = scheduleList?.map((mapdata) => {
  //   if (mapdata.id === slug.id) {
  //     return mapdata;
  //   }
  // });
  const EditList = scheduleList?.filter((item) => {
    if (item.id === EditData.id) {
      return item;
    }
  });

  useEffect(() => {
    form.setFieldValue("week_day_id", EditData?.week_day_id);
    form.setFieldValue("interval", EditData?.interval);
    form.setFieldValue("start_time", moment(EditData?.start_time, "HH:mm:ss"));
    form.setFieldValue("end_time", moment(EditData?.end_time, "HH:mm:ss"));
    form.setFieldValue("status", EditData?.schedule_status);
  });
  useEffect(() => {
    if (scheduleData?.updateSchedule?.success === true) {
      history.push("/schedule");
    }
  }, [scheduleData?.updateSchedule?.success]);
  // useEffect(() => {
  //   if (scheduleData?.updateSchedule?.inProgress === true) setLoading(true);
  //   else if (scheduleData?.updateSchedule?.success === true) setLoading(false);
  //   else setLoading(false);
  // }, [scheduleData?.updateSchedule?.inProgress === true]);
  return (
    <div>
      {" "}
      <Spin
        size="large"
        spinning={loading}
        tip="Please wait a moment"
        style={{ margin: "20px" }}
      />{" "}
      <ScheduleForm
        onFinish={onFinish}
        title={"Addnew.schedule.label.editschedule"}
        buttonName={"Update"}
        editData={EditList}
        form={form}
        startTime={EditData?.start_time}
        endTime={EditData?.end_time}
      />
    </div>
  );
};

export default EditSchedule;
