import React from "react";

import "../../styles/modules/doctor.less";

const PrintTable = ({ dataSource, columns, documentTitle }) => {
  console.log("dataSourcedataSource", dataSource);
  return (
    <>
      <h3>{documentTitle}</h3>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                style={{ border: "1px solid black", padding: "8px" }}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((dataItem, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(dataItem).map((key, colIndex) => {
                console.log(`dataItem[${key}]:`, dataItem[key], dataItem);
                return (
                  dataItem[key] !== undefined && (
                    <td
                      key={colIndex}
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      {dataItem[key]}
                    </td>
                  )
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
export default PrintTable;
