import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ViewPharmacy from "../ViewPharmacy";
import { Col, Row, Table, Typography } from "antd";

const ViewSupplier = () => {
  const location = useLocation();
  const supplierdata = location.state?.supplierData;

  const bankData = [
    {
      name: "Bank Name",
      value: supplierdata?.bank_name,
    },
    {
      name: "Account Number",
      value: supplierdata?.account_number,
    },
    {
      name: "IFSC Code",
      value: supplierdata?.ifsc_code,
    },
    {
      name: "Payable At",
      value: supplierdata?.payable_at,
    },
  ];

  const columns = [
    {
      title: "name",
      dataIndex: "name",
    },
    {
      title: "value",
      dataIndex: "value",
    },
  ];
  const supplierData = [
    {
      name: "Company Name",
      value: supplierdata?.company_name,
    },
    {
      name: "Contact Person",
      value: supplierdata?.contact_person,
    },
    {
      name: "Email",
      value: supplierdata?.email,
    },
    {
      name: "mobile",
      value: supplierdata?.mobile,
    },
    {
      name: "Address",
      value: supplierdata?.address,
    },
    {
      name: "City",
      value: supplierdata?.city,
    },
    {
      name: "State",
      value: supplierdata?.state,
    },
    {
      name: "Country",
      value: supplierdata?.country,
    },
    {
      name: "Zipcode",
      value: supplierdata?.zipcode,
    },
    {
      name: "Fax",
      value: supplierdata?.fax,
    },
    {
      name: "City",
      value: supplierdata?.city,
    },
  ];
  return (
    <Row
      className="margin-row"
      justify="center"
      align="middle"
      gutter={[0, 16]}
      style={{ backgroundColor: "white" }}
    >
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <ViewPharmacy
          PurchaseType="Supplier"
          data={supplierdata}
          viewTable="false"
        />
      </Col>
      <Col xl={22} lg={22} md={22} sm={24} xs={24}>
        <Row className="margin-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Typography className="fs-20">Supplier Section</Typography>{" "}
            <Table
              showHeader={false}
              columns={columns}
              dataSource={supplierData.filter((element) => element !== null)}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>

          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Row className="margin-row" gutter={[0, 12]}>
              <Col xl={20} lg={24} md={20} sm={17} xs={24}>
                <Typography className="fs-20">Bank Section</Typography>
                <Table
                  showHeader={false}
                  columns={columns}
                  dataSource={bankData.filter((element) => element !== null)}
                  pagination={false}
                  scroll={{ x: true }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewSupplier;
