import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Form, Input, Spin, Avatar } from "antd";

import IntlMessages from "../../util/IntlMessages";
import ButtonComponent from "../../components/Button/ButtonComponent";
import FormInput from "../../components/Input/FormInput";

import { useDispatch, useSelector } from "react-redux";

import FormItem from "antd/es/form/FormItem";
import { APP_ROUTES } from "../../constants/Routes";
import CountrySelector from "../Doctor/component/CountrySelector";
import StateSelector from "../Doctor/component/StateSelector";
import CitySelector from "../Doctor/component/CitySelector";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import BloodGroupSelectBox from "./bloodGroupSelectBox";

import { Select } from "antd";
import { patientAddListInProgress } from "../../appRedux/admin/patient/action/action";

const AddNewPatient = () => {
  const organization_id = localStorage.getItem("org_id");
  const { Option } = Select;
  const [form] = Form.useForm();
  const [country, setCountry] = useState("");
  const [image, setImage] = useState("");
  const [photo123, setphoto123] = useState(null);
  const selectedCountryID = useRef("");
  const selectedCityID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const onChangeCountry = (value) => {
    setCountry(value);
    setCity(null);
    setState(null);
    form.setFieldValue("state", "");
    form.setFieldValue("city", "");
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
    setCity(null);
    form.setFieldValue("city", "");
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedCityID.current = city;
  }, [city]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);
  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }
  const [Loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const user_id = localStorage.getItem("id");
  const history = useHistory();
  const dispatch = useDispatch();
  const onFinish = (datas) => {
    const handleSuccess = () => {
      history.push(APP_ROUTES.PATIENT1);
    };

    let form = new URLSearchParams();
    form.append("firstName", datas.firstName);
    form.append("lastName", datas.lastName);
    form.append("contact", datas.contact);

    form.append("country_code", datas.country_code);
    form.append("city", datas.city);
    form.append("state", datas.state);
    form.append("email", datas.email);
    form.append("address1", datas.address1);
    form.append("address2", datas.address2 ? datas.address2 : "");
    form.append("dob", datas.dob);
    form.append("organization_id", organization_id);
    form.append("role_id", "3");
    form.append("user_id", user_id);
    form.append("blood_group_id", datas.blood_group_id);
    form.append("profile_image", image ? image : null);
    form.append("gender_type", datas.gender_type);

    dispatch(patientAddListInProgress({ form, handleSuccess }));
  };
  const patientData = useSelector((state) => state.patient);

  const patientList = patientData?.addList;

  useEffect(() => {
    if (patientList?.inProgress) {
      setLoading(true);
    } else if (patientList?.success) {
      setLoading(false);
      setphoto123(null);
      form.resetFields();
      //  history.push("/patient");
    }
  }, [patientList?.inProgress, patientList?.success]);

  // useEffect(() => {
  //   if (patientList?.success) {
  //   }
  // }, [patientList?.success]);
  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];

  return (
    <>
      {/* <RegistrationComponent 
     onFinish={onFinish}
     title={"Addnew.patient.label.AddNewPatient"}
     buttonName={"submit"}
   form={form}
     /> */}

      <Spin indicator={antIcon} spinning={patientList?.inProgress}>
        <Card>
          <h1>{<IntlMessages id={"Addnew.patient.label.AddNewPatient"} />}</h1>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col className="img">
                {photo123 === null ? (
                  <Avatar size={94} icon={<UserOutlined />} />
                ) : (
                  <img className="img-btn" src={photo123} />
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormItem
                  label="Profile"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please upload your image!",
                  //   },
                  // ]}
                  name="profile_image"
                  valuePropName="fileList"
                >
                  <Input
                    type={"file"}
                    accept="image/*"
                    name="profile_image"
                    onChange={(e) => encodeImageFileAsURL(e)}
                  />
                </FormItem>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="FirstName"
                  name={"firstName"}
                  label={<IntlMessages id={"Addnew.patient.label.firstName"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.patient.validator.firstName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="LastName"
                  name={"lastName"}
                  label={<IntlMessages id={"Addnew.patient.label.lastName"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.patient.validator.lastName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="email"
                  placeholder="Email"
                  name={"email"}
                  label={<IntlMessages id={"Addnew.patient.label.email"} />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.email"} />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="contact"
                  label={<IntlMessages id={"Addnew.patient.label.contact"} />}
                  name={"contact"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.contact"} />
                      ),
                    },
                    {
                      pattern: /^[6789][0-9]{9}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  ]}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="contact"
                  label={<IntlMessages id={"Addnew.patient.label.contact"} />}
                  name={"contact"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.contact"} />
                      ),
                    },
                  ]}
                />
              </Col> */}
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormInput
          labelCol={"24"}
          wrapperCol={"24"}
            type="text"
            placeholder="street"
            label={<IntlMessages id={"Addnew.patient.label.street"} />}
            name={"street"}
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"Addnew.patient.validator.street"} />
                ),
              },
            ]}
          />
        </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormInput
          labelCol={"24"}
          wrapperCol={"24"}
            type="text"
            placeholder="country"
            label={<IntlMessages id={"Addnew.patient.label.country"} />}
            name={"country"}
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages id={"Addnew.patient.validator.country"} />
                ),
              },
            ]}
          />
        </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="date"
                  placeholder="DOB"
                  label={<IntlMessages id={"Addnew.patient.label.dob"} />}
                  name={"dob"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.dob"} />
                      ),
                    },
                    {
                      pattern: /^\d{4}-\d{2}-\d{2}$/,
                      message: "Please enter a valid DOB",
                    },
                    // {
                    //   validator: validateDateFormat,
                    // },
                  ]}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="dob"
                  label={<IntlMessages id={"Addnew.patient.label.dob"} />}
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message: (
                        <IntlMessages id={"Addnew.patient.validator.dob"} />
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    className="calender-responsive"
                    placeholder="Date of Birth"
                  />
                </Form.Item>
              
              </Col> */}

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CountrySelector
                  margin="mb-30"
                  label={<IntlMessages id={"Addnew.patient.label.country"} />}
                  name={"country_code"}
                  onChange={onChangeCountry}
                  country={country}
                  placeholder={"Select Country"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Country is required",
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StateSelector
                  margin="mb-30"
                  label={"State"}
                  name={"state"}
                  onChange={onChangeState}
                  country={state}
                  countryId={country?.id}
                  placeholder={"Select  State"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "State is required",
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CitySelector
                  margin="mb-30"
                  label={"City"}
                  name={"city"}
                  onChange={onChangeCity}
                  country={city}
                  StateId={state?.id}
                  placeholder={"Select  City"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "City is required",
                    },
                  ]}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="Address"
                  label={<IntlMessages id={"Addnew.patient.label.address1"} />}
                  name={"address1"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.patient.validator.address1"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="Address 2"
                  label={<IntlMessages id={"Addnew.patient.label.address2"} />}
                  name={"address2"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: (
                  //       <IntlMessages
                  //         id={"Addnew.patient.validator.address2"}
                  //       />
                  //     ),
                  //   },
                  // ]}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormInput
            labelCol={"24"}
            wrapperCol={"24"}
            type = "text"
            placeholder="role_id"
              label={<IntlMessages id={"Addnew.patient.label.role_id"} />}
              name={"role_id"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.patient.validator.role_id"} />
                  ),
                },
              ]}
            />
            
          </Col> */}
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormInput
            labelCol={"24"}
            wrapperCol={"24"}
            type = "text"
            placeholder="user_id"
              label={<IntlMessages id={"Addnew.patient.label.user_id"} />}
              name={"user_id"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.patient.validator.user_id"} />
                  ),
                },
              ]}
            />
            
          </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <BloodGroupSelectBox
                  margin="mb-30"
                  label={"Blood Group"}
                  name={"blood_group_id"}
                  placeholder={"Blood Group is required"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your blood group",
                    },
                  ]}
                />
              </Col>

              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <OrganizationSelector
                  margin="mb-30"
                  label={"Organization"}
                  name={"organization_id"}
                  placeholder={"Select Your Organization"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Organization",
                    },
                  ]}
                />
              </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Gender"
                  name="gender_type"
                  rules={[
                    {
                      required: true,
                      message: "Gender is required",
                    },
                  ]}
                >
                  <Select placeholder="Select gender">
                    {genderOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row className="button-row">
              <Col className="register-button">
                {" "}
                <Link to={APP_ROUTES.CANCELPATIENTUPDATE}>
                  <ButtonComponent
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>
                <ButtonComponent
                  type="primary"
                  label="Submit"
                  htmlType="submit"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddNewPatient;
