import React, { useEffect } from "react";
import "../../styles/modules/admin.less";
import { Card, Col, Row, Spin, Table } from "antd";

import ColorCard from "../../components/card/ColorCard";

import { patientDashboardInProgress } from "../../appRedux/Patient/Action/Action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const PatientDashboardScreen = () => {
  const dispatch = useDispatch();

  const patientDashBoard = useSelector(
    (state) => state?.patientAppointment?.patientDashboard
  );
  const countData = patientDashBoard?.data?.count;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
    },
    {
      title: "Week Day",
      dataIndex: "week_day",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      render: (start_time) => moment(start_time, ["h:mm A"]).format("hh:mm A"),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (end_time) => moment(end_time, ["h:mm A"]).format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Symptom",
      dataIndex: "symptom",
    },
  ];
  useEffect(() => {
    dispatch(patientDashboardInProgress());
  }, []);
  return (
    <Card>
      <Spin indicator={antIcon} spinning={patientDashBoard?.inProgress}>
        <Row gutter={[16, 16]}>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <ColorCard
              count={countData?.doctorCount}
              title={"Doctors"} //appoitments
              cardColor={"gx-pink-purple-gradient"}
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <ColorCard
              count={countData?.patientCount}
              title={"Patient"} //prescription
              cardColor={"gx-blue-cyan-gradient"}
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <ColorCard
              count={countData?.appointmentCount}
              title={"Appointments"} // appoitments
              cardColor={"gx-green-cyan-gradient"}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="px-0">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="h4 gx-text-capitalize">Recent Appointment </h2>
            <Table
              showHeader={true}
              columns={columns}
              dataSource={patientDashBoard?.data?.count?.recentAppointments}
              pagination={false}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default PatientDashboardScreen;
