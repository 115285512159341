import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Form, Row, Spin } from "antd";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../constants/Routes";
import IntlMessages from "../../../util/IntlMessages";
import { LoadingOutlined } from "@ant-design/icons";
import StockAdjustmentProductTable from "./StockAdjustmentProductTable";

const AddStockAdjustment = ({ stockAdjustmentData, editStockAdjustment }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const [Loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const addStockAdjustmentData = useSelector(
    (state) => state?.pharmacy?.addStockAdjustment
  );
  const editStockAdjustmentData = useSelector(
    (state) => state?.pharmacy?.editStockAdjustment
  );

  const onFinish = (values) => {
    if (
      addStockAdjustmentData?.inProgress ||
      editStockAdjustmentData?.inProgress
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (addStockAdjustmentData?.success || editStockAdjustmentData?.success) {
      history.push(APP_ROUTES.STOCKADJUSTMENT);
    }
  }, [addStockAdjustmentData]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <Card>
        <Row>
          <Col span={24}>
            <h1 className="text">
              {editStockAdjustment ? (
                <IntlMessages id={"label.editStockAdjustment"} />
              ) : (
                <IntlMessages id={"label.addStockAdjustment"} />
              )}
            </h1>
          </Col>
          <Divider />
        </Row>
        <StockAdjustmentProductTable
          onFinish={onFinish}
          form={form}
          editStockAdjustmentData={stockAdjustmentData}
          editStockAdjustment={editStockAdjustment}
        />
      </Card>
    </Spin>
  );
};

export default AddStockAdjustment;
