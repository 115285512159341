import {
  ADD_HOSPITAL_ERROR,
  ADD_HOSPITAL_IN_PROGRESS,
  ADD_HOSPITAL_SUCCESS,
  ADD_ORG_TIME_ERROR,
  ADD_ORG_TIME_PROGRESS,
  ADD_ORG_TIME_SUCCESS,
  ADD_PACKAGE_ERROR,
  ADD_PACKAGE_IN_PROGRESS,
  ADD_PACKAGE_SUCCESS,
  ADD_PLAN_ERROR,
  ADD_PLAN_IN_PROGRESS,
  ADD_PLAN_SUCCESS,
  ADD_SA_ORG_ERROR,
  ADD_SA_ORG_IN_PROGRESS,
  ADD_SA_ORG__SUCCESS,
  ADD_SUBSCRIPTION_DETAILS_ERROR,
  ADD_SUBSCRIPTION_DETAILS_INPROGRESS,
  ADD_SUBSCRIPTION_DETAILS_SUCCESS,
  ADD_SUBSCRIPTION_ERROR,
  ADD_SUBSCRIPTION_IN_PROGRESS,
  ADD_SUBSCRIPTION_PAYMENT_ERROR,
  ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS,
  ADD_SUBSCRIPTION_PAYMENT_SUCCESS,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSRIPTION_DETAILS_INPROGRESS,
  DEFAULT_ORGANIZATION,
  DEFAULT_PLAN,
  DEFAULT_SUBSCRIPTION,
  DEFAULT_SUBSCRIPTION_PAYMENT,
  DELETE_HOSPITAL_ERROR,
  DELETE_HOSPITAL_IN_PROGRESS,
  DELETE_HOSPITAL_SUCCESS,
  DELETE_PACKAGE_SA_ERROR,
  DELETE_PACKAGE_SA_IN_PROGRESS,
  DELETE_PACKAGE_SA__SUCCESS,
  DELETE_PLAN_ERROR,
  DELETE_PLAN_IN_PROGRESS,
  DELETE_PLAN_SUCCESS,
  DELETE_SA_ORG_ERROR,
  DELETE_SA_ORG_IN_PROGRESS,
  DELETE_SA_ORG__SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION_IN_PROGRESS,
  DELETE_SUBSCRIPTION_SUCCESS,
  EDIT_HOSPITAL_ERROR,
  EDIT_HOSPITAL_IN_PROGRESS,
  EDIT_HOSPITAL_SUCCESS,
  EDIT_PACKAGE_ERROR,
  EDIT_PACKAGE_IN_PROGRESS,
  EDIT_PACKAGE_SUCCESS,
  EDIT_PLAN_ERROR,
  EDIT_PLAN_IN_PROGRESS,
  EDIT_PLAN_SUCCESS,
  EDIT_SA_ORG_ERROR,
  EDIT_SA_ORG_IN_PROGRESS,
  EDIT_SA_ORG__SUCCESS,
  EDIT_SUBSCRIPTION_ERROR,
  EDIT_SUBSCRIPTION_IN_PROGRESS,
  EDIT_SUBSCRIPTION_SUCCESS,
  GET_HOSPITAL_LIST_ERROR,
  GET_HOSPITAL_LIST_IN_PROGRESS,
  GET_HOSPITAL_LIST_SUCCESS,
  GET_MODULE_PERMISSION_ERROR,
  GET_MODULE_PERMISSION_IN_PROGRESS,
  GET_MODULE_PERMISSION_SUCCESS,
  GET_ORG_DETAILS_BY_ID_ERROR,
  GET_ORG_DETAILS_BY_ID_IN_PROGRESS,
  GET_ORG_DETAILS_BY_ID_SUCCESS,
  GET_PACKAGES_ERROR,
  GET_PACKAGES_IN_PROGRESS,
  GET_PACKAGES_SUCCESS,
  GET_PLAN_LIST_ERROR,
  GET_PLAN_LIST_IN_PROGRESS,
  GET_PLAN_LIST_SUCCESS,
  GET_SA_ORG__LIST_ERROR,
  GET_SA_ORG__LIST_IN_PROGRESS,
  GET_SA_ORG__LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_ERROR,
  GET_SUBSCRIPTION_LIST_IN_PROGRESS,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUPER_ADMIN_DASHBOARD_ERROR,
  GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS,
  GET_SUPER_ADMIN_DASHBOARD_SUCCESS,
  SUBSCRIPTION_PAMENT_DEFAULT,
} from "../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  superAdminDashboard: { ...defaultStatus },
  hospitalList: { ...defaultStatus },
  addHospital: { ...defaultStatus },
  editHospital: { ...defaultStatus },
  deleteHospital: { ...defaultStatus },
  packageList: { ...defaultStatus },
  addPackage: { ...defaultStatus },
  editPackage: { ...defaultStatus },
  deletePackage: { ...defaultStatus },
  subscriptionList: { ...defaultStatus },
  addSubscription: { ...defaultStatus },
  editSubscription: { ...defaultStatus },
  deleteSubscription: { ...defaultStatus },
  modulePermission: { ...defaultStatus },
  planList: { ...defaultStatus },
  addPlan: { ...defaultStatus },
  editPlan: { ...defaultStatus },
  deletePlan: { ...defaultStatus },
  orgList: { ...defaultStatus },
  addOrg: { ...defaultStatus },
  editOrg: { ...defaultStatus },
  deleteOrg: { ...defaultStatus },
  addubscriptionPayment: { ...defaultStatus },
  addSubscriptionDetails: { ...defaultStatus },
  addOrgTime: { ...defaultStatus },
  getOrgDetailsById: { ...defaultStatus },
};
const SuperAdminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUPER_ADMIN_DASHBOARD_IN_PROGRESS:
      return {
        ...state,
        superAdminDashboard: { ...defaultStatus, inProgress: true },
      };
    case GET_SUPER_ADMIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        superAdminDashboard: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SUPER_ADMIN_DASHBOARD_ERROR:
      return {
        ...state,
        superAdminDashboard: { ...defaultStatus, ...payload, error: true },
      };
    case GET_HOSPITAL_LIST_IN_PROGRESS:
      return {
        ...state,
        hospitalList: { ...defaultStatus, inProgress: true },
      };
    case GET_HOSPITAL_LIST_SUCCESS:
      return {
        ...state,
        hospitalList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_HOSPITAL_LIST_ERROR:
      return {
        ...state,
        hospitalList: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_HOSPITAL_IN_PROGRESS:
      return {
        ...state,
        addHospital: { ...defaultStatus, inProgress: true },
      };
    case ADD_HOSPITAL_SUCCESS:
      return {
        ...state,
        addHospital: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_HOSPITAL_ERROR:
      return {
        ...state,
        addHospital: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_HOSPITAL_IN_PROGRESS:
      return {
        ...state,
        editHospital: { ...defaultStatus, inProgress: true },
      };
    case EDIT_HOSPITAL_SUCCESS:
      return {
        ...state,
        editHospital: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_HOSPITAL_ERROR:
      return {
        ...state,
        editHospital: { ...defaultStatus, ...payload, error: true },
      };
    case DELETE_HOSPITAL_IN_PROGRESS:
      return {
        ...state,
        deleteHospital: { ...defaultStatus, inProgress: true },
      };
    case DELETE_HOSPITAL_SUCCESS:
      return {
        ...state,
        deleteHospital: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_HOSPITAL_ERROR:
      return {
        ...state,
        deleteHospital: { ...defaultStatus, ...payload, error: true },
      };
    case GET_PACKAGES_IN_PROGRESS:
      return {
        ...state,
        packageList: { ...defaultStatus, inProgress: true },
      };
    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        packageList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PACKAGES_ERROR:
      return {
        ...state,
        addPackage: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_PACKAGE_IN_PROGRESS:
      return {
        ...state,
        addPackage: { ...defaultStatus, inProgress: true },
      };
    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        addPackage: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_PACKAGE_ERROR:
      return {
        ...state,
        addHospital: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_PACKAGE_IN_PROGRESS:
      return {
        ...state,
        editPackage: { ...defaultStatus, inProgress: true },
      };
    case EDIT_PACKAGE_SUCCESS:
      return {
        ...state,
        editPackage: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_PACKAGE_ERROR:
      return {
        ...state,
        editPackage: { ...defaultStatus, ...payload, error: true },
      };
    case DELETE_PACKAGE_SA_IN_PROGRESS:
      return {
        ...state,
        deletePackage: { ...defaultStatus, inProgress: true },
      };
    case DELETE_PACKAGE_SA__SUCCESS:
      return {
        ...state,
        deletePackage: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_PACKAGE_SA_ERROR:
      return {
        ...state,
        deletePackage: { ...defaultStatus, ...payload, error: true },
      };
    case GET_SUBSCRIPTION_LIST_IN_PROGRESS:
      return {
        ...state,
        subscriptionList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptionList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SUBSCRIPTION_LIST_ERROR:
      return {
        ...state,
        subscriptionList: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_SUBSCRIPTION_IN_PROGRESS:
      return {
        ...state,
        addSubscription: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        addSubscription: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SUBSCRIPTION_ERROR:
      return {
        ...state,
        addSubscription: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_SUBSCRIPTION_IN_PROGRESS:
      return {
        ...state,
        editSubscription: { ...defaultStatus, ...payload, inProgress: true },
      };
    case EDIT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        editSubscription: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_SUBSCRIPTION_ERROR:
      return {
        ...state,
        editSubscription: { ...defaultStatus, ...payload, error: true },
      };
    case DELETE_SUBSCRIPTION_IN_PROGRESS:
      return {
        ...state,
        deleteSubscription: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        deleteSubscription: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        deleteSubscription: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_SUBSCRIPTION:
      return {
        ...state,
        addSubscription: { ...defaultStatus },
        editSubscription: { ...defaultStatus },
      };
    case GET_MODULE_PERMISSION_IN_PROGRESS:
      return {
        ...state,
        modulePermission: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_MODULE_PERMISSION_SUCCESS:
      return {
        ...state,
        modulePermission: { ...defaultStatus, ...payload, success: true },
      };
    case GET_MODULE_PERMISSION_ERROR:
      return {
        ...state,
        modulePermission: { ...defaultStatus, ...payload, error: true },
      };
    case GET_PLAN_LIST_IN_PROGRESS:
      return {
        ...state,
        planList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_PLAN_LIST_SUCCESS:
      return {
        ...state,
        planList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PLAN_LIST_ERROR:
      return {
        ...state,
        planList: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_PLAN_IN_PROGRESS:
      return {
        ...state,
        addPlan: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_PLAN_SUCCESS:
      return {
        ...state,
        addPlan: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_PLAN_ERROR:
      return {
        ...state,
        addPlan: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_PLAN_IN_PROGRESS:
      return {
        ...state,
        editPlan: { ...defaultStatus, ...payload, inProgress: true },
      };
    case EDIT_PLAN_SUCCESS:
      return {
        ...state,
        editPlan: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_PLAN_ERROR:
      return {
        ...state,
        editPlan: { ...defaultStatus, ...payload, error: true },
      };
    case DELETE_PLAN_IN_PROGRESS:
      return {
        ...state,
        deletePlan: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        deletePlan: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_PLAN_ERROR:
      return {
        ...state,
        deletePlan: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_PLAN:
      return {
        ...state,
        addPlan: { ...defaultStatus, ...payload, inProgress: true },
        editPlan: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_SA_ORG__LIST_IN_PROGRESS:
      return {
        ...state,
        orgList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_SA_ORG__LIST_SUCCESS:
      return {
        ...state,
        orgList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SA_ORG__LIST_ERROR:
      return {
        ...state,
        orgList: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_ORGANIZATION:
      return {
        ...state,
        addOrg: { ...defaultStatus },
        editOrg: { ...defaultStatus },
      };
    case ADD_SA_ORG_IN_PROGRESS:
      return {
        ...state,
        addOrg: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_SA_ORG__SUCCESS:
      return {
        ...state,
        addOrg: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SA_ORG_ERROR:
      return {
        ...state,
        addOrg: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_SA_ORG_IN_PROGRESS:
      return {
        ...state,
        editOrg: { ...defaultStatus, ...payload, inProgress: true },
      };
    case EDIT_SA_ORG__SUCCESS:
      return {
        ...state,
        editOrg: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_SA_ORG_ERROR:
      return {
        ...state,
        editOrg: { ...defaultStatus, ...payload, error: true },
      };
    case DELETE_SA_ORG_IN_PROGRESS:
      return {
        ...state,
        deleteOrg: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_SA_ORG__SUCCESS:
      return {
        ...state,
        deleteOrg: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_SA_ORG_ERROR:
      return {
        ...state,
        deleteOrg: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_SUBSCRIPTION_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        addubscriptionPayment: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_SUBSCRIPTION_PAYMENT_SUCCESS:
      return {
        ...state,
        addubscriptionPayment: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SUBSCRIPTION_PAYMENT_ERROR:
      return {
        ...state,
        addubscriptionPayment: { ...defaultStatus, ...payload, error: true },
      };
    case SUBSCRIPTION_PAMENT_DEFAULT:
      return {
        ...state,
        addubscriptionPayment: { ...defaultStatus },
        addSubscriptionDetails: { ...defaultStatus },
      };
    case ADD_SUBSCRIPTION_DETAILS_INPROGRESS:
      return {
        ...state,
        addSubscriptionDetails: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        addSubscriptionDetails: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SUBSCRIPTION_DETAILS_ERROR:
      return {
        ...state,
        addSubscriptionDetails: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_SUBSCRIPTION_PAYMENT:
      return {
        ...state,
        addubscriptionPayment: { ...defaultStatus, ...payload },
        addSubscriptionDetails: {
          ...defaultStatus,
        },
      };
    case ADD_ORG_TIME_PROGRESS:
      return {
        ...state,
        addOrgTime: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_ORG_TIME_SUCCESS:
      return {
        ...state,
        addOrgTime: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_ORG_TIME_ERROR:
      return {
        ...state,
        addOrgTime: { ...defaultStatus, ...payload, error: true },
      };
    case GET_ORG_DETAILS_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getOrgDetailsById: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_ORG_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        getOrgDetailsById: { ...defaultStatus, ...payload, success: true },
      };
    case GET_ORG_DETAILS_BY_ID_ERROR:
      return {
        ...state,
        getOrgDetailsById: { ...defaultStatus, ...payload, error: true },
      };
    default:
      return state;
  }
};
export default SuperAdminReducer;
