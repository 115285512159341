import { Col, Input, Row } from "antd";

import Form from "antd/lib/form/Form";

import React, { useEffect, useState } from "react";
import ButtonComponent from "../../components/Button/ButtonComponent";

// import "../../../styles/modules/patient.less";
import "../../styles/modules/patient.less";
import { useDispatch, useSelector } from "react-redux";
import { PatientFileUploadInProgress } from "../../appRedux/admin/patient/action/action";

const PatientCSVfile = ({ handleOk }) => {
  const [file, setFile] = useState(null);
  // const [fileName,setFileName]= useState('');
  // const [error, setError] = useState();
  const { csv } = useSelector((state) => state.patient);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  var reader = new FileReader();
  const handleOnChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const validateFile = (rule, value, callback) => {
    const regex = /(\.csv|\.xlsx)$/i;
    if (!regex.test(value)) {
      // callback('only csv and xlsx are allowed');
      return Promise.reject("only csv and xlsx are allowed");
    } else {
      // callback();
      return Promise.resolve();
    }
  };

  const onFinish = (datas) => {
    let formData = new FormData();
    // let formData = new URLSearchParams();

    formData.append("file", file);

    dispatch(PatientFileUploadInProgress(formData));
  };

  useEffect(() => {
    if (csv?.success) {
      form.resetFields();
    }
  }, [csv?.success]);

  const handleCancel = () => {
    handleOk();
    setFile(null);
    form.setFieldsValue({ file: null });
  };
  return (
    <div>
      <Form
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        colon={"true"}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="File"
            name="file"
            rules={[
              { required: true, message: "Please upload your file" },
              { validator: validateFile },
            ]}
          >
            <Input type="file" onChange={handleOnChange} />
          </Form.Item>
        </Col>

        <Row className="button-row">
          <Col className="register-button ">
            <ButtonComponent
              label="Cancel"
              className="btn-cancel csv-btn secondary-color"
              onClick={handleCancel}
            />
            <ButtonComponent
              type="primary"
              label="Submit"
              htmlType="submit "
              className="csv-btn"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PatientCSVfile;
