import React from "react";
import { useLocation } from "react-router-dom";
import AppointmentQuestions from "./AppointmentQuestions";

function AppointmentQuestionsEdit() {
  const locations = useLocation();

  return (
    <div>
      <AppointmentQuestions edit={true} editData={locations?.state} />
    </div>
  );
}

export default AppointmentQuestionsEdit;
