import React, { useEffect, useState } from "react";
import { Spin, Modal, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import "../../../../styles/modules/pharmacy.less";
import { useSelector } from "react-redux";

import TableComponent from "../../../../components/Table/TableComponent";

import moment from "moment";
import { salesListByPatientInProgress } from "../../../../appRedux/Pharmacy/PharmacyAction";

const ViewSalesHistory = ({ patientId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { salesByPatientList } = useSelector((state) => state?.pharmacy);

  const [filteredUsers, setFilteredUsers] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const formData = {
      patient_id: patientId?.patient_id,
      pagination: true,
      pageNo: 1,
      pageSize: 10,
    };
    dispatch(salesListByPatientInProgress(formData));
  }, [patientId?.patient_id]);

  const totalRecords = salesByPatientList.data?.totalRecords;

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const formData = {
      patient_id: patientId?.patient_id,
      pagination: true,
      pageNo: page,
      pageSize: 10,
    };
    dispatch(salesListByPatientInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(PurchaseRequestList);
    } else {
      const newData = PurchaseRequestList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const columns = [
    {
      title: "SO.No",
      dataIndex: "sales_code",
      sortDirections: ["ascend", "descend"],
      key: "sono",
      sorter: (a, b) => {
        if (a && b && a.sono && b.sono) {
          return a.sono.localeCompare(b.sono);
        }
        return 0;
      },
    },
    {
      title: "SO.Date",
      dataIndex: "sales_date",
      sortDirections: ["ascend", "descend"],
      key: "soDate",
      sorter: (a, b) => {
        if (a && b && a.soDate && b.soDate) {
          return a.soDate.localeCompare(b.soDate);
        }
        return 0;
      },
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      sortDirections: ["ascend", "descend"],
      key: "doctor",
      sorter: (a, b) => {
        if (a && b && a.doctor && b.doctor) {
          return a.doctor.localeCompare(b.doctor);
        }
        return 0;
      },
    },
    {
      title: "Medicines",
      dataIndex: "medicines",
      sortDirections: ["ascend", "descend"],
      key: "medicines",
      sorter: (a, b) => {
        if (a && b && a.medicines && b.medicines) {
          return a.medicines.localeCompare(b.medicines);
        }
        return 0;
      },
      render: (_, record) => {
        return record?.sales_details?.length;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      sortDirections: ["ascend", "descend"],
      key: "total",
      sorter: (a, b) => {
        if (a && b && a.total && b.total) {
          return a.total.localeCompare(b.total);
        }
        return 0;
      },
    },
    {
      title: "Paid",
      dataIndex: "deposite",
      sortDirections: ["ascend", "descend"],
      key: "paid",
      sorter: (a, b) => {
        if (a && b && a.paid && b.paid) {
          return a.paid.localeCompare(b.paid);
        }
        return 0;
      },
    },
    {
      title: "Due",
      dataIndex: "due",
      sortDirections: ["ascend", "descend"],
      key: "due",
      sorter: (a, b) => {
        if (a && b && a.due && b.due) {
          return a.due.localeCompare(b.due);
        }
        return 0;
      },
      render: (_, record) => {
        return (
          <Typography className="red-text">
            {(
              Number(record?.total).toFixed(2) -
              Number(record?.deposite).toFixed(2)
            ).toFixed(2)}
          </Typography>
        );
      },
    },
  ];

  const PurchaseRequestList = salesByPatientList.data?.data || [];
  return (
    <Spin indicator={antIcon} spinning={salesByPatientList?.inProgress}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : PurchaseRequestList}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        rowselector={handleSelectChange}
        itemList={PurchaseRequestList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"supplierName"}
      />

      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <Typography>Are you sure, you want to delete the Data</Typography>
      </Modal>
    </Spin>
  );
};

export default ViewSalesHistory;
