import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import TableComponent from "../../../components/Table/TableComponent";
import moment from "moment";
import {
  deleteSpecializationInProgress,
  getSpecializationInProgress,
} from "../../../appRedux/doctor/actions/Specialization";

const SpecializationTable = () => {
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "created At",
      dataIndex: "createdAt",
    },
    {
      title: "updated At",
      dataIndex: "updatedAt",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: "/specialization/edit",
                  state: record,
                }}
              >
                <ButtonComponent type="primary" icon={<EditOutlined />} />
              </Link>
            </Col>

            <Col className="button-edit">
              <ButtonComponent
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const dispatch = useDispatch();
  const specializationData = useSelector((state) => state.specialization);
  const specializationListData = specializationData?.Specialization;
  const specializationList = specializationListData?.data?.data;
  const DeleteResponse = specializationData?.deleteSpecialization?.success;
  const totalRecords = specializationListData?.data?.totalRecords;
  const specialization_List =
    specializationList &&
    specializationList.map((obj) => {
      if (obj.createdAt && obj.updatedAt) {
        return {
          ...obj,
          createdAt: moment(obj.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(obj.updatedAt).format("MM/DD/YYYY"),
        };
      }

      return obj;
    });

  const handlePage = (page) => {
    dispatch(getSpecializationInProgress(page));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(specialization_List);
    } else {
      const newData = specialization_List.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const editData = (record) => {};
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteSpecializationInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (specializationData?.Specialization?.inProgress) {
      setLoading(true);
    } else if (
      specializationData?.Specialization?.success ||
      specializationData?.Specialization?.error
    ) {
      setLoading(false);
    }
  }, [
    specializationData?.Specialization?.inProgress,
    specializationData?.Specialization?.success,
    specializationData?.Specialization?.error,
  ]);
  useEffect(() => {
    if (DeleteResponse) {
      const page = 1;
      dispatch(getSpecializationInProgress(page));
    }
  }, [DeleteResponse]);
  useEffect(() => {
    const page = 1;
    dispatch(getSpecializationInProgress(page));
  }, []);
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : specialization_List}
        itemList={specialization_List}
        filteredUsers={filteredUsers}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        setFilteredUsers={setFilteredUsers}
        field={"Name"}
        rowselector={handleSelectChange}
      />{" "}
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
      >
        <p>Are you sure, you want to delete the Data ?</p>
      </Modal>
    </Spin>
  );
};

export default SpecializationTable;
