import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import IntlMessages from "../../../util/IntlMessages";

const PatientDueHeader = ({ view, Due, patientName, SerialNO }) => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {
              <IntlMessages
                id={
                  view
                    ? `View Dues - ${patientName}  (${SerialNO})`
                    : "Patient Dues "
                }
              />
            }
          </Typography>

          <Col className="py-10">
            <Typography className="f-20">
              <span className="inline">
                <IntlMessages id={"Due Amount"} />
              </span>{" "}
              <Typography className="red-text inline">{Due}</Typography>
            </Typography>
          </Col>
        </Col>

        <Col>
          {/* <Link to={APP_ROUTES.ADDSALES}>
            <ButtonComponent
              type="primary"
              label={<IntlMessages id={"Addnew.patient.addnew"} />}
              icon={<PlusCircleOutlined />}
              onClick={() => {
                dispatch(defaultStateAddUpdatePayment());
              }}
            />
          </Link> */}
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default PatientDueHeader;
