import { axiosInstance } from "../../../../util/utilz";

export const getpatientListEffect = (page, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/patients/organization?organization_id=${organization_id}&pagesize=10&pageNo=${page}&pagination_required=true`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
export const patientFileUploadEffect = (formData, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/patients/bulkInsertPatient",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type":
        "multipart/form-data;boundary=<calculated when request is sent>",
    },
  });
};
export const addPatientListEffect = (form, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/admin/addPatient",
    method: "POST",
    data: form,
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
export const updatePatientListEffect = ({ id, form }) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/patients/update?patient_id=${id}`,
    method: "PUT",
    data: form,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};
export const deletePatientListEffect = (formData, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");

  return axiosInstance.request({
    url: `/patients/delete/${formData?.deleteId}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${tokens}`,
      organizationId: `${formData?.organization_id}`,

      "Content-Type": "application/json",
    },
  });
};
export const getpatientListByIdEffect = (form, token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");

  return axiosInstance.request({
    url: `/patients/details?patient_id=${form.id}&organization_id=${form.organization_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
export const getBloodGroupListEffect = (token) => {
  const tokens = localStorage.getItem("user_id") || token;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: "/bloodgroup/list",
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
  });
};
export const getPatientBillingReportEffect = (page, form) => {
  const token = localStorage.getItem("user_id");
  const org_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/billing/getReports?from_date=${page?.from_date}&organization_id=${org_id}&report_type=daily&to_date=${page?.to_date}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
