import React, { useEffect } from "react";
import { Col, Row, Spin, Table, Typography } from "antd";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PrinterFilled,
  RightOutlined,
} from "@ant-design/icons";
import "../../../styles/modules/pharmacy.less";
import Logo from "../../../assets/images/DocMotez/logo.png";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import { getBillingByInvoiceListInProgress } from "../../../appRedux/doctor/actions/addPayment";
import { useDispatch, useSelector } from "react-redux";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { useRef } from "react";
import { saveAs } from "@progress/kendo-file-saver";

const InvoiceBilling = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pdfContentRef = useRef(null);
  const invoiceData = location.state?.record;
  const navigateButton = location.state?.button;
  const history = useHistory();
  const goBackHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    if (invoiceData?.invoice_no) {
      dispatch(getBillingByInvoiceListInProgress(invoiceData?.invoice_no));
    }
  }, [invoiceData?.invoice_no]);

  const { billingPaymentreducer } = useSelector((state) => state);
  const inVoiceData = billingPaymentreducer?.invoiceList?.data?.data;
  const invoiceNoMatch = inVoiceData?.invoice_no.match(/\d+/g); // Matches all digits
  const invoiceLetter = inVoiceData?.invoice_no.match(/[a-zA-Z]+/g)[0]; // Matches all letters

  // Extract the numeric part of the invoice number
  const invoiceNo = Number(invoiceNoMatch?.[0]); // Extracted number

  const getNextInvoiceNo = (currentInvoiceNo, increment) => {
    const paddedInvoiceNo = currentInvoiceNo.toString().padStart(3, "0"); // Ensure 3-digit format
    const newInvoiceNo = Number(paddedInvoiceNo) + increment; // Calculate new number
    return newInvoiceNo.toString().padStart(3, "0"); // Pad with zeros again if needed
  };

  useEffect(() => {
    if (billingPaymentreducer?.invoiceList?.error) {
      const invoiceLetterState = invoiceData?.invoice_no.match(/[a-zA-Z]+/g)[0];
      const inVoiceNOState = `${invoiceLetterState}001`;

      dispatch(getBillingByInvoiceListInProgress(inVoiceNOState));
    }
  }, [billingPaymentreducer?.invoiceList?.error]);
  const columns = [
    {
      title: "SNO",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "package_name",
      key: "package_name",
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record, index) =>
        Number(record.price) * Number(record.quantity),
    },
  ];

  const handelPrev = (data) => {
    const newInvoiceNo =
      data === "prev"
        ? getNextInvoiceNo(invoiceNo, -1) // Decrement by 1
        : getNextInvoiceNo(invoiceNo, 1); // Increment by 1

    const inVoiceNO = `${invoiceLetter}${newInvoiceNo}`;

    dispatch(getBillingByInvoiceListInProgress(inVoiceNO));
  };
  let timerId;

  const debounce = (func, delay) => {
    clearTimeout(timerId);
    timerId = setTimeout(func, delay);
  };

  const handlePrint = () => {
    debounce(() => {
      window.print();
    }, 500);
  };

  useEffect(() => {
    if (navigateButton === "false" && inVoiceData) {
      handlePrint();
    }
  }, [navigateButton, inVoiceData]);

  const handleDownloadPDF = async () => {
    const content = pdfContentRef.current;

    const options = {
      paperSize: "A3",
      scale: 1,
      margin: { left: "1cm", right: "1cm" }, // Adjust margins as needed
      landscape: true,
      // Set a fixed DPI for screen resolution, e.g., 96 DPI
      pdf: {
        autoTable: { useCss: true },
        avoidLinks: true,
        format: "A3",
        scale: 1,
      },
    };

    const group = await drawDOM(content, options);
    exportPDF(group).then((data) => {
      saveAs(data, "download.pdf");
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Spin
      spinning={billingPaymentreducer?.invoiceList?.inProgress === true}
      indicator={antIcon}
    >
      <Row justify="center" align="middle" gutter={[0, 16]}>
        <Col xl={22} lg={22} md={22} sm={24} xs={22}>
          {navigateButton === "true" && (
            <>
              <Row justify={"space-between"} className="hide-on-print">
                <ButtonComponent
                  type="primary"
                  icon={<ArrowLeftOutlined style={{ fontSize: "20px" }} />}
                  onClick={goBackHandler}
                />
                <Row justify="column">
                  <ButtonComponent
                    type="primary"
                    icon={<PrinterFilled style={{ fontSize: "20px" }} />}
                    onClick={handlePrint}
                  />
                  <ButtonComponent
                    type="primary"
                    className="blue-color"
                    onClick={handleDownloadPDF}
                    icon={<ArrowDownOutlined style={{ fontSize: "20px" }} />}
                  />
                </Row>
                <Link to={APP_ROUTES.ADDBILLINGS}>
                  <ButtonComponent
                    type="primary"
                    className="blue-color"
                    icon={<PlusCircleFilled style={{ fontSize: "16px" }} />}
                  />
                </Link>
              </Row>
              <Row justify={"space-evenly"} className="hide-on-print">
                <ButtonComponent
                  type="primary"
                  className="blue-color"
                  icon={<LeftOutlined style={{ fontSize: "16px" }} />}
                  onClick={() => {
                    handelPrev("prev");
                  }}
                />

                <ButtonComponent
                  type="primary"
                  className="blue-color"
                  icon={<RightOutlined style={{ fontSize: "16px" }} />}
                  onClick={() => {
                    handelPrev("fu");
                  }}
                />
              </Row>
            </>
          )}
          <div ref={pdfContentRef} style={{ margin: "0", padding: "0" }}>
            <Row className="invoice-content" gutter={[0, 16]}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row justify={"space-between"} gutter={[16, 16]}>
                  <Col xl={16} lg={16} md={16} sm={16} xs={24}>
                    <Typography className="invoice-heading">
                      {inVoiceData?.organization_name}
                    </Typography>
                    <Typography className="invoice-heading-address">
                      {inVoiceData?.organization_street},
                      {inVoiceData?.organization_address},
                      {inVoiceData?.organization_city},
                      {inVoiceData?.organization_state},
                      {inVoiceData?.organization_country},
                      {inVoiceData?.organization_country_code},
                    </Typography>
                    <Typography className="invoice-heading-address">
                      Tel: +91 {inVoiceData?.organization_contact},
                      {inVoiceData?.organization_alternate_contact}
                    </Typography>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={24}>
                    <img src={Logo} width={150} />
                  </Col>
                </Row>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row
                  justify={"space-between"}
                  className="margin-row"
                  gutter={[16, 16]}
                >
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} justify="center">
                    <Typography className="payment-invoice">
                      PAYMENT INVOICE
                    </Typography>
                  </Col>
                  <Col xl={14} lg={14} md={14} sm={14} xs={24}>
                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Patient Name</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_name}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Patient ID</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_serial_no}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Address</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_address1},
                        </Typography>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_address2},
                        </Typography>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_city},
                        </Typography>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_state},
                        </Typography>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_country_code}.
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Phone</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography className="fw-5">
                          {inVoiceData?.patient_contact}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={10} lg={10} md={10} sm={10} xs={24}>
                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Invoice</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography className="fw-5">
                          {inVoiceData?.invoice_no}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Date</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography className="fw-5">
                          {inVoiceData?.DATE}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Doctor</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography className="fw-5">
                          DR. {inVoiceData?.doctor_name} (
                          {inVoiceData?.doctor_serial_no})
                        </Typography>
                      </Col>
                    </Row>

                    <Row className="margin-row" gutter={[16, 16]}>
                      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Typography>Status</Typography>
                      </Col>
                      <Col xl={1} lg={1} xs={1} md={1} sm={1}>
                        <Typography>:</Typography>
                      </Col>
                      <Col xl={14} lg={14} md={14} xs={14} sm={14}>
                        <Typography
                          className={`fw-5 ${
                            Number(inVoiceData?.status) === 3
                              ? "cancle-color"
                              : " active-color"
                          }`}
                          color="green"
                        >
                          {Number(inVoiceData?.status) === 3
                            ? "Cancle"
                            : "Active"}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mb-20 ">
                <Table
                  dataSource={inVoiceData?.PatientBillingSubPackages}
                  columns={columns}
                  pagination={false}
                />
              </Col>
            </Row>
            <Row className="margin-row mt-10">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[16, 10]}>
                  <Col xs={24} align="right">
                    <Typography>
                      <Typography>
                        Sub Total : ₹{inVoiceData?.sub_total}
                      </Typography>
                    </Typography>
                  </Col>
                  <Col xs={24} align="right">
                    <Typography>
                      Grand Total : ₹{invoiceData?.grand_total}
                    </Typography>
                  </Col>
                  <Col xs={24} align="right">
                    <Typography>
                      Amount Recieved : ₹{invoiceData?.paid_amount}
                    </Typography>
                  </Col>
                  <Col xs={24} align="right">
                    <Typography>
                      Amount to be paid : ₹{inVoiceData?.due}
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default InvoiceBilling;
