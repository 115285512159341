import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/Table/TableComponent";
import Auxiliary from "../../../util/Auxiliary";
import { Button, Col, Modal, Row, Spin, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  CloseSquareOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import CollectPaymentModal from "../CollectPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBillingInProgress,
  getBillingListInProgress,
  undoBillInProgress,
} from "../../../appRedux/doctor/actions/addPayment";
import { APP_ROUTES } from "../../../constants/Routes";
import Message from "../../../components/Message/Message";
import CancleModal from "./CancleModal";
import { access, formatDate, formatPrice } from "../../../util/utilz";

const BillingTable = () => {
  const organization_id = localStorage.getItem("org_id");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isdeleteModalOpen, setIsdeleteModalOpen] = useState(false);
  const [selectedBillingId, setSelectedBillingId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [reason, setReason] = useState(null);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const { billingPaymentreducer } = useSelector((state) => state);
  const tableData = billingPaymentreducer?.biilingList?.data?.data;
  const totalRecords = billingPaymentreducer?.biilingList?.data?.totalRecords;

  const showModal = (record) => {
    setSelectedBillingId(record.id);
  };
  const showDeleteModel = (showDeleteModel) => {
    setDeleteId(showDeleteModel.id);
    setReason(showDeleteModel.reason);
    setIsdeleteModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleDleteCancel = () => {
    setIsdeleteModalOpen(false);
  };

  useEffect(() => {
    const paylod = {
      organization_id: organization_id,
      pageNo: 1,
    };
    dispatch(getBillingListInProgress(paylod));
  }, []);
  const handlePage = (page) => {
    const paylod = {
      organization_id: organization_id,
      pageNo: page,
    };
    dispatch(getBillingListInProgress(paylod));
  };
  const handleModalSubmit = () => {
    setIsModalVisible(false);
  };
  const handleDelete = (deleteData) => {};
  const handleOk = () => {
    const payload = {
      id: deleteId,
      reason: reason,
    };
    dispatch(deleteBillingInProgress(payload));

    setNavigate(true);
  };

  const handleConformCancle = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = (data) => {};
  const handleUndo = (data) => {
    dispatch(undoBillInProgress(data?.id));
    setNavigate(true);
  };
  const cancel = () => {
    Message.error("Cancelled");
  };
  useEffect(() => {
    if (
      (billingPaymentreducer?.deleteBilling?.success && navigate) ||
      (billingPaymentreducer?.addPayments?.success && navigate) ||
      (billingPaymentreducer?.undo?.success && navigate)
    ) {
      window.location.reload();
      setNavigate(false);
    }
  }, [
    billingPaymentreducer?.deleteBilling?.success && navigate,
    billingPaymentreducer?.addPayments?.success && navigate,
    billingPaymentreducer?.undo?.success && navigate,
  ]);

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      width: 150,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      width: 150,

      render: (_, record) => {
        return (
          <>
            <Typography
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textWrap: "nowrap",
              }}
            >
              {record.patient_name},
            </Typography>
            <Typography>{record.patient_address1}</Typography>
            <Typography>{record.patient_address2}</Typography>
            <Typography>{record.patient_city}</Typography>
            <Typography>{record.patient_state}.</Typography>
          </>
        );
      },
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      flex: 1,
    },
    {
      title: "Date",
      dataIndex: "DATE",
      width: 150,
      render: (data) => formatDate(data),
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      width: 150,
      render: (data) => formatPrice(data),
    },

    {
      title: "Discount",
      dataIndex: "discount",
      width: 150,
      render: (data) => formatPrice(data),
    },
    {
      title: "Grand Total",
      dataIndex: "grand_total",
      width: 150,
      render: (data) => formatPrice(data),
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_amount",
      width: 150,
      render: (data) => formatPrice(data),
    },
    {
      title: "Due",
      dataIndex: "due",
      width: 150,
      render: (data) => formatPrice(data),

      render: (dueAmount, record, index) => {
        return (
          <div>
            {record?.duePending === "N" ? (
              <p style={{ textAlign: "center" }}>{dueAmount}</p>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <p style={{ textAlign: "center" }}>{dueAmount}</p>
                  </div>
                  <div>
                    <Col>
                      <ButtonComponent
                        icon={
                          <span style={{ marginRight: "10px" }}>
                            <i className="icon icon-calendar" />
                          </span>
                        }
                        onClick={() => showModal(record)}
                        size="large"
                        type="primary"
                        label="collect"
                      />
                    </Col>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      },
    },

    // {
    //   title: "Remarks",
    //   dataIndex: "remarks",
    //   width: 150,
    // },
    // {
    //   title: "Ref By Doctor",
    //   dataIndex: "ref_doc_name",
    //   width: 150,
    // },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        const isCancle = tableData?.find((d) => Number(d?.status) === 3);
        return (
          <Row>
            {Number(record?.status) === 3 ? (
              <>
                <Col xs={24}>
                  <Typography className="cancled-text">
                    This payment was cancelled!.
                  </Typography>
                </Col>
                <div style={{ display: "flex" }}>
                  <Col>
                    <ButtonComponent
                      icon={<UndoOutlined />}
                      size="large"
                      onClick={() => {
                        handleUndo(record);
                      }}
                      label="Undo"
                      style={{ background: "rgb(0,128,0)", color: "white" }}
                    />
                  </Col>
                  <Col span={1}>
                    <Tooltip placement="top" title={record?.reason}>
                      <Button
                        icon={<ExclamationCircleOutlined />}
                        size="large"
                        type="primary"
                      >
                        Reason
                      </Button>
                    </Tooltip>
                  </Col>
                </div>
              </>
            ) : (
              <>
                <Col xs={isCancle ? 5 : 0}></Col>
                <Col xs={isCancle ? 15 : 24}>
                  <Row>
                    {access("billingPayment", "edit") && (
                      <Col xs={7}>
                        {" "}
                        <Tooltip placement="top" title={"Edit"}>
                          {" "}
                          <Link
                            to={{
                              pathname: APP_ROUTES.EDITBILLINGS,
                              state: record,
                            }}
                          >
                            <ButtonComponent
                              icon={<EditFilled />}
                              size="small"
                              type="primary"
                            />
                          </Link>
                        </Tooltip>
                      </Col>
                    )}

                    <Col xs={7}>
                      {" "}
                      <Tooltip placement="top" title={"Print"}>
                        {" "}
                        <Link
                          to={{
                            pathname: APP_ROUTES.INVOICEBILLING,
                            state: { record, button: "false" },
                          }}
                        >
                          <ButtonComponent
                            icon={<PrinterOutlined />}
                            size="small"
                            className="secondary-color"
                          />
                        </Link>
                      </Tooltip>
                    </Col>
                    <Col xs={7}>
                      {" "}
                      <Tooltip placement="top" title={"Invoice"}>
                        <Link
                          to={{
                            pathname: APP_ROUTES.INVOICEBILLING,
                            state: { record, button: "true" },
                          }}
                        >
                          <ButtonComponent
                            size="small"
                            icon={
                              <span>
                                <i className="icon icon-calendar" />
                              </span>
                            }
                            type="primary"
                          />
                        </Link>
                      </Tooltip>
                    </Col>
                    {access("billingPayment", "delete") && (
                      <Col xs={24}>
                        {/* <Popconfirm
                          title="Are you sure to delete?"
                          onConfirm={() => {
                            handleDelete(record);
                          }}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        > */}
                        <ButtonComponent
                          icon={<CloseSquareOutlined />}
                          size="large"
                          type="danger"
                          label="cancel"
                          onClick={() => {
                            showDeleteModel(record);
                          }}
                        />
                        {/* </Popconfirm> */}
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xs={isCancle ? 5 : 0}></Col>
              </>
            )}
          </Row>
        );
      },
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    if (
      billingPaymentreducer?.biilingList?.inProgress === true ||
      billingPaymentreducer?.deleteBilling?.inProgress === true
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [billingPaymentreducer]);
  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          type="patientBilling"
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          documentTitle={"Payment List"}
        />
        <CollectPaymentModal
          isVisible={isModalVisible}
          onCancel={handleCancel}
          onSubmit={handleModalSubmit}
          selectedBillingId={selectedBillingId}
          setNavigate={setNavigate}
        />
        <CancleModal
          isModalOpen={isModalOpen}
          handleConformCancle={handleConformCancle}
          handleSubmit={handleSubmit}
        />
        <Modal
          title="Delete Data"
          open={isdeleteModalOpen}
          onOk={handleOk}
          onCancel={handleDleteCancel}
          cancelButtonProps={{ className: "secondary-color" }}
        >
          <p>Are you sure, you want to delete the Data</p>
        </Modal>
      </Spin>
    </Auxiliary>
  );
};

export default BillingTable;
