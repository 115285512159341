import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography, Button } from "antd";
import "../../../styles/modules/pharmacy.less";
import {
  CreditCardOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import IntlMessages from "../../../util/IntlMessages";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Upload, message } from "antd";
import { addBulkMedicineInprogress } from "../../../appRedux/Pharmacy/PharmacyAction";

const BulkMedicine = () => {
  const dispatch = useDispatch();
  const addBulkMedicineData = useSelector(
    (state) => state?.pharmacy?.addBulkMedicine
  );

  const [form] = Form.useForm();
  const history = useHistory();

  const [currentFile, setCurrentFile] = useState(null);

  const onFinish = () => {
    if (!currentFile) {
      message.error("Please upload a file before submitting.");
      return;
    }
    const formData = { file: currentFile };

    dispatch(addBulkMedicineInprogress(formData));
    if (addBulkMedicineData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const [Loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleFileChange = (info) => {
    if (info.file.status === "removed") {
      setCurrentFile(null);
    } else if (info.file.type === "text/csv") {
      setCurrentFile(info.file);
    }
  };
  useEffect(() => {
    if (addBulkMedicineData?.success) {
      history.push("/medicine");
    }
  }, [addBulkMedicineData]);
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  <IntlMessages id={"label.addBulkMedicine"} />
                </Typography>
              </Col>

              <Col xl={8} lg={8} md={24} sm={24} xs={24} className="mt-30">
                <Form.Item
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.uploadFIle"} />
                  }
                  name="csv_file"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.csvfile"}
                        />
                      ),
                    },
                  ]}
                >
                  <Upload
                    accept=".csv"
                    maxCount={1}
                    onChange={handleFileChange}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                  </Upload>
                </Form.Item>

                {/* <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                  size="large"
                >
                  <Upload
                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    listType="picture"
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                  </Upload>
                </Space> */}
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col xl={23} align="right" className="button-alignment-pharmacy">
                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label="Submit"
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default BulkMedicine;
