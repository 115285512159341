import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  Typography,
  DatePicker,
  Checkbox,
} from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { getModulePermissionInProgress } from "../../../appRedux/super-admin/SuperAdminAction";
import { useDispatch, useSelector } from "react-redux";
const CheckboxGroup = Checkbox.Group;
const { Text } = Typography;
const AddPackage = ({ editPackage, editPackageData }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [packageValue, setPackageValue] = useState("");
  const [frontendValue, setFrontendValue] = useState("Yes");
  const modulePermissionData = useSelector(
    (state) => state?.superAdmin?.modulePermission
  );
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (datas) => {
    console.log("datas", datas, checkedList);
  };

  const [Loading, setLoading] = useState(false);
  const changeFrontend = (value) => {
    setFrontendValue(value);
  };

  const frontendList = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const modulePermissionList = modulePermissionData?.data?.data;
  const [checkedList, setCheckedList] = useState(
    modulePermissionList?.map((item) => item.value)
  );
  const onChange = (checkedList) => {
    setCheckedList(checkedList);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (editPackage) {
      form.setFieldsValue(editPackageData);
      setCheckedList(editPackageData?.permitted_modules);
    }
  }, [editPackage]);

  useEffect(() => {
    dispatch(getModulePermissionInProgress());
  }, []);
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  {!editPackage ? (
                    <IntlMessages id={"label.addPackage"} />
                  ) : (
                    <IntlMessages id={"label.editPackage"} />
                  )}
                </Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"package_name"}
                        label={
                          <IntlMessages
                            id={"Addnew.admin.label.package_name"}
                          />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.admin.validator.package_name"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"patient_limit"}
                        label={
                          <IntlMessages
                            id={"Addnew.admin.label.patient_limit"}
                          />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.admin.validator.patient_limit"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="text"
                        name={"doctor_limit"}
                        label={
                          <IntlMessages
                            id={"Addnew.admin.label.doctor_limit"}
                          />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.admin.validator.doctor_limit"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        type="number"
                        name={"price"}
                        label={<IntlMessages id={"Addnew.admin.label.price"} />}
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.admin.validator.price"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        label={
                          <IntlMessages
                            id={"Addnew.admin.label.show_in_frontend"}
                          />
                        }
                        name={"show_in_frontend"}
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.admin.validator.show_in_frontend"}
                              />
                            ),
                          },
                        ]}
                      >
                        <FormSelectBox
                          //   placeholder="Select Package"
                          value={frontendValue}
                          options={frontendList}
                          onChange={changeFrontend}
                        />
                      </Form.Item>
                    </Col>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Row>
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          // style={{ marginLeft: "2px" }}
                        >
                          <Typography.Title level={5}>
                            Module Permission
                          </Typography.Title>
                          <CheckboxGroup
                            style={{ width: "100%" }}
                            value={checkedList}
                            onChange={onChange}
                          >
                            <Row>
                              {modulePermissionList &&
                                modulePermissionList
                                  ?.map((item) => ({
                                    label: capitalizeFirstLetter(item.name),
                                    value: item.name,
                                  }))
                                  .map((item) => (
                                    <Col span={24} key={item.value}>
                                      <Checkbox value={item.value}>
                                        {item.label}
                                      </Checkbox>
                                    </Col>
                                  ))}
                            </Row>
                          </CheckboxGroup>
                          {/* {data.map((item) => (
                              <Col span={24}>
                                <Checkbox
                                  key={item.value}
                                  value={item.value}
                                  onChange={() => onChange(item.value)}
                                >
                                  {item.label}
                                </Checkbox>
                              </Col>
                            ))} */}
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.PACKAGES}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={editPackage ? "Save" : "Submit"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default AddPackage;
