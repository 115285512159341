import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  ADD_SCHEDULE_IN_PROGRESS,
  DELETE_SCHEDULE_IN_PROGRESS,
  GETDATA_SCHEDULE_IN_PROGRESS,
  REQUEST_SCHEDULE_IN_PROGRESS,
  UPDATE_SCHEDULE_IN_PROGRESS,
  WEEKDAY_SCHEDULE_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";

import {
  addScheduleError,
  addScheduleSuccess,
  deleteScheduleError,
  deleteScheduleSuccess,
  getDataScheduleError,
  getDataScheduleSuccess,
  requestScheduleError,
  requestScheduleSuccess,
  updateScheduleError,
  updateScheduleSuccess,
  weekDayScheduleError,
  weekDayScheduleSuccess,
} from "../actions/Schedule";
import {
  AddScheduleEffect,
  DeleteScheduleEffect,
  GetDataScheduleEffect,
  ScheduleEffect,
  UpdateScheduleEffect,
  WeekdayScheduleEffect,
} from "./effects/ScheduleEffect";

function* ScheduleWatcher({ payload }) {
  try {
    let { data } = yield call(ScheduleEffect, payload);

    yield put(
      requestScheduleSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      requestScheduleError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* AddScheduleWatcher({ payload }) {
  try {
    let { data } = yield call(AddScheduleEffect, payload);
    if (data?.scheduleInfo) {
      yield put(
        addScheduleSuccess({
          data: data,
          message: data["message"],
          success: true,
        })
      );
      yield call(Message, { type: "success", content: data.message });
    } else if (data.message) {
      yield call(Message, { type: "error", content: data.message });
    }
  } catch (e) {
    yield put(
      addScheduleError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* DeleteScheduleWatcher({ payload }) {
  try {
    let { data } = yield call(DeleteScheduleEffect, payload);

    yield put(
      deleteScheduleSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteScheduleError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* UpdateScheduleWatcher({ payload }) {
  try {
    let { data } = yield call(UpdateScheduleEffect, payload);

    yield put(
      updateScheduleSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      updateScheduleError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* GetDataScheduleWatcher({ payload }) {
  try {
    let { data } = yield call(GetDataScheduleEffect, payload);
    yield put(
      getDataScheduleSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getDataScheduleError({
        authError: true,
        message: e["message"],
      })
    );
  }
}
function* WeekdayScheduleWatcher() {
  try {
    let { data } = yield call(WeekdayScheduleEffect);

    yield put(
      weekDayScheduleSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      weekDayScheduleError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
export default function* ScheduleSaga() {
  yield takeLatest(REQUEST_SCHEDULE_IN_PROGRESS, ScheduleWatcher);
  yield takeLatest(ADD_SCHEDULE_IN_PROGRESS, AddScheduleWatcher);
  yield takeLatest(DELETE_SCHEDULE_IN_PROGRESS, DeleteScheduleWatcher);
  yield takeLatest(UPDATE_SCHEDULE_IN_PROGRESS, UpdateScheduleWatcher);
  yield takeLatest(GETDATA_SCHEDULE_IN_PROGRESS, GetDataScheduleWatcher);
  yield takeLatest(WEEKDAY_SCHEDULE_IN_PROGRESS, WeekdayScheduleWatcher);
}
