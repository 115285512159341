import axios from "axios";
import { axiosInstance } from "../../../../util/utilz";

export const getDoctorDashBoardEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/user/doctorDashBoard`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
